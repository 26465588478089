import React, { useEffect, useState } from "react";
import "./App.css";
import "../node_modules/waypoints/src/waypoint.js";
import "bootstrap-icons/font/bootstrap-icons.json";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import LinaerStepper from "./components/Apply_as_dev/MainForm";
import Signup from "./components/Apply_as_dev/signup.js";
import Apply_dev from "./components/Apply_as_dev/MainForm";
import { CssBaseline, Container, Paper, Box } from "@material-ui/core";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//
// home contents
import Home from "./components/Home";
import About from "./components/About";
import Fordevs from "./components/For_devs";
// login
// import Login from "./components/login/app_login.js";
import Forgotpassword from "./components/forgot-password";
import Card from "./components/cards";
import Design from "./components/design";

import Logins from "./components/login/login__.js";
import Login from "./components/login";
// import ProtectedRoute from './components/login/./ProtectedRoute';
// import ProtectedPage from './components/login/./ProtectedPage'

// job_description
import Ai_engineer from "./components/footer/Job_des/Ai_engineer";
import Android from "./components/footer/Job_des/Android";
import Angular_dev from "./components/footer/Job_des/Angular_dev";
import Application_dev from "./components/footer/Job_des/Application_dev";
import AWS from "./components/footer/Job_des/AWS";
import Back_end from "./components/footer/Job_des/Backend";
import Blackchain from "./components/footer/Job_des/Blockchain";
import Ci_cd_engineer from "./components/footer/Job_des/Ci_cd_engineer";
import Content_dev from "./components/footer/Job_des/Content_dev";
import Job_devops from "./components/footer/Job_des/Job_devops";


import Database_dev from "./components/footer/Job_des/Database_dev";
import Data_analyst from "./components/footer/Job_des/Data_analyst";
import Data_scientist from "./components/footer/Job_des/Data_scientist";
import Dot_net from "./components/footer/Job_des/Dot_net";
import Embedded_dev from "./components/footer/Job_des/Embedded_dev";
import Flutter_dev from "./components/footer/Job_des/Flutter_dev";
import Front_end_dev from "./components/footer/Job_des/Front_end_dev";

import Full_stack from "./components/footer/Job_des/Full_stack";
import HTML from "./components/footer/Job_des/HTML";
import Ios_dev from "./components/footer/Job_des/Ios_dev";
import Java_script from "./components/footer/Job_des/java_script";
import Java from "./components/footer/Job_des/Java";
import Machine_dev from "./components/footer/Job_des/Machine_dev";
import Mongo_db from "./components/footer/Job_des/Mongodb";
import Node from "./components/footer/Job_des/Node";
import Python from "./components/footer/Job_des/python";
import Qa_engineer from "./components/footer/Job_des/Qa_engineer";
import Reactjs from "./components/footer/Job_des/React";


import Ruby from "./components/footer/Job_des/ruby";
import Scala_dev from "./components/footer/Job_des/Scala_dev";
import Senior_java from "./components/footer/Job_des/Senior_java";
import Senior_software from "./components/footer/Job_des/Senior_software";
import Software_engineer from "./components/footer/Job_des/Software_engineer";
import SQL from "./components/footer/Job_des/Sql";
import Ui_ux_designer from "./components/footer/Job_des/Ui_ux_designer";
import Unity3d_dev from "./components/footer/Job_des/Unity3d_dev";
import Vue from "./components/footer/Job_des/Vue";
import Wordpress_dev from "./components/footer/Job_des/Wordpress_dev";
import Cplus from "./components/footer/Job_des/c++";

import Typescript from "./components/footer/Job_des/Typescript";

// Hire devs
import Hire_android from "./components/footer/Hire_dev/hire_android";
import Hireangular from "./components/footer/Hire_dev/Hire_angular";
import Hire_ios from "./components/footer/Hire_dev/hire_ios";
import ReactNative from "./components/footer/Job_des/Reactnative";
import Hire_aws from "./components/footer/Hire_dev/hire_aws";
import React_Native from "./components/footer/Hire_dev/react_native";
import Hire_devops from "./components/footer/Hire_dev/hire_devops";
import Hire_flutter from "./components/footer/Hire_dev/hire_flutter";
import Hire_net from "./components/footer/Hire_dev/hire_net";
import Hire_php from "./components/footer/Hire_dev/hire_php";
import Ecommerce from "./components/footer/Hire_dev/ecommercw";
import Button from "./components/buttons";

import React_js from "./components/footer/Hire_dev/hire_react";
import Hire_part_time from "./components/footer/Hire_dev/hire_part_time";
import Hire_python from "./components/footer/Hire_dev/hire_python";
import Hire_mobile_app from "./components/footer/Hire_dev/hire_mobile_app";
import Hire_frontend from "./components/footer/Hire_dev/hire_frontend";
import Hire_javascript from "./components/footer/Hire_dev/hire_javascript";
import Hire_blockchain from "./components/footer/Hire_dev/hire_blockchain";
import Hire_offshore from "./components/footer/Hire_dev/hire_offshore";
import Saas_js from "./components/footer/Hire_dev/hire_saas";
import Jquery from "./components/footer/Hire_dev/hirer_jquery";
import Vue_js from "./components/footer/Hire_dev/hire_vue";
import Rube_js from "./components/footer/Hire_dev/hire_rube";
import Senior_js from "./components/footer/Hire_dev/hire_senior";
import Lead_js from "./components/footer/Hire_dev/hire_lead";
import Startup_js from "./components/footer/Hire_dev/hire_startup";
import BackEnd_js from "./components/footer/Hire_dev/hire_backend";
import Hire_spring from "./components/footer/Hire_dev/hire_spring";
import Hire_swift from "./components/footer/Hire_dev/hire_swift";
import Hire_iphone from "./components/footer/Hire_dev/hire_iphone";
import Kotlin_js from "./components/footer/Hire_dev/hire_kotlin";
// import Datascientist_js from "./components/footer/Hire_dev/hire_datascience.js";
// import DataScientist_js from "./components/footer/Hire_dev/hire_datascience";
import Architects_js from "./components/footer/Hire_dev/hirearchitect";
import FullStack_js from "./components/footer/Hire_dev/hire_fullstack";
import Software_js from "./components/footer/Hire_dev/hire_software";
import Hire_machine_learning from "./components/footer/Hire_dev/hire_machine_learning";
import Hire_typescript from "./components/footer/Hire_dev/hire_typescript";
import Hire_node from "./components/footer/Hire_dev/hire_node";
import Hire_remote_dev from "./components/footer/Hire_dev/hire_remote_dev";
import SeniorAndroid_js from "./components/footer/Hire_dev/hire_software_dev";
// import 'testimonials/dist/types/components/testimonials/ShowMoreWrapper'
// import 'testimonials/dist/types/const'
// import 'testimonials/dist/types/exports/react'
// import 'testimonials/dist/types/hooks'
// import 'testimonials/dist/esm/components'
// import 'testimonials/dist/esm/const'
// import 'testimonials/dist/esm/exports'
// import 'testimonials/dist/esm/hooks'

import { gapi } from "gapi-script";
import Terms from "./components/terms_use";
import Career from "./components/career";
import Contact from "./components/contact";
import Privacy from "./components/privacy_policy";
import Faq_business from "./components/faq_for_business";
import Faq_dev from "./components/faq_for_dev";
import Review_dev from "./components/review_dev";
import OTPinput from "./components/OTPinput";
import Hire_software_dev from "./components/footer/Hire_dev/hire_software_dev";
import Forgot from "./components/forgot.js";
import Recovered from "./components/Recovered";
// -------------------------------------------------------------------------------------

import AdminLogin from "./components/admin/AdminLogin";

import Pro from "./components/admin/pro";
import Dash from "./components/admin/dash";
import Deve from "./components/admin/deve";
import Clint from "./components/admin/clint";
import ClienUpdate from "./components/admin/clienUpdate.js"
import Add_pro from "./components/admin/add_pro";
import AdminHeader from "./components/admin/AdminHeader";

import Application from "./components/Apply_as_dev/Application";
import MyApplication from "./components/Apply_as_dev/Application/MyApplication";
import MyExperience from "./components/Apply_as_dev/Application/MyExperience";

import Profile from "./components/Apply_as_dev/Application/Profile";

import Project from "./components/Apply_as_dev/Application/Project";


import FormValidation from "./components/sample";
import { createContext } from "react";
import Datascience from "./components/footer/Hire_dev/hire_datascience";
import HireMainForm from "./components/hire-talent/hireMainForm";
import Developerlist from "./components/admin/Developerlist.js"
import AddProject from "./components/Apply_as_dev/Application/AddProject.js/addProject.js"
import Education from "./components/Apply_as_dev/Application/education";
import Header from "./components/Header";
import Clientedit from "./components/admin/Clientedit.js"
import Pdf_view from "./components/admin/Pdf_view.js"
import Developer_pdf from "./components/Apply_as_dev/Developer_pdf.js"
const clientId =
  "335970032579-6ng8ulmlcbhoa208jqrc8elmetfq0tad.apps.googleusercontent.com";
export const RecoveryContext = createContext();

function App() {

  const [page, setPage] = useState("Forgotpassword");
  const [email, setEmail] = useState();
  const [otp, setOTP] = useState();
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  return (
    <RecoveryContext.Provider
      value={{ page, setPage, otp, setOTP, setEmail, email }}
    >
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route path="/apply_devs" element={<Apply_dev />} />
            <Route path="/" element={<Home />} />
            <Route path="FormValidation" element={<FormValidation />} />
            <Route path="/application" element={<Application />} />
            <Route path="/about" element={<About />} />
            <Route path="/for_devs" element={<Fordevs />} />
            <Route path="/login" element={<Login />} />
            <Route path="/header" element={<Header />} />
            <Route path="/logins" element={<Logins />} />
            {/* <ProtectedRoute path="/protected" component={ProtectedPage} /> */}
            <Route path="/hire" element={<HireMainForm />} />
            <Route path="/otp" element={<OTPinput />} />


            <Route path="/ruby" element={<Ruby />} />
            <Route path="/Ai_engineer" element={<Ai_engineer />} />
            <Route path="/android" element={<Android />} />
            <Route path="/Angular_dev" element={<Angular_dev />} />
            <Route path="/Application_dev" element={<Application_dev />} />
            <Route path="/aws" element={<AWS />} />
            <Route path="/back-end" element={<Back_end />} />
            <Route path="/Blockchain" element={<Blackchain />} />
            <Route path="/Ci_cd_engineer" element={<Ci_cd_engineer />} />
            <Route path="/c++" element={<Cplus />} />
            <Route path="/signs" element={<Signup />} />
            <Route path="/Content_dev" element={<Content_dev />} />
            <Route path="/database_dev" element={<Database_dev />} />
            <Route path="/data_analyst" element={<Data_analyst />} />
            <Route path="/data_scientist" element={<Data_scientist />} />
            {/* <Route path="/data_analysis" element={<Data_analyst />} /> */}
            <Route path="/dot_net" element={<Dot_net />} />
            <Route path="/Embedded_dev" element={<Embedded_dev />} />
            <Route path="/Flutter_dev" element={<Flutter_dev />} />
            <Route path="/Full_stack" element={<Full_stack />} />
            <Route path="/front_end_dev" element={<Front_end_dev />} />
            <Route path="/HTML" element={<HTML />} />
            <Route path="/Ios_dev" element={<Ios_dev />} />
            <Route path="/java_script" element={<Java_script />} />
            <Route path="/java" element={<Java />} />
            <Route path="/Machine_dev" element={<Machine_dev />} />
            <Route path="/mongoDB" element={<Mongo_db />} />
            <Route path="/node" element={<Node />} />
            <Route path="/python" element={<Python />} />
            <Route path="/Qa_engineer" element={<Qa_engineer />} />
            <Route path="/reactjs" element={<Reactjs />} />
            <Route path="/reactnative" element={<ReactNative />} />

            <Route path="/Scala_dev" element={<Scala_dev />} />
            <Route path="/Senior_java" element={<Senior_java />} />
            <Route path="/Senior_software" element={<Senior_software />} />
            <Route path="/sql" element={<SQL />} />
            <Route path="/Job_devops" element={<Job_devops />} />
            <Route path="/Software_engineer" element={<Software_engineer />} />
            <Route path="/Typescript" element={<Typescript />} />
            <Route path="/Ui_ux_designer" element={<Ui_ux_designer />} />
            <Route path="/Unity3d_dev" element={<Unity3d_dev />} />
            <Route path="/Wordpress_dev" element={<Wordpress_dev />} />
            <Route path="/vue" element={<Vue />} />
            {/* <ScrollToTop /> */}
            <Route path="/hireangular" element={<Hireangular />} />
            <Route path="/card" element={<Card />} />
            <Route path="/des" element={<Design />} />

            {/* hire dev */}
            <Route path="/hire_android" element={<Hire_android />} />
            <Route path="/hire_angular" element={<Hireangular />} />
            <Route path="/hire_aws" element={<Hire_aws />} />
            <Route path="/hire_net" element={<Hire_net />} />
            <Route path="/react_js" element={<React_js />} />
            <Route path="/button" element={<Button />} />
            <Route path="/hire_flutter" element={<Hire_flutter />} />
            <Route path="/hire_php" element={<Hire_php />} />
            <Route path="/react_native" element={<React_Native />} />
            <Route path="/devop" element={<Hire_devops />} />
            <Route path="/hire_ios" element={<Hire_ios />} />
            <Route path="/ruby" element={<Ruby />} />
            <Route path="/Ecommerce" element={<Ecommerce />} />
            <Route path="/React_js" element={<React_js />} />
            <Route path="/Hire_part_time" element={<Hire_part_time />} />
            <Route path="/Hire_python" element={<Hire_python />} />
            <Route path="/Hire_mobile_app" element={<Hire_mobile_app />} />
            <Route path="/Hire_frontend" element={<Hire_frontend />} />

            <Route path="/Hire_javascript" element={<Hire_javascript />} />
            <Route path="/Hire_blockchain" element={<Hire_blockchain />} />
            <Route path="/Hire_offshore" element={<Hire_offshore />} />
            <Route path="/hire_Saas" element={<Saas_js />} />
            <Route path="/hire_Jquery" element={<Jquery />} />
            <Route path="/hire_vue" element={<Vue_js />} />
            <Route path="/hire_Rube" element={<Rube_js />} />
            <Route path="/hire_Senior_developer" element={<Senior_js />} />
            <Route path="/hire_tech_lead" element={<Lead_js />} />
            <Route path="/hire_startup" element={<Startup_js />} />
            <Route path="/hire_backend" element={<BackEnd_js />} />
            <Route path="/hire_spring" element={<Hire_spring />} />
            <Route path="/hire_swift" element={<Hire_swift />} />
            <Route path="/hire_iphone" element={<Hire_iphone />} />
            <Route path="/hire_kotlin" element={<Kotlin_js />} />
            <Route path="/hire_datascience" element={<Datascience />} />
            <Route path="/hire_architect" element={<Architects_js />} />
            <Route path="/hire_senior_android" element={<SeniorAndroid_js />} />
            <Route path="/hire_fullstack" element={<FullStack_js />} />
            <Route path="/hire_software" element={<Software_js />} />
            <Route
              path="/hire_machine_learning"
              element={<Hire_machine_learning />}
            />
            <Route path="/hire_typescript" element={<Hire_typescript />} />
            <Route path="/hire_node" element={<Hire_node />} />
            <Route path="/hire_iphone" element={<Hire_iphone />} />
            <Route path="/hire_remote_dev" element={<Hire_remote_dev />} />
            <Route path="/hire_software_dev" element={<Hire_software_dev />} />
            {/* <Route path="/Hire_part_time" element={<Hire_part_time />} /> */}

            <Route path="/apply" element={<LinaerStepper />} />
            {/* <Route path="/hire" element={<Form />} /> */}
            {/* <Route path="/HIRE" element={<HorizontalLinearStepper />} /> */}

            <Route path="/terms" element={<Terms />} />
            <Route path="/career" element={<Career />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/faq_for_business" element={<Faq_business />} />
            <Route path="/faq_dev" element={<Faq_dev />} />
            <Route path="/abouts" element={<About />} />
            <Route path="/review" element={<Review_dev />} />

            {/* <Route path="/react_native" element={<React_native />} /> */}
            <Route path="/otp" element={<OTPinput />} />
            <Route path="/Forgotpassword" element={<Forgotpassword />} />
            <Route path="/forgot/:id" element={<Forgot />} />
            <Route path="/recovered" element={<Recovered />} />

            <Route path="/adminlogin" element={<AdminLogin />} />

            <Route path="/Clint" element={<Clint />} />
            <Route path="/Pro" element={<Pro />} />
            <Route path="/Dash" element={<Dash />} />
            <Route path="/Deve" element={<Deve />} />
            <Route path="/Add_pro" element={<Add_pro />} />
            <Route path="/myapplication" element={<MyApplication />} />
            <Route path="/myexperience" element={<MyExperience />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/project" element={<Project />} />
            <Route path="/adminHeader" element={<AdminHeader />} />
            <Route path="/addproject" element={<AddProject />} />
            <Route path="/education" element={<Education />} />
            <Route path="/Clientedit/:id" element={<Clientedit />} />
            <Route path="/clientupdate/:id" element={<ClienUpdate />} />
            <Route path="/Developerlist/:id" element={<Developerlist />} />
            <Route path="/Pdf_view" element={<Pdf_view />} />
            <Route path="/Developer_pdf" element={<Developer_pdf />} />

          </Routes>
        </div>
        <script src={require("./js/custom")}></script>
      </BrowserRouter>

    </RecoveryContext.Provider>
  );
}

export default App;
