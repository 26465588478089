import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useNavigate } from 'react-router-dom'



function Career() {
  const navigate = useNavigate();
  return (

    <>
      <Header />
      <div className="top">

        <div className="container car">
          <h1 className="career">Alan Tech <br /><span>Open Position</span></h1><br />
          <button type="button" className="btn btn-primary" onClick={() => navigate('/hire')}  >Enter the devs</button>
        </div>
      </div>

      <h3 className="read">Have you ever heard about Alan tech before?</h3>
      <section id="careers">
        <div className="container  mt-4" data-aos="">

          <div className="row">

            <div className="col-lg-6 col-md-12 " data-aos="fade-right" data-aos-delay="100">
              <p><b>If you have, here’s what it probably was:</b></p>
              <p> 1. “Their website and brand are just so freaking cool.
                Check it out: AlanTech/</p><p>2. “I love their CEO’s Twitter! They’re completely transparent and share
                  everything, including actual numbers.”</p><p>3. “I know several developers who work with AlanTech. All of them
                    said that AlanTech’s team is amazing and the clients they’ve been connected with are the best they’ve ever
                    had.”</p><p>4. “I read an article about them on Forbes.”</p><p>5. “My friend works at AlanTech and they’re really
                      chill. Their team is fully remote and a lot of team members work from different cities and even countries,
                      but they make sure they also get to have offline events & people come from all over the world.”</p><p> 6.
                        “Their business model is cool. They connect devs with American startups for full-time projects. Their rates
                        for developers are above the market average and they have a zero-fee policy for programmers.”</p><p>7. They’ve
                          paid out more than $8 000 000 to Ukrainian developers to date, brewed over 3000 bottles of craft beer to
                          celebrate that occasion, and just gave it all out to the IT community for free.</p>
            </div>

            <div className="col-lg-6 col-md-12 " data-aos="fade-left" data-aos-duration="1000">
              <p><b>And if you haven’t heard of us yet... well, now you have :</b></p><p>What people may not know about us is that
                we’re also one of the few Ukrainian companies that are in the startup game for real. We play on the global
                market competing with giant international corporations.</p><p>It’s not an easy game, but if you’re fast &
                  eager, not a single Goliath stands a chance against you.</p><p>We try our best to keep our team small and only
                    hire the best talent we can’t live without.</p><p>Thanks to that, we can make sure that:</p><p>1. You will forget
                      about bureaucracy and micromanagement</p><p>2. You will have a direct impact on what’s going on. Not just on
                        paper.</p><p>3. You will have plenty of room to grow.</p>
            </div>
          </div>

        </div>
      </section>

      <section id="featured-services" className="featured-services pb-1">
        <div className="container" data-aos="fade-up" data-aos-duration="1000">

          <div className="row my-5 ">
            <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
              <div className="icon-box " data-aos="" data-aos-delay="100">
                <div className="mt-2 d-flex justify-content-center">
                  <img src={require('../images/hire_devs/job_img1-removebg-preview.png')} height="100%" width="100%" alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box " data-aos="" data-aos-delay="200">
              <div className="">
                <img src={require('../images/hire_devs/mo19-removebg-preview.png')} height="100%" width="100%" alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4  d-flex align-items-stretch mb-5 mb-lg-0" >
            <div className="icon-box " data-aos="" data-aos-delay="300">
              <div className="">
                <img src={require('../images/hire_devs/mo7-removebg-preview.png')} height="100%" width="100%" alt="" />
              </div>
            </div>
          </div>
          </div>
    
      
          </div>
      </section>
      <section id="featured-services" className="featured-services pb-1">
        <div className="container " data-aos="">
          <div className="row my-5">
            <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"style={{margin:"auto"}}>

            </div>
          </div>
        </div>
      </section>
      <section id="featured-services" className="featured-services pb-1">
        <div className="container  text-center" data-aos="fade-right" data-aos-duration="1000">
          <h3>Jop Opening</h3>
          <img src={require('../images/hire_devs/nojob.webp')} height="40%" width="40%" alt="" style={{margin:"auto"}}/>
          <h5 className="mt-5">Sorry, no job openings at the moment.</h5>
          <h6>We open new jobs from time to time, so please check again soon!</h6>
          <div className="social pt-5">
            <a href="https://twitter.com/AlanTechnologies" className="twitter ps-5" target="_blank"><i className="bi bi-twitter"></i></a>
            <a href="https://www.facebook.com/alantechnnologies" className="facebook ps-5" target="_blank"><i
              className="bi bi-facebook"></i></a>
            <a href="https://www.instagram.com/alan_technologies/?hl=en" className="instagram ps-5" target="_blank"><i
              className="bi bi-instagram"></i></a>
          </div>
          <div className="pt-5">
            <a href="">
              <h6>View websites . Help</h6>
            </a>
            <span>
              <h6>Powered by</h6> <a href="">
                <h6>workable . Accessibility </h6>
              </a>
            </span>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Career;
