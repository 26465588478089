import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate } from "react-router-dom";
import { Container, Typography, TextField, Box, Grid, FormControl } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";



function CvSamples() {
  const [developer, setDeveloper] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  function SweetAlert() {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "linkedIn Updated",
      confirmButtonText: "OK",
      timer: 8000,
    }).then(async (result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    })
  }

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setDeveloper({
      ...developer,
      [name]: value,
    });
  };
  useEffect(() => {
    const fetchDate = async () => {
      const token = localStorage.getItem("token");
      const user_id = localStorage.getItem('user_id')
      if (token == null) {
        navigate("/login");
      } else {
        const response = await axios.get(`${process.env.REACT_APP_URL}/profile`, {
          headers: {
            'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
            "Auth-Key": process.env.REACT_APP_AUTH_KEY,
            'User-id': user_id,
            'Authorization': token
          }
        })
        if (response.status == 200) {
          setDeveloper(response.data.data)
          setLoading(false);
        }
        else {
          setLoading(false)
          alert('sorry, something went wrong please try after sometime')
        }
      }
    }
    fetchDate()
  }, []);

  async function updateCv() {
    setLoading(true);
    const formData = new FormData();
    formData.append('linkedIn', developer.linkedIn)
    formData.append('image', file);

    try {
      const token = localStorage.getItem('token')
      const user_id = localStorage.getItem('user_id');
      const response = await axios.put(`${process.env.REACT_APP_URL}/developers`, {
        id: developer.id,
        linkedIn: developer.linkedIn
      },
        {
          headers: {
            'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
            "Auth-Key": process.env.REACT_APP_AUTH_KEY,
            'User-id': user_id,
            'Authorization': token
          }
        }

      );
      if (response.status == 200) {
        setLoading(false)
        SweetAlert();
      }
      else {
        setLoading(false)
        alert('sorry, something went wrong please try after sometime')
      }
    } catch (error) {
      console.log(error);
    }
  }
  const box = {
    width: {
      lg: 1000
    },
    height: {
      lg: '100%',
      md: '100%',
      xs: '100%'
    }

  };


  const [file, setFile] = useState('');
  const [fname, setFname] = useState('')
  const onInputChange = (e) => {
    if (e.target.files.length > 0) {
      setFname(e.target.files[0])
      setFile(URL.createObjectURL(e.target.files[0]));
    }
  }

  if (loading) {
    return (<div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    )
  }

  const token = localStorage.getItem('token');
  const user_id = localStorage.getItem('user_id');

  const handlesubmit = async (e) => {
    const formData = new FormData()
    formData.append("userfile", fname)
    e.preventDefault();
    try {
      const response = await axios.post("https://dev.alantechnologies.in/api/v1/Profile/docmentupload", formData, {
        headers: {
          'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
          'Auth-Key': process.env.REACT_APP_AUTH_KEY,
          'User-id': user_id,
          'Authorization': token,
        },

      })
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Uplaoded  successful.',
        });
        window.location.reload()

      } else {
        console.log(response.error, "error");
      }

    } catch (error) {
      console.log(error, "error")
    }

  }



  const handleClick = () => {
    navigate(`/Developer_pdf?pdfUrl=${encodeURIComponent(`https://dev.alantechnologies.in/uploads/${developer.cvUpload}`)}`);
  }


  return (
    <Box>
      <Container sx={box}>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              CvSamples
            </Typography>
          </Grid>

          <Grid item xs={12} lg={12}>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    label="Linkedin"
                    name="linkedIn"
                    value={developer.linkedIn}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={4}>
                <form onSubmit={handlesubmit} encType="multipart/formdata">
                  
                  <Button
                    sx={{ py: { lg: 2, md: 2, xs: 1 } }}
                    fullWidth
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    {fname ? fname.name : 'Upload cv'}
                    <VisuallyHiddenInput type="file" accept="/pdf" onChange={onInputChange} />
                  </Button>
                  <Grid item xs={12} lg={12}>
                    {
                      file ? (
                        <button
                          startIcon={<CloudUploadIcon />}
                          fullWidth
                          className="mt-3 cv-button w-50"
                          component="label"
                          type="submit"
                          variant="contained"
                        >
                          Submit
                        </button>
                      ) : (
                        null
                      )
                    }
                  </Grid>
                </form>
              </Grid>

            </Grid>


          </Grid>

          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={updateCv}
              sx={{ py: { lg: 2, md: 2, xs: 1 }, bgcolor: " #294b86 !important", color: "white" }}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={6}>
            {
              developer.cvUpload ? (
                <Button
                  fullWidth
                  onClick={handleClick}
                  sx={{ py: { lg: 2, md: 2, xs: 1 }, bgcolor: " #294b86 !important", color: "white" }}
                >
                  See Your Previous Cv
                </Button>

              ) : (
                null
              )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default CvSamples;
