import React, { useEffect } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Card from "../../cards";
import { useNavigate } from 'react-router-dom'
function Front_end_dev() {
    const navigate = useNavigate();
    return (
        <>
            <Header />
            <section id="job_front" className="faq heros d-flex align-items-center">
                <div className="container aos-init aos-animate" data-aos="fade-right"  data-aos-duration='1000'>
                    <h1>FRONT-END DEVELOPER<br /><span>JOB DESCRIPTION</span></h1>
                    <br />
                </div>
            </section>

            <section className="heros ">
 <div className="greybg">
 <div className="container pt-3 " data-aos="fade-right" data-aos-duration='1000'>
                    <p>
                        There are more than ten million job openings in the United States every month,
                        but only seven million people are looking for a full-time job, Statista reports.
                        The first step to hiring a good software engineer is to write a proper description
                        of the skills necessary and the responsibilities this person would have to be a
                        part of your company. This is what we call a job description. It is a list of
                        requirements an employee is expected to meet to get a job and the working conditions
                        the company offers when hiring. </p>

                    <p> Here is an example of a front-end developer job description structure:</p>

                    <p> <span className="text-primary fs-5">✔</span> Job brief</p>

                    <p> <span className="text-primary fs-5">✔</span> Responsibilities</p>

                    <p><span className="text-primary fs-5">✔</span> Requirements and skills </p>

                    <p><span className="text-primary fs-5">✔</span> Frequently asked questions </p>

                   
                </div>
                <div id="mediabutton">
                <button className="col-sm-4 btn btn-primary mb-3 shadow-lg" onClick={() => navigate('/hire')}>grab a front-end dev!</button>

                </div>
 </div>

                <div className="container mt-4" data-aos='fade-up'  data-aos-duration='1000'  >
                <h2>Job brief</h2>
                        <p> To start, write a few words about the position you are hiring for. It is what we call a job
                            brief.</p>
                            <h2>For example:</h2>
                           <p> Say some words about your company and its specialization, who you are looking for</p>
                     <p>The Big Red Software Company is hiring front-end developers. We are looking for a specialist
                            to join our team of professionals to work with the web interface’s design, content, and
                            functionality.</p>
                    <div className="row">
                        <div className="col-lg-6 col-md-12"  data-aos="fade-right"  data-aos-duration='1000'>
                            <h2 className=" ">Front-end developer<span> Responsibilities</span></h2>
                            <p> Next, you want to outline the responsibilities and tasks of a new team member. Say some words
                                about the needs your company intends to fulfill in a front-end engineer position and the
                                required education, experience, and skills for someone who wants to work with you.
                            </p>
                            <h2>For example:</h2>

                            <p>The responsibilities of the front-end web developer in our company are: </p>

                            <p> <span className="text-primary fs-5">✔</span>  To work with the web interface’s design, content, and functionality;</p>

                            <p> <span className="text-primary fs-5">✔</span>  Communicate with a development manager, other developers, and
                                clients;</p>

                            <p> <span className="text-primary fs-5">✔</span>  From a senior developer, we expect to contribute meaningfully to important
                                decisions and be willing and capable of mentoring other developers at any level when
                                appropriate.</p>
                        </div>
                        <div className="col-lg-6 col-md-12 "  data-aos="fade-left"  data-aos-duration='1000'>
                            <h2>Front-end Developer<span> Requirements and Skills</span></h2>
                            <p>For hiring front-end developers, their knowledge and experience are significant. To make sure the right person
                                will join your company, you need to know what skills are necessary for a frontend engineer and the background
                                work and education you expect them to have.
                            </p>
                            <h2>For example:</h2>

                            <p> Here are the requirements and skills needed for a potential front-end programmer:</p>

                            <p> <span className="text-primary fs-5">✔</span>  Front-end web developer education or any other in the same area;</p>

                            <p> <span className="text-primary fs-5">✔</span>  Experience in working with Angular, React, Vue.js; </p>

                            <p> <span className="text-primary fs-5">✔</span> Minimum three years of experience working as a front-end engineer or in a
                                similar department;</p>

                         </div>
                         <div id="mediabutton">
                         <button className=" col-sm-4 btn btn-primary shadow-lg " onClick={() => navigate('/hire')}>need a front-end ace?</button>
                        
                         </div>
                    </div>
                </div>
                <div className="container mt-3">
                    <h2 className="" data-aos="fade-right"  data-aos-duration='1000'>Job description example</h2>
                    <div data-aos='fade-up'  data-aos-duration='1000'>
                    <p > The Big Red Software company is looking for a front-end developer. They will join our team of
                        specialists and work with the web interface’s design, content, and functionality.</p> <p> HTML, JavaScript,
                        and CSS will help them adapt websites to different screen formats and make them look appealing. The
                        responsibilities: communicating with the back-end programmers team for more efficient work and with a client
                        to find the best way to meet his needs and expectations and create a convenient-to-use website or web
                        application.</p>
                         <p> This is an excellent chance to move forward in your career and find a great team of professional
                        colleagues.</p>  
                          <p>We offer decent pay and other pleasant additions.
                     Flexible working hours, vacations,
                        social security, home office, and working from home, courses — we will get you covered. </p>
                        </div>
                    <h2 className="mt-4"  data-aos="fade-left" data-aos-duration='1000'>The responsibilities of the <span>Front-end Web Developer</span> in our company are: </h2>
                   <div  data-aos="fade-up" data-aos-duration='1000'>
                    <p> <span className="text-primary fs-5">✔</span> Working with the web interface’s design, content, and functionality;</p>

                    <p> <span className="text-primary fs-5">✔</span>  Communicating with a development manager, other developers, and clients;</p>


                    <p> <span className="text-primary fs-5">✔</span>  Meaningful contributions to important decisions, will, and capability of
                        mentoring other developers at any level when appropriate.</p>
                        </div>
                    <h2 className="mt-4" data-aos="fade-right" data-aos-duration='1000'>Here are the requirements and skills needed for our potential <span>Front-end Programmer:</span>
                    </h2>
                    <div data-aos='fade-up' data-aos-duration='1000'>
                    <p> <span className="text-primary fs-5">✔</span> Front-end web developer education or any other in the same area;</p>


                    <p> <span className="text-primary fs-5">✔</span>  Minimum three years of experience working as a front-end engineer or in a
                        similar area; </p>

                    <p> <span className="text-primary fs-5">✔</span>  Deep knowledge of coding languages such as HTML, JavaScript, and CSS. Being
                        familiar with web development frameworks and libraries is also would be necessary.</p>
                        </div>
                </div>


               <div className="mt-3">
                <Card />
                </div> 
            </section>
            <section id="about" className="front mb-5">
                <div className="container">
                    <div className="col-12">
                        <div className="jumbotron bg-body-tertiary mx-4 p-4 " data-aos="fade-up" data-aos-onDurationChange='1000'>
                            <h2 className="text-center">HIRE AN <span>EXPERIENCED DEVELOPER</span> WITHIN 2 WEEKS</h2>

                            <div className="row">
                                <div className="d-flex col-12 col-lg-6">
                                    <img src={(require('../../../images/job_description/donna-scrivania jpg.jpg'))} className="img-fluid" alt="devs" />
                                </div>
                                <div className=" col-12 col-lg-6  my-5 ">
                                    <p> Pre-vetted. With proven experience and resonable rate expectations.</p>
                             <div id="mediabutton ">
                             <buttoon> <a className="btn btn-primary btn col-12 col-lg-4 col-md-5 mx-3" href="#" role="button" onClick={() => navigate('/hire')}>Connect me with a Dev</a>
                                   </buttoon>
                             </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/* <!-- ======= Frequently Asked Questions Section ======= --> */}
            <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up" data-aos-duration='1000'>

                    <div className="section-title">
                        <h2>F.A.Q</h2>
                        <h3>Frequently Asked <span>Questions</span></h3>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <ul className="faq-list">

                                <li>
                                    <div data-bs-toggle="collapse" className="collapsed question" href="#faq1">What does a front-end developer
                                        do?<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            The front-end developer is responsible for everything users see and click on web pages or web
                                            applications. He uses HTML, JavaScript, and CSS to program functional and beautiful interfaces,
                                            that work on any screen. The front-end engineer communicates with the team of back-end developers
                                            and the client.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq2" className="collapsed question">What is the difference between
                                        a front-end developer and a back-end developer?<i className="bi bi-chevron-down icon-show"></i><i
                                            className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            These two roles are closely related, and even more, they need to work together for a
                                            well-operating site, but here are the differences. A front-end developer works with the
                                            client-side of the website or web application and makes it easy to use and pleasant to look at,
                                            while a back-end developer works with the server-side technologies and makes the website
                                            functional.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq3" className="collapsed question">What makes a good front-end web
                                        developer? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                                    </div>
                                    <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            First of all, a desire to be a front-end web programmer. Then education and experience are
                                            required, such as web-design education or any other in a similar area. More than three years of
                                            experience as a front-end web developer. Other necessary skills are fluency in HTML, JavaScript,
                                            and CSS programming languages, communication, and problem-solving skills.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq4" className="collapsed question">Who does a front-end web
                                        developer work with? <i className="bi bi-chevron-down icon-show"></i><i
                                            className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            Most of the time, front-end engineers will communicate with two sides. First, with the development
                                            manager and the back-end developers team. It is supposed to keep work on a project coordinated and
                                            make a website or web application fully functional. And second, with a client to listen to all of
                                            his requests and try to satisfy them.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq5" className="collapsed question">Do front-end developers need to
                                        know design? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                                    </div>
                                    <div id="faq5" className="collapse" data-bs-parent=".faq-list">

                                        <p>
                                            It is not necessary but beneficial. Working with UI/UX designers to make the website’s appearance
                                            visually appealing is a big part of being a front-end programmer. The coder doesn’t need to know
                                            everything about design. Still, it will be helpful for him and his team to fix design problems or
                                            discuss different design questions and ideas and make a visually appealing product. The front-end
                                            engineer will build a user-facing area of a website, he will be responsible for choosing the
                                            proper framework for the website and ensuring the structure, data, navigation, and other
                                            functional and visual elements on the page look harmonic and aesthetic.
                                        </p>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </section>

            {/* <!-- End Frequently Asked Questions Section --></br> */}
            <Footer />
        </>

    )
}


export default Front_end_dev