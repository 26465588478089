import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RecoveryContext } from "../App";
import axios from 'axios';
import { Container, Paper,Box,Typography, TextField, Grid,Button } from "@mui/material";

import OTPInput, { ResendOTP } from "otp-input-react";
import Swal from "sweetalert2";


export default function () {

  const [email,setEmail]=useState('');
  const [timerCount, setTimer] = React.useState(60);
  const [disable, setDisable] = useState(true);
  
  const location=useLocation();

  const navigate=useNavigate();
  
  const [otp, setOtp] = useState("");

  function onSubmit() {
    if(otp!=""){
    axios
      .post(`${process.env.REACT_APP_URL}/developers/otpValidation`, {
        otp: otp,
        recipient_email: email,
      })
      .then((res) =>{
      console.log(res,"response")
      navigate("/reset",{state:{"email":email}})

      })
      .catch((err)=>{
        console.log(err,"errro")
        if(err.response.status==404){
        SweetAlert()
        }
          
      });
    }
  }

  // resend otp 
  function resendOTP() {
    axios
    .post(`${process.env.REACT_APP_URL}/developers/forgotPassword`, {
      email:email,
    })
    .then((res) => {
      console.log(res,"response dadadad");
    })
    .catch((err) => {
      console.log(err,"error dadadad");
    });
  }

  // success popup
  function SweetAlert() {
    Swal.fire({
      icon: 'error',
      title: 'OTP is Incorrect',
    })
  }

  React.useEffect(() => {
    if(location.state!=null){
    setEmail(location.state.email)
    console.log(location.state.email,"locaion ooottttpppp");

    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        if (lastTimerCount <= 1) setDisable(false);
        if (lastTimerCount <= 0) return lastTimerCount;
        return lastTimerCount - 1;
      });
    }, 1000); //each count lasts for a second
    return () => clearInterval(interval);
  }else{
    navigate("/forgotpassword")
  }
  }, [disable,location]);



  const container = {
    maxWidth: {
      lg: 500,
      xs: 400,
    },
    height: {
      lg: '100vh',
      md: '100vh',
      xs: '600px'
    },
    display: "flex", alignItems: "center"
  }

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  };


  const renderButton = (buttonProps) => {
    return (
      <div {...buttonProps} style={{color:"#294b86"}}>
        {buttonProps.remainingTime !== 0 ? `Please wait for ${buttonProps.remainingTime} sec` : <a onClick={resendOTP}>Resend OTP</a>}
      </div>
    );
  };
  const renderTime = () => React.Fragment;

  return (
    <>

    <Box>
      <Container sx={container}>
           <Paper elevation={5} sx={{
            p: {
              lg: "40px",
              xs: "20px",
              md: "20px"
              }}}>
                 <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
              <img src={require("../images/ALAN Final logo-01.png")} height={"40%"} width={"60%"} />
            </Box>

            <Typography variant="subtitle1" sx={{ textAlign: "center", my: 1 }}>
            We have sent a OTP verification code to your <b>{email}</b>
            </Typography>

            <Grid Container spacing={2}>

            <Grid item xs={12}>
            <OTPInput style={containerStyle} value={otp} onChange={setOtp} autoFocus OTPLength={4} otpType="number" disabled={false} />
            </Grid>

            <Grid item xs={12}>
            <Button variant="contained" type="submit" fullWidth sx={{ py: 1 ,my:3}} onClick={onSubmit}>Submit</Button>
           </Grid>

            <Grid item xs={12} >
            <ResendOTP style={{justifyContent:"center"}} renderButton={renderButton} renderTime={renderTime} />
            </Grid>

            </Grid>


           </Paper>
      </Container>
    </Box>
    
          </>
  );
}