import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import jsonData from "../Apply_as_dev/data.json";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from "@mui/material/FormControlLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import { Container, TextField, Button, Grid, Typography } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
import Radio from "@mui/material/Radio";
import { auto } from "@popperjs/core";
import RadioGroup from "@mui/material/RadioGroup";
const involment = [
    { "weekTime": "40", "workType": "full-time" },
    { "weekTime": "20-35", "workType": "part-time" },
    { "weekTime": "5-19", "workType": "miniumum" },

  ]
const AddProjectSchema = Yup.object().shape({
  Project_Or_Company_Name: Yup.string().required(
    "Enter the Project Or Company Name"
  ),
//   Project_Link: Yup.string().required("Enter the Project Link"),
  type_stack: Yup.string().required("Enter the type stack"),
  Your_Role: Yup.string().required("Enter the Your Role"),
  Project_Description: Yup.string().required("Enter the Project Description"),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Add_pro() {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        Project_Or_Company_Name: "",
        type_stack: "",
        Project_Description: "",
      },
      validationSchema: AddProjectSchema,
    });

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const theme = useTheme();
  const [stackName, setStackName] = React.useState([]);
  const [startDate, setStartDate] = React.useState({});
  const [endDate, setEndDate] = React.useState({});
  const seniorityLevel = jsonData.SeniorityLevel;


  useEffect(() => {
    values.type_stack = stackName

    console.log(startDate, "shgdgag")
  }, [stackName, endDate, startDate])

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setStackName(typeof value === "string" ? value.split(",") : value);
  };

  const [developer, setDeveloper] = useState("");


  function SweetAlert() {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Add Project Updated",
      confirmButtonText: "OK",
      timer: 8000,
    });
  }
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/login");
    } else {
      const data = localStorage.getItem("user");
      const developer = JSON.parse(data);
      setDeveloper(developer);
    }
  }, []);

  async function addProject() {
    try {
      return console.log(values, "kjkjkj")
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/developers/addProject/${developer.id}`,
        values
      );
      if (response.status == 200) {
        const user = JSON.stringify(developer);
        localStorage.setItem("user", user);
        SweetAlert();
        navigate("/application");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const button = {
    marginTop: { lg: 5 },
    backgroundColor: "#294b86",
  };

  const box = {
    width: {
      lg: 1000,
      marginTop: 100,
    },
    height: {
      lg: "100vh",
      md: "100vh",
      xs: "600px",
    },
  };
  const names = jsonData.names;

  const handleDelete = (e, value) => {
    e.preventDefault();
    setStackName(stackName.filter((name) => name !== value));
  };

  const developerCheckbox = {
    width: { lg: 220, xs: 170, md: 200 },
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    textAlign: "center",
    py: { lg: 1, xs: 1 },
    bgcolor: "#f0ecec",
    borderRadius: "10px",
    border: "2px solid white",
    color: "black",
    fontWeight: "bold",
    fontSize: { lg: "17px", xs: "15px" }
  }


  return (
    <>
      <Box>
        <Container sx={box}>
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom>
              Add Project
            </Typography>
            <Typography variant="h6" gutterBottom>
              "Add you Projects"
            </Typography>
          </Grid>

          <Grid item xs={12} lg={6}>
            <TextField
              type="text"
              fullWidth
              margin="normal"
              name="Project_Or_Company_Name"
              label="Project"
              value={values.Project_Or_Company_Name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                !!errors.Project_Or_Company_Name &&
                touched.Project_Or_Company_Name
              }
              helperText={
                touched.Project_Or_Company_Name &&
                  !!errors.Project_Or_Company_Name
                  ? errors.Project_Or_Company_Name
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12} lg={12} >
            <FormControl
              margin="normal"
              fullWidth>
              <InputLabel id="demo-multiple-chip-label">Technologies you Need</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                margin="normal"
                value={stackName}
                onChange={handleChange1}
                input={
                  <OutlinedInput id="select-multiple-chip" label="Technologies you used in the process -Type Stack" />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        clickable
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(e) => e.stopPropagation()}
                          />
                        }
                        onDelete={(e) => handleDelete(e, value)}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, stackName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              type="text"
              fullWidth
              margin="normal"
              name="Project_Description"
              label="Project Description"
              value={values.Project_Description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                !!errors.Project_Description && touched.Project_Description
              }
              helperText={
                touched.Project_Description && !!errors.Project_Description
                  ? errors.Project_Description
                  : ""
              }
            />
          </Grid>

{/*     
            <Grid  item xs={12} lg={6} md={6}>
              <TextField
                type="text"
                fullWidth
                margin="normal"
                name="Your_Role"
                label="Involement Time"
                value={values.Your_Role}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.Your_Role && touched.Your_Role}
                helperText={
                  touched.Your_Role && !!errors.Your_Role ? errors.Your_Role : ""
                }
              />
            </Grid> */}
          
          <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      label="SeniorityLevel "
                      name="seniorityLevel"
                      value={values.seniorityLevel}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.seniorityLevel && Boolean(errors.seniorityLevel)}
                      helperText={errors.seniorityLevel && touched.seniorityLevel ? errors.seniorityLevel : ""}
                    >
                      <MenuItem value="" disabled>
                        SeniorityLevel
                      </MenuItem>
                      {seniorityLevel.map((data, index) => (
                        <MenuItem key={index} value={data}>
                          {data}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>

                  <Typography variant="subtitle2" sx={{ mb: 2 }}>WHAT'S THE EXPECTED LEVEL OF INVOLVEMENT?</Typography>
                  <FormControl>
                    <RadioGroup
                    
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="involment"
                      value={values.involment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <Grid container spacing={{ lg: 2, xs: 0 }}>
                        
                        {involment.map((data) => (
                          <Grid item xs={4} lg={4}>

                            <FormControlLabel value={data.weekTime + " " + data.workType} control={<Radio
                            
                              icon={
                                
                                <Box sx={[developerCheckbox,
                                  {
                                    width: { lg: auto, xs: auto },
                                    display: { lg: "flex", xs: "block" }

                                  }]}>
                                  <img
                                    src={require("../../components/buttonimages/clockhire.png")}
                                    width="20%"
                                  />
                                  <Box sx={{ fontSize: { lg: "16px", xs: "12px" }, lineHeight: 1.5, marginTop: { xs: 1 } }}>
                                    <p style={{ marginBottom: 0 }}>{data.weekTime} HOURS/WEEK</p>
                                    <span>{data.workType}</span>
                                  </Box>
                                </Box>
                              }
                              checkedIcon={
                                <Box sx={[developerCheckbox,
                                  {
                                    width: { lg: auto, xs: auto },
                                    display: { lg: "flex", xs: "block" },
                                    border: "2px solid #294b86",
                                    bgcolor: "#294b86",
                                    color: "white",
                                  }
                                ]}>
                                  <img
                                    src={require("../../components/buttonimages/clockhire.png")}
                                    width="20%"
                                  />
                                  <Box sx={{ fontSize: { lg: "16px", xs: "12px" }, lineHeight: 1.5, marginTop: { xs: 1 } }}>
                                    <p style={{ marginBottom: 0 }}>{data.weekTime} HOURS/WEEK</p>
                                    <span>{data.workType}</span>
                                  </Box>
                                </Box>
                              }
                            />} />
                          </Grid>
                        ))}
                      </Grid>
                    </RadioGroup>
                    <FormHelperText sx={{ color: "red" }}>{errors.involment}</FormHelperText>
                  </FormControl>
                </Grid>
          <Grid item xs={12} sx={button} margin="normal">
            <Button
              fullWidth
              sx={{
                py: 2,
                bgcolor: " #294b86 !important",
                color: "white",
              }}
              onClick={addProject}
            >
              Save Project
            </Button>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Add_pro;
