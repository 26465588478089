import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import CloseButton from "react-bootstrap/CloseButton";
import Modal from "@mui/material/Modal";
import Pagination from "react-js-pagination";
import Toast from "react-bootstrap/Toast";
import Swal from "sweetalert2";
import axios from 'axios';

function Deve() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [serialNumber, setSerialNumber] = useState(1);
  const [filtereddata, setFiltereddata] = useState([])
  function SweetAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: ' Deleted successfully',
      showConfirmButton: false,
      timer: 1500
    })
  }

  const navigate = useNavigate();

  const [openmodal, setOpen] = React.useState(false);
  const handleOpen = (user) => {
    setSelectedUser(user);

    setOpen(true);
  };

  const handleClosemodal = () => {
    setSelectedUser(null); // Clear the selectedUser state
    setOpen(false);
  };

  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 8;

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const token = localStorage.getItem('token');
  const User_id = localStorage.getItem('Admin_id')
  useEffect(() => {
    const fetchdeveloper = async () => {
      if (!token) {
        console.log("first")
        return navigate('/');
      }
      const response = await axios.get(`${process.env.REACT_APP_URL}/developers`, {
        headers: {
          'Client-service': process.env.REACT_APP_CLIENT_SERVICE,
          "Auth-Key": process.env.REACT_APP_AUTH_KEY,
          'User-id': '1',
          'Authorization': token
        }
      })
      if (response.status == 200) {
        const fetchedData = response.data.data.developer;
        console.log(response.data.data)
        const dataWithSerialNumbers = fetchedData.map((user, index) => ({
          ...user,
          serialNumber: serialNumber + index,
        }));
        setData(dataWithSerialNumbers);
        setSerialNumber(serialNumber + fetchedData.length);
        setFiltereddata(dataWithSerialNumbers)
      }
      else {
        return navigate('/');
      }
    }
    fetchdeveloper();
  }, []);

  const handleFilter = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = data.filter((item) => {
      return (
        item.firstName?.toLowerCase()?.includes(query) ||
        item.lastName?.toLowerCase()?.includes(query) ||
        item.email?.toLowerCase()?.includes(query) ||
        item.seniorityLevel?.toLowerCase()?.includes(query) ||
        item.phoneNumber?.toLowerCase()?.includes(query) ||
        item.country?.toLowerCase()?.includes(query)
      );
    });
    setFiltereddata(filtered);
  };

  return (
    <>
      <input type="checkbox" id="menu-toggle" />
      <div className="sidebar">
        <div className="side-header">
          {/* <h3>M<span>odern</span></h3> */}
          <img
            src={require("../../images/ALAN Final logo-01.png")}
            className="pt-2"
          />
        </div>

        <div className="side-content">
          <div className="side-menu">
            <ul>
              <li>
                <a onClick={() => navigate("/dash")} >
                  <div className="d-flex justify-content-center">
                    <span className="fas fa-home me-2"></span>
                    <small>Dashboard</small>

                  </div>
                </a>
              </li>
              <li>
                <a onClick={() => navigate("/Deve")} className="active">
                  <div className="d-flex justify-content-center">
                    <span className="fas fa-user-alt me-2"></span>
                    <small>Developers</small>
                  </div>
                </a>
              </li>
              <li>
                <a onClick={() => navigate("/Clint")}>
                  <div className="d-flex justify-content-center">
                    <span className="fas fa-envelope me-2"></span>
                    <small>Clients List</small>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="main-content">
        <AdminHeader />

        <main className="dashboard-main" id="develeper">
          <div className="page-header">
            <h1 className="mt-5">Developers</h1>
          </div>

          <div className="page-content">
            <div className="records table-responsive">
              <div className="">
                <input
                  type="text"
                  className="float-end mb-2 mt-2 w-25 me-1 form-control searchbar-bar"
                  onChange={handleFilter}
                  placeholder="Search"
                />
              </div>
              <div>
                <table width="100%" className="mt-5 ">
                  <thead>
                    <tr>
                      <th className="text-center">
                        <span className="las la-sort"></span>SI.NO
                      </th>
                      <th className="text-center">
                        <span className="las la-sort"></span>FIRST NAMES
                      </th>
                      <th className="text-center">
                        <span className="las la-sort"></span>SECOND NAMES
                      </th>
                      <th className="text-center">
                        <span className="las la-sort"></span>EMAIL
                      </th>
                      <th className="text-center">
                        <span className="las la-sort"></span>SENIORITY
                      </th>
                      <th className="text-center">
                        <span className="las la-sort"></span> MOBILE NUMBER
                      </th>
                      <th className="text-center">
                        <span className="las la-sort"></span> COUNTRY
                      </th>
                      <th className="text-center">
                        <span className="las la-sort"></span> ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtereddata.slice(
                      (activePage - 1) * itemsPerPage,
                      activePage * itemsPerPage
                    )
                      .map((user) => (
                        <tr key={user.id}>
                          <td className="text-center">
                            <p>{user.serialNumber}</p>
                          </td>
                          <td className="text-center">
                            <p>{user.firstName}</p>
                          </td>
                          <td className="text-center">
                            <p>{user.lastName}</p>
                          </td>
                          <td className="text-center">
                            <p>{user.email}</p>
                          </td>

                          <td className="text-center">
                            <p>{user.seniorityLevel}</p>
                          </td>

                          <td className="text-center">
                            <p>{user.phoneNumber}</p>
                          </td>

                          <td className="text-center">
                            <p>{user.country}</p>
                          </td>
                          <td className="text-center">
                            <div className="actions">
                              <span
                                className="fas fa-eye"
                                onClick={() => navigate(`/Developerlist/${user.id}`)}
                              ></span>
                              {/* <span
                                className="fas fa-trash"
                                onClick={() => handleDelete(user.id)} // Pass the user's ID
                              ></span> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={data.length}
              pageRangeDisplayed={3}
              onChange={handlePageChange}
              // itemClass="page-item"
              linkClass="page-link"
              className="page"
              innerClass="pagination justify-content-center  "
            />
          </div>
        </main>
      </div>


      <Toast
        style={{
          position: "fixed",
          bottom: "1rem",
          right: "1rem",
          zIndex: 1,
          color: "green"
        }}
        onClose={() => setShowDeleteSuccess(false)}
        show={showDeleteSuccess} // Show the Toast when showDeleteSuccess is true
        delay={3000}
        autohide
      >
        <Toast.Body>User deleted successfully.</Toast.Body>
      </Toast>
    </>
  );
}

export default Deve;
