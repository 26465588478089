import React, { useState } from "react";
import countries from "countries-list";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Box,
} from "@mui/material";


function UserDetails({ nextStep, handleChange, values, errors, touched, handleBlur, setFieldValue, setFieldTouched }) {

  const [btn, setBtn] = useState(false)

  const country = Object.values(countries.countries);
  
  const countryOptions = country.map((res) => {
    return res.name;
  });


  React.useEffect(() => {
    if ((values.firstName.length > 3 && values.lastName.length >= 1 && values.country.length > 3) &&
      (errors.firstName == undefined && errors.lastName == undefined & errors.country == undefined)) {
      setBtn(false);
    } else {
      setBtn(true);
    }

  }, [values])



  const saveAndContinue = (e) => {
    e.preventDefault();

    if (
      (values.firstName.length > 3 && values.lastName.length >= 1 && values.country.length > 3) &&
      (errors.firstName == undefined && errors.lastName == undefined & errors.country == undefined)) {
      nextStep();

    }
  };

  // style
  const paperStyle = {
    padding: { lg: "45px", xs: "20px", md: "30px" },
  };

  const container = {
    width: {
      lg: 500,
      md: 500
    },
    height: {
      lg: '100vh',
      md: '100vh',
      xs: '600px'
    },
    display: "flex", alignItems: "center",
  };

  function AvoidSpace(event) {
    var k = event ? event.which : window.event.keyCode;
    if (k == 32) return false;
  }


  return (
    <>
      <Box>
        <Container sx={container}>
          <Paper elevation={5} sx={paperStyle}>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
              <img src={require("../../images/ALAN Final logo-01.png")} height={"40%"} width={"60%"} />
            </Box>
            <Typography variant="h6" align="center" gutterBottom>
              PROFILE INFO
            </Typography>
            <form onSubmit={saveAndContinue} autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    name="firstName"
                    onkeypress="return AvoidSpace(event)"

                    autoFocus
                    value={values.firstName}
                    onChange={handleChange} // Updated here
                    helperText={errors.firstName && touched.firstName ? errors.firstName : ""}
                    onBlur={handleBlur}
                    error={touched.firstName && Boolean(errors.firstName)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange} // Updated here
                    helperText={errors.lastName && touched.lastName ? errors.lastName : ""}
                    onBlur={handleBlur}
                    error={touched.lastName && Boolean(errors.lastName)}
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Autocomplete
                    options={countryOptions}
                    value={values.country}
                    onChange={(_, newValue) => {
                      setFieldValue('country', newValue ? newValue : "");
                      setFieldTouched('country', true);
                    }}
                    onInputChange={(_, newInputValue) => {
                      setFieldValue('country', newInputValue);
                      setFieldTouched('country', true);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select your country"
                        variant="outlined"
                        fullWidth
                        error={touched.country && Boolean(errors.country)}
                        helperText={touched.country && errors.country}
                      // value={values.country}
                      />
                    )}
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="LinkedIn"
                    variant="outlined"
                    name="linkedIn"
                    value={values.linkedIn}
                    onChange={handleChange} // Updated here
                    helperText={errors.linkedIn && touched.linkedIn ? errors.linkedIn : ""}
                    onBlur={handleBlur}
                    error={touched.linkedIn && Boolean(errors.linkedIn)}
                  />
                </Grid> */}

                <Grid item xs={12}>
                  <Button
                    disabled={btn}
                    type="submit"
                    fullWidth
                    sx={{ py: 1, bgcolor: " #294b86 !important", color: "white" }}
                  >
                    Next
                  </Button>
                </Grid>

              </Grid>
            </form>
          </Paper>
        </Container>
      </Box>
    </>
  );
}

export default UserDetails;