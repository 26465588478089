import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, Container, Grid, Paper, TextField, InputAdornment, IconButton, FormHelperText } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Swal from 'sweetalert2'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import GoogleLogin from 'react-google-login';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Enter the email'),
  password: Yup.string().required('Enter the password'),
});


function Login() {

  const [userEmailValid, setUserEmailValid] = useState(false);
  const [userPwd, setUserPwd] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async (values) => {
    setUserPwd(false)
    setUserEmailValid(false)
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/auth2/login`, {
        username: values.email,
        password: values.password,
      },
        {
          headers: {
            'Client-service': process.env.REACT_APP_CLIENT_SERVICE,
            'Auth-Key': process.env.REACT_APP_AUTH_KEY
          }
        });

      if (response.status == 200) {
        const token = response.data.token;
        const user_id = response.data.id;
        localStorage.setItem('token', token);
        localStorage.setItem('user_id', user_id);
        navigate('/application')
      }
      else {
        setLoading(false)
        Swal.fire({
          icon: "error",
          title: "Invalid Credencials!",
          text: "Email And Password Are Not Matched",
        });
      }

      // Redirect or perform other actions upon successful login
    } catch (error) {
      alert('Internal Server Error, Please Try after sometime')
      console.error('Login error:', error);
    }

  }

  // Password show and hide function
  const handleClickShowPassword = () => setShowPassword((show) => !show);


  const { values, handleChange, errors, touched, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: loginSchema,
    onSubmit,

  })

  React.useEffect(() => {
    if (values.email.length > 2 && errors.email != undefined) {
      setUserEmailValid(false)
    }
    if (values.password.length >= 0 && errors.password != undefined) {
      setUserPwd(false)
    }

  }, [errors])

  // Material ui variable
  const container = {
    maxWidth: {
      lg: 500,
      xs: 400,
    },
    height: {
      lg: '100vh'
    },
    display: "flex", alignItems: "center"
  }
  if (loading) {
    return (<div class="spinner2">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    )
  }

  const clientid = '363895787436-qliagdeost4k4elmi368tc1n2qd051hi.apps.googleusercontent.com';
  const onSuccess = async (response) => {
    try {
      const apiResponse = await axios.post(
        `${process.env.REACT_APP_URL}/auth2/googlelogin`,
        {
          username: response.profileObj.email,
        },
        {
          headers: {
            'Client-service': process.env.REACT_APP_CLIENT_SERVICE,
            'Auth-Key': process.env.REACT_APP_AUTH_KEY,
          },
        }
      );

      if (apiResponse.status === 200) {
        const token = apiResponse.data.token;
        const user_id = apiResponse.data.id;
        localStorage.setItem('token', token);
        localStorage.setItem('user_id', user_id);
        navigate('/application');
      } else {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'User Not Found!',
          text: ' There is no user in this email .Please register.',
        });
      }
    } catch (error) {
      alert('Internal Server Error. Please try again later.');
      console.error('Login error:', error);
    }
  };


  const onFailure = (response) => {
    console.log(response, "resop")
  }

  return (
    <>
      <Box >
        <Container sx={container} >

          <Paper elevation={5} sx={{
            p: {
              lg: "30px",
              xs: "20px",
              md: "20px"
            }
          }}>


            <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
              <img src={require("../images/ALAN Final logo-01.png")} height={"40%"} width={"60%"} />
            </Box>
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>
                  <GoogleLogin
                    clientId={clientid}
                    buttonText="Login with Google"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={'single_host_origin'}
                    className="custom-google-login-button mb-3"
                  />
                  <p className="text-center">or</p>
                  <TextField
                    label="Email"
                    name="email"
                    fullWidth
                    autoFocus
                    helperText={errors.email && touched.email ? errors.email : ""}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email) || userEmailValid}

                  />
                  <FormHelperText sx={{ color: "#d32f2f" }}>{userEmailValid ? "email not existed" : ""}</FormHelperText>
                </Grid>

                <Grid item xs={12}>


                  <TextField
                    label="Password"
                    name="password"
                    type={showPassword ? 'text' : 'password'} // Toggle password visibility
                    fullWidth
                    helperText={errors.password && touched.password ? errors.password : ""}
                    value={values.password}
                    error={touched.password && Boolean(errors.password) || userPwd}
                    onBlur={handleBlur}
                    onChange={handleChange}

                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText sx={{ color: "#d32f2f" }}>{userPwd ? "password is not match" : ""}</FormHelperText>


                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Link to="/forgotpassword" >
                      Forgot Password?
                    </Link>
                  </Box>

                </Grid>


                <Grid item xs={12} >
                  <Button variant="contained" type="submit" fullWidth sx={{ py: 1 }}>Log in</Button>
                </Grid>
              </Grid>
            </form>

            <Box sx={{ fontSize: "12px", lineHeight: "15px", my: 2 }}>
              By logging in, I agree to the Terms of Use. See the Privacy Policy for information on our privacy practices.
            </Box>

            <Box sx={{ textAlign: "center" }}>
              <Link to="/apply_devs"> New To AlanTech? Create an Account</Link>
            </Box>


          </Paper>
        </Container>
      </Box>
    </>
  );
}
export default Login;
