  import React, { useRef } from 'react';
  import Footer from "./Footer";
  import Header from "./Header";
  import emailjs from '@emailjs/browser';
  import Swal from 'sweetalert2';
  import {useFormik}from 'formik'
  import {singupValidation} from './Validation'
  const intialValues={
    name:"",
    email:"",
    subject:"",
    message:""
  }
  
  function Contact(){
    const {values,handleBlur,handleChange,handleSubmit,errors}=useFormik({
      initialValues:intialValues,
      validationSchema:singupValidation,
      onSubmit:(values)=>{
        console.log(values);
      }
    })
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();

      emailjs.sendForm('service_uyst04e', 'template_tazhfvm', form.current, 'RhJFfwGtpZ9hJd1ql')
        .then((result) => {
            Swal.fire({
              title: 'Success,We Will Contact You Later',
              width: 600,
              padding: '3em',
              color: '#716add',
              background: '#fff url(/images/trees.png)',
              backdrop: `
                rgba(0,0,123,0.4)
                url("/images/nyan-cat.gif")
                left top
                no-repeat
              `
            })
            e.target.reset();

        }, (error) => {
            console.log(error.text);
        });
    };
      return(
          <>
          <Header/>
          <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2 className='mt-4'>Contact</h2>
          <h3><span>Contact Us</span></h3>
          <p><i class="bi bi-quote"></i>Your thoughts matter and we are eager to hear from you. Contact us today .<i class="bi bi-quote"></i></p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-4">
            <div className="info-box mb-4">
            <i class="bi bi-geo-alt-fill"></i>
              <h3>Our Address</h3>
              <p> No: 38, Ramakrishna Street, Tambaram West, Chennai-600 045</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="info-box  mb-4">
            <i class="bi bi-envelope-at-fill"></i>
            <h3>Email Us</h3>
              <p>support@alantechnologies.in</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="info-box  mb-4">
            <i class="bi bi-telephone-forward-fill"></i>
              <h3>Call Us</h3>
              <p>+91 9629998555</p>
            </div>
          </div>

        </div>
        <div className='mobilemap container'>
    <div className='row'>
    <div className=" col-12 ">
              <div className="mapouter"><div className="gmap_canvas">
                <iframe className="gmap_iframe  "  frameborder="0" scrolling="no" marginheight="0" marginwidth="0" height="200rem%" width="100%" src="https://maps.google.com/maps?width=800&amp;height=800&amp;hl=en&amp;q=alan technologies, Ramakrishna Street Tambaram West Chennai-600 045&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://gachanox.io/"></a></div> </div>
          </div>

        <div className='mt-5 pt-5'>
        <div className=" col-12  forms w-100 ">
            <form ref={form} onSubmit={sendEmail} className='php-email-form mt'>
              <div className="row">
                <div className="form-group">
                <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" pattern="[a-zA-Z]+"  onChange={handleChange} onBlur={handleBlur} value={values.name} />
                {errors.name && <small style={{ color: 'red' }}>{errors.name}</small>}
   </div>
                <div className="form-group">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email"onChange={handleChange} onBlur={handleBlur} value={values.email}/>
                  {errors.email && <small style={{ color: 'red' }}>{errors.email}</small>}
                </div>
              </div>
              <div className="form-group">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" onChange={handleChange} onBlur={handleBlur} value={values.subject}/>
                {errors.subject && <small style={{ color: 'red' }}>{errors.subject}</small>}
              </div>
              <div className="form-group">
                <textarea className="form-control" name="message" rows="5" placeholder="Message" onChange={handleChange} onBlur={handleBlur} value={values.message}></textarea>
                {errors.message && <small style={{ color: 'red' }}>{errors.message}</small>}
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message"></div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>
        </div>
    </div>
      </div>

      {/* laptop view */}
        <div className="row laptopmap" data-aos="fade-up" data-aos-delay="100">

          <div className="col-lg-6 col-12 ">
              <div className="mapouter"><div className="gmap_canvas">
                <iframe className="gmap_iframe  "  frameborder="0" scrolling="no" marginheight="0" marginwidth="0" height="350%" width="100%" src="https://maps.google.com/maps?width=800&amp;height=800&amp;hl=en&amp;q=alan technologies, Ramakrishna Street Tambaram West Chennai-600 045&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://gachanox.io/"></a></div> </div>
          </div>

          <div className="col-lg-6  col-12">
            <form ref={form} onSubmit={handleSubmit} className='php-email-form ms-5'>
              <div className="row">
                <div className="col form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" onChange={handleChange} onBlur={handleBlur} value={values.name}/>
                  {errors.name && <small style={{ color: 'red' }}>{errors.name}</small>}
                </div>
                <div className="col form-group">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" onChange={handleChange} onBlur={handleBlur} value={values.email}/>
                  {errors.email && <small style={{ color: 'red' }}>{errors.email}</small>}
                </div>
              </div>
              <div className="form-group">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" onChange={handleChange} onBlur={handleBlur} value={values.subject}/>
                {errors.subject && <small style={{ color: 'red' }}>{errors.subject}</small>}
              </div>
              <div className="form-group">
                <textarea className="form-control" name="message" rows="5" placeholder="Message" onChange={handleChange} onBlur={handleBlur} value={values.message}></textarea>
                {errors.message && <small style={{ color: 'red' }}>{errors.message}</small>}
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message"></div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

        </div>
      
      </div>
      {/* laptop view */}


      <div className='tabmap'>
      <div className='row'>
    <div className=" col-12 ">
              <div className="mapouter"><div className="gmap_canvas">
                <iframe className="gmap_iframe  "  frameborder="0" scrolling="no" marginheight="0" marginwidth="0" height="200rem%" width="100%" src="https://maps.google.com/maps?width=800&amp;height=800&amp;hl=en&amp;q=alan technologies, Ramakrishna Street Tambaram West Chennai-600 045&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://gachanox.io/"></a></div> </div>
          </div>

        <div className='mt-5 pt-5'>
        <div className=" col-12  forms w-100 ">
            <form ref={form} onSubmit={sendEmail} className='php-email-form mt'>
              <div className="row">
                <div className="col form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name"   onChange={handleChange} onBlur={handleBlur} value={values.name}/>
                  {errors.name && <small style={{ color: 'red' }}>{errors.name}</small>}
                </div>
                <div className="col form-group">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" onChange={handleChange} onBlur={handleBlur} value={values.email}/>
                  {errors.email && <small style={{ color: 'red' }}>{errors.email}</small>}
                </div>
              </div>
              <div className="form-group">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" onChange={handleChange} onBlur={handleBlur} value={values.subject}/>
                {errors.subject && <small style={{ color: 'red' }}>{errors.subject}</small>}
              </div>
              <div className="form-group">
                <textarea className="form-control" name="message" rows="5" placeholder="Message" onChange={handleChange} onBlur={handleBlur} value={values.message}></textarea>
                {errors.message && <small style={{ color: 'red' }}>{errors.message}</small>}
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message"></div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>
        </div>
    </div>
      </div>
    
    </section>
    <Footer/>
          </>
      )
  }
  export default Contact