import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import { Container, TextField, Button, Grid, Typography } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const AddProjectSchema = Yup.object().shape({
  Unversity_other_inst: Yup.string().required(
    "Enter the Unversity /other institution"
  ),
  Field_courseName: Yup.date().required("Enter the field of study/ Course name"), // Updated validation for date
  Your_degree_Level: Yup.date().required("Enter the Your degree /level"), // Updated validation for date
  graduation_year: Yup.string().required("Enter the Graduatin year")
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Education() {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        Unversity_other_inst: "",
        Field_courseName: "",
        Your_degree_Level: "",
        graduation_year: ""
      },
      validationSchema: AddProjectSchema,
    });


  const [developer, setDeveloper] = useState("");
  const [loading, setLoading] = useState(false);
  function SweetAlert() {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Education Updated",
      confirmButtonText: "OK",
      timer: 8000,
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/application");
      }
    });
  }
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/addEducation");
    } else {
      const data = localStorage.getItem("user");
      const developer = JSON.parse(data);
      setDeveloper(developer);
    }
  }, []);

  async function addEducation() {
    setLoading(true);
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    try {
      const formData = new FormData();
      formData.append('unversity_other_inst', values.Unversity_other_inst)
      formData.append('field_coursename', values.Field_courseName)
      formData.append('your_degree_level', values.Your_degree_Level)
      formData.append('graduation_year', JSON.stringify(values.graduation_year))
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/profile/education`, formData, {
        headers: {
          'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
          "Auth-Key": process.env.REACT_APP_AUTH_KEY,
          'User-id': user_id,
          'Authorization': token
        }
      }
      );
      if (response.status == 200) {
        setLoading(false);
        const user = JSON.stringify(developer);
        localStorage.setItem("user", user);
        SweetAlert();

      }
      else {
        setLoading(false)
        alert('sorry, something went wrong please try after sometime')
      }
    } catch (error) {
      console.log(error);
    }
  }

  const button = {
    marginTop: { lg: 5 },
    backgroundColor: "#294b86",
  };

  const box = {
    width: {
      lg: 1000,
      marginTop: 100,
    },
    height: {
      lg: "100vh",
      md: "100vh",
      xs: "600px",
    },
  };

  useEffect(() => {
    values.graduation_year = value
  })


  const [value, setValue] = React.useState("");
  if (loading) {
    return (<div class="spinner2">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    )
  }
  return (
    <>
      <Box>
        <Container sx={box}>
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom>
              Add your education details
            </Typography>
            <Typography variant="h6" gutterBottom>
              It’s an advantage if you have a technical education. However, Mark Z didn’t finish his, so what? We will not include this info to your profile if it is irrelevant.
            </Typography>

          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              margin="normal"
              type="text"
              fullWidth
              label=" Unversity /other institution"
              name="Unversity_other_inst"
              value={values.Unversity_other_inst}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <TextField
              type="text"
              fullWidth
              margin="normal"
              name="Field_courseName"
              label="Field of study /course name"
              value={values.Field_courseName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              type="text"
              fullWidth
              margin="normal"
              name="Your_degree_Level"
              label=" Your degree / level"
              value={values.Your_degree_Level}
              onChange={handleChange}
              // onBlur={handleBlur}
              error={
                !!errors.Your_degree_Level &&
                touched.Your_degree_Level
              }
              helperText={
                touched.Your_degree_Level &&
                  !!errors.Your_degree_Level
                  ? errors.Your_degree_Level
                  : ""
              }
            />
          </Grid>


          <Grid item xs={12} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Graduation Year"
                  value={values.graduation_year}
                  onChange={(newValue) => {
                    if (newValue) {
                      const dateObj = new Date(newValue);
                      const date = dateObj.getDate();
                      const month = dateObj.getMonth() + 1;
                      const year = dateObj.getFullYear();
                      const newvaluedata = [date, month, year]
                      setValue(newvaluedata)

                    }
                  }}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </DemoContainer>
            </LocalizationProvider>


          </Grid>

          <Grid item xs={12} sx={button} margin="normal">
            <Button
              fullWidth
              sx={{
                py: 2,
                bgcolor: " #294b86 !important",
                color: "white",
              }}
              onClick={addEducation}
            >
              Save Education
            </Button>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Education;
