import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Card from "../../cards";
import { useNavigate } from 'react-router-dom'

function Vue() {
    const navigate = useNavigate();
    return (
        <>
            <Header />
            <section id="vueheader" className="faq heros d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-duration="1000">
                    <h1 className="col-sm-6">
                        VUE.JS DEVELOPER JOB<span>DESCRIPTION </span>
                    </h1>
                    <br />
                </div>
            </section>
       <div className="greybg">
       <div className="container">
                <div id="framework" data-aos="fade-up" data-aos-duration='1000'>
                    <p className="pt-2">
                        Vue.js has become a widely adopted JavaScript framework, allowing developers to seamlessly transition between
                        full-fledged Vue projects or incorporate Vue components into existing projects. It boasts simplicity and ease of
                        learning, making it an excellent choice for building single-page applications and interfaces. If you're in
                        search of a comprehensive Vue.js developer job description to post on online job boards or your company's
                        careers page, our curated list of essential duties and responsibilities, accompanied by a complete sample job
                        posting, is an invaluable resource to kickstart your recruitment process. Crafting a clear and well-crafted
                        Vue.js job description will attract skilled and suitable Vue.js programmers to join your organization.<br />
                    </p>
              
                </div>
                <div id='mediabutton'>
                <button className="col-sm-3 btn btn-primary mb-3 shadow-lg mt-4" onClick={() => navigate('/hire')}>
                        HIRE TOP VUE.JS DEVS
                    </button>
                </div>
            </div>
       </div>
            <div className="container">
                <div id="foremost" data-aos="fade-right" data-aos-duration='1000'>
                    <h2 className="mt-4">Company Description</h2>
                    <p>When crafting a job posting for a Vue.js developer position, it is essential to start with a concise
                        introduction to your company. This introductory section should showcase your company's work culture and the
                        reasons why an experienced Vue.js developer would find it appealing to join your team. You may want to highlight
                        unique selling propositions that set your company apart from others and include your organization's mission
                        statement or overarching goal. This initial part of the job posting sets the tone and provides candidates with a
                        glimpse of what makes your company an attractive and exciting place to work for skilled Vue.js developers.</p>
                    <h2>Sample:</h2>
                    <p>In response to the era of perpetual work stress and extensive screen
                        exposure, our company has developed a unique platform that offers individuals the space and opportunity to focus
                        on themselves and prioritize their mental well-being. Our platform is dedicated to educating people about the
                        significance of nurturing our minds, just as we do with our homes and workplaces. Our ultimate objective is to
                        demonstrate that self-care can be effortless and within reach for everyone. By creating this platform, we aspire
                        to promote a healthier and more balanced approach to life, where mental health is given the attention it
                        deserves.</p>
                    <p>At our company, we firmly believe in the significance of mental health care, and this shared value is deeply
                        ingrained within our team members. We place a strong emphasis on self-education and effective communication
                        practices to foster healthy work relationships and a positive work environment. To cater to individual
                        preferences and work styles, we provide both remote and in-house work options, allowing our employees the
                        flexibility they need to thrive.</p>
                </div>
            </div>
            <div className="container">
                <div id="essentia" data-aos="fade-up" data-aos-duration='1000'>
                    <h2 className="mt-4"><span>Vue.js Developer</span> Job Profile</h2>
                    <p>When composing a concise Vue.js developer job description, it is essential to outline the fundamental tasks
                        that the programmer will be responsible for while working with our company. We are seeking candidates with
                        primary skills and experiences that align with our requirements.</p>
                    <h2>Sample:</h2>
                    <p>We are actively seeking a highly skilled Vue.js software developer to join our engineering team and
                        contribute to the creation of mobile and web applications. In this role, you will be entrusted with providing
                        a set of essential tools and project structures, integrating Vue into existing projects, and transforming
                        current applications into Vue-based ones.</p>
                </div>
            </div>
            <div className="container">
                <div id="qualifications" data-aos="fade-left" data-aos-duration='1000'>
                    <h2 className="mt-4"><span>Vue.js Developer</span> Skills, Qualifications, and Requirements</h2>
                    <p>
                        The subsequent sections detailing qualifications, skills, and responsibilities are an elaboration of the
                        previous job description. At AlanTech, we have curated a comprehensive list of essential skills, qualifications,
                        and requirements that a Vue.js developer should possess to become a proficient and highly skilled programmer.
                        These qualifications encompass the most common yet crucial attributes sought after in candidates to excel in
                        their roles as Vue.js developers. By adhering to these criteria, we aim to attract and hire top-notch talent who
                        can contribute significantly to our projects and uphold the high standards of our company.</p>
                    <h2>Sample:</h2>
                    <p><span className="text-primary fs-5">✔</span>   A Bachelor's degree in computer science, software engineering, information
                        technology, or a closely related field is required for this position.</p>
                    <p><span className="text-primary fs-5">✔</span>   We are looking for candidates with 2 to 4 years of hands-on production experience
                        specifically with Vue 2/3.</p>
                    <p><span className="text-primary fs-5">✔</span>   The ideal candidate should also have production experience with Nuxt.js.</p>
                    <p><span className="text-primary fs-5">✔</span>   We expect the candidate to demonstrate high proficiency in CSS, HTML, and modern UI
                        libraries.</p>
                    <p><span className="text-primary fs-5">✔</span>   The candidate is required to possess a deep understanding of JavaScript fundamentals
                        and should be fluent in Typescript.</p>
                    <p><span className="text-primary fs-5">✔</span>   The candidate should be familiar with testing environments such as Jest and
                        Mocha.</p>
                    <p><span className="text-primary fs-5">✔</span>   The candidate should have expertise in browser-based troubleshooting.</p>
                    <p><span className="text-primary fs-5">✔</span>   We are seeking candidates with experience in integrating and maintaining external
                        software libraries and APIs.</p>
                    <p><span className="text-primary fs-5">✔</span>   The ideal candidate should possess knowledge of modern front-end development tools
                        such as Babel, Git, and Webpack.</p>
                    <p><span className="text-primary fs-5">✔</span>   The company description;</p>
                </div>
            </div>
           <div className="greybg">
           <div className="container">
                <div id="acquaint" data-aos="fade-up" data-aos-duration='1000' >
                    <h2 className="mt-4"><span>Vue.js Developer</span> Duties and Responsibilities</h2>
                    <p>As a Vue.js developer at our company, your responsibilities will
                        include:<br /><br />
                        Developing and maintaining user interfaces for web applications using Vue.js.<br /><br />
                        Integrating Vue.js into existing projects and converting current applications into Vue-based ones.<br /><br />
                        Collaborating closely with the back-end team of engineers and UI designers to ensure seamless application
                        development.</p>
                    <h2 >Sample:</h2>
                    <p><span className="text-primary fs-5">✔</span>   TAs a Vue.js developer, your focus will be on writing JavaScript code that is secure,
                        stable, and easy to maintain over time. Ensuring the reliability and resilience of the codebase is essential in
                        delivering robust and long-lasting applications. Your attention to security practices will help safeguard our
                        systems and protect user data. Additionally, crafting code that is easy to sustain will enable seamless updates
                        and enhancements as the projects evolve. Emphasizing these qualities in your coding approach will contribute
                        significantly to the overall success of our development endeavors.</p>
                    <p><span className="text-primary fs-5">✔</span>   Your role as a Vue.js developer entails designing and developing user-oriented
                        applications with a primary focus on Vue.js. You will be responsible for creating intuitive and engaging user
                        interfaces that provide a seamless and enjoyable experience for our users. By leveraging the power of Vue.js, you
                        will craft dynamic and responsive web applications that meet the needs and expectations of our target audience.
                        Your ability to understand user requirements and translate them into practical and visually appealing solutions
                        will be vital in delivering top-notch applications that enhance user satisfaction and drive business
                        success.</p>
                    <p><span className="text-primary fs-5">✔</span>   As a Vue.js developer, one of your key responsibilities will be to maintain existing
                        applications by thoroughly testing and optimizing them for enhanced performance and security. You will proactively
                        identify areas for improvement and implement necessary changes to ensure that the applications continue to operate
                        at their best. Through rigorous testing, you will pinpoint any potential vulnerabilities and take measures to
                        bolster the security of the applications, safeguarding them against potential threats. Your dedication to
                        maintaining and improving our applications will contribute to a seamless and secure user experience, bolstering
                        our reputation and ensuring the long-term success of our projects.</p>
                    <p><span className="text-primary fs-5">✔</span>   As a Vue.js developer, you will be tasked with constructing robust and durable
                        component libraries. These libraries will serve as a foundational resource for our development team, facilitating
                        the creation of consistent and reusable components across various projects. Your expertise in Vue.js will play a
                        crucial role in designing components that are flexible, scalable, and adhere to best practices, allowing for
                        efficient and streamlined application development. By constructing such durable component libraries, you will
                        contribute to the overall efficiency, maintainability, and standardization of our codebase, fostering a cohesive
                        and productive development environment.</p>
                    <p><span className="text-primary fs-5">✔</span>   As a Vue.js developer, part of your responsibility will be to troubleshoot issues
                        within the Vue.js framework and project dependencies, especially concerning security matters. Your expertise in
                        Vue.js and related technologies will enable you to identify potential vulnerabilities or security gaps and take
                        prompt action to address them. By diligently addressing these issues, you will ensure the robustness and integrity
                        of our applications, providing a secure user experience for our customers and safeguarding sensitive data. Your
                        proactive approach to security troubleshooting will be crucial in maintaining the trust and confidence of our
                        users and stakeholders.</p>
                    <p><span className="text-primary fs-5">✔</span>   As a Vue.js developer, you will utilize the Vue.js framework for implementing
                        front-end units. Your proficiency in Vue.js will allow you to effectively create and integrate user interface
                        components, enabling the seamless construction of dynamic and interactive web applications. By harnessing the
                        capabilities of Vue.js, you will build front-end units that are modular, efficient, and easy to maintain. Your
                        expertise will be pivotal in delivering a cohesive and responsive user experience, ultimately contributing to the
                        success of our projects and the satisfaction of our users.</p>
                    <p><span className="text-primary fs-5">✔</span>   As a Vue.js developer, you will play a crucial role in implementing automated testing
                        as an integral part of the development and maintenance process. By incorporating automated testing practices, you
                        will ensure the reliability and consistency of our applications. Writing test cases and utilizing testing
                        frameworks will help identify potential issues early in the development cycle, reducing the chances of bugs and
                        enhancing the overall quality of our codebase. Your commitment to automated testing will streamline the
                        maintenance process and lead to more efficient updates and improvements. Ultimately, this approach will contribute
                        to a smoother development workflow and result in applications that are stable, secure, and meet the highest
                        standards of quality assurance.</p>
                    <p><span className="text-primary fs-5">✔</span>   As a Vue.js developer, it is essential to stay up-to-date with the latest features
                        and updates of the Vue.js framework. Your dedication to continuously learning and exploring new capabilities will
                        enable you to leverage the full potential of Vue.js in your development projects. By keeping abreast of the latest
                        advancements, you will be well-equipped to implement cutting-edge solutions and stay ahead of industry trends.
                        Embracing the latest features of Vue.js will not only enhance your own proficiency but also contribute to the
                        overall innovation and excellence of our applications.</p>
                    <p><span className="text-primary fs-5">✔</span>   As a Vue.js developer, you will actively contribute to the ongoing improvement of our
                        applications by suggesting security and performance enhancements. Your keen eye for identifying potential
                        vulnerabilities and areas for optimization will be instrumental in ensuring the robustness and efficiency of our
                        codebase. By proposing and implementing security measures, you will help fortify our applications against
                        potential threats and protect our users' data. Additionally, your insights into performance improvements will lead
                        to faster and more responsive applications, enhancing the overall user experience. Your proactive approach to
                        suggesting these enhancements will play a pivotal role in continuously elevating the quality and reliability of
                        our software products.</p>
                 
                </div>
                <div id="mediabutton">
                <button className="col-sm-3 btn btn-primary mb-3 shadow-lg mt-4" onClick={() => navigate('/hire')}>
                        MIGHTY VUE.JS DEVS FOR HIRE
                    </button>
                </div>
            </div>
           </div>
            <div className="container">
                <div id=" hundreds" data-aos="fade-left" data-aos-duration='1000'>
                    <h2 className="mt-4">Benefits and Perks for <span>Vue.Js Developers</span></h2>
                    <p>Above all, working with us as a Vue.js developer offers a host of
                        compelling reasons that set us apart from other opportunities in the market. We recognize that Vue.js developers
                        are in high demand, and we value our team members' contributions and wellbeing. As a candidate, you can expect
                        the following perks and benefits:<br /><br />

                        Career Growth and Learning: We are committed to fostering a culture of continuous learning and professional
                        growth. You will have ample opportunities to enhance your skills, attend workshops, conferences, and take part
                        in challenging projects that will advance your career.<br /><br />

                        Competitive Salary: We offer a competitive salary package that reflects your skills, experience, and the value
                        you bring to our team.<br /><br />

                        Health and Wellness: Your health and wellbeing matter to us. We provide comprehensive health insurance packages
                        to ensure you and your family are well taken care of.<br /><br />

                        Flexible Work Arrangements: We understand the importance of work-life balance, and we offer flexible work
                        arrangements, including remote work options, to accommodate your needs and preferences.<br /><br />

                        Collaborative Environment: You will be working in a collaborative and supportive environment where your ideas
                        and contributions are valued and appreciated. We believe in open communication and a team-driven
                        approach.<br /><br />

                        Cutting-edge Projects: As a Vue.js developer with us, you will have the opportunity to work on cutting-edge
                        projects that push the boundaries of technology and innovation.<br /><br />


                    </p>
                    <h2>Sample:</h2>
                    <p>
                        At our company, where we are dedicated to creating a platform for mindfulness practices, we take care of our
                        employees' well-being and provide unique benefits to enhance their experience. As an employee, you will receive
                        a yearly subscription to our platform, allowing you to personally experience the product you are working on and
                        the positive impact it has on the human body.

                    </p>
                </div>
            </div>
            <div className="container" data-aos="fade-up" data-aos-duration='1000'>
                <div id="  continuous ">
                    <h2 className="mt-4" ><span>Vue.Js Developer</span> Job Description</h2>
                    <p>In response to the challenges posed by continuous workload stress and
                        constant screen exposure, our company has developed a unique platform that encourages individuals to prioritize
                        their mental health and well-being. Our platform serves as a dedicated space for people to take time for
                        self-reflection and mindfulness practices. Through our platform, we aim to educate individuals on the
                        significance of nurturing their minds, just as they do with their homes and workplaces. Our ultimate goal is to
                        demonstrate that self-care is not only essential but also easy and accessible to everyone. By promoting a
                        culture of self-care, we aspire to create a positive and transformative impact on people's lives, helping them
                        lead healthier and more balanced lifestyles.<br /><br />
                        At our company, the value of mental health care is deeply ingrained in our team's shared beliefs. We place great
                        importance on fostering a work environment that prioritizes self-education and effective communication practices
                        to cultivate healthy and supportive work relationships.<br /><br />
                        We are currently seeking a highly skilled Vue.js software developer to join our development teams in creating
                        mobile and web applications. Your role will be instrumental in providing essential tools and project structure
                        to enable seamless development.</p>
                </div>
            </div>
            <div className="container">
                <div id=" Bachelor " data-aos="fade-right" data-aos-duration='1000'>
                    <h2 className="mt-4">Qualifications:</h2>
                    <p><span className="text-primary fs-5">✔</span>   A Bachelor's degree in computer science, software engineering, information
                        technology, or a closely related field is a prerequisite for this position.</p>
                    <p><span className="text-primary fs-5">✔</span>   Proficiency in the Vue.js framework is required for this role.</p>
                    <p><span className="text-primary fs-5">✔</span>   Previous experience as a Vue.js or React.js developer is highly desirable for this
                        position</p>
                    <p><span className="text-primary fs-5">✔</span>   We are seeking candidates with a high level of proficiency in front-end development
                        languages, including CSS, HTML, and JavaScript.</p>
                    <p><span className="text-primary fs-5">✔</span>   Proficiency in JavaScript coding is a key requirement for this position.</p>
                    <p><span className="text-primary fs-5">✔</span>   Candidates should have familiarity with efficiency testing applications like Jest and
                        Mocha</p>
                    <p><span className="text-primary fs-5">✔</span>   The candidate should be skilled in browser-based troubleshooting techniques.</p>
                    <p><span className="text-primary fs-5">✔</span>   The candidate should have practical experience in designing and consuming APIs.</p>
                    <p><span className="text-primary fs-5">✔</span>   The ideal candidate should possess knowledge of modern development tools such as
                        Babel, Git, and Webpack.</p>
                    <h2 className="mt-4">Responsibilities:</h2>
                    <p><span className="text-primary fs-5">✔</span>   The candidate should be capable of writing JavaScript code that is secure, stable,
                        and easy to maintain over time.</p>
                    <p><span className="text-primary fs-5">✔</span>   The candidate's role will involve designing and developing user-oriented applications
                        using Vue.js.</p>
                    <p><span className="text-primary fs-5">✔</span>   As a Vue.js developer, you will be responsible for maintaining existing applications,
                        which includes conducting testing and optimization to enhance their performance and security.</p>
                    <p><span className="text-primary fs-5">✔</span>   As a Vue.js developer, one of your primary tasks is to construct durable and modular
                        libraries and units. These components will serve as the building blocks for our applications, ensuring their
                        longevity, reusability, and maintainability. Your expertise in constructing such libraries will contribute to a
                        cohesive and efficient development process, fostering consistency and scalability in our projects. By creating
                        durable and modular units, you will help us achieve a robust and flexible codebase, empowering our team to deliver
                        high-quality applications with ease.</p>
                    <p><span className="text-primary fs-5">✔</span>   As a Vue.js developer, you will be responsible for troubleshooting issues within the
                        Vue.js framework and project dependencies, particularly in regard to security matters. Your expertise in Vue.js
                        will enable you to identify and address potential vulnerabilities and security-related concerns effectively. By
                        diligently troubleshooting and resolving these issues, you will ensure the overall robustness and security of our
                        applications, providing a safe and reliable user experience. Your proactive approach to security troubleshooting
                        will be instrumental in upholding the integrity of our software and protecting our users' data and privacy.</p>
                    <p><span className="text-primary fs-5">✔</span>   As a Vue.js developer, your main responsibility will be to utilize the Vue.js
                        framework for implementing front-end units. With your expertise in Vue.js, you will create and integrate user
                        interface components to build dynamic and interactive web applications. By harnessing the capabilities of Vue.js,
                        you will develop front-end units that are modular, efficient, and easy to maintain. Your proficiency in Vue.js
                        will be instrumental in delivering a seamless and user-friendly experience, ensuring our applications meet the
                        highest standards of quality and usability.</p>
                    <p><span className="text-primary fs-5">✔</span>   As a Vue.js developer, one of your key responsibilities will be to implement
                        automated testing into the development and maintenance process. By integrating automated testing practices, you
                        will ensure the reliability and stability of our applications. Writing test cases and utilizing testing frameworks
                        will help identify potential issues early in the development cycle, reducing the chances of bugs and enhancing the
                        overall quality of our codebase. Your commitment to automated testing will streamline the maintenance process and
                        lead to more efficient updates and improvements. Ultimately, this approach will contribute to a smoother
                        development workflow and result in applications that are robust, secure, and meet the highest standards of quality
                        assurance. </p>
                    <p><span className="text-primary fs-5">✔</span>   As a Vue.js developer, it is crucial to stay up-to-date with the latest Vue.js
                        updates. Keeping yourself informed about new features, enhancements, and best practices in Vue.js will enable you
                        to harness its full potential and deliver cutting-edge solutions. By staying current with Vue.js updates, you can
                        ensure that your development skills remain relevant and that you can leverage the latest advancements to create
                        efficient and innovative applications. Your dedication to continuous learning and staying informed will contribute
                        to your success as a Vue.js developer and positively impact the projects you work on.</p>
                    <p><span className="text-primary fs-5">✔</span>   As a Vue.js developer, you will play a pivotal role in suggesting security and
                        performance improvements for our applications. Your keen eye for identifying potential vulnerabilities and areas
                        for optimization will be invaluable in enhancing the overall quality and resilience of our software. By
                        proactively recommending security measures, you will help safeguard our applications against potential threats and
                        protect our users' data. Additionally, your insights into performance improvements will contribute to faster and
                        more responsive applications, leading to an enhanced user experience. Your proactive approach to suggesting these
                        enhancements will be crucial in maintaining the highest standards of security, efficiency, and user satisfaction
                        for our projects.</p>

                </div>
            </div>
          <div className="greybg">
          <div className="container" data-aos="fade-up"data-aos-duration='1000'>
                <div id=" platform ">
                    <h2 className="mt-4" >Perks and Benefits</h2>
                    <p>
                        As our company is dedicated to creating a platform for mindfulness practices, we place a strong emphasis on our
                        employees' well-being. As a part of our commitment to their health and mindfulness, we offer our employees a
                        yearly subscription to our platform. This subscription allows them to experience firsthand the product they are
                        contributing to and enjoy the benefits it brings to the human body.<br /><br />

                        In addition to the subscription, we believe in promoting a healthy work-life balance. Therefore, our employees
                        receive 25 days of paid vacation and seven days of paid sick leave annually, enabling them to take time off when
                        needed and recharge.<br /><br />

                        To further support our employees' well-being, we offer bonuses aimed at improving their immunity. They can use
                        these bonuses for activities such as gym memberships or purchasing supplements, which align with their
                        individual preferences and health goals.<br /><br />

                        Additionally, for those working from home, we provide a home office improvement bonus, ensuring they have a
                        comfortable and conducive work environment.<br /><br />

                        We believe that by prioritizing our employees' physical and mental health, we create a supportive and motivated
                        workforce that can contribute effectively to our mission of promoting mindfulness practices through our
                        platform.</p>
                </div>
            </div>
          </div>


            <div className="container">
          <div id="devs" >
            <h2 className="mt-5 mb-4 text-center" data-aos="fade-up" data-aos-duration='1000'>Are you in search of<span> Developers?</span></h2>
            <Card />
          </div>
</div>




            <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up" data-aos-duration='1000'>

                    <div className="section-title">
                        <h2>F.A.Q</h2>
                        <h3>Frequently Asked <span>Questions</span></h3>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <ul className="faq-list">

                                <li>
                                    <div data-bs-toggle="collapse" className="collapsed question" href="#faq1">What is Vue.js used for?<i
                                        className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                                        <p>

                                            Vue.js is a publicly- accessible front-end JavaScript framework used to develop user interfaces.
                                            Vue.js is used along with JavaScript to build desktop, mobile, and single-page applications.

                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq2" className="collapsed question">Why hire a Vue.js developer?<i
                                        className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                                        <p>

                                            Vue.js developers are worth hiring when you need to come up with and develop solid and user-oriented
                                            interactive UIs and single-page applications. You can hire a Vue.js developer when there is a need to
                                            update ongoing projects written on JavaScript.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq3" className="collapsed question">How much does a Vue JS developer
                                        earn? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                                    </div>
                                    <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                                        <p>

                                            According to Glassdoor, a Vue.js developer’s hourly wage in the USA varies between 55 USD per hour to
                                            75 USD per hour, thus making an average annual of 116,000 dollars. However, luckily for you, AlanTech
                                            can match you with a professional Vue.js developer within 24 hours for much lower rates. For example,
                                            you can hire a Vue.js professional from Ukraine for as much as 26USD per hour or from Spain for 42 USD
                                            an hour; maybe you will get along with a Lithuanian developer better, 32USD per hour.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq4" className="collapsed question">What is the most effective method
                                        for hiring Vue.js developers?<i className="bi bi-chevron-down icon-show"></i><i
                                            className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            The most effective way to hire a Vue.js developer is through a vendor who can offer you a vetted
                                            middle or senior programmer in a short span of time—someone like us, AlanTech.
                                            Want to get a mighty dev from us? We can match you with one (or five!) withing two days of receiving
                                            your request.</p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq5" className="collapsed question">Is it enough to provide a
                                        competitive wage to high-level Vue.js engineers in order to hire them? <i
                                            className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                                    </div>
                                    <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            Besides a competitive salary, consider offering a flexible schedule with both in-house and remote
                                            working hours. Additionally, most companies provide their employees with health insurance and bonuses
                                            for educational activities to help them grow professionally.
                                        </p>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </>
    )
}
export default Vue