import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import { useNavigate } from 'react-router-dom'
import Buttons from '../../buttons'

function Hire_typescript() {
    const navigate = useNavigate();
    return (
        <>
            <Header />
            <section id="hireetypescript" className="d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-duration='1000'>
                    <h1 className="col-sm-6">HIRE FREELANCE <span><br />TYPESCRIPT DEVELOPERS WITH AlanTech<br /></span></h1>
                    <br />

                </div>

            </section>
            <div className="container mt-4" data-aos="flip-right" data-aos-duration='1000'>
                <p>Are you seeking to swiftly enhance your emerging startup with skilled TypeScript developers? Look no further—we've crafted the simplest formula for you!</p>

            </div>
            <div id='mediabutton'>
                <button className="col-sm-3  btn btn-primary mb-5 shadow-lg mt-3 mx-4" onClick={() => navigate('/hire')}>
                    GET YOUR DEVELOPER
                </button>
            </div>
            <div className="container mt-4">
                <div className="row">
                    <div className="coposerd  col-12 col-lg-6" data-aos="flip-right" data-aos-duration='1000'>
                        <img className="img-responsive boder-" src={(require('../../../images/hire_devs/gif2.gif'))} width="100%" height="90%" alt="" />
                    </div>
                    <div className="compatibioly col-12 col-lg-6 " data-aos="flip-left">
                        <h2 className="mt-3"> How To Staff Your Startup?</h2>
                        <p>You've embarked on the journey of establishing a startup with the potential to eventually conquer the competitive app market. Your team of like-minded founders is already on board, extending their helping hands and offering supportive shoulders. Allow me to extend my congratulations: you're nearing the end of that initial euphoric phase. </p>
                        <p>Now, the unsettling considerations surrounding staffing come into play. Where can you find web developers who won't strain your financial resources? How much time is at risk of being consumed during the hiring process? Will you be managing this hiring endeavor alone? Can you effectively assess and differentiate the skilled candidates from the less capable ones?</p>
                        <p>Admittedly, these reflections can induce a sense of discomfort and urgency. Yet, we come bearing a solution for you – yes, indeed! Dive into our exceptionally comprehensive article, where we unveil a strategy for swiftly and seamlessly hiring TypeScript engineers without undue hassle.</p>



                    </div>

                </div>
            </div>
            <div className="why-us section-bg" id="why-us">
                <div className="container-fluid " data-aos="fade-up" data-aos-duration='1000'>

                    <div className="row">

                        <div className="col-lg-6 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                            <div className="content">
                                <h2>TypeScript and <strong>why you need it</strong></h2>

                            </div>

                            <div className="accordion-list">
                                <ul>
                                    <li>
                                        <a data-bs-toggle="collapse" className="collapse" data-bs-target="#accordion-list-1"><span>01</span>TypeScript is a JavaScript extension  <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-1" className="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                JS is the old but still popular language used for website and application creation. To create a website, you need HTML, CSS, and JavaScript. HTML will define the layout of the content appearing on your pages, CSS will help create the visuals, and JS is responsible for the page interactivity and the behavior of all its components. All that said, learning TypeScript for JavaScript developers is a piece of cake.
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed"><span>02</span> A good front-end dev is proficient in HTML, CSS, and JS <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                Considering the abundance of new websites and web applications, small wonder these skills are among the most desirable in the IT world
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed"><span>03</span>TypeScript contains all the JS details and a bit more <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                Hence, the JS code also works as TypeScript code. The extensions drawing the line between TS and JS help the programmers to be more data-specific in their code.
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4" className="collapsed"><span>04</span>Annotating variables in TS allows verifying their match in all the code<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-4" className="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                Talk is cheap — but extremely important. For the last decade, mobile owners have been showing a rapacious appetite for new chats and chat apps. Everything counts here, every minor feature can be crucial for the outcome. Erasing old programs is just as simple as downloading new ones. With AlanTech, you can be sure that your iPhone chat apps will persist. We make people care.
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-5" className="collapsed"><span>04</span>Adding types reduces the complexity<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-5" className="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                Nowadays, when time is probably the most costly asset of all businesses, one can’t help valuing all the tools and tips helping to make things quicker.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div className="p-4 col-lg-6 align-items-stretch order-1 order-lg-2  mt-5" data-aos="fade-up" data-aos-duration='1000'>
                            <img className="img-responsive" src={(require('../../../images/hire_devs/gif20.gif'))} width="100%" height="80%" data-aos="zoom-in" data-aos-delay="150" /> &nbsp;
                        </div>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="coverageof  col-12 col-lg-6 " data-aos="flip-right" data-aos-duration='1000'>
                        <img className="img-responsive boder-" src={(require('../../../images/hire_devs/gift2.gif'))} width="100%" height="100%" alt="" />
                    </div>
                    <div className="cofoer col-12 col-lg-6 mt-3" data-aos="flip-left">
                        <h2> What Does an Excellent TypeScript Developer Look Like?</h2>
                        <p>The idea of an exceptional TypeScript developer is an amalgamation of two key components: an exceptional developer coupled with a standout TypeScript specialist. With this in mind, let's delve into the aspects and interdependencies that define an outstanding developer. So, how can we encapsulate the qualities of such an individual?</p>

                        <p>  Proficiency Extended to Adjacent Fields: They exhibit expertise not only within their primary area of specialization but also possess knowledge that extends into related domains. Their openness to incorporate supplementary insights whenever the conceptual evolution demands showcases their dedication to constant improvement.</p>

                        <p> Accurate Project Assessment: They have a knack for comprehensively evaluating a project, accurately gauging the time required for its completion. This keen estimation demonstrates their capacity to grasp project dynamics effectively.</p>



                    </div>

                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="thenativeseress col-12 col-lg-6" data-aos="flip-right" data-aos-duration='1000'>
                        <h2 className="">When should You Start Searching For TypeScript Developers</h2>
                        <p>Securing a skilled TypeScript development expert isn't a straightforward endeavor. True professionals are often already employed, while novices might pose a risk to your budget and project stability. Seasoned talents frequently opt for well-established and renowned companies, favoring them over newer entrants. Undoubtedly, the intangible benefits offered by startups, such as a sense of belonging, participation in shaping corporate culture, and involvement in decision-making, are invaluable. Nevertheless, the formidable reputation of industry giants like Netflix, Tesla, or Amazon significantly influences employment decisions.</p>

                        <p>The key is to commence your search for TypeScript developers the moment you realize your project can't advance without them. Early action is crucial. Begin by assessing your project's requirements from the outset, outlining the specialists essential for reaching the MVP stage, and initiating your market exploration.</p>
                    </div>
                    <div className="thenmond  col-12 col-lg-6" data-aos="flip-left" data-aos-duration='1000'>
                        <img className="img-responsive boder-" src={(require('../../../images/hire_devs/th8.jpg'))} width="100%" height="100%" alt="" />
                    </div>
                </div>
            </div>
            <div className="container mt-3">
                <div className="row">
                    <div className="coverageofess  col-12 col-lg-6" data-aos="flip-right" data-aos-duration='1000'>
                        <img className="img-responsive boder-" src={(require('../../../images/hire_devs/gift3.gif'))} width="100%" height="100%" alt="" />
                    </div>

                    <div className="covedfrt col-12 col-lg-6" data-aos="flip-left" data-aos-duration='1000'>
                        <h2>Hiring All By Yourself</h2>
                        <p>Certainly, you might embody the type of individual who fearlessly embarks on a plethora of daring undertakings - from tempestuous sea voyages to foraging for unfamiliar mushrooms, or even venturing to hire TypeScript developers for your burgeoning startup all on your own. However, statistically speaking, you're in the minority (my apologies for that revelation). The truth is, not many startup proprietors possess such audacious inclinations - especially if they lack coding expertise and are incapable of assessing candidates' abilities and aptitude prior to the work commencement.</p>
                        <p>Scouring through potential candidates</p>
                        <p>Sifting through the applicant pool</p>
                        <p>Extending invitations for job interviews</p>
                        <p>Attentively parsing through their responses</p>
                        <p>Diligently scrutinizing all available information</p>

                    </div>

                </div>
            </div>
            <section id="featured-services" className="featured-services mt-5">

                <div className="container py-5" data-aos="fade-up" data-aos-duration='1000'>
                    <h2 className="text-center mt-3">Hiring all<span> by yourself? Thanks, but no.</span></h2>

                    <div className="row mt-5">
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-duration='1000' >
                                <div className="icon text-center"><i class="bi bi-calendar2-heart"></i></div>
                                <h4 className="title text-center"><a href="">FREELANCER</a></h4>
                                <p className="description text-center text-dark">It’s undoubtedly the most visitable bidding platform, where you can get free hands for all the jobs you need to complete. The candidates are plentiful, but you never know  </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-duration='1000'>
                                <div className="icon text-center"><i class="bi bi-caret-right-square-fill"></i></div>
                                <h4 className="title text-center"><a href="">PEOPLEPERHOUR</a></h4>
                                <p className="description text-center text-dark">PeoplePerHour is the «longest-running freelance website in the UK»‎ (est. 2007), where you can aptly hire TypeScript freelancers from Great Britain for your projects.  </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-duration='1000'>
                                <div className="icon text-center"><i class="bi bi-chat-dots-fill"></i></div>
                                <h4 className="title text-center"><a href="">Vetting marketplaces</a></h4>
                                <p className="description text-center text-dark">
                                    Vetting marketplaces test and check the developers they offer. Generally, the candidates you choose have already completed some tests and proven their skills,  </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box text-center" data-aos-duration='1000'>
                                <div className="icon"><i class="bi bi-chat-heart"></i></div>
                                <h4 className="title text-center"><a href="">TURING</a></h4>
                                <p className="description text-center  text-dark">This vetting platform helps the top 1% of the world’s talents find the Valley companies of their dream. The «intelligent talent cloud» technology,</p>
                            </div>
                        </div>


                    </div>

                </div>
            </section>

            <div className="container py-4">
                <h2 className="text-center mt-3" data-aos='fade-up' data-aos-duration='1000'>What do you get with AlanTech?</h2>
                <div className="row mt-4">
                    <div className="etDeve col-12 col-lg-6" data-aos="flip-right" data-aos-duration="1000" >

                        <p><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-04.png'))} width="10%" height="10%" data-aos="flip-right" data-aos-duration="1500" /> professional networks check (LinkedIn, GitHub, forums)</p>
                        <p><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-05.png'))} width="10%" height="10%" data-aos="flip-right" data-aos-duration="1500" />english proficiency test (oral/written communication)</p>

                    </div>
                    <div className="etDeveess col-12 col-lg-6" data-aos="flip-left" data-aos-duration="1000">
                        <p><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-07.png'))} width="10%" height="10%" data-aos="flip-left" data-aos-duration="1500" />soft skills test (communication, time management, empathy)</p>
                        <p><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-08.png'))} width="10%" height="10%" data-aos="flip-left" data-aos-duration="1500" />coding proficiency test (live coding & code analysis)</p>

                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="compatiyer  col-12 col-lg-6 mt-5" data-aos="flip-right" data-aos-duration='1000'>
                        <img className="img-responsive boder-" src={(require('../../../images/hire_devs/th22.jpg'))} width="100%" height="90%" alt="" />
                    </div>
                    <div className="compesdr col-12 col-lg-6 " data-aos="flip-left" data-aos-duration='1000'>
                        <h2 className="mt-5">Speed:</h2>
                        <p>We find TypeScript experts for your startup faster than you can say «typescript experts.» A joke? Yes, but only to some extent. You’ll already enjoy our first offer in 2-3 working days. Nevertheless, you can always reject it if something goes wrong: we won’t place any additional charges and substitute your candidate as smoothly as possible. ‎  </p>
                        <h2>Convenience:</h2>
                        <p>All our Typescript developers are mainly from Eastern or Central Europe. That means their time zone is very convenient for distant collaboration. If you are a Great Britain- or Western Europe-based startup, all the processes go simultaneously, unbothered by the time shifts (since the maximum difference is 2 hours). You’ll get timely updates from your European developers right before your morning coffee if you’re from the USA.</p>
                    </div>
                </div>
            </div>

            <div className="why-us section-bg" id="why-us" >
                <div className="container-fluid " data-aos="fade-up" data-aos-duration='1000'>

                    <div className="row">

                        <div className="col-lg-6 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                            <div className="content">
                                <h2> What are<strong>our superpowers?</strong></h2>

                            </div>

                            <div className="accordion-list">
                                <ul>
                                    <li>
                                        <a data-bs-toggle="collapse" className="collapse" data-bs-target="#accordion-list-1"><span>01</span>Transparent profiles  <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-1" className="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                Transparent and intuitive dev profiles will make your choice easy and perfectly trimmed.
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed"><span>02</span> Matching and onboarding <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                Our Matching and Customer Success squads will make your collaboration with top TypeScript developers smooth and productive.
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed"><span>03</span>Extremely fast hiring <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                We will replenish your star team diabolically fast. If your needs change during the startup life, we will do our best to comply. In addition to TypeScript developers, we can also offer Flutter, AWK, Android, and many more — so don’t constrain your entrepreneurial imagination.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div className="p-4 col-lg-6 align-items-stretch order-1 order-lg-2  mt-5" data-aos="flip-left" data-aos-duration='1000'>
                            <img className="img-responsive" src={(require('../../../images/hire_devs/mo8.avif'))} width="100%" height="90%" data-aos="zoom-in" data-aos-duration='1000' /> &nbsp;
                        </div>
                    </div>

                </div>
            </div>
            <section id="featured-services" className="featured-services ">

                <div className="container py-5" data-aos="fade-up" data-aos-duration='1000'>
                    <h2 className="text-center ">What will you get<span> when you hire a TypeScript professional with us</span></h2>

                    <div className="row mt-5">
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-duration="1500" >
                                <div className="icon text-center"><i class="bi bi-1-square-fill"></i></div>
                                <h4 className="title text-center"><a href="">Quick first contact for requirements check</a></h4>
                                <p className="description text-center text-dark">Our sales manager is always at your service. Just hit the button — and start the talk! </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
                                <div className="icon text-center"><i class="bi bi-2-square-fill"></i></div>
                                <h4 className="title text-center"><a href="">company, workflow, developers’ pool, and vetting process</a></h4>
                                <p className="description text-center text-dark">At AlanTech, discovery calls are a piece of cake. You elaborate on your wishes,    </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                                <div className="icon text-center"><i class="bi bi-3-square-fill"></i></div>
                                <h4 className="title text-center"><a href="">First candidates ready for the interview in 2-3 days</a></h4>
                                <p className="description text-center text-dark">
                                    Remember what you’ve read before: we are the vetting marketplace.   </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box text-center" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                                <div className="icon"><i class="bi bi-4-square-fill"></i></div>
                                <h4 className="title text-center"><a href="">A detailed interview with the desired candidate</a></h4>
                                <p className="description text-center text-dark">Do you want to check if the candidates are top coders and understand what they do? </p>
                            </div>
                        </div>


                    </div>

                </div>
            </section>
            <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up" data-aos-duration='1000'>
                    <div className="section-title">
                        <h2 >F.A.Q</h2>
                        <h3>Frequently Asked<span> Questions</span></h3>

                    </div>

                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <ul className="faq-list">

                                <li>
                                    <div data-bs-toggle="collapse" className="collapsed question" href="#faq1">What features of Typescript stand out over JavaScript? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                                        <p>

                                            Typescript supports static typing allowing to check the accuracy at build time
                                            Thanks to the fact that TypeScript identifies the compilation defects during development, there are much fewer errors left at runtime. Lf JS is an interpreted language, and it doesn’t have such bonus features

                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq2" className="collapsed question">What are the components of TypeScript?<i className="bi bi-chevron-down icon-show"></i><i
                                        className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq2" className="collapse" data-bs-parent=".faq-list">


                                        <p>Language (all the keywords, syntax, and annotations of types);
                                            Compiler (converts the TS instructions to JS equivalents);</p>

                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq3" className="collapsed question">Why choose TypeScript for your project?<i className="bi bi-chevron-down icon-show"></i><i
                                        className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq3" className="collapse" data-bs-parent=".faq-list">


                                        <p>It’s a perfect variant for your project, thanks to its vast advantages over JS. What are they?

                                            Optional static typing;
                                            Enhanced readability;
                                            IDE support;
                                            Object orientation possibilities;
                                            Gifted community support;
                                            Integrated support for new versions of ECMAScript..</p>

                                    </div>
                                </li>


                            </ul>
                        </div>
                    </div>

                </div >
            </section >


            <Buttons />
            <Footer />
        </>
    )
}

export default Hire_typescript
