import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from 'react-router-dom'
function About() {
    const navigate = useNavigate();
    return (

        <>
            <Header />
            <section id="about" className="about section">
                <div className="container aos-init aos-animate" data-aos="fade-up">

                    <div className="section-title pb-0">
                        <h2 className="mt-4"><span>About Us</span></h2>
                        <h3>WE ARE <span>ALAN</span></h3>
                    </div>
                    <div className="row">
                        <div
                            className="col-lg-6 col-md-6  col-sm-10 pt-1 pt-lg-0 content d-flex flex-column justify-content-center aos-init aos-animate"
                            data-aos="fade-up" data-aos-delay="100">

                            <p>
                                It sounds like you're describing Alantech as a unique platform or community that differs from traditional freelance platforms, dev shops, or software development companies. Alantech appears to be positioning itself as an exclusive community for startup sidekicks.
                                .</p>
                        </div>
                        <div className="col-lg-6  col-md-6 col-sm-10 aos-init aos-animate" data-aos="fade-right" data-aos-delay="100">
                            <img src={(require('../images/about/unnamed.gif'))} className="img-fluid ms-5" alt="" width="80%" />
                        </div>
                    </div>
                </div>
            </section>

            <section id="team" className="team section-bg">
                <div className="container">
                    <h3 className="pb-3 text-center">
                        Don't Worry; Our Vetting Process is
                        <span> Easy and Straightforward.</span>
                    </h3>

                    <div className="row my-3">
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up"
                            data-aos-duration='1000'>
                            <div className="member">
                                <div className="member-img">
                                    <img src={(require('../images/hire_devs/gif2.gif'))} className="img-fluid mt-4" alt="" />
                                </div>
                                <div className="member-info">
                                    <h4 className="fw-bold text-center">Cycling</h4>
                                    <p className="text-center">
                                        These are moral and ethical principles derived from religious texts and beliefs.The Ten Commandments in Christianity, which include "Thou shalt not steal""
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up"
                            data-aos-duration='1000'>
                            <div className="member">
                                <div className="member-img">
                                    <img src={(require('../images/hire_devs/gif3.gif'))} className="img-fluid mt-4" alt="" />
                                </div>
                                <div className="member-info">
                                    <h4 className="fw-bold text-center">Honor thy</h4>
                                    <p className="text-center">
                                        Treat others as you want to be treated" is a fundamental ethical commandment promoting.Treat others as you want to be treated" is a fundamental ethical commandment.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up"
                            data-aos-duration='1000'>
                            <div className="member">
                                <div className="member-img">
                                    <img src={(require('../images/hire_devs/mo8.avif'))} className="img-fluid mt-5" alt="" />
                                </div>
                                <div className="member-info">
                                    <h4 className="fw-bold text-center">Help shorthand</h4>
                                    <p className="text-center">
                                        These are rules and regulations established by governments to maintain order and ensure justice within society.Obeying traffic laws and paying taxes  .
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up"
                            data-aos-duration='1000'>
                            <div className="member">
                                <div className="member-img">
                                    <img src={(require('../images/hire_devs/gif8.gif'))} className="img-fluid mt-4" alt="" />
                                </div>
                                <div className="member-info mt-4">
                                    <h4 className="fw-bold text-center">Self-Care Commandments</h4>
                                    <p className="text-center">
                                        Focusing on personal well-being, self-care commandments emphasize the importance of maintaining physical, mental, and emotional health.Prioritize self-care" and "Set boundaries
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p data-aos="fade-up" data-aos-duration='1000'
                    >
                        Joining Alan is easy, but not too easy. this is true for everyone, not
                        just developers. our clients have to match the standards too.
                    </p>
                </div>
            </section>

            {/* <section id="featured-services" className="featured-services pt-0 pb-4">
                <div className="container aos-init aos-animate" data-aos="fade-up">
                    <h3 className="text-center fw-bold mt-5">COMMANDMENTS <span>WE LIVE BY</span></h3>
                    <br />
                    <div className="row">
                        <div className="col-md-4 col-lg-4 col-sm-6 d-flex align-items-stretch mb-5 mb-lg-0 john">
                            <div className="icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                <img src={(require('../images/about/character-10-05.png'))} className="image" alt="" />
                                <p className="fw-bold text-center">Cycling</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-4 col-sm-6 d-flex align-items-stretch mb-5 mb-lg-0 john">
                            <div className="icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                                <img src={(require('../images/about/character-10-06.png'))} className="image" alt="" />
                                <p className="fw-bold text-center"></p>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-4 col-sm-6 d-flex align-items-stretch mb-5 mb-lg-0 john">
                            <div className="icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
                                <img src={(require('../images/about/character-10-07.png'))} className="image" alt="" />
                                <p className="fw-bold text-center"></p>
                            </div>
                        </div>

                    </div>

                </div>
            </section> */}

            <section id="service" className="about owncolor pt-0 pb-0">
                <div className="container aos-init aos-animate" data-aos="fade-up">
                    <div className="title mt-2">
                        <h3 className="text-center fw-bold mt-5">OUR <span>LEGACY</span></h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center aos-init aos-animate"
                            data-aos="fade-up" data-aos-delay="100">
                            <ul>
                                <li>
                                    <i className="bi bi-shop"></i>
                                    <div>
                                        <p>Building startups is all about the joy of creating something that did not exist before. lucky you!
                                            sometimes, people who work for Alan wish they knew the feeling too.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <i class="bi bi-cart-check-fill"></i>
                                    <div>
                                        <p>But we have our own prophecy to fulfill.
                                            the one told in the ancient manuscript we pass on from generation to generation:
                                            the tale of Alan tech.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <i className="bi bi-images"></i>
                                    <div>

                                        <p>The testament visible only to those destined to see. the rest? programmed to ignore, unable to escape
                                            the illusion, they simply walk past the mystery, unnoticing.
                                            will you be able to read the age-old text?
                                        </p>
                                    </div>

                                </li>
                                <div className="d-flex ms-5 ps-3 d-flex justify-content-center">
                                    <button className="auth rounded " onClick={() => navigate('/hire')}>Find Out</button>
                                </div>
                            </ul>
                        </div>
                        <div className="col-lg-6 aos-init aos-animate" data-aos="fade-right" data-aos-delay="100">
                            <img src={(require('../images/about/book'))} className="img-fluid ms-5" width="88%" alt="" />
                        </div>

                    </div>

                    <br />

                </div>
            </section>
            <section id="services" className="services pt-0 pb-0">
                <div className="container aos-init aos-animate" data-aos="fade-up">
                    <div className="row pt-0 my-3">
                        <h3 className="mt-2 fw-bold text-center">LEADERS</h3>
                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="zoom-in"
                            data-aos-delay="100">
                            <div className="icon-box">
                            <img src={(require('../images/about/character-10-04.png'))} id="face" alt="" />
                                
                                <p><span> Bathri narayanan // co-founder, CEO </span><br />

                                    the man behind the juice conspiracy and our founding father.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0 aos-init aos-animate" data-aos="zoom-in"
                            data-aos-delay="200">
                            <div className="icon-box">
                            <img src={(require('../images/about/character-10-07.png'))} id="face" alt="" />
                               
                                <p><span>Hema // co-founder, CEO </span><br />
                                    handles the operations.
                                    which kind?
                                    the secret ones.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0 aos-init aos-animate" data-aos="zoom-in"
                            data-aos-delay="300">
                            <div className="icon-box">
                            <img src={(require('../images/about/character-10-06.png'))} id="face" alt="" />
                            
                                <p><span>Manikandan // CTO</span><br />
                                    the guardian and co founder of the coding and development.</p>
                            </div>
                        </div>

                        <div className="  col-lg-4 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="zoom-in"
                            data-aos-delay="100">
                            <div className="icon-box mt-3">
                                <img src={(require('../images/about/character-10-02.png'))} id="face" alt="" />
                                <p><span> Kiruthika // head of client support</span>
                                    <br />
                                    remember how you got here? exactly.
                                    just what he does.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0 aos-init aos-animate" data-aos="zoom-in"
                            data-aos-delay="200">
                            <div className="icon-box mt-3">

                            <img src={(require('../images/about/character-10-05.png'))} id="face" alt="" />
                                <p><span>yevgeniya kruglova
              // head of talent acquisition</span>
                                    <br />
                                    defines the good
                                    and sorts out the evil in our midst.
                                </p>
                            </div>
                        </div>

                        <div className="my-3 col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0 aos-init aos-animate" data-aos="zoom-in"
                            data-aos-delay="300">
                            <div className="icon-box mt-3">
                            <img src={(require('../images/about/character-10-03.png'))} id="face" alt="" />
                                <p><span>malky volodarsky
              // head of client support</span>
                                    <br />
                                    your family was
                                    unsupportive? it’s okay,
                                    we’re your family now.
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            <Footer />
        </>
    )
}
export default About;
