import React from "react";
import Footer from "./Footer";
import Header from "./Header";

function Terms() {
    return (
        <>
            <Header />
            <section id="contact" className="contact">
                <div className="container" data-aos="fade-up">

                    <div className="section-title mt-4">
                        <h2>Terms</h2>
                        <h3><span>Terms of Use</span></h3>

                    </div>
                    <div className="col-xl-12">
                        <p className="para">These terms of use (“Terms of Use”) set
                            forth the terms and conditions of Coding Ninjas
                            Inc. d/b/a AlanTech (“us”, “our”, and "AlanTech")
                            applicable to your (“you”, “your” and “yourself”)
                            use of the AlanTech website located at https://AlanTech,
                            and any related software, applications,
                            services and/or products (collectively the “Platform”),
                            including without limitation your use of the
                            Platform: (A) to obtain development services as a "Client”
                            (B) to perform development services as a
                            "Developer" and/or (C) to partner with us to provide
                            developers to clients as a “Partner.”</p>
                        <p> Your use
                            of the Platform constitutes your agreement to all of the
                            terms and conditions in the Terms of Use,
                            and AlanTech encourages you to read the Terms of Use
                            carefully. In the event of a conflict between
                            these Terms of Use and any other agreement, whether
                            written or oral, between AlanTech and you,
                            whether in your individual capacity or as the
                            representative of a Client, Developer, or Partner,
                            these Terms of Use shall supersede and control.</p>
                        <p>If you object to anything in the terms of use, including the jury waiver and class action waiver in Section 25, or otherwise do not understand or agree to be bound by these terms of use, do not use the platform and exit immediately, as you are not authorized to use the platform.</p>
                        <p>For purposes of the Terms of Use, “AlanTech” means and
                            includes AlanTech and its subsidiaries and
                            affiliates, and its and their investors, officers,
                            directors, employees, agents, representatives and
                            assigns. “User” means each person who accesses or uses
                            the Platform, including Clients, Developers
                            and Partners. </p>
                        <p>You understand and agree that AlanTech may revise, modify
                            and/or supplement the Terms of Use (the
                            “Revised Terms of Use”) at any time. Any Revised Terms
                            of Use will be effective immediately after
                            notice is sent to you via email. If you do not agree to
                            the Revised Terms of Use, you must stop
                            using the Platform and close your account. You can
                            deactivate your account by emailing us at
                            support@AlanTech. By continuing to use the Platform
                            following notice, or by failing to deactivate
                            your account, you hereby expressly agree to be bound by
                            the Revised Terms of Use and acknowledge
                            that your continued use of the Platform is valid
                            consideration for the Revised Terms of Use.</p>
                        <h3 className="mt-3">General Terms</h3>
                        <ol>
                            <li><p className="mt-4"><strong> Privacy Policy. </strong>Refer to the
                                AlanTech Privacy Policy at
                                https://AlanTech/privacy-policy/ for
                                information on how AlanTech collects, uses and
                                discloses information about you.</p> </li>

                            <li><p><strong> License. </strong> Subject to the terms and
                                conditions set forth in the Terms of Use,
                                AlanTech grants you a personal, nonexclusive,
                                nontransferable, revocable license to access and
                                use the Platform solely for your personal,
                                noncommercial use. Rights not expressly granted to
                                you in the Terms of Use are reserved by AlanTech.</p></li>

                            <li><p><strong> Platform Eligibility. </strong>To be
                                eligible to use the Platform, you represent,
                                warrant and covenant that you: (A) Are eighteen (18)
                                years of age or older; (B) Have full power
                                and authority to enter into the Terms of Use and to
                                be bound by the Terms of Use; (C) Will not
                                violate any other agreement to which you are a party
                                by agreeing to the Terms of Use; (D) Are
                                not restricted from using the Platform in any way or
                                for any reason; (E) Are not using the
                                Platform for reasons that are in competition with or
                                adverse to AlanTech; and (F) Will only
                                maintain one account at any given time. </p></li>

                            <li><p><strong> Platform is a Venue. </strong>The Platform
                                offers a venue where Clients and
                                Developers connect for the performance of
                                development services (“Services”). Clients and
                                Developers contract directly with each other and
                                AlanTech is not a party to any contracts or
                                agreements between them. By using the Platform, you
                                understand and agree that no joint venture,
                                partnership, employment, franchise or agency
                                relationship exists between you and AlanTech or
                                AlanTech and any other User.
                            </p></li>

                            <li><p><strong>Independent Contractors. </strong>AlanTech
                                is not an employment service or employment agency,
                                and AlanTech does not employ Developers. In all
                                cases, Developers are independent contractors and
                                not employees of AlanTech. That means Developers are
                                free to accept or reject any Services, perform
                                Services free from the supervision and control of
                                AlanTech, and provide their own tools and equipment.
                                It is the obligation of Clients to supervise and
                                control the Services performed by Developers. All
                                contracts, agreements and arrangements for the
                                completion of Services are between Clients and
                                Developers, and AlanTech is not a party to that
                                relationship.
                                As such, AlanTech does not withhold
                                taxes including, without limitation, unemployment
                                insurance, workers’ compensation, employer’s
                                liability, social security or other payroll
                                withholdings. You understand and agree that if
                                AlanTech is found liable for any taxes, other than
                                on AlanTech’ income, you will immediately reimburse
                                and pay to AlanTech an equivalent amount, including
                                any interest and penalties thereon.</p> </li>

                            <li><p><strong>Connecting Users; No Control. </strong>The
                                Platform leverages a proprietary algorithm to
                                connect Users for Services based on numerous factors
                                including, without limitation, Client criteria and
                                Developer skills. Notwithstanding the foregoing,
                                AlanTech does not have control over any aspect of
                                any Services including, without limitation, the
                                legality, timing, quality, performance or
                                non-performance thereof.
                                Further, AlanTech does not
                                supervise or control any aspect of the Services
                                performed by Developers, and Clients are responsible
                                for supervising and controlling the Services
                                performed by Developers. As such, AlanTech makes no
                                representations, warranties or covenants about any
                                aspect of any Services performed.</p></li>

                            <li><p><strong>Client Subscription Fees. </strong>Clients
                                agree to pay AlanTech the subscription fees
                                associated with the selected subscription for the
                                Services of Developers (“Subscription Fee”). All
                                Subscription Fees must be paid directly to AlanTech
                                in advance through the Platform. Clients agree that
                                it shall maintain a positive balance of an amount
                                equal to not less than: (A) one (1) week’s worth of
                                the Services of Developers for weekly Subscription
                                Fees paid by debit card or credit card for the
                                subsequent week’s Services; and, (B) one (1) month’s
                                worth of the Services of Developers for monthly
                                Subscription Fees invoiced and/or paid by ACH or
                                wire transfer for the subsequent month’s Services.
                                For Subscription Fees paid by debit card or credit
                                card, Clients hereby authorize AlanTech to charge
                                the credit card or debit card for the Subscription
                                Fees associated with the selected subscription plus
                                any amounts owed for Services of Developers that
                                exceed the selected subscription. Clients may
                                request to pause their subscription at any time by
                                using the “Request to pause” feature in Client’s
                                account. </p></li>

                            <li><p><strong>Developer Placement Fees.</strong>In the
                                event that Clients hire or contract with Developers
                                directly, or pay Developers off the Platform,
                                Clients agree to pay AlanTech an amount equal to
                                fourteen thousand U.S. dollars ($14,000 USD) as a
                                direct placement fee and not as a penalty
                                (“Placement Fee”). Clients agree to pay such
                                Placement Fee within five (5) days of such direct
                                hire, contract or payment, as applicable. Clients
                                hereby authorize AlanTech to charge the credit card
                                or debit card on file for the Placement Fee
                                associated with such direct hire, contract or
                                payment, as applicable. In the event that such
                                credit card or debit card cannot be charged for the
                                Placement Fee, such Client agrees that it shall pay
                                the Placement Fee pursuant to the invoice therefor.</p></li>

                            <li><p><strong>Late or Non Payment.</strong>For any payment
                                due to AlanTech that is more than three (3) business
                                days past due including, without limitation,
                                Subscription Fees and Placement Fees, fifteen
                                percent (15%) interest will be added to the total
                                amount due, compounded each thirty (30) days after
                                payment is due, in addition to collections costs and
                                fees incurred including, without limitation,
                                attorneys’ fees, court costs and other related
                                collection expenses.</p></li>

                            <li><p><strong>Developer Payments.</strong>Developers are
                                paid for Services on a “time and materials” basis,
                                which means that Developers are paid for the time
                                spent performing Services, the tools and equipment
                                required to perform the Services and approved
                                expenses (if applicable). Developers are paid by the
                                10th of the month following the month in which such
                                Services are performed.</p></li>

                            <li><p><strong>Partner Payments.</strong>Partners are paid
                                by AlanTech for the Services of Partner-sourced
                                Developers on a “time and materials” basis, which
                                means that Developers are paid for the time spent
                                performing Services, the tools and equipment
                                required to perform the Services and approved
                                expenses (if applicable). In the event that Clients
                                hire or contract with Partner-sourced Developers
                                directly, or pay Partner-sourced Developers off the
                                Platform, AlanTech shall pay Partner fifty percent
                                (50%) of Placement Fees received by AlanTech for
                                such Developers. Partners are paid for the Services
                                of Partner-sourced Developers by the 10th of the
                                month following the month in which such Services are
                                performed by such Developers. Partners are paid
                                their portion of the Placement Fees received by
                                AlanTech and owed to Partners by the 10th of the
                                month following the month in which such Placement
                                Fee was received by AlanTech.</p></li>

                            <li><p><strong>Developer Restrictions.</strong>As a
                                Developer, you represent, warrant and covenant that
                                you are not: (A) a citizen or resident of, or
                                located in, a geographic area that is subject to
                                U.S. sanctions or embargoes; (B) an individual, or
                                an individual employed by or associated with an
                                entity, identified on the U.S. Department of
                                Commerce Denied Persons or Entity List, the U.S.
                                Department of Treasury’s Specially Designated
                                Nationals or Blocked Persons Lists, or the U.S.
                                Department of State’s Debarred Parties List or
                                otherwise ineligible to receive items subject to
                                U.S. export control laws and regulations or other
                                economic sanction rules.</p></li>

                            <li><p><strong>Disputes Between Clients and Developers.</strong>Clients
                                understand and agree that it is their obligation to
                                communicate directly with Developers and to
                                supervise and control the Services performed by
                                Developers. In the event of any disputes between
                                Clients and Developers including, without
                                limitation, disputes related to the quality of
                                Services performed by Developers, Clients agree that
                                such disputes must be brought to AlanTech within ten
                                (10) business days following the month in which such
                                disputed Services were performed. All disputes must
                                be reported using the “Report a problem” feature in
                                Clients’ account. Clients understand and agree that
                                the only remedy for such dispute is the
                                reperformance of the Services that are the subject
                                of such dispute and, where such Services cannot
                                reasonably and adequately be reperformed, the
                                maximum remedy is the refund of an amount equal to
                                the hours paid for the disputed Services up to a
                                maximum of forty (40) hours.</p></li>

                            <li><p><strong>Account Security.</strong>You represent,
                                warrant and covenant that at all times you will: (A)
                                Maintain the security and confidentiality of your
                                account information, including, without limitation,
                                your username and password; (B) Be responsible for
                                all activity that occurs under your account, whether
                                by you or by others; (C) Notify us immediately of
                                any unauthorized use of your profile, the need to
                                deactivate your profile due to security concerns, or
                                any other breach of security; (D) Be liable for all
                                charges, damages and losses incurred or suffered by
                                you and/or by AlanTech as a result of activity under
                                your account; and, (E) Ensure that you log out of
                                your account at the end of each session. </p></li>

                            <li><p><strong>Your Responsibilities.</strong>You
                                represent, warrant and covenant that at all times
                                you will: (A) Be solely responsible for your online
                                and offline interactions with other Users; (B) Not
                                disclose confidential information and/or ideas on or
                                through the Platform that you do not want used or
                                shared by others; (C) Comply with all applicable
                                laws, rules and regulations, including, without
                                limitation, privacy laws, intellectual property
                                laws, export control laws, tax laws, and applicable
                                regulatory requirements; (D) Provide accurate
                                information in your profile, and update such
                                information as necessary; (E) Use the Platform in an
                                honest, respectful and professional manner; and, (F)
                                Use the Platform solely for permitted purposes as it
                                is intended to be used.</p></li>

                            <li><p><strong>Prohibited Uses.</strong>In connection with
                                your use of the Platform, you represent, warrant and
                                covenant that at all times you will not:</p><p> (A) Act
                                dishonestly or unprofessionally by engaging in
                                unprofessional behavior or by posting inappropriate,
                                inaccurate, or objectionable information and/or
                                content to the Platform;</p><p> (B) Duplicate, license,
                                    sublicense, publish, broadcast, transmit,
                                    distribute, perform, display, sell, rebrand, or
                                    otherwise transfer information found on the
                                    Platform;</p><p> (C) Reverse engineer, decompile,
                                        disassemble, decipher or otherwise attempt to derive
                                        the source code for any underlying intellectual
                                        property used in connection with the Platform;</p><p> (D)
                                            Include information that is confidential in nature
                                            or that reveals your identity or sensitive personal
                                            information such as an email address, phone number
                                            or address; </p><p>(E) Create a user profile for anyone
                                                other than yourself or otherwise use or attempt to
                                                use another's account without our authorization;</p><p> (F)
                                                    Utilize information and/or content you view on
                                                    and/or obtain from the Platform to provide any
                                                    service that is competitive with the Platform or our
                                                    business;</p><p> (G) Imply or state, directly or
                                                        indirectly, that you are affiliated with or endorsed
                                                        by AlanTech;</p><p> (H) Adapt, modify or create derivative
                                                            works based on the Platform or the technology
                                                            underlying the Platform, or other User’s information
                                                            and/or content;</p>
                                information therein;<p> (I) Deep-link to the Platform
                                    for any purpose; </p><p>(J) Use manual or automated
                                        software, devices, scripts, robots, or other means
                                        or processes to access, “scrape,” “crawl” or
                                        “spider” the Platform;</p><p> (K) Use bots or other
                                            automated methods to add or download contacts, send
                                            or redirect messages or perform any other
                                            activities;</p><p> (L) Repeatedly access, via automated or
                                                manual means or processes, the Platform for purposes
                                                of monitoring its availability, performance or
                                                functionality or for any competitive purpose;</p><p> (M)
                                                    Engage in “framing,” “mirroring,” or otherwise
                                                    simulating the appearance or function of the
                                                    Platform; </p><p>(N) Attempt to or actually access the
                                                        Platform by any means other than through the
                                                        interfaces provided by us;</p><p> (O) Attempt to or
                                                            actually override any security component included in
                                                            or underlying the Platform; </p><p>(P) Engage in any action
                                                                that directly or indirectly interferes with the
                                                                proper working of or places an unreasonable load on
                                                                our infrastructure, including but not limited to
                                                                sending unsolicited communications to other Users or
                                                                our personnel, attempting to gain unauthorized
                                                                access, or transmitting or activating computer
                                                                viruses;</p> <p> (Q) Remove any copyright, trademark or
                                                                    other proprietary rights notices contained in or on
                                                                    the Platform; </p><p>(R) Remove, cover or otherwise obscure
                                                                        any form of advertisement included on the Platform;</p><p>
                                    (S) Harass, abuse or harm another person, including
                                    sending unwelcome communications to Users;</p> <p> (T)
                                        Collect, use or transfer any information, including
                                        but not limited to, personally identifiable
                                        information obtained from the Platform except as
                                        expressly permitted in the Terms of Use or as the
                                        owner of such information may expressly permit;</p><p> (U)
                                            Share non-Users’ information without their express
                                            consent;</p><p> (V) Interfere with or disrupt the Platform,
                                                including but not limited to any servers or networks
                                                connected to the Platform;</p><p> (W) Upload a cartoon,
                                                    symbol, drawing or any information and/or content
                                                    other than a head-shot photograph of yourself in
                                                    your profile photo (if applicable);</p><p> (X) Participate,
                                                        directly or indirectly, in the setting up or
                                                        development of a network that seeks to create a
                                                        pyramid scheme or other similar practice;</p><p> (Y)
                                                            Infringe on or use our brand, logos and/or
                                                            trademarks, or words likely to be confused with
                                                            those words, in any business name, email, or URL; or</p>
                                <p> (Z) upload, post to the Platform, email, transmit or
                                    otherwise make available or initiate any information
                                    and/or content that:</p><p>(i) Falsely states,
                                impersonates or otherwise misrepresents your
                                identity, including but not limited to the use of a
                                pseudonym, or misrepresenting your current or
                                previous positions and qualifications, or your
                                affiliations with a person or entity, past or
                                present;</p>  <p>  (ii) Is unlawful, libelous, abusive,
                                obscene, discriminatory or otherwise objectionable;</p>
                                <p> (iii) Adds to a content field information and/or
                                content that is not intended for such field (e.g,
                                submitting a telephone number in the “title” or any
                                other field, or including telephone numbers, email
                                addresses, street addresses or any personally
                                identifiable information for which there is not a
                                field provided by us);</p>  <p> (iv) Includes information
                                that you do not have the right to disclose or make
                                available under any law or under contractual or
                                fiduciary relationships (such as insider
                                information, or proprietary and confidential
                                information learned or disclosed as part of
                                employment relationships or under nondisclosure
                                agreements);</p>   <p> (v) Infringes upon patents, trademarks,
                                trade secrets, copyrights or other proprietary
                                rights;</p>   <p> (vi) Includes any unsolicited or
                                unauthorized advertising, promotional materials,
                                “junk mail,” “spam,” “chain letters,” “pyramid
                                schemes,” or any other form of solicitation
                                including, without limitation:</p>   <p> (a) using the
                                Platform to send messages to people who do not know
                                you or who are unlikely to recognize you as a known
                                contact;</p>   <p> (b) using the Platform to connect to people
                                who do not know you and then sending unsolicited
                                promotional messages to those connections without
                                their permission; and</p>   <p> (c) sending messages to
                                distribution lists, newsgroup aliases or group
                                aliases;</p>   <p> (vii) Contains software viruses, worms, or
                                any other computer code, files or programs that
                                interrupt, destroy or limit the functionality of any
                                computer software or hardware or telecommunications
                                equipment of ours or any User; and/or </p>   <p>(viii) Forges
                                headers or otherwise manipulates identifiers in
                                order to disguise the origin of any communication
                                transmitted through the Platform.</p></li> 

                            <li><p><strong>Suspension and Termination. </strong>AlanTech
                                may suspend or remove you from the Platform at any
                                time for any or no reason at all, and suspension or
                                removal will be effective immediately upon delivery
                                of such notice. If AlanTech suspends or removes you
                                from the Platform, you may not be entitled to
                                rejoin. In addition to suspension or removal,
                                AlanTech reserves the right to take appropriate
                                legal action, including without limitation pursuing
                                civil, criminal, and injunctive relief. Even after
                                your suspension or removal, the Terms of Use will
                                remain enforceable against you.</p> </li>

                            <li><p><strong>Developer Content.</strong>Developer hereby
                                grants AlanTech a royalty-free, perpetual,
                                irrevocable, non-exclusive, fully paid, transferable
                                right and license to use, reproduce, modify, adapt,
                                publish, distribute, translate, communicate to the
                                public, create derivative works from, distribute,
                                perform and display any messages, information, data,
                                text, images, or other materials submitted to or
                                transmitted through the Platform (in whole or part)
                                (collectively, “Developer Content”) worldwide, or to
                                incorporate it in other works in any form, media, or
                                technology now known or later developed (for the
                                full term of any rights that may exist to such
                                Developer Content), without restriction or
                                compensation, and to grant and authorize sublicenses
                                thereof. In addition, you warrant that you have
                                waived all so-called "moral rights" in the Developer
                                Content. AlanTech and its designees shall have the
                                right, but not the obligation, in their sole
                                discretion to edit, refuse to post, or remove any
                                Developer Content posted on the Platform that
                                violates the Terms of Use or is otherwise
                                objectionable in AlanTech sole and exclusive
                                discretion. By submitting Developer Content to or
                                through the Platform, you represent and warrant
                                that: (A) You own the Developer Content; (B) You are
                                entitled to submit the Developer Content; and, (C)
                                the Developer Content is accurate, truthful, not
                                confidential, and not in violation of any
                                contractual restrictions or other third party
                                rights.</p></li>

                            <li><p><strong>Third-Party Websites.</strong>The Platform
                                may link to websites and/or applications operated by
                                third parties, including, without limitation,
                                licensors or advertisers (“Third-Party Website”).
                                AlanTech does not control Third-Party Websites and
                                is not responsible for them, their availability,
                                their information and/or content or any viruses that
                                may be accessed through them. The inclusion of
                                hyperlinks or other connections to Third-Party
                                Websites does not imply any endorsement of them or
                                any association with their owners or operators. You
                                are solely responsible for reviewing and evaluating
                                whether you want to access or use such Third-Party
                                Websites, including any applicable terms and/or
                                privacy practices.</p></li>

                            <li><p><strong>AlanTech Intellectual Property.</strong>All
                                of the design, text, and graphics of the Platform,
                                as well as the selection and arrangement thereof,
                                are proprietary and copyrighted works of AlanTech,
                                with all rights reserved. AlanTech, AlanTech and the
                                AlanTech/eyeball logo are registered trademarks of
                                AlanTech. The Platform images and text, and all page
                                headers, graphics, HTML based computer programs used
                                to generate pages on the Platform, and icons are our
                                trademarks, service marks and/or trade dress, and
                                may not be used without our prior written
                                permission. All uses thereof inure to our benefit.
                                All other trademarks, product names and company
                                names or logos included on the Platform are the
                                property of their respective owners.</p></li>

                            <li><p><strong>Ownership of Developers Work Product.</strong>All
                                deliverables, if any, and portions thereof, and all
                                intermediate and partial versions thereof (the “Work
                                Product”) created by a Developer for a Client shall
                                be works-made-for-hire owned by such Client upon
                                full payment of all amounts due therefor. In the
                                event the Work Product or any portion thereof is
                                found by a court of competent jurisdiction not to be
                                a “work-made-for-hire” within the meaning of the
                                Copyright Act of 1976, as amended, Developer hereby
                                expressly assigns to Client all exclusive right,
                                title and interest in and to the Work Product
                                without further consideration, and free from any
                                claim or rights of retention on the part of
                                Developer. Developer agrees to execute all documents
                                that may, in Client’s reasonable discretion, be
                                required to perfect such assignment.</p> </li>

                            <li><p><strong>Confidentiality.</strong>Any information
                                and/or content not generally known by or disclosed
                                to the public including, without limitation,
                                information and/or content:</p> <p>(A) disclosed by Clients
                                to AlanTech and/or Developers; </p><p>(B) created by
                                Developers for Clients; and, </p><p>(C) any information
                                and/or content disclosed pursuant to a mutually
                                executed confidentiality and/or nondisclosure
                                agreement (collectively, “Confidential Information”)
                                shall be maintained in strict confidence by the
                                recipient of such Confidential Information
                                (“Recipient”) including, without limitation,
                                AlanTech and Developer. Recipients shall not use or
                                disclose any Confidential Information and access to
                                and use of any Confidential Information shall be
                                restricted by Recipient to those persons with known
                                discretion and with a need to use the information,
                                provided that such persons have executed a
                                confidentiality and/or nondisclosure agreement.
                                Notwithstanding anything herein to the contrary,
                                Confidential Information shall not include
                                information and/or content that is: </p><p>(a) already
                                known to or otherwise in the possession of a person
                                at the time of receipt from the other party and that
                                was not known or received as the result of violation
                                of any obligation of confidentiality; </p><p>(b) publicly
                                available or otherwise in the public domain prior to
                                disclosure by a party; </p><p>(c) rightfully obtained by a
                                party from any third party having a right to
                                disclose such information without restriction and
                                without breach of any confidentiality obligation by
                                such third party; </p><p>(d) developed by a party
                                independent of any disclosure hereunder, as
                                evidenced by written records; or</p> <p>(e) disclosed
                                pursuant to the order of a court or administrative
                                body of competent jurisdiction or a government
                                agency, provided that the party receiving such order
                                shall notify the other prior to such disclosure and
                                shall cooperate with the other party in the event
                                such party elects to legally contest, request
                                confidential treatment, or otherwise avoid such
                                disclosure. </p></li>

                            <li><p><strong>DMCA Claims.</strong>If you believe, in good
                                faith, that any materials on the Platform infringe
                                upon your copyrights, please send the following
                                information to AlanTech’ Copyright Agent at: 340 S
                                AlanTech Ave, #7065, Walnut, CA 91789, USA:</p><p>(A) A
                                description of the copyrighted work that you claim
                                has been infringed, including the URL (Internet
                                address) or other specific location on the Platform
                                where the material you claim is infringed is
                                located; </p><p>(B) Include enough information to allow us
                                to locate the material, and explain why you think an
                                infringement has taken place; </p><p>(C) Your name,
                                address, telephone number, and e-mail address; </p><p>(D) A
                                statement by you that you have a good faith belief
                                that the disputed use is not authorized by the
                                copyright owner, its agent, or the law; </p><p>(E) A
                                statement by you, made under penalty of perjury,
                                that the information in your notice is accurate, and
                                that you are the copyright owner or authorized to
                                act on the copyright owner's behalf; and </p><p>(F) An
                                electronic or physical signature of the owner of the
                                copyright or the person authorized to act on behalf
                                of the owner of the copyright interest.</p> </li>

                            <li><p><strong>Jurisdiction; Venue.</strong> Any dispute,
                                demand, claim, controversy, cause of action, lawsuit
                                or proceeding (collectively, “Dispute”) arising out
                                of or in any way related to the Platform and/or the
                                Terms of Use shall be governed by and construed in
                                accordance with the laws of the State of Delaware,
                                without regard to its provisions regarding conflicts
                                of law. You submit to the exclusive jurisdiction of
                                the state and federal courts of Delaware.</p> </li>

                            <li><p><strong>No Jury; Class Action Waiver. </strong>you hereby irrevocably waive your right to trial by jury in any dispute involving AlanTech. you further agree to waive any right to resolve any dispute involving AlanTech as a representative or member in any class or representative action, except where such waiver is prohibited by law or deemed by a court of law to be against public policy. to the extent you are permitted by law or court of law to proceed with a class or representative action, you agree that: (i) the prevailing party shall not be entitled to recover attorneys' fees or costs associated with pursuing the class or representative action; and (ii) you will not submit a claim or otherwise participate in any recovery secured through the class or representative action.</p> </li>

                            <li><p><strong>Disclaimer of Warranties. </strong> THE
                                platform is provided on an "as is" and "as available" basis without warranties of any kind, express or implied, including, without limitation, warranties of title, custom, usage, merchantability, noninfringement, or fitness for a particular purpose. you hereby expressly agree that use of the platform is at your sole and exclusive risk. further, AlanTech does not represent or warrant that the platform is free from viruses, trojan horses, worms, time bombs, spiders, cancel bots, corrupted files, or any other similar software, malware, or materials that may damage, interfere with, disrupt, impair, disable, or otherwise overburden the operation of any device, computer system, or network.</p></li>

                            <li><p><strong>Release.</strong> To the extent permitted by law, AlanTech hereby expressly disclaims, and you hereby expressly release AlanTech from, any and all disputes for damages, injuries, losses, and liabilities associated with the following: (a) AlanTech's own negligence; (b) that use of the platform will be uninterrupted or error-free; (c) that any services will be reliable, accurate, safe, timely, or complete; (d) the inaccuracy, untimeliness, or incompleteness of any information and/or content including, without limitation, any reliance upon such information and/or content; (e) any misstatements or misrepresentations made by any user; (f) third-party websites including, without limitation, related to availability, terms of use, privacy practices, information, content, materials, advertising, charges, payment, products, and/or services; (g) the conduct of any user online or offline including, without limitation, harassment, abuse, defamation, injury, and damage to or destruction of property; (h) the conduct of third parties including, without limitation, hackers or others who engage in the unauthorized access, use, or misappropriation of any information and/or content; and (i) any information and/or content that may be misappropriated, intercepted, deleted, destroyed, or used by others.</p> </li>

                            <li><p><strong>Limitation of Liability.</strong> extent permitted by law, under no circumstances will alantech be liable to any user or any third party for direct, indirect, incidental, consequential, special, punitive, or exemplary damages arising from, in connection with, or in any way related to the services or the platform, even if advised of the possibility of the same. notwithstanding anything in the terms of use to the contrary, if it is determined that alantech is liable to you, in no event will the aggregate liability, whether arising in contract, tort, strict liability or otherwise, exceed five-hundred dollars ($500).</p></li>

                            <li><p><strong>Indemnity. </strong>you agree to defend, indemnify, and hold AlanTech harmless from and against any and all disputes, including without limitation for damages, injuries, losses, liabilities, and reasonable legal and accounting fees (collectively, "indemnified claims"), resulting from, or alleged to result from: (a) your breach of the terms of use; (b) any information and/or content you submit to or transmit through the platform; (c) your misuse of the platform including, without limitation, your unauthorized or unlawful use of the platform; (d) your violation of any applicable law, rule, or regulation pertaining to your use of the platform; (e) the use of the platform by any other person using your account. we will use reasonable efforts to provide you with prompt notice of any such indemnified claims and may assist you, at our expense and option, in defending any such indemnified claims.</p></li>

                            <li><p><strong>Waiver; Severability. </strong>Failure by
                                AlanTech to
                                enforce any provision(s) of the Terms of Use
                                will not be construed as a waiver of any
                                provision or right. The Terms of Use constitute
                                the entire agreement between you and AlanTech.
                                If any provision of the Terms of Use is found to
                                be invalid or unenforceable, the remaining
                                provisions will be enforced to the fullest
                                extent possible, and the remaining provisions
                                will remain in full force and effect. The Terms
                                of Use will inure to the benefit of AlanTech,
                                its successors and assigns.</p></li>

                        </ol>

                    </div>

                </div>
            </section>
            <Footer />
        </>
    )
}

export default Terms