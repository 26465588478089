import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import {
  Container,
  InputAdornment,
  Typography,
  Button,
  FormHelperText,
  TextField,
  Box,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";

function Signup({ handleChange, values, errors, touched, handleBlur }) {

  const navigate = useNavigate();

  function SweetAlert() {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Developer data saved",
      confirmButtonText: "OK",
      timer: 8000,
    });
  }
  const handleSubmits = async (e) => {
    e.preventDefault();

    if (
      errors.email === undefined &&
      errors.phoneNumber === undefined &&
      errors.password === undefined &&
      values.email &&
      values.phoneNumber &&
      values.password
    ) {
      try {
        // Check if email already exists
        const emailCheckResponse = await axios.post(
          `${process.env.REACT_APP_URL}/developers/signupExistEmail`,
          { email: values.email }
        );

        if (emailCheckResponse.data.exists) {
          // Email exists
          setUserEmailValid(true);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Email already exists",
            confirmButtonText: "OK",
          });
        } else {
          // Email doesn't exist, proceed with registration
          setUserEmailValid(false);

          const registerResponse = await axios.post(
            `${process.env.REACT_APP_URL}/developers/register`,
            values
          );

          if (registerResponse.data.success) {
            // Successful registration
            console.log("Developer data saved:", registerResponse.data.message);
            SweetAlert();
            navigate("/login");
          } else {
            // Registration failed
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Failed to register developer",
              confirmButtonText: "OK",
            });
          }
        }
      } catch (error) {
        console.error("Error during registration:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred during registration",
          confirmButtonText: "OK",
        });
      }
    } else {
      // Handle form validation errors
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please check the form for errors",
        confirmButtonText: "OK",
      });
    }
  };

  // const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = React.useState(false);
  const [userEmailValid, setUserEmailValid] = useState(false);
  const [btn, setBtn] = useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  React.useEffect(() => {
    if (values.email.length > 2 && errors.email != undefined) {
      setUserEmailValid(false)
    }
  }, [errors.email])


  React.useEffect(() => {

    if ((errors.email === undefined && errors.phoneNumber === undefined && errors.password === undefined && !userEmailValid)
      && (!!values.email && !!values.phoneNumber && values.password)) {
      setBtn(false)
    } else {
      setBtn(true)
    }
  }, [values, errors])
  const client_service = process.env.REACT_APP_CLIENT_SERVICE;
  const Auth_Key = process.env.REACT_APP_AUTH_KEY;
  const URL = process.env.REACT_APP_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if ((errors.email === undefined && errors.phoneNumber === undefined && errors.password === undefined && !userEmailValid) &&
      (!!values.email && !!values.phoneNumber && values.password)) {
      const formData = new FormData();
      formData.append('Mainspecialization', values.Mainspecialization);
      formData.append('country', values.country);
      formData.append('email', values.email);
      formData.append('experiences', values.experiences);
      formData.append('firstName', values.firstName);
      formData.append('hourlyRate', values.hourlyRate);
      formData.append('lastName', values.lastName);
      formData.append('linkedIn', values.linkedIn);
      formData.append('password', values.password);
      formData.append('phoneNumber', values.phoneNumber);
      formData.append('project_engagement', values.project_engagement);
      formData.append('seniorityLevel', values.seniorityLevel);
      formData.append('newSkill', JSON.stringify(values.newSkill))


      const response = await axios.post(`${URL}/developers`, formData, {
        headers: {
          'Client-service': client_service,
          "Auth-Key": Auth_Key
        }
      })
      if (response.status == 200) {
        SweetAlert();
        navigate('/application')
      }
      else {
        alert("error in saving details try after sometimes")
      }

    }
  };

  const emailValidation = async () => {
    const formData = new FormData();
    formData.append('email', values.email)
    const response = await axios.post(`${process.env.REACT_APP_URL}/auth2/checkemail`, formData, {
      headers: {
        'Client-service': process.env.REACT_APP_CLIENT_SERVICE,
        "Auth-Key": process.env.REACT_APP_AUTH_KEY
      }
    })
    if (response.data.status == 1) {
      setUserEmailValid(true)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Email already used please login",
      });
    }
    else {
      setUserEmailValid(false)

    }
    console.log(response.data.status)
  }
  const paperStyle = {
    padding: { lg: "50px", xs: "20px", md: "30px" },
  };

  const box = {
    width: {
      lg: 600,
      md: 500,
    },
    height: {
      lg: "100vh",
      md: "100vh",
      xs: "600px",
    },
    display: "flex",
    alignItems: "center",
  };
  const button = {
    marginTop: { lg: 5 },
    backgroundColor: "#294b86  ",
  };

  return (
    <Box>
      <Container sx={box}>
        <Paper elevation={5} sx={paperStyle}>
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
                  <img
                    src={require("../../images/ALAN Final logo-01.png")}
                    height={"40%"}
                    width={"60%"}
                  />
                </Box>
                <Typography variant="h4" align="center" gutterBottom>
                  Personal Details
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="email"
                  label="Email Address"
                  name="email"
                  variant="outlined"
                  value={values.email}
                  onChange={handleChange}
                  error={Boolean(errors.email) && touched.email || userEmailValid}
                  helperText={Boolean(errors.email) && touched.email ? errors.email : "" || userEmailValid}
                  onBlur={(e) => {
                    handleBlur(e);
                    emailValidation(e);
                  }}
                />
                <FormHelperText sx={{ color: "#d32f2f" }}>{userEmailValid ? "email already exist" : ""}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  label="Phone Number"
                  name="phoneNumber"
                  variant="outlined"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  error={Boolean(errors.phoneNumber) && touched.phoneNumber}
                  helperText={Boolean(errors.phoneNumber) && touched.phoneNumber ? errors.phoneNumber : ""}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>

                <TextField
                  label="Password"
                  name="password"
                  type={showPassword ? 'text' : 'password'} // Toggle password visibility
                  fullWidth
                  helperText={errors.password && touched.password ? errors.password : ""}
                  value={values.password}
                  error={touched.password && Boolean(errors.password)}
                  onBlur={handleBlur}
                  onChange={handleChange}

                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

              </Grid>

              <Grid item xs={12}>
                <Button
                  disabled={btn}
                  fullWidth
                  type="submit"
                  sx={{ py: 1, bgcolor: " #294b86 !important", color: "white" }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </Box>
  );
}

export default Signup;
