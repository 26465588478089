import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Button from "../../buttons";
import { useNavigate } from 'react-router-dom'

function Hire_remote_dev() {
    const navigate = useNavigate();
    return (
        <>
            <Header />
            <section id="hireeremote" className="d-flex align-items-center">
                <div className="container col-12" data-aos="zoom-out" data-aos-duration="1000">
                    <h1>TIRED OF SLOW IN-HOUSE TEAMS? <span><br />HIRE THE BEST REMOTE DEVS<br /></span></h1>
                    <br />

                </div>

            </section>
            <div className="container mt-4" data-aos="fade-right" data-aos-duration="1000">
                <p>Our impeccable process of vetting, matching, and recruiting eliminates any possibility of errors.</p>

            </div>
            <div id='mediabutton'>
                <button className="col-sm-2  btn btn-primary mb-5 shadow-lg mt-3 mx-4" onClick={() => navigate('/hire')}>
                MATCH ME WITH A DEV
                </button>
            </div>

            <section id="featured-services" className="featured-services">

                <div className="container" data-aos="fade-up">
                    <h2 className="text-center mt-5">WHY HIRE<span> OUR DEVELOPERS?</span></h2>

                    <div className="row my-5 d-flex justify-content-center">
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-duration="1500" >
                                <div className="icon text-center"><i class="bi bi-1-square-fill"></i></div>

                                <p className="description text-center text-dark">AlanTech offers vetted developers only</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
                                <div className="icon text-center"><i class="bi bi-2-square-fill"></i></div>

                                <p className="description text-center text-dark">You’ll get the first candidates in 1-2 days</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                                <div className="icon text-center"><i class="bi bi-3-square-fill"></i></div>

                                <p className="description text-center text-dark">No sweat: we’ll give you a hand</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Button />


            <div className="container mt-5">
                <div className="row">
                    <div className="dreamteam  col-12 col-lg-6" data-aos="fade-right" data-aos-duration="1000">
                        <img className="img-responsive boder-" src={(require('../../../images/hire_devs/th2.jpg'))} width="100%" height="100%" alt="" />
                    </div>
                    <div className="dreamteamess col-12 col-lg-6" data-aos="fade-left" data-aos-duration="1000">
                        <h2 className="mt-5 mb-3">WHAT ARE THE PILLARS OF OUR TEMPLE?</h2>
                        <p>Limber and precise matching</p>

                        <p>Extra fast hiring</p>

                        <p> Micromanagement-free approach</p>

                        <p> Omnipotent support</p>

                        <p> No payment complications</p>

                    </div>
                    <div id='mediabutton'>
                        <button className="col-sm-4  btn btn-primary mb-5 shadow-lg mt-3 mx-4" onClick={() => navigate('/hire')}>
                            HIRE MIGHTY DEVS
                        </button>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <h2 className="text-center">OUR VETTING SIEVE LEAVES</h2>
                <div className="row mt-4">
                    <div className="etDeve col-12 col-lg-6" data-aos="fade-right" data-aos-duration="1000"  >

                        <p><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-04.png'))} width="10%" height="10%" data-aos="fade-right" data-aos-duration="1000" /> professional networks check (LinkedIn, GitHub, forums)</p>
                        <p><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-05.png'))} width="10%" height="10%" data-aos="fade-right" data-aos-duration="1000" />english proficiency test (oral/written communication)</p>

                    </div>
                    <div className="etDeveess col-12 col-lg-6" data-aos="fade-left" data-aos-duration="1000" >
                        <p><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-07.png'))} width="10%" height="10%" data-aos="fade-left" data-aos-duration="1000" />soft skills test (communication, time management, empathy)</p>
                        <p><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-08.png'))} width="10%" height="10%" data-aos="fade-left" data-aos-duration="1000" />coding proficiency test (live coding & code analysis)</p>

                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">

                    <div className="dreamteamess col-12 col-lg-6" data-aos="fade-right" data-aos-duration="1000">
                        <h2 className="mb-3"> AlanTech IS <span>THE MARKETPLACE </span> FOR PERFECT MATCHES </h2>
                        <p> tired of babysitting your developers?</p>

                        <p> no time for constant control?</p>

                        <p>with our engineers, your startup will fly at once!</p>
                        <p> forget the neverending hunt for the perfect engineers that steals most of the working days for every budding startup. what do you want more — carefully think over your future strategies or tediously scan the internet for qualified specialists? plan the profits — we’ll take care of the staffing routine. our procedures are plain and swift. hit the button to check it out!</p>

                    </div>
                    <div id='mediabutton'>
                        <button className="col-sm-4  btn btn-primary mb-5 shadow-lg mt-3 mx-4" onClick={() => navigate('/hire')}>
                            CONFESS YOUR NEEDS
                        </button>
                    </div>
                    <div className="dreamteam  col-12 col-lg-6" data-aos="fade-left" data-aos-duration="1000">
                        <img className="img-responsive boder-" src={(require('../../../images/hire_devs/th19.jpg'))} width="100%" height="100%" alt="" />
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="dreamteam  col-12 col-lg-6" data-aos="fade-right" data-aos-duration="1000">
                        <img className="img-responsive boder-" src={(require('../../../images/hire_devs/gif18.gif'))} width="100%" height="100%" alt="" />
                    </div>
                    <div className="dreamteamess col-12 col-lg-6" data-aos="fade-left" data-aos-duration="1000">
                        <h2 className="mb-3">BECAUSE YOU AIN’T A SPIDER…</h2>
                        <p>Every superhero requires their own dream team, and iconic names like Superman, Spider-Man, and Batman readily come to mind. However, when it comes to the realm of digital startups and web applications, such a lone-wolf approach is no longer effective. Building a web service, especially one that aims to be a top-tier contender in the app world, necessitates a different strategy.</p>

                        <p>For those embarking on the extensive journey of recruitment, which involves navigating through assessments, evaluations, and learning from mistakes, a pivotal choice emerges: should the team be in-house or remote? While the traditional in-house hiring process can be time-consuming, the landscape has shifted significantly with the surge of remote work, accelerated even further in the post-pandemic era. </p>

                    </div>
                    <div id='mediabutton'>
                        <button className="col-sm-4  btn btn-primary mb-5 shadow-lg mt-3 mx-4" onClick={() => navigate('/hire')}>
                            CHOOSE YOUR DEV
                        </button>
                    </div>
                </div>
            </div>
            <div className="container ">
                <div className="row">

                    <div className="dreamteamess col-12 col-lg-6" data-aos="fade-right" data-aos-duration="1000">
                        <h2 className="mt-5 mb-3">BOO! IT’S NOT A POLTERGEIST</h2>
                        <p>What exactly is remote development, and how can it contribute to your global success? In simple terms, a remote developer stands in contrast to an in-house developer. While the latter is a permanent member of your team, working consistently throughout the week, the former is brought on board for specific periods and tasks.</p>

                        <p>Unlike in-house developers who are often responsible for a wide range of tasks, remote developers can be enlisted for particular assignments that align with their expertise. By breaking down your workload into distinct functional components, you have the option to engage multiple independent contractors, typically one or two for each task. This strategic approach proves advantageous: if any individual lags behind, their role can be seamlessly filled by a replacement without causing disruptions to your business operations.</p>

                    </div>
                    <div id='mediabutton'>
                        <button className="col-sm-4  btn btn-primary mb-5 shadow-lg mt-3 mx-4" onClick={() => navigate('/hire')}>
                            CONFESS YOUR NEEDS
                        </button>
                    </div>
                    <div className="dreamteam  col-12 col-lg-6" data-aos="fade-left" data-aos-duration="1000">
                        <img className="img-responsive boder-" src={(require('../../../images/hire_devs/gif20.gif'))} width="100%" height="100%" alt="" />
                    </div>
                </div>
            </div>


            <section id="featured-services" className="featured-services ">

                <div className="container mt-5" data-aos="fade-up">
                    <h2 className="text-center">CALL YOUR TROUBLESHOOTING<br />SQUAD & SAVE THE EFFORTS!<br /></h2>

                    <div className="row my-5 d-flex justify-content-center">
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-duration="1500" >
                                <div className="icon text-center"><i class="bi bi-calendar2-heart"></i></div>

                                <p className="description text-center text-dark">finding an excellent in-house developer can be tedious if you work far from global markets. hiring remote software developers is a real panacea for such conditions.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
                                <div className="icon text-center"><i class="bi bi-caret-right-square-fill"></i></div>

                                <p className="description text-center text-dark">on the overheated markets, real professionals (even with hilariously busted hourly rates) are snapped up like hotcakes. you snooze – you lose! </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                                <div className="icon text-center"><i class="bi bi-chat-dots-fill"></i></div>

                                <p className="description text-center text-dark">
                                    hiring in-house for first projects can be ill-advised if your company is a small but fast-growing startup. are you 100 % sure you want to cover all the additional expenses?</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            <section id="featured-services" className="featured-services mt-5">

                <div className="container" data-aos="fade-up">
                    <div className="row my-5 d-flex justify-content-center">
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-duration="1500" >
                                <div className="icon text-center"><i class="bi bi-browser-edge"></i></div>

                                <p className="description text-center text-dark">companies with irregular workloads should also look for remote teams: if you hire in-house, you pay the fixed sum monthly — the workload notwithstanding.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
                                <div className="icon text-center"><i class="bi bi-chat-left-dots-fill"></i></div>

                                <p className="description text-center text-dark">for midterm projects up to one year, it’s wiser to hire remote developers: for full-timers, the integration is longer — if the project ends soon, it doesn’t pay off.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                                <div className="icon text-center"><i class="bi bi-chat-square-text-fill"></i></div>

                                <p className="description text-center text-dark">
                                    usability is the cornerstone of all open-source e-commerce projects. hiring remote devs for maintenance and service is convenient: small irregular tasks are their daily bread.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            <div className="container ">
                <div className="row">
                    <div className="dreamteam  col-12 col-lg-6" data-aos="fade-right" data-aos-duration="1000">
                        <img className="img-responsive boder-" src={(require('../../../images/hire_devs/th10.jpg'))} width="100%" height="100%" alt="" />
                    </div>

                    <div className="dreamteamess col-12 col-lg-6" data-aos="fade-left" data-aos-duration="1000">
                        <h2 className="mt-5 mb-3" ><span>REMOTE </span> IS A NEW BLACK!</h2>
                        <p >The prominence and significance of remote development in the realm of burgeoning startups stem from several key factors. We are firmly entrenched in what can be termed the "remote age," where traditional in-house employment is steadily relinquishing its stronghold on the job market.  </p>

                        <p>In contemporary times, individuals are increasingly recognizing the inefficiencies of commuting to physical offices on a daily basis, which can consume anywhere from one to three hours of their precious time.  </p>
                        <p>This is even more evident in the case of programmers and developers. The tools of their trade are inherently portable, and wireless connectivity has become nearly ubiquitous, rendering the need to spend prime hours within the confines of an office less and less compelling.</p>

                    </div>
                


                </div>
            </div>
            <div className="container mt-5">
                <h2 className="text-center">How Do We <span>Vet Developers</span> at AlanTech?</h2>
                <div className="row mt-4">
                    <div className="webel col-12 col-lg-6 " data-aos="fade-right" data-aos-duration="1000" >

                        <p><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-04.png'))} width="10%" height="10%" data-aos="fade-right" data-aos-duration="1000" /> the highest hourly rates for the remote devs are in the us (up to $70) — but it’s still cheaper than hiring in-house teams and agencies. in eastern europe, prices are much lower for the same skill set, which is $45 on average (more below).</p>
                        <p><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-05.png'))} width="10%" height="10%" data-aos="fade-right" data-aos-duration="1000" />you can find any skills desirable for your project in the remote development market. if these proficiencies are evenly distributed between several people, plan the timing/budget, and hire them all. say no to incompetence, and staff your startup beneficially.</p>
                        <p><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-06.png'))} width="10%" height="10%" data-aos="fade-right" data-aos-duration="1000" />evolve — or stay poor. that’s the jungle law of the remote world. if nobody offers you a monthly salary, you should win the daily bread, standing out from the it crowd. that’s why most remote specialists keep pace with all the modern requirements.</p>

                    </div>
                    <div className="webeless col-12 col-lg-6 " data-aos="fade-left" data-aos-duration="1000" >
                        <p><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-07.png'))} width="10%" height="10%" data-aos="fade-left" data-aos-duration="1000" />it’s generally acknowledged that unhindered straightforward communication is a key to transparency. without any mediators between you and your hired web developers, every bit of information will be passed on undistorted, and all the processes will speed up tremendously</p>
                        <p><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-08.png'))} width="10%" height="10%" data-aos="fade-left" data-aos-duration="1000" />everyone who worked with in-houses knows they are unavailable after 6 pm. however, most businesses go on developing even after the evening tea, right? remote devs don’t have to sit in your office daily, but they will help you in real need.</p>
                        <p><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-09.png'))} width="10%" height="10%" data-aos="fade-left" data-aos-duration="1000" />modern online and standalone instruments for relaxing time tracking and task sharing (trello, slack, teamviewer, jiminny) help you follow the working schedule of all your remote developers. all your projects will be released in time with no profit loss.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">

                    <div className="companyis col-12 col-lg-6 " data-aos="fade-right" data-aos-duration="1000">
                        <h2 className="mb-3">HELLO, PONY!SHOW ME YOUR TRICKS </h2>
                        <p>  Efficiently Organize Work Schedule: They should have the ability to structure their work hours effectively in order to achieve the predetermined objectives. </p>

                        <p>  Stay Current with Emerging Technologies: Proficiency in new and relevant technologies is essential, enabling them to judiciously incorporate these tools where appropriate. </p>

                        <p>  Engage in Cross-Cultural Communication: Given the potential multinational nature of the project, they should be adept at engaging in discussions about potential issues with colleagues from diverse backgrounds. </p>

                        <p>  Possess Essential Soft Skills: A comprehensive range of soft skills, including adept time management, empathy, effective communication, and active listening, are imperative for seamless remote collaboration. </p>



                    </div>
                  
                    <div className="companyiser col-12 col-lg-6" data-aos="fade-left" data-aos-duration="1000">
                        <img className="img-responsive" src={(require('../../../images/hire_devs/gif6.gif'))} width="100%" height="90%" alt="" />
                    </div>

                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="companyiser col-12 col-lg-6" data-aos="fade-right" data-aos-duration="1000">
                        <img className="img-responsive" src={(require('../../../images/hire_devs/gif7.gif'))} width="100%" height="90%" alt="" />
                    </div>
                    <div className="companyis col-12 col-lg-6 " data-aos="fade-left" data-aos-duration="1000">
                        <h2 className="mb-3">NEED A SUPERSTAR FOR YOUR STARTUP?  </h2>
                        <p> why hire top remote developers through AlanTech? — we carefully test all our remote developers. we check the coding, communication, and english skills to ensure only the brightest and the most efficient professionals are considered worthy of our customers. </p>

                        <p>  during the background and reference checks, we gather information from former employers. keep calm; our current staff employees were sieved through this procedure too. </p>

                        <p> our english tests ensure transparent and smooth communication between you and your future squad. </p>

                        <p>  we prefer live interviews. communication is the key, and perfect communication is the key to excellent projects. </p>
                        <p>only 4 out of 100 candidates successfully pass everything we prepare for them. to make the perfect team of 10 colleagues, we screen 250 strangers.</p>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">

                    <div className="companyis col-12 col-lg-6 " data-aos="fade-right" data-aos-duration="1000">
                        <h2 className="mb-3">Bidding Marketplaces</h2>
                        <p> bidding marketplaces offer you plenty of candidates — but these aces are not checked (just as their credentials and achievements). choosing there, you should have a time reserve for all kinds of circumstances. pros — a bigger pool of options, cons — a cat in the sack is a cat in the bag, and the surprises aren’t always pleasant. </p>

                        <h2 className="mb-3">Vetting Marketplaces (Psst! That’s What You Need!)</h2>
                        <p> if the thought of choosing the suitable dev for your project out of the troubled waters of the bidding marketplaces makes you nervous, then the best option for you is probably vetting marketplaces.
                            they all test their developers, so those who get the “available” status are checked and proven eligible. no additional pain in the back. </p>
                    </div>
                    <div className="companyiser col-12 col-lg-6" data-aos="fade-left" data-aos-duration="1000">
                        <img className="img-responsive" src={(require('../../../images/hire_devs/gif8.gif'))} width="100%" height="90%" alt="" />
                    </div>
                </div>
            </div>


            <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up mt-5">
                    <div className="section-title">
                        <h2 >F.A.Q</h2>
                        <h3>Frequently Asked<span> Questions</span></h3>

                    </div>

                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <ul className="faq-list">

                                <li>
                                    <div data-bs-toggle="collapse" className="collapsed question" href="#faq1">What is a remote developer? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                                        <p>

                                            A remote developer is a professional coder creating web pages and applications for many different clients and getting money for every project. Since no employer dictates their rate, they choose it themselves — just as they choose clients or projects to work with.

                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq2" className="collapsed question">How to hire a great remote developer? <i className="bi bi-chevron-down icon-show"></i><i
                                        className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq2" className="collapse" data-bs-parent=".faq-list">


                                        <p>It’s not easy to find freelance coders who will be decent — but the time you spend on it will bring you numerous benefits in the future. Therefore, it’s essential to analyze the hiring process and choose the proper steps.</p>

                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq3" className="collapsed question">What is the difference between programmer and developer?<i className="bi bi-chevron-down icon-show"></i><i
                                        className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq3" className="collapse" data-bs-parent=".faq-list">


                                        <p>A developer is a software professional who manages the code in computer programs, specializing in a particular language or framework(s). In addition, developers maintain program documentation, computer architecture, user interfaces, and databases.</p>

                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq4" className="collapsed question">What should I know before hiring a developer?  <i className="bi bi-chevron-down icon-show"></i><i
                                        className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq4" className="collapse" data-bs-parent=".faq-list">


                                        <p>If you want to tie top talents with prominent competitors, activate your collective mind, cooperate with HR and Marketing departments, and consider your clear advantages. Incorporate them into your ad campaign. Queues of applicants prove that you’ve done everything correctly.</p>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div >
            </section >

            <Footer />
        </>
    )
}

export default Hire_remote_dev
