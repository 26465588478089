import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

function Footer() {
  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef(null);
  const navigate = useNavigate();
  const handleOutsideClick = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const[state,setState]=useState(false)

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  function Close() {
  
    setState(!state)

  }
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const [isHire, setIsHire] = useState(false);
  const listHire = useRef(null);

  const handleOutsideHire = (event) => {
    if (listHire.current && !listHire.current.contains(event.target)) {
      setIsHire(false);
    }
  };

  useEffect(() => {
    if (isHire) {
      document.addEventListener("mousedown", handleOutsideHire);
    } else {
      document.removeEventListener("mousedown", handleOutsideHire);
    }
    console.log(isHire);
    return () => {
      document.removeEventListener("mousedown", handleOutsideHire);
    };
  }, [isHire]);

  return (
    <div>
      <div id="popupContainer">
        <div id="popupContent"></div>
      </div>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Our Social Networks</h4>
                <p></p>
                <div className="social-links mt-3">
                  <a
                    href="https://twitter.com/alantech2023"
                    className="twitter"
                    target="_blank"
                  >
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/alantechnnologies"
                    className="facebook"
                    target="_blank"
                  >
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/alan_technologies/?hl=en"
                    className="instagram"
                    target="_blank"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                <li>
          
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a onClick={() => navigate("/about")}>About</a>
                  </li>

                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a onClick={() => navigate("/career")}>Career</a>
                   
                  </li>

                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a onClick={() => navigate("/contact")}>
                      Contact
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a onClick={() => navigate("/terms")}>Terms Of Use</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a onClick={() => navigate("/privacy")}>Privacy policy</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Resources</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right "></i>{" "}
                    <a onClick={() => setIsOpen(!isOpen)}>Job descriptions</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a onClick={() => setIsHire(!isHire)}>Hire developers</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a onClick={() => navigate("/faq_for_business")}>
                      Faq business
                    </a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a onClick={() => navigate("/faq_dev")}>Faq developers</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a onClick={() => navigate("/review")}>Review</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                <h4>Australia</h4>
                <span>
                  <strong>James David </strong>
                  <br />
                  Principal Technology Consultant
                  <br />
                  No:09, Merauke Street Whalan,
                  <br />
                  New South Wales PIN-2770
                  <br />
                  <strong>Phone:</strong> +61 414 624 839
                </span>
              </div>

              <div class="col-lg-3 col-md-6 footer-contact">
                <h4>Chennai</h4>
                <span>
                  No:38, Ramakrishna Street
                  <br />
                  Tambaram West
                  <br />
                  Chennai-600 045 Tamilnadu
                </span>
                <br />
                <strong>Phone:</strong> +91 9629998555
                <br />
                <strong>Email:</strong> info@alantechnologies.in
              </div>
            </div>
          </div>
        </div>

        <script src="assets/vendor/php-email-form/validate.js"></script>
        <div className="d-flex justify-content-evenly col-10 w-100 poptop  " >
          {isOpen && (
            <div className="row popup overflow-scroll" id="pop" ref={listRef} show={show}    >
          
             
                <b className="text-white"></b>
             
              <div className="col-lg-3 col-12 ">
                <ul>
                  <li>
                    <Link to="/front_end_dev">Front-end developer</Link>
                  </li>
                 
                  <li>
                    <Link to="/java_script">Javascript developer</Link>
                  </li>
                  <li>
                    <Link to="/reactjs"> Reactjs developer</Link>
                  </li>
                  <li>
                    <Link to="/dot_net"> Net developer</Link>
                  </li>
                  <li>
                    <Link to="/android">Android developer</Link>
                  </li>
                  <li>
                    <Link to="/aws">AWS developer</Link>
                  </li>
                  <li>
                    <Link to="/back-end">Back-end developer</Link>
                  </li>
                 
                  <li>
                    <Link to="/mongoDB">Mongodb developer</Link>
                  </li>
                  <li>
                    <Link to="/Typescript">Typescript developer</Link>
                  </li>
                  <li>
                    <Link to="/java">Java developer</Link>
                  </li>
                </ul>
              </div>
              <div className=" col-lg-3 col-12">
                <ul>
                  <li>
                    <Link to="/reactnative">react native developer</Link>
                  </li>
                  <li>
                    <Link to="/Content_dev">Content developer</Link>
                  </li>
                  <li>
                    <Link to="/Full_stack">full-stack developer</Link>
                  </li>
                  <li>
                    <Link to="/data_scientist">data scientist</Link>
                  </li>
                  <li>
                    <Link to="/HTML">html developer</Link>
                  </li>
                  <li>
                    <Link to="/Ai_engineer"> ai engineer</Link>
                  </li>
                  <li>
                    <Link to="/Angular_dev">angular developer</Link>
                  </li>
                  <li>
                    <Link to="/Application_dev">application developer</Link>
                  </li>
                  <li>
                    <Link to="/c++">c++ developer</Link>
                  </li>
                  <li>
                    <Link to="/Ios_dev">ios developer</Link>
                  </li>
                  <li>
                    <Link to="/node">node.js developer</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-12">
                <ul>
                  <li>
                    <Link to="/Wordpress_dev">wordpress developer</Link>
                  </li>
                  <li>
                    <Link to="/Embedded_dev">embedded developer</Link>
                  </li>
                  <li>
                    <Link to="/Ui_ux_designer">ui/ux designer</Link>
                  </li>
                  <li>
                    <Link to="/Qa_engineer">qa engineer</Link>
                  </li>
                  <li>
                    <Link to="/Flutter_dev">flutter developer</Link>
                  </li>
                  <li>
                    <Link to="/Software_engineer">software engineer</Link>
                  </li>
                  <li>
                    <Link to="/Machine_dev"> machine learning engineer</Link>
                  </li>
                  <li>
                    <Link to="/database_dev">database developer</Link>
                  </li>
                  <li>
                    <Link to='/data_analyst'>data analyst</Link>
                  </li>
                  <li>
                    <Link to="/Ci_cd_engineer">ci/cd engineer</Link>
                  </li>
                </ul>
              </div>
              <div className=" col-lg-3  col-12">
                <ul>
                  <li>
                    <Link to="/sql">sql developer</Link>
                  </li>
                  <li>
                    <Link to="/vue">vue.js developer</Link>
                  </li>
                  <li>
                    <Link to="/Senior_software">senior software developer</Link>
                  </li>
                  <li>
                    <Link to="/Unity3d_dev">unity3d developer</Link>
                  </li>
                  <li>
                    <Link to="/Scala_dev">scala developer</Link>
                  </li>
                  <li>
                    <Link to="/Senior_java">senior java developer</Link>
                  </li>
                  <li>
                    <Link to="/python">python developer</Link>
                  </li>
                  <li>
                    <Link to="/ruby">ruby on rails developer</Link>
                  </li>
                  <li>
                    <Link to="/Blockchain">blockchain developer</Link>
                  </li>
                  <li>
                    <Link to="/Job_devops">devops</Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className=" d-flex justify-content-center  poptop  ">
          {isHire && (
            <div className="row popup p-3 overflow-scroll " id="pop" ref={listHire}>
             
              <a className=" me-5 pe-5">
                <b className="text-white"></b>
              </a>
              <div className="col-md-6 col-12 col-lg-3 ">
                <ul>
                  <li>
                    <Link to="/hire_aws">Hire aws developer</Link>
                  </li>
                  <li>
                    <Link to="/hire_android">Hire android developers</Link>
                  </li>
                  <li>
                    <Link to="/hire_ios">Hire ios developers</Link>
                  </li>
                  <li>
                    <Link to="/hire_angular">Hire angular developers</Link>
                  </li>
                  <li>
                    <Link to="/hire_php">Hire php developers</Link>
                  </li>

                  <li>
                    <Link to="/hire_net">Hire .net developers</Link>
                  </li>
                  <li>
                    <Link to="/Ecommerce">Hire ecommerce developers</Link>
                  </li>
                  <li>
                    <Link to="/React_js">Hire react developers</Link>
                  </li>
                  <li>
                    <Link to="/Hire_part_time">hire part-time developers</Link>
                  </li>
                  <li>
                    <Link to="/Hire_python">hire python developers</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-lg-3">
                <ul>
                  <li>
                    <Link to="/Hire_mobile_app">hire app developers</Link>
                  </li>
                  <li>
                    <Link to="/Hire_frontend">hire front end developers</Link>
                  </li>
                  <li>
                    <Link to="/Hire_javascript">
                      hire javascript developers
                    </Link>
                  </li>
                  <li>
                    <Link to="/Hire_blockchain">
                      hire Blockchain developers
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/devop">hire Devops engineer</Link>
                  </li>
                  <li>
                    <Link to="/hire_flutter">hire Flutter developers</Link>
                  </li>

                  <li>
                    {" "}
                    <Link to="/hire_Jquery">hire jquery developers</Link>
                  </li>
                 
                  <li>
                    <Link to="/Hire_offshore">hire Offshore developers</Link>
                  </li>
                  <li>
                    <Link to="/hire_Rube">hire Ruby developers</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-12 col-lg-3">
                <ul>
                  <li>
                    <Link to="/hire_vue">hire vue.js developers</Link>
                  </li>

                  <li>
                    <Link to="/hire_Saas">hire SaaS developers</Link>
                  </li>
                  <li>
                    <Link to="/hire_Senior_developer">
                      hire Senior developers
                    </Link>
                  </li>
                  <li>
                    <Link to="/hire_tech_lead">hire Tech lead developers</Link>
                  </li>
                  <li>
                    <Link to="/react_native">hire React Native developers</Link>
                  </li>
                  <li>
                    <Link to="/hire_software">hire software architects</Link>
                  </li>
                  <li>
                    <Link to="/hire_typescript">
                      hire typescript developers
                    </Link>
                  </li>

                  <li>
                    <Link to="/hire_spring">hire spring developers</Link>
                  </li>
                  <li>
                    <Link to="/hire_swift">hire swift developers</Link>
                  </li>

                  <li>
                    <Link to="/hire_fullstack">hire full-stack developers</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-12 col-lg-3">
                <ul>
                <li><Link to='/hire_node'>
                    hire node.js developers</Link></li>
                  <li><Link to='/hire_senior_android'>
                    hire senior android developers</Link></li>
                  <li><Link to='/hire_startup'>
                    hire startup developers</Link></li>
                  
                 
                  <li>
                    <Link to="/hire_architect">hire architect developers</Link>
                  </li>
                  <li>
                    <Link to="/hire_iphone">hire iphone developers</Link>
                  </li>
                  <li>
                    <Link to="/hire_backend">hire backend developers </Link>
                  </li>
                  <li>
                    <Link to="/hire_dataScience">hire data science developers</Link>
                  </li>
                  
                  <li>
                    <Link to="/hire_kotlin">hire kotlin developers </Link>
                  </li>
                  <li>
                    <Link to="/hire_machine_learning">
                      hire Machine Learning engineers
                    </Link>
                  </li>
                  <li>
                    <Link to="/hire_remote_dev">hire Remote developers </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className="container py-4">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>2023</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed by{" "}
            <a href="http://duskcoder.com/" target="_blank">
              Duskcoder Technosoft Corp
            </a>
          </div>
        </div>
      </footer>

      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>

      <script src="assets/vendor/purecounter/purecounter_vanilla.js"></script>
      <script src="assets/vendor/aos/aos.js"></script>
      <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
      <script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
      <script src="assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
      <script src="assets/vendor/swiper/swiper-bundle.min.js"></script>
      <script src="assets/vendor/waypoints/noframework.waypoints.js"></script>
    </div>
  );
}

export default Footer;
