import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

function Faq_business() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Header />
      <div className="container">

        <Box className="mt-3" sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example" className="faqtopbar" style={{ backgroundColor: "#294b86" }}>
                <Tab style={{ color: "white" }} label="Billing" value="1" />
                <Tab style={{ color: "white" }} label="General" value="2" />
                <Tab style={{ color: "white" }} label="Developers" value="3" />
                <Tab style={{ color: "white" }} label="Getting started" value="4" />
                <Tab style={{ color: "white" }} label="Claims" value="5" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up">

                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <ul className="faq-list">

                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq1"> Usage-based Pricing <i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                            <p>billing consists of two parts:<br />
                              1. An initial security deposit, which can be utilized to cover the final week of work or refunded before concluding the project.<br />
                              2. Weekly or monthly payments that occur throughout the project's duration.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq2">When will the charges be applied?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              We bill every Tuesday based on the developer's reported hours from the previous week.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq3"> What options are available to address any additional hours worked by the developer and not yet accounted for in billing?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                            <p>The system will manage it, and it will bill you for the hours that your developer has recorded for the previous week.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq4">Can I accumulate additional hours?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                            <p>you don’t collect hours at all. the system charges you according to the developer’s reports.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq5">
                            What occurs with my remaining balance once the project concludes?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              We will refund your deposit to your credit card.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq6">What are the available payment methods you accept?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq6" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              Our payment structure resembles a weekly or monthly subscription, and we accept payments via credit card, which are processed through the Stripe platform.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq7"> what is a deposit? <i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq7" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              The initial payment serves as a security deposit, held in reserve for your benefit. It can be utilized to cover the final week of work or refunded as unused hours when the project is concluding.</p>
                          </div>
                        </li>


                      </ul>
                    </div>
                  </div>

                </div>
              </section>
            </TabPanel>
            <TabPanel value="2">
              <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up">

                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <ul className="faq-list">

                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq1"> what is AlanTech? <i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                            <p>AlanTech is a platform for hiring vetted developers on-demand.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq2">What sets you apart from other freelance platforms?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              There are two significant distinctions. First, we meticulously assess and interview every developer who seeks to join our network. This rigorous vetting process ensures that each client collaborates with highly qualified professionals who possess strong work ethics and interpersonal skills. Second, we exclusively connect you with one candidate at a time, always striving to pair you with the candidate we believe best fits your project's requirements and is ready to commence work promptly.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq3">Is there a minimum requirement for the number of project hours?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                            <p>we start at 160 hours of work for an individual position and will be happy to send some candidates for your consideration. depending on your project’s needs, we can offer developers that are ready to work full-time (40 hours per week) or part-time (20 hours per week).</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq4">
                            Is there a required minimum duration for a project?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              We can initiate the project as long as there are a minimum of 160 hours of work required. On average, contracts at AlanTech last for six months, and many clients have chosen to retain AlanTech developers for multiple years.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq5"> Do I have ownership of the intellectual property (IP)? <i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              Indeed, you do! Our terms of use explicitly address this matter. However, if you wish, you can also consider signing a Master Services Agreement (MSA) or any other essential documents directly with the developer.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq6">
                            Can I expect to receive a project estimate before we commence?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq6" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              Provide us with your project's requirements and specifics, and we'll analyze the details to determine the estimated number of hours required for the work. This figure will help you project the overall cost accurately.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq7">
                            What technology stacks and platforms are you experienced with? <i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq7" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              We typically have expertise in JavaScript, Python, Ruby on Rails (RoR), data science, Java, and Swift. However, please feel free to specify the particular technology stack you are interested in.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq8">
                            Are there any initial fees or upfront charges?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq8" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              Our approach differs from that. We request the deposit just prior to initiating the project, following your approval of the estimates and the chosen candidates for collaboration. The deposit will be refunded upon project completion.</p>
                          </div>
                        </li>


                      </ul>
                    </div>
                  </div>

                </div>
              </section>
            </TabPanel>
            <TabPanel value="3">
              <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up">

                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <ul className="faq-list">

                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq1"> Do your developers have strong English language skills?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              Our team consists exclusively of developers who possess a high level of proficiency in English, ensuring effective and transparent communication.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq2">What is your process for thoroughly assessing and confirming the qualifications of your developers?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                            <p>Each developer who applies undergoes a comprehensive evaluation process known as the "Great Alan Trial," which includes five stages:</p>

                              <p>Review of CV & Portfolio</p>
                              <p>Background and Identity Verification</p>
                              <p>English Proficiency Assessments (Oral and Written)</p>
                              <p>Coding Test</p>
                              <p>Live Interview Task</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq3"> what are your hourly rates? <i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                            <p>Our pricing begins at $45 to $85 per hour for professionals skilled in JavaScript, Python, Ruby on Rails (RoR), data science, blockchain, Java, and Swift.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq4"> Is it possible for developers to work from my physical office location? <i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                            <p>
Bringing developers on board as full-time employees allows them the option to visit your office and meet your team. In such cases, we will handle all travel expenses. Relocation arrangements can be discussed on a case-by-case basis.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq5"> 
Will I have direct communication with the developer?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                            <p>yes!</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq6"> 
Where are your developers geographically situated?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq6" className="collapse" data-bs-parent=".faq-list">
                            <p>
Approximately 96% of our freelance developers are based in Eastern Europe.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq7">
Is it possible for me to conduct my own interview and assessment of the developer? <i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq7" className="collapse" data-bs-parent=".faq-list">
                            <p>you are more than welcome to.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq8">can i see the developer’s cv? <i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq8" className="collapse" data-bs-parent=".faq-list">
                            <p>be our guest!</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq9">Am I able to request a technical interview, a live coding session, or a test task as part of the evaluation process for the developer?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq9" className="collapse" data-bs-parent=".faq-list">
                            <p>Certainly! While our developers undergo thorough pre-vetting, you have the option to conduct a technical interview or live coding session to verify their skills align with their CVs. Regarding test tasks or take-home assignments, they may be considered on an individual basis, as our vetting process typically renders them redundant.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq10"> how experienced are your developers? <i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq10" className="collapse" data-bs-parent=".faq-list">
                            <p>Our developers typically possess a minimum of four years of professional experience, and a candidate must have a minimum of two and a half years of industry experience to be eligible to join our team.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq11"> can you help me hire a junior developer? <i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq11" className="collapse" data-bs-parent=".faq-list">
                            <p>
We do not engage with junior developers, and we recommend the same for you. Instead, we can provide you with capable mid-level candidates who offer competitive hourly rates. They are receptive to growth and can adapt to your preferences while minimizing the likelihood of novice errors.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq12">Is it secure to collaborate with you given your location in Ukraine?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq12" className="collapse" data-bs-parent=".faq-list">
                            <p>To be completely transparent, these are challenging times, but at AlanTech, we operate with unwavering precision. The majority of our team members have relocated to secure areas. Our developers in Ukraine maintain continuous communication with our team, ensuring their full commitment to work. Our network of developers is continually expanding geographically, encompassing specialists in various countries including Spain, Poland, Italy, Romania, Greece, Bulgaria, Hungary, Portugal, Czech Republic, Lithuania, Latvia, Croatia, Bosnia and Herzegovina, Slovakia, Estonia, Moldova, Albania, North Macedonia, Turkey, Slovenia, Montenegro, Kosovo, Cyprus, Andorra, Malta, Brazil, Mexico, Colombia, Argentina, Peru, Chile, Guatemala, Ecuador, Bolivia, Haiti, Dominican Republic, Honduras, Paraguay, El Salvador, Costa Rica, Panama, and Uruguay. Thus, any preferences regarding the location of developers will be taken into account.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq713"> do your developers do fixed-price projects? <i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq713" className="collapse" data-bs-parent=".faq-list">
                            <p>nope, they don’t do it.</p>
                          </div>
                        </li>



                      </ul>
                    </div>
                  </div>

                </div>
              </section>
            </TabPanel>
            <TabPanel value="4">
              <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up">

                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <ul className="faq-list">

                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq1">
What's the typical lead time for initiating a project? <i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                            <p>
The timeline for project initiation varies depending on the project's scope and requirements. On average, it typically takes one to two days to identify the ideal candidate and an additional two days or less for them to commence work.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq2">
What is the process for bringing a developer on board from your platform?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                            <p>we have three simple steps for you. first, we’ll ask you a couple of questions about the projects. then we’ll offer the best-fitting candidate. after that, it is time to fund your account and get things up to speed.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq3"> how do i start? <i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                            <p>
The process involves several simple steps. Start by completing the provided form, and our manager will reach out to you to discuss the specifics. Following that, you'll have the opportunity to meet with your designated candidate and provide your approval. The final step is making the initial payment, and you'll be good to go!</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq4">
Do you employ software to monitor the developer's progress and track their working hours?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                            <p>Our core belief is in fostering a transparent and trustworthy working relationship. We do not compel the use of time-tracking tools or screen recordings during the work process. Our dashboard enables developers to independently report their completed work hours without imposing any additional monitoring measures. However, if your internal policies necessitate the use of time-tracking software, we are open to discussing this possibility during the hiring process, provided that the developer is comfortable with it.</p>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </div>

                </div>
              </section>
            </TabPanel>
            <TabPanel value="5">
              <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up">

                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <ul className="faq-list">

                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq1">
What should be my course of action if I encounter a problem or concern with the developer?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                            <p>If you encounter any issues, please don't hesitate to inform us promptly by sending an email to claims@AlanTech, and we will promptly reach out to assist you.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq2">
What is the process for obtaining a refund if I am dissatisfied with the developer's work?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                            <p>let us sort it out for you. please, start with filing your claim to claims@AlanTech</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq3">
If I'm not satisfied with the developer and the partnership isn't going well, what options are available for assistance or resolution? <i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                            <p>no worries. please contact malky at m@AlanTech. we will suggest more candidates for you to consider.</p>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </div>

                </div>
              </section>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
      <Footer/>
    </>
  )
}
export default Faq_business