import React, { useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import axios from 'axios';
function AdminHeader() {


  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const clearandnavigate = async () => {
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id');
    const response = await axios.post(`${process.env.REACT_APP_URL}/auth2/logout`, {}, {
      headers: {
        'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
        "Auth-Key": process.env.REACT_APP_AUTH_KEY,
        'User-id': user_id,
        'Authorization': token
      }
    })
    if (response.status == 200) {
      localStorage.clear();
      navigate("/");
    }
  };


 const  display ={display:{lg:"none"}}

  return (
    <header className="dashboard-header">
      <div className="header-content">
        <label for="menu-toggle">
          <span>
            <i className="fa fa-bars"></i>
          </span>
        </label>

        <div className="header-menu ">
          <label for="">
            <span className="las la-search "></span>
          </label>

          <div className="user">


            <span className="las la-power-off"></span>
            <IconButton 
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle sx={{ width: 40, height: 40 }} />
            </IconButton>


            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={clearandnavigate}>Log Out</MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </header>
  );
}

export default AdminHeader;
