import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Card from "../../cards";
import { useNavigate } from 'react-router-dom'

function Java_script() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <section id="job_script" class="faq heros d-flex align-items-center">
        <div class="container" data-aos="zoom-out" data-aos-duration="1000">
          <h1>
            JAVASCRIPT DEVELOPER
            <span> JOB DESCRIPTION</span>
          </h1>
          <br />
        </div>
      </section>
      <section class="heros">
   <div className="greybg">
   <div class="container pt-3 " data-aos="fade-up" data-aos-duration="1000">
          <div id="step">
            <p class="">
              As per Statista's data, the United States witnesses more than ten million job openings monthly, whereas there
              are merely seven million individuals actively searching for full-time employment. If you aim to hire a skilled
              software engineer for your company, the first and vital step is to create a well-crafted job description.</p><p>   This
                document plays a crucial role in outlining the necessary skills and responsibilities expected from the
                candidate
                to seamlessly integrate into your organization. Essentially, the job description acts as a comprehensive list
                of
                qualifications that potential candidates must meet to be eligible for the position. Additionally, it provides
                an
                overview of the working conditions offered by your company during the hiring process.
            </p>
           
          </div>
          <div id="mediabutton">
          <button class="col-sm-2 btn btn-primary mb-3 shadow-lg"onClick={() => navigate('/hire')}>Hire a Javascript pro!</button>

          </div>
        </div>
   </div>
        <div class="container mt-4" data-aos="fade-right" data-aos-duration="1000">
          <div id="javas">
            <h2 class="">How to write a <span>JavaScript Developer</span> job description</h2>
            <p>Once you have determined the specific skill set you desire in a JavaScript professional, it is essential to
              create a precise job description. Crafting a well-structured job description is a powerful method to highlight
              your requirements and attract top talents who possess the relevant skills. This approach ensures that
              qualified
              candidates promptly submit their CVs, enabling you to find the perfect match with the speed of lightning. </p>

          </div>
        </div>
        <div class="container" data-aos="fade-up" data-aos-duration="1000">
          <div id="sum">
            <h2 class="">Summary:</h2>

            <p>
              In this section, your main focus should be on addressing the fundamental questions that a prospective
              candidate
              might have initially: </p>


            <p><span className="text-primary fs-5">✔</span> What is the reason behind your company's need for a JS
              engineer?</p>

            <p> <span className="text-primary fs-5">✔</span> How does this role integrate within your existing
              working
              team?</p>

            <p><span className="text-primary fs-5">✔</span> Could you please provide information about the working conditions in your company?
            </p>

          </div>
        </div>
        <div class="container" data-aos="fade-left" data-aos-duration="1000">
          <div id="hiri">
            <h2 class=" mb-4">Requirements</h2>
            <p>When seeking to hire front-end developers, their knowledge and experience carry immense importance. To
              ensure the best fit for your company, it becomes essential to identify the crucial skills expected from
              a
              front-end engineer, as well as the preferred work background and educational qualifications you seek in
              potential candidates.</p>

            <p><span className="text-primary fs-5">✔</span> Which technical skills are necessary for this
              role?</p>

            <p><span className="text-primary fs-5">✔</span> What soft skills are preferred for this role?
            </p>

            <p><span className="text-primary fs-5">✔</span> What relevant experiences would be advantageous for
              this role?</p>

          </div>
        </div>
        <div class="container mt-3" data-aos="fade-up" data-aos-duration="1000">
          <div id="role">
            <h2 class="mb-4">Responsibilities</h2>
            <p>
              Clearly outline the key deliverables expected from the JavaScript developer role and provide a description
              of
              the ideal candidate's daily routine.</p>
            <h2 class="mb-4">Benefits</h2>
            <p>The candidate should obtain comprehensive information regarding bonuses, compensations, potential training
              opportunities, and additional perks offere</p>
            <p> Provide a brief company description, outlining the work culture and emphasizing the unique selling points
              that make working with your organization an enticing opportunity. Pitch yourself to potential candidates,
              showcasing the advantages of joining your company.</p>
          <div>
            </div> </div>

        </div>

        <div class="container" data-aos="fade-right" data-aos-duration="1000">
          <div className="row">
            <div id="skill">
              <h2 class="mt-4 mb-4"><span>JavaScript Developer</span> job responsibilities</h2>
              <p>Below is an example of what a job responsibilities list for a skilled JavaScript developer can
                include.</p>
              <p> In the role of a JavaScript specialist at our company, your responsibilities will involve:</p>

              <p><span className="text-primary fs-5">✔</span> Create and engineer JavaScript applications;</p>

              <p><span className="text-primary fs-5">✔</span> Craft code components that are reusable and testable, with a focus on meeting
                the
                needs of end users; </p>

              <p><span className="text-primary fs-5">✔</span> Emphasize writing high-performance code, as performance is crucial to our
                success;</p>


              <p><span className="text-primary fs-5">✔</span>  Collaborate closely with the QA and UI/UX departments to ensure optimal user
                satisfaction and software reliability;</p>


              <p><span className="text-primary fs-5">✔</span>  Create a comprehensive set of unit tests for your application to ensure
                everything
                is functioning correctly;</p>


              <p><span className="text-primary fs-5">✔</span>  Produce detailed documentation for each element of the application;</p>


            </div>
          </div>
        </div>
       <div className="greybg">
       <div class="container" data-aos="fade-up" data-aos-duration="1000">
          <div id="experience">
            <h2 class="mt-4 mb-4"><span>JavaScript Developer</span> requirements and qualifications </h2>
            <p>
              While the following sample provides a list of JS developer requirements and qualifications, feel free to
              customize it by adding, removing, or modifying points to align with your specific expectations:</p>
            <p><span className="text-primary fs-5">✔</span> A minimum of three years of experience in software development;</p>
            
            <p><span className="text-primary fs-5">✔</span>  Demonstrate a strong command of JavaScript, including Core and DOM
              APIs;</p>

            <p><span className="text-primary fs-5">✔</span> Possess at least three years of experience working with Node.js;</p>
   


            <p><span className="text-primary fs-5">✔</span> Familiarity with front-end JavaScript frameworks and libraries, with a preference
              for React.js;
            </p>


            <p><span className="text-primary fs-5">✔</span> Comprehensive understanding and practical application of SOLID principles and
              GOF
              patterns;</p>


            <p><span className="text-primary fs-5">✔</span>    Proficient understanding of Continuous Integration (CI) principles;</p>

           
            <p><span className="text-primary fs-5">✔</span>  Experience with Typescript and React Native would be considered
              advantageous;</p>

            <p><span className="text-primary fs-5">✔</span> Possess familiarity with browser performance and behavior;</p>
            
            <p><span className="text-primary fs-5">✔</span>  Comprehension of the operational principles of JavaScript module loaders such as
              Require.js and AMD;</p>


            <p><span className="text-primary fs-5">✔</span>  Basic understanding of AJAX, partial page updates, and asynchronous request
              handling;</p>


            <p><span className="text-primary fs-5">✔</span>  Relevant professional certifications.</p>

          </div>
        </div>
       </div>

        <div class="container" data-aos="fade-left" data-aos-duration="1000">
          <div id="benefits">
            <h2 class="mt-4 mb-4">Benefits and perks for <span>JavaScript Developers</span></h2>
            <p>Get ready for the most exhilarating part of this job description! We take immense pride in offering a
              myriad
              of benefits to ensure our candidates' happiness. Rest assured, we haven't missed a thing, but we always
              recommend checking with our HR specialists for more details. Here's how we'll make you happy:</p>
            <p>Example:</p>

            <p><span className="text-primary fs-5">✔</span> Opportunities for career advancement and personal growth;</p>

            <p><span className="text-primary fs-5">✔</span>  Timely and fair compensation;</p>

            <p><span className="text-primary fs-5">✔</span>  Comprehensive medical coverage;</p>


            <p><span className="text-primary fs-5">✔</span> A team that provides support and encouragement;</p>


            <p><span className="text-primary fs-5">✔</span>   Access to complimentary psychotherapy services.</p>
            
          </div>
        </div>

        <div className="mt-3">
        <Card />
        </div>
        <section id="faq" className="faq section-bg">
          <div className="container" data-aos="fade-up" data-aos-duration="1000">

            <div className="section-title">
              <h2>F.A.Q</h2>
              <h2>Need more info?<span>Here's a FAQ for you!</span></h2>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <ul className="faq-list">

                  <li>
                    <div data-bs-toggle="collapse" className="collapsed question" href="#faq1">What does a JavaScript developer do?<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                      <p>
                        Millions of web pages are created on the internet with the help of JavaScript programming language. It is used to write code for programs, scripts, applications, services, and websites — connecting the front end (visual part) with the back end. As a rule, JS developers are supposed to connect with other front-end engineers specializing in layouts, CSS, and visual design.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div data-bs-toggle="collapse" href="#faq2" className="collapsed question">Is JavaScript a good choice for your career?<i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                      <p>
                        Everyone deciding which programming language to learn will inevitably ponder over a thousand questions. Shall I start with the easiest ones? With the most relevant to my fields of interest? Which languages have the best learning curve? Which of them is the most useful? Python or Java? Or maybe, JavaScript?
                      </p>
                    </div>
                  </li>

                  <li>
                    <div data-bs-toggle="collapse" href="#faq3" className="collapsed question">What are the basic data types of JavaScript? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                    </div>
                    <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                      <p>
                        Six main data types can be grouped into three categories. In the primitive (or primary) category, we have String, Number, and Boolean. The composite category consists of Object, Array, and Function types. Undefined and Null types belong to the Special category.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div data-bs-toggle="collapse" href="#faq4" className="collapsed question">Is JavaScript coding? <i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                      <p>
                        JavaScript is a programming language that implements complex features on web pages, both on the front and back. When you see a non-static web page (i.e., a page not only showing you some pictures or text statically but also displaying animations, interactive elements, and video jukeboxes)
                      </p>
                    </div>
                  </li>



                </ul>
              </div>
            </div>

          </div>
        </section>

      </section>
      <Footer />
    </>
  )
}
export default Java_script;