import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import jsonData from "../../data.json";
import Swal from "sweetalert2";
import axios from "axios";
import { Container, FormControl, TextField, Grid, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Typography,
  MenuItem,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";

function TechStack() {
  const Mainspecialization = jsonData.Mainspecialization;
  const seniorityLevel = jsonData.SeniorityLevel;
  const names = jsonData.names;
  const years = jsonData.Years;

  const [developer, setDeveloper] = useState({
    Mainspecialization: "",
    seniorityLevel: "",
    experiences: "",
    newSkill: ""
  });

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skill, setSkill] = useState("");
  const [newExperience, setNewExperience] = useState("");
  const [coreError, setCoreError] = useState(false);
  const [skillErr, setSkillErr] = useState(false);
  const [hideskillandExperience, setHideskillandExperience] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  function SweetAlert() {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Tech Stack Updated",
      confirmButtonText: "OK",
      timer: 8000,
    }).then(async (result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    })
  }


  useEffect(() => {
    const fetchDate = async () => {
      const token = localStorage.getItem("token");
      const user_id = localStorage.getItem('user_id')
      if (token == null) {
        navigate("/login");
      } else {
        const response = await axios.get(`${process.env.REACT_APP_URL}/profile`, {
          headers: {
            'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
            "Auth-Key": process.env.REACT_APP_AUTH_KEY,
            'User-id': user_id,
            'Authorization': token
          }
        })
        if (response.status == 200) {
          setDeveloper(response.data.data)
          setLoading(false);
        }
        else {
          setLoading(false)
          alert('sorry, something went wrong please try after sometime')
        }
      }
    }
    fetchDate()
  }, []);


  useEffect(() => {
    setDeveloper({ ...developer, newSkill: selectedSkills })

    if (selectedSkills.length >= 4) {
      setHideskillandExperience(true)
    } else {
      setHideskillandExperience(false)
    }
  }, [selectedSkills])


  const handleChange = (event) => {
    const { name, value } = event.target;
    setDeveloper({
      ...developer,
      [name]: value,
    });
  };


  async function updateTechStack() {
    setLoading(true);
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id');
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/developers`,
        {
          id: developer.id,
          Mainspecialization: developer.Mainspecialization,
          seniorityLevel: developer.seniorityLevel,
          experiences: developer.experiences,
          newSkill: JSON.stringify(developer.newSkill)
        }, {
        headers: {
          'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
          "Auth-Key": process.env.REACT_APP_AUTH_KEY,
          'User-id': user_id,
          'Authorization': token
        }
      }
      );
      if (response.status === 200) {
        const user = JSON.stringify(developer);
        localStorage.setItem("user", user);
        SweetAlert();
        setLoading(false);
        // window.location.reload();
      }
      else {
        setLoading(false)
        alert('sorry, something went wrong please try after sometime')
      }
    } catch (error) {
      console.log(error);
    }
  }


  const addSkill = () => {
    if (skill && newExperience) {
      setCoreError(false)

      if (selectedSkills.length == 0) {
        setSelectedSkills([
          ...selectedSkills,
          { skill: skill, experience: newExperience },
        ]);
        setSkill("");
        setNewExperience("");

      } else {
        let existSkills = selectedSkills.filter((data) => {
          return data.skill == skill
        })
        if (existSkills.length == 1) {
          setSkillErr(true)
        }
        else {
          setSkillErr(false)
          setSelectedSkills([
            ...selectedSkills,
            { skill: skill, experience: newExperience },
          ]);
          setSkill("");
          setNewExperience("");
        }
      }


    } else {
      setCoreError(true)
    }
  };


  // Removing Skill
  const removeSkill = (index) => {
    const updatedSkills = [...selectedSkills];
    updatedSkills.splice(index, 1);
    setSelectedSkills(updatedSkills);
  };


  const button = {
    backgroundColor: "#294b86",
  };
  const cancelButton = {

    position: "absolute",
    top: 0,
    right: 0,
  }

  const add = {
    backgroundColor: "#294b86",
    color: "white",
    padding: 2,
  };
  const para = {
    marginTop: 5
  }
  const box = {
    width: {
      lg: 1000
    },
    height: {
      lg: '100%',
      md: '100%',
      xs: '100%'
    },
    display: "flex", alignItems: "flex-start",
  };
  if (loading) {
    return (<div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    )
  }

  return (
    <Box >
      <Container sx={box}>
        <form onSubmit={updateTechStack} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} display={"flex"} sx={{ display: { lg: "flex", xs: "block" } }}>
              <Typography variant="h4">Core technologies</Typography>
              <p sx={para}>(Add up to 4 go-to technologies)</p>
            </Grid>

            <Grid item xs={12} lg={5} md={5}>
              <Autocomplete
                value={skill}
                onChange={(_, newValue) => setSkill(newValue)}
                options={names}
                disabled={hideskillandExperience}
                renderInput={(params) => (
                  <TextField
                    xs={12}
                    {...params}
                    label="Choose Skill"
                    variant="outlined"
                    fullWidth
                    error={coreError && !skill || skillErr}
                    helperText={coreError && !skill ? "select the option" : skillErr ? "skill already added" : ""}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={5} md={5}>

              <TextField
                select
                fullWidth
                variant="outlined"
                label="Experience"
                value={newExperience}
                onChange={(e) => (setNewExperience(e.target.value))}
                disabled={hideskillandExperience}
                error={coreError && !newExperience}
                helperText={coreError && !newExperience ? "select the option" : ""}
              >
                <MenuItem value="" disabled>
                  Year of experience
                </MenuItem>
                {years.map((data, index) => (
                  <MenuItem key={index} value={data}>
                    {data}
                  </MenuItem>
                ))}
              </TextField>

            </Grid>

            <Grid item xs={12} lg={2} md={2}>
              <Button
                fullWidth
                onClick={addSkill}
                disabled={hideskillandExperience}
                sx={[
                  add,
                  {
                    "&:hover": {
                      bgcolor: "#294b86",
                    },
                  },
                ]}
              >
                +
              </Button>
            </Grid>

            {/* {/ Dynamic Skill and Experience Fields /} */}

            <Grid item xs={12}>
              <Grid container spacing={2}>
                {selectedSkills.map((skill, index) => (
                  <Grid item xs={12} lg={3} md={6}>
                    <TextField
                      fullWidth
                      type="text"
                      value={`${skill.skill} - ${skill.experience}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              sx={cancelButton}
                              onClick={() => removeSkill(index)}
                            >   <FontAwesomeIcon icon={faTimes} /></IconButton>

                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ my: 2 }}>
              <Typography sx={{ fontSize: { lg: "40px", xs: "30px" }, textAlign: { lg: "flex-start", xs: "center", md: "start" } }}>
                GENERAL DETAILS
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={5} md={5}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Main specialization"
                    name="Mainspecialization"
                    value={developer.Mainspecialization}
                    onChange={handleChange}
                  >
                    {Mainspecialization.map((data, index) => (
                      <MenuItem key={index} value={data}>
                        {data}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} lg={4} md={4}>
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    label="SeniorityLevel"
                    name="seniorityLevel"
                    value={developer.seniorityLevel}
                    onChange={handleChange}
                  >
                    {seniorityLevel.map((data, index) => (
                      <MenuItem key={index} value={data}>
                        {data}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} lg={3} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label="Experience as a dev"
                      name="experiences"
                      value={developer.experiences}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>


              </Grid>
            </Grid>



            <Grid item xs={12}>
              <Button
                fullWidth
                onClick={updateTechStack}
                // disabled={btn}
                sx={{ py: 1, bgcolor: " #294b86 !important", color: "white" }}
              >
                Save
              </Button>

            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );

}

export default TechStack;