import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { Container, Typography, Button, Box, Grid, } from "@mui/material";




function ProfileContact() {
  const navigate = useNavigate();
  const [developer, setDeveloper] = useState("");
  const [loading, setLoading] = useState(true);
  function SweetAlert() {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Contact Updated",
      confirmButtonText: "OK",
      timer: 8000,
    }).then(async (result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }
  const handleChange = (event) => {
    const { name, value } = event.target;

    setDeveloper({
      ...developer,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchDate = async () => {
      const token = localStorage.getItem("token");
      const user_id = localStorage.getItem('user_id')
      if (token == null) {
        navigate("/login");
      } else {
        const response = await axios.get(`${process.env.REACT_APP_URL}/profile`, {
          headers: {
            'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
            "Auth-Key": process.env.REACT_APP_AUTH_KEY,
            'User-id': user_id,
            'Authorization': token
          }
        })
        if (response.status == 200) {
          setDeveloper(response.data.data)
          setLoading(false);
        }
        else {
          setLoading(false)
          alert('sorry, something went wrong please try after sometime')
        }
      }
    }
    fetchDate()
  }, []);

  async function updatecontact() {
    setLoading(true);
    try {
      const token = localStorage.getItem('token')
      const user_id = localStorage.getItem('user_id');
      const response = await axios.put(`${process.env.REACT_APP_URL}/developers`, {
        id: developer.id,
        phoneNumber: developer.phoneNumber,
      },
        {
          headers: {
            'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
            "Auth-Key": process.env.REACT_APP_AUTH_KEY,
            'User-id': user_id,
            'Authorization': token
          }
        }

      );
      if (response.status == 200) {
        setLoading(false);
        SweetAlert();
      }
      else {
        setLoading(false)
        alert('sorry, something went wrong please try after sometime')
      }
    } catch (error) {
      console.log(error);
    }
  }
  const button = {
    marginTop: { lg: 5 },
    backgroundColor: "#294b86",
  };
  const box = {
    width: {
      lg: 1000
    },
    height: {
      lg: '100%',
      md: '100%',
      xs: '100%'
    }
  };
  if (loading) {
    return (<div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    )
  }
  return (
    <Box>
      <Container sx={box}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Contact
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <TextField
              type="text"
              fullWidth
              margin="normal"
              label="Email"
              value={developer.email}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              margin="normal"
              type="text"
              fullWidth
              label="Phone number"
              name="phoneNumber"
              value={developer.phoneNumber}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sx={button} margin="normal">
          <Button fullWidth sx={{ py: 2, bgcolor: " #294b86 !important", color: "white" }} onClick={updatecontact}>
            Save
          </Button>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProfileContact;
