import React from "react";
import Carousel from "react-elastic-carousel";
import Footer from "./Footer";
import Header from "./Header";
import DemoVideo from "../video/VID-20240321-WA0000.mp4"
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";
function Home() {
  const navigate = useNavigate();


  return (
    <div className="heros">
      <Header />
      <section id="hero" className="d-flex align-items-center">
        <div className="container" data-aos="zoom-out" data-aos-duration="1000">
          <h1>
            WITNESS
            <span>
              {" "}
              THE POWER OF <br />
              THE SUPREME DEVELOPERS
            </span>
          </h1>
          <h2>YOU’VE BEEN SEARCHING FOR</h2>
          <h6>
            "Instead of wasting time and money on hiring unreliable developers,
            why not gather
            <br /> dedicated enthusiasts to swiftly bring your vision to life
            with extraordinary speed?"{" "}
          </h6>
          <br />
          <div className="d-flex john">
            <button
              onClick={() => navigate("/hire")}
              className="btn-get-started scrollto border border-0"
            >
              Find suitable developer for me
            </button>
            <button
              onClick={() => navigate("/apply_devs")}
              className="btn-get-started scrollto btn-end border border-0"
            >
              Demonstrate your capabilities
            </button>
          </div>
        </div>
      </section>

      <main id="main">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-6 col-12">
              <video controls width="100%" height="100%" autoPlay>
                <source src={DemoVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="col-lg-6 col-12 ">
              <div className="mt-4">
                <h5><span style={{color:"#294b86",fontWeight:"700"}}>Effortless Applications:</span><span style={{ fontSize: "15px" }}> Follow Our Step-by-Step Guidance</span></h5>
              </div>
              <div className="videoContent mt-4">
                <h5 style={{
                  lineHeight: "36px",
                  textAlign: "justify",
                  fontSize: "19px"
                }}>Welcome to Alantechnologies! Simplify your application process with our step-by-step instructions and helpful videos. Start your application, follow each step, and finish effortlessly. Our clear guidance and visuals guarantee a smooth experience. Congratulations on completing the process! For assistance, reach out anytime.</h5>

              </div>

            </div>
          </div>


        </div>
        <section id="featured-services" className="featured-services">
          <div
            className="container"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="row">
              <h2 className="text-center mt-5">
                {" "}
                Embrace the illuminating <span>power of Alan Tech</span>
              </h2>
              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mt-4 mb-lg-0">
                <div
                  className="icon-box text-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <i className="bi bi-dribbble"></i>
                  </div>
                  <h4 className="title">
                    <a href="">One week Match</a>
                  </h4>
                  <p className="description text-dark">
                    You possess a ground-breaking vision.
                    <br />
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mt-4 mb-lg-0">
                <div
                  className="icon-box text-center"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <i className="bi bi-file-earmark-text"></i>
                  </div>
                  <h4 className="title">
                    <a href="">Custom Assisted Matching</a>
                  </h4>
                  <span className="description text-dark">
                    Our developer provided profoundly practical
                  </span>
                </div>
              </div>

              <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mt-4 mb-lg-0">
                <div
                  className="icon-box text-center"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <i className="bi bi-speedometer2"></i>
                  </div>
                  <h4 className="title">
                    <a href="">Zero-Risk Replacement Guarantee</a>
                  </h4>
                  <span className="description text-dark">
                    Alan Tech operates autonomously, without external
                    dependencies.
                  </span>
                </div>
              </div>
            </div>
            <div className="text-center pt-4 mb-4">
              <a
                onClick={() => navigate("/apply_devs")}
                className="btn btn-primary"
                role="button"
              >
                Demonstrate your capabilities
              </a>
            </div>
          </div>
        </section>

        <section
          id="about"
          className="about section"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="container d-flex justify-content-center cycleimage">
            <img
              src={require("../images/home/imagefor.png")}
              alt=""
              height="75%"
              width="75%"
            />
          </div>
        </section>

        <section id="section" className="section">
          <div
            className="container"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="section-title">
              <h2>Developer</h2>
              <h3>
                ENGAGE SKILLED DEVELOPERS TO TRANSFORM YOUR{" "}
                <span>VISION INTO REALITY </span>
              </h3>
            </div>

            <div className="row">
              <div
                className="col-lg-6"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <img
                  src={require("../images/home/leftside.jpg")}
                  className="img-fluid own"
                  alt=""
                />
              </div>
              <div
                className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h3>ENGAGE SKILLED DEVELOPERS TO TRANSFORM</h3>
                <p className="fst-italic ">
                  You possess a ground-breaking vision. We possess the
                  extraordinary developers capable of transforming it into
                  reality. Only the most exceptional 1% of developers, carefully
                  selected from a pool of over 500 job boards, is granted entry
                  into our revered community of divine developers.
                </p>
                <ul>
                  <li>
                    <i className="bi bi-shop"></i>
                    <div>
                      <h5>
                        Our developer provided profoundly practical guidance
                      </h5>
                      <p>
                        {" "}
                        Our developer provided profoundly practical guidance
                        when it came to setting things up initially. It was
                        incredibly reassuring to have their inquiries about user
                        numbers and service usage. They bestowed upon me a
                        clearer comprehension of the optimal choices to pursue.
                      </p>
                    </div>
                  </li>
                  <li>
                    <i className="bi bi-images"></i>
                    <div>
                      <h5>
                        The software developers at Alan Tech are self-sufficient
                      </h5>
                      <p>
                        The software developers at Alan Tech are
                        self-sufficient, eliminating the need for constant
                        supervision. You will be connected with exceptional
                        programmers who seamlessly integrate into your team,
                        ensuring uncompromised product quality. Furthermore,
                        their commitment to the success of your project is equal
                        to your own, demonstrating unwavering dedication.
                      </p>
                    </div>
                  </li>
                </ul>
                <div className="text-center mb-3">
                  <a
                    onClick={() => navigate("/hire")}
                    className="btn btn-primary"
                    role="button"
                  >
                    Hire a remote dev
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="featured-services" className="featured-services">
          <div
            className="container mt-3"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="row mb-3">
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon text-center">
                    <i className="bi bi-dribbble"></i>
                  </div>
                  <h4 className="title text-center">
                    <a href="">The Resumé Review</a>
                  </h4>
                  <p className="description text-center text-dark">
                    After reviewing our resumés and LinkedIn profiles, we
                    promptly eliminate developers who lack exceptional
                    experience.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon text-center">
                    <i className="bi bi-file-earmark-text"></i>
                  </div>
                  <h4 className="title text-center">
                    <a href="">Personal Prostration</a>
                  </h4>
                  <p className="description text-center text-dark">
                    Surviving developers undergo a screening call to assess
                    their soft skills and language proficiency.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon text-center">
                    <i className="bi bi-speedometer2"></i>
                  </div>
                  <h4 className="title text-center">
                    <a href="">Hard Skills Check</a>
                  </h4>
                  <p className="description text-center text-dark">
                    Our senior, in-house developers evaluate the technical
                    skills of the remaining candidates.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div
                  className="icon-box text-center"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <i className="bi bi-person-badge"></i>
                  </div>
                  <h4 className="title text-center">
                    <a href="">The Dev’s Advocate</a>
                  </h4>
                  <p className="description text-center text-dark">
                    We exclusively accept remote developers who demonstrate a
                    deep passion for the startup hustle and possess a strong
                    work ethic akin to that of a deity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="software" className="software">
          <div
            className="container"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h2 class="text-center mt-3">
              A UTOPIA OF OUTSTAFFED
              <span> SOFTWARE ENGINEERS</span>
            </h2>

            <div className="d-flex justify-content-center mt-5 mb-2">
              <a
                onClick={() => navigate("/hire")}
                className="btn btn-primary"
                href="#"
                role="button"
              >
                Find suitable developer for me
              </a>
            </div>
          </div>
        </section>

        <section id="skills" className="skills">
          <div className="container" data-aos="fade-up">
            <div className="row skills-content">
              <div className="col-lg-6">
                <div className="progress">
                  <span className="skill">
                    React<i className="val">100%</i>
                  </span>
                  <ProgressBar now={100} />;
                </div>

                <div className="progress">
                  <span className="skill">
                    Angular<i className="val">90%</i>
                  </span>
                  <ProgressBar now={90} />;
                </div>

                <div className="progress">
                  <span className="skill">
                    Node.JS <i className="val">75%</i>
                  </span>
                  <ProgressBar now={75} />;
                </div>
              </div>

              <div className="col-lg-6">
                <div className="progress">
                  <span className="skill">
                    PHP <i className="val">80%</i>
                  </span>
                  <ProgressBar now={80} />
                </div>

                <div className="progress">
                  <span className="skill">
                    Laravel<i className="val">90%</i>
                  </span>
                  <ProgressBar now={90} />;
                </div>

                <div className="progress">
                  <span className="skill">
                    Vue.jS<i className="val">55%</i>
                  </span>
                  <ProgressBar now={55} />;
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="clients" className="clients section-bg">
          <div
            className="container"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <div className="row">
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src={require("../images/home/avata.png")}
                  className="img-fluid"
                  width="100%"
                  height="100%"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src={require("../images/home/bhrishbg.png")}
                  className="img-fluid"
                  width="100%"
                  height="100%"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src={require("../images/home/teamworklog.png")}
                  className="img-fluid"
                  width="100%"
                  height="100%"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src={require("../images/home/mobiusbg.png")}
                  className="img-fluid"
                  width="100%"
                  height="100%"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src={require("../images/home/schneiderbg.png")}
                  className="img-fluid"
                  width="100%"
                  height="100%"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src={require("../images/home/rudimaxbg.png")}
                  className="img-fluid"
                  width="100%"
                  height="100%"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section id="testimonials" className="testimonials">
          <div
            className="container"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <div
              className="testimonials-slider swiper"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="swiper-wrapper">
                <Carousel>
                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src={require("../images/home/business.jpg")}
                        className="testimonial-img"
                        alt=""
                      />
                      <h3>Eddie</h3>
                      <h4>Rudimax &amp; South Africa</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                        Having worked at Alantechnologies, I can share my personal perspective on what it's like to be a part of this company. In my experience, Alantechnologies offers a decent work environment with an overall rating of 3.3 out of 5, based on feedback from over 257 anonymous employee reviews.
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src={require("../images/home/man.jpg")}
                        className="testimonial-img"
                        alt=""
                      />
                      <h3>Sara Wilsson</h3>
                      <h4>Mobius</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                        Working at Alantechnologies has been a rewarding experience. I would rate my time here at 4.7 out of 5 stars, based on my own personal experience. Alantechnologies is known for its exceptional company culture, which I would rate at the top with a score of 4.8. The work environment is welcoming, and the team spirit is remarkable.
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src={require("../images/home/business.jpg")}
                        className="testimonial-img"
                        alt=""
                      />
                      <h3>Jena Karlis</h3>
                      <h4>Schenider</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                        One area that could see improvement is skill development, which I have rated at 4.3. While Alantechnologies provides opportunities for skill enhancement, there is room for further growth and development. Nevertheless, the overall experience has been positive, and I've had the chance to work with some great colleagues.
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src={require("../images/home/man.jpg")}
                        className="testimonial-img"
                        alt=""
                      />
                      <h3>Matt Brandon</h3>
                      <h4>Avatta</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                        If you're considering a career at Alantechnologies, I recommend reading detailed reviews from individuals in various job profiles, departments, and locations in the reviews section. It's a great way to get a firsthand understanding of what it's like to be part of the Alantechnologies team.
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src={require("../images/home/business.jpg")}
                        className="testimonial-img"
                        alt=""
                      />
                      <h3>John Larson</h3>
                      <h4>Bhrish</h4>
                      <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                        Working at Alantechnologies has truly been a remarkable journey. I'd like to share my personal perspective on this company, giving it a solid rating of 4.7 out of 5 stars. Alantechnologies is well-known for fostering an exceptional company culture, and in my view, it certainly deserves the high rating of 4.8 in this aspect. The workplace exudes positivity, and there's a strong sense of unity among the team members.
                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                    </div>
                  </div>
                </Carousel>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </section>

        {/* <section id="pricing" className="pricing ">
          <div className="container mt-3" data-aos="fade-up">
            <div className="section-title">
              <h2>Pricing</h2>
              <h3>
                Check our <span>Pricing Plan</span>
              </h3>
              <p>
                Ut possimus qui ut temporibus culpa velit eveniet modi omnis est
                adipisci expedita at voluptas atque vitae autem.
              </p>
            </div>

            <div className="row">
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="box">
                  <h3>Startup Plan</h3>
                  <h4>
                    <sup>$</sup>55 to<sup>$</sup>95 <span> / month</span>
                  </h4>
                  <ul>
                    <li>100s of developers available</li>
                    <li>custom miraculous matching™</li>
                    <li>weekly reporting</li>
                    <li>developer time tracking</li>
                    <li>full-time and part-time available</li>
                    <li>on-demand support</li>
                    <li>48-hr matching</li>
                    <li>replacement guarantee</li>
                  </ul>
                  <div className="btn-wrap">
                    <a href="#" className="btn-buy">
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 mt-4 mt-md-0"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="box featured">
                  <h3>Business Plan</h3>
                  <h4>
                    <sup>$</sup>19 to <sup>$</sup>50<span> / month</span>
                  </h4>
                  <ul>
                    <li>100s of developers available</li>
                    <li>custom miraculous matching™</li>
                    <li>weekly reporting</li>
                    <li>developer time tracking</li>
                    <li>full-time and part-time available</li>
                    <li>on-demand support</li>
                    <li>48-hr matching</li>
                    <li>replacement guarantee</li>
                  </ul>
                  <div className="btn-wrap">
                    <a href="#" className="btn-buy">
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 mt-4 mt-lg-0"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="box">
                  <h3>Developer Plan</h3>
                  <h4>
                    <sup>$</sup>29<span> / month</span>
                  </h4>
                  <ul>
                    <li>100s of developers available</li>
                    <li>custom miraculous matching™</li>
                    <li>weekly reporting</li>
                    <li>developer time tracking</li>
                    <li>full-time and part-time available</li>
                    <li>on-demand support</li>
                    <li>48-hr matching</li>
                    <li>replacement guarantee</li>
                  </ul>
                  <div className="btn-wrap">
                    <a href="#" className="btn-buy">
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section id="faq" className="faq section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>F.A.Q</h2>
              <h3>
                Frequently Asked<span>Questions</span>
              </h3>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <ul className="faq-list">
                  <li>
                    <div
                      data-bs-toggle="collapse"
                      className="collapsed question fw-bold fs-6"
                      href="#faq1"
                    >
                      Why opt for hiring remote developers?
                      <i className="bi bi-chevron-down icon-show"></i>
                      <i className="bi bi-chevron-up icon-close"></i>
                    </div>
                    <div
                      id="faq1"
                      className="collapse"
                      data-bs-parent=".faq-list"
                    >
                      <p>
                        In the past decade, the remote segment of the IT labour
                        market has experienced consistent growth. With
                        skyrocketing internet speeds, ubiquitous network
                        coverage, and lightweight laptops, the need to confine
                        oneself to an inconvenient office environment has
                        diminished. Consider app developers: why limit
                        productivity to an office when equal productivity can be
                        achieved on the beach? Startup owners also question the
                        necessity of office rentals when alternatives are
                        affordable. Thus, the trend of remote work emerges.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div
                      data-bs-toggle="collapse"
                      href="#faq2"
                      className="collapsed question fw-bold fs-6"
                    >
                      Advantages for Employers in Embracing Remote Work?
                      <i className="bi bi-chevron-down icon-show"></i>
                      <i className="bi bi-chevron-up icon-close"></i>
                    </div>
                    <div
                      id="faq2"
                      className="collapse"
                      data-bs-parent=".faq-list"
                    >
                      <p>
                        Cost savings: Recent research indicates that start-ups
                        can potentially save up to $1000 per year per remote
                        worker. When considering the average company size, these
                        savings can be substantial. Workflow automation:
                        Embracing remote work necessitates rationalization,
                        digitization, and automation of tasks. By effectively
                        implementing tools like Jira and Agile methodologies,
                        and streamlining documentation flow, work processes
                        become faster and more efficient. Access to a diverse
                        talent pool:
                      </p>
                    </div>
                  </li>

                  <li>
                    <div
                      data-bs-toggle="collapse"
                      href="#faq3"
                      className="collapsed question fw-bold fs-6"
                    >
                      What are the factors influencing the salaries of remote
                      web developers?
                      <i className="bi bi-chevron-down icon-show"></i>
                      <i className="bi bi-chevron-up icon-close"></i>
                    </div>
                    <div
                      id="faq3"
                      className="collapse"
                      data-bs-parent=".faq-list"
                    >
                      <p>
                        The compensation of remote web developers is influenced
                        by several simultaneous factors. These can include:
                        Proficiency in programming languages: The level of
                        expertise and mastery in specific programming languages,
                        whether in full-stack development, front-end
                        specialization, or back-end specialization, can impact
                        salaries. Years of experience: The amount of
                        professional experience accrued by remote web developers
                        plays a significant role in determining their salaries.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div
                      data-bs-toggle="collapse"
                      href="#faq4"
                      className="collapsed question fw-bold fs-6"
                    >
                      What sets Alan Technologies remote developers apart?
                      <i className="bi bi-chevron-down icon-show"></i>
                      <i className="bi bi-chevron-up icon-close"></i>
                    </div>
                    <div
                      id="faq4"
                      className="collapse"
                      data-bs-parent=".faq-list"
                    >
                      <p>
                        Alan Tech distinguishes itself as a talent marketplace
                        that diligently screens all remote developers before
                        presenting them to clients. Here's an overview of our
                        candidate vetting process: Reference check: We conduct
                        thorough reference checks to ensure the credibility and
                        professional background of the candidates. Soft skills
                        assessment: Evaluating soft skills is a crucial step in
                        our vetting process, ensuring that the developers
                        possess effective communication, collaboration, and
                        problem-solving abilities.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div
                      data-bs-toggle="collapse"
                      href="#faq5"
                      className="collapsed question fw-bold fs-6"
                    >
                      What is the timeline for hiring a remote developer?
                      <i className="bi bi-chevron-down icon-show"></i>
                      <i className="bi bi-chevron-up icon-close"></i>
                    </div>
                    <div
                      id="faq5"
                      className="collapse"
                      data-bs-parent=".faq-list"
                    >
                      <p>
                        The duration of the hiring process for a remote
                        developer can vary depending on several factors,
                        including the hiring approach, client's budget,
                        geographical considerations, business type, and the
                        experience level required for the specific developer.
                        Other factors may also come into play. If you are
                        seeking a mid-level or senior remote developer with
                        substantial experience, it is beneficial to engage with
                        vetting platforms like Alan Tech as their hiring process
                        typically takes up to a week, which is considered
                        relatively fast.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div
                      data-bs-toggle="collapse"
                      href="#faq6"
                      className="collapsed question fw-bold fs-6"
                    >
                      What if the developer I hire doesn't meet expectations?
                      <i className="bi bi-chevron-down icon-show"></i>
                      <i className="bi bi-chevron-up icon-close"></i>
                    </div>
                    <div
                      id="faq6"
                      className="collapse"
                      data-bs-parent=".faq-list"
                    >
                      <p>
                        Occasionally, a lack of synergy can arise between you
                        and a hired contractor. However, if you choose to hire
                        developers through Alan Tech, you're in luck. In such
                        cases, all unsatisfactory candidates will be swiftly
                        replaced by the platform. To initiate the substitution
                        process, simply inform the Customer Success department
                        of your need for a replacement and await the arrival of
                        a new professional to pick up where the previous one
                        left off.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div
                      data-bs-toggle="collapse"
                      href="#faq7"
                      className="collapsed question fw-bold fs-6"
                    >
                      How to secure a remote job as a web developer?
                      <i className="bi bi-chevron-down icon-show"></i>
                      <i className="bi bi-chevron-up icon-close"></i>
                    </div>
                    <div
                      id="faq7"
                      className="collapse"
                      data-bs-parent=".faq-list"
                    >
                      <p>
                        The employment prospects for web developers have seen
                        significant growth, with a projected 8% increase from
                        2019 to 2029, as reported by the Bureau of Labour
                        Statistics. Additionally, by 2028, approximately 75% of
                        teams worldwide are expected to operate predominantly in
                        remote setups. Web developers have the flexibility to
                        work remotely in a remarkable number of cases, as they
                        can set up their workplace anywhere, requiring only a
                        table, chair, and a reliable Wi-Fi connection.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div
                      data-bs-toggle="collapse"
                      href="#faq8"
                      className="collapsed question fw-bold fs-6"
                    >
                      What does the vetting process entail?
                      <i className="bi bi-chevron-down icon-show"></i>
                      <i className="bi bi-chevron-up icon-close"></i>
                    </div>
                    <div
                      id="faq8"
                      className="collapse"
                      data-bs-parent=".faq-list"
                    >
                      <p>
                        At Alantech all developers who wish to join our
                        community undergo a comprehensive vetting process
                        consisting of four key stages. To begin, our internal
                        specialists carefully review professional credentials
                        and conduct online interviews with candidates who
                        successfully pass the initial resume screening. The
                        first interview focuses on assessing English language
                        proficiency (as English serves as the primary language
                        at Alantech with most clients being from the US or UK)
                        and evaluating essential soft skills such as
                        communication, planning, time management, empathy, and
                        prior experience with remote work.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div
                      data-bs-toggle="collapse"
                      href="#faq9"
                      className="collapsed question fw-bold fs-6"
                    >
                      What if I don't pass the vetting process?
                      <i className="bi bi-chevron-down icon-show"></i>
                      <i className="bi bi-chevron-up icon-close"></i>
                    </div>
                    <div
                      id="faq9"
                      className="collapse"
                      data-bs-parent=".faq-list"
                    >
                      <p>
                        Not passing the vetting process is not a cause for
                        alarm. Our vetting process is intentionally rigorous to
                        ensure that we deliver only the highest calibre of
                        talent to our valued customers. If candidates encounter
                        challenges or difficulties with certain technical tasks
                        during the vetting process, they have the opportunity to
                        enhance their skills and gain a competitive edge to
                        reapply for Alan Tech vetting at a later time. We
                        encourage candidates to continue developing their
                        competencies and refining their abilities in order to
                        potentially succeed in future vetting attempts.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div
                      data-bs-toggle="collapse"
                      href="#faq10"
                      className="collapsed question fw-bold fs-6"
                    >
                      What can you be relieved of when hiring remote developers?
                      <i className="bi bi-chevron-down icon-show"></i>
                      <i className="bi bi-chevron-up icon-close"></i>
                    </div>
                    <div
                      id="faq10"
                      className="collapse"
                      data-bs-parent=".faq-list"
                    >
                      <p>
                        In our digitalized era, remote developers offer a
                        seamless solution, thanks to compact and efficient
                        laptops that allow web engineers to work from virtually
                        anywhere in the world. This remote working model not
                        only provides convenience but also brings significant
                        cost savings for employers. Recent research suggests
                        that startups can save up to $10,601 on remote workers.
                        Let's explore other benefits that remote workers can
                        offer.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div
                      data-bs-toggle="collapse"
                      href="#faq11"
                      className="collapsed question fw-bold fs-6"
                    >
                      What if the developer I hire doesn't meet expectations?
                      <i className="bi bi-chevron-down icon-show"></i>
                      <i className="bi bi-chevron-up icon-close"></i>
                    </div>
                    <div
                      id="faq11"
                      className="collapse"
                      data-bs-parent=".faq-list"
                    >
                      <p>
                        Occasionally, a lack of synergy can arise between you
                        and a hired contractor. However, if you choose to hire
                        developers through Alan Tech, you're in luck. In such
                        cases, all unsatisfactory candidates will be swiftly
                        replaced by the platform. To initiate the substitution
                        process, simply inform the Customer Success department
                        of your need for a replacement and await the arrival of
                        a new professional to pick up where the previous one
                        left off. Providing detailed feedback about the
                        unsatisfactory contractor will greatly assist in finding
                        a candidate better suited to your specific requirements.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="devs" className="devs">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h3 className="">
                ON-DEMAND DEVS AND TEAMS HAND-PICKED FROM THE STARS
              </h3>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <p className="mt-5">
                  we’ll hand-pick candidates with relevant experience only;
                  we’ll make sure they can do the work, not just talk the talk;
                  they’re inspired, motivated, and ready to start asap; and if
                  there’s no chemistry between you, we’ll offer you a swift
                  replacement.
                </p>

                <p>
                  The software developers at Alan Tech are self-sufficient,
                  eliminating the need for constant supervision. You will be
                  connected with exceptional programmers who seamlessly
                  integrate into your team, ensuring uncompromised product
                  quality.
                </p>
                <p>
                  Their unwavering dedication to your project's success is a
                  testament to their commitment, matching your own level of
                  enthusiasm. Their relentless pursuit of excellence ensures
                  that your goals are met with precision and passion. Together,
                  you form a powerful alliance, driven by a shared vision and
                  determination to achieve greatness.
                </p>
              </div>
              <div className="col-lg-6 own2">
                <img
                  className="homeimg p-5"
                  src={require("../images/home/home3.gif")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </main >
      <Footer />
    </div >
  );
}

export default Home;


