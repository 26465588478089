import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import { useNavigate } from 'react-router-dom'
import Buttons from '../../buttons'

function Startup_js() {
  const navigate = useNavigate();
    return (
        <>
            <Header />
            <div id="hirestartup" className="d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-duration="1000">
                    <h1 className="pt-5 col-sm-6" style={{ color: 'white' }}>Recruit <span>Startup Developers</span> for On Time Success – AlanTech</h1>
                    <div id='mediabutton'>
                        <button className="col-sm-3  btn btn-primary mb-5 shadow-lg mt-3"  onClick={() => navigate('/hire')}>Match me with a dev</button>
                    </div>
                </div>
            </div>
            <div className="container" >
                <div className="row" >
                    <div className="col-md-12 col-lg-6"data-aos='fade-right' data-aos-duration="1000">
                        <h2 className="mt-4">Deadlines Burning Bright? <span>Hire Startup Developers</span> To Handle Your Project Needs:</h2>
                        <p className="mt-3">A year ago you could only dream about the number of customers and orders you have today. But now your dreams are reality, and sometimes you probably think you have too much on your plate at once.</p>
                        <p><span className="pe-3">✔ </span>А product to constantly develop and update</p>
                        <p><span className="pe-3">✔ </span>Scheduled feature launches as committed:Backed by a loyal community of investors and users, ensuring promises are fulfilled.</p>
                        <p><span className="pe-3">✔ </span>The budget that you are running short of the lack of qualified working hands to work on all the tasks.</p>
                        <p><span className="pe-3">✔ </span>Consistent failure to keep pace can lead to product decay and user migration towards more competitive alternatives.</p>
                    </div>
                    <div className="col-md-12 col-lg-6"data-aos='fade-left' data-aos-duration="1000">
                        <img src={(require('../../../images/hire_devs/gif2.jpg'))} width="100%" height="100%" alt="" />
                    </div>
                </div>


            </div>
            <div className="container">
                <div className="mt-3" data-aos='fade-up' data-aos-duration="1000">
                    <h2>Your Shortest Road To Happiness Here is What You Can Do:</h2>
                    <p>Take a safe shortcut, and get help with your project within just 24 hours. Tomorrow, you will start working with a startup developer whose experience perfectly matches your project needs.</p>
                    <p>Choose to go all the way, and expose yourself to numerous trials and errors. We will prepare you for whatever awaits you on this damned road. But, you will have to walk all the way and find a programmer for startup yourself.</p>
                    <h2>The long road: when you hire startup developers yourself:</h2>
                    <p>Okay, so you like to figure things out on your own. Good. Then, let’s take a closer look at what options you typically have as a startup CTO in a crunch. We’ll start from the most expensive options, and move to the most affordable ones.</p>
                </div>
            </div>
            <div className="compier">
            <div className="container py-5">
                <div className="" data-aos='fade-up' data-aos-duration="1000">
                 <h2 className="text-center mb-3">Development Companies</h2>
                 <p className="text-center">Finding a startup developer? Pfft, baby talk. Better use the services of a whole development company. Right?</p>
                 </div>
                    <div className="row content mt-3">
                        <div className="col-lg-6" data-aos="fade-right" data-aos-duration="1000" >
                            <div className="section-title mt-3 ">
                                <h2>Pros</h2>
                            </div>



                            <p className="mt-4"><i className="bi bi-check2-all"></i> You pay for the full development cycle, from layouts to the finished product.</p>
                            <p className="mt-4"><i className="bi bi-check2-all"></i> Your project is in the hands of a big professional team with distributed roles and responsibilities.</p>
                            <p className="mt-4"><i className="bi bi-check2-all"></i> Deadlines are clearly communicated and rarely postponed.</p>
                            <p className="mt-4"><i className="bi bi-check2-all"></i> Your project manager will serve as a mediator between you and the development team, and will keep you updated about the development process.</p>
                           
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left" data-aos-duration="1000">
                            <div className="section-title mt-3">
                                <h2>cons</h2>
                            </div>
                            <p className="mt-4"><i className="bi bi-x-circle"></i> Too much depends on flawlessly clear communication and management.</p>
                            <p className="mt-4"><i className="bi bi-x-circle"></i> Even small changes may take time and money to implement. Making changes on the fly is not the strongest suit of any dev company.</p>
                            <p className="mt-4"><i className="bi bi-x-circle"></i> Expect like one free revision, if any at all. Usually, development companies charge you for everything.</p>
                            <p className="mt-4"><i className="bi bi-x-circle"></i> Apart from communicating your concerns or wishes to the project manager, you have little control over what is going on during the course of development.</p>
                           

                        </div>
                    </div>
                    </div>
                </div>
                <div className="container" >
                    <h2 className="mt-5">
                    Development Companies Rates:</h2>
                    <div className="row">
                        <div className="col-lg-6 p-4"data-aos="fade-right"data-aos-duration="1000">
                            <img src={(require('../../../images/hire_devs/devt.jpeg'))} height="100%" width="100%" />
                        </div>
                        <div className="col-lg-6  mt-5 pt-lg-0 content"data-aos="fade-left"data-aos-duration="1000">
                           <p className="mt-3"><span className="pe-3">✔ </span> Rates vary per company: Crafting mobile or web apps is intricate, making upfront cost estimation challenging.</p>
                           <p className="mt-3"><span className="pe-3">✔ </span> Exploring, we employed online calculators to gauge a rough mobile app development cost.</p>
                           <p className="mt-3"><span className="pe-3">✔ </span> Consider crafting a video streaming app for iOS and Android, encompassing security layers, social network integration, messaging, ratings, user profiles, multi-language functionality, and tablet optimization.</p>
                           <p className="mt-3"><span className="pe-3">✔ </span> The cost could range from $250,000 to $320,000, though this estimate isn't definitive. Various calculators yield varying figures, yet they consistently hover in the six-digit range.</p>
                          

                        </div>
                    </div>
                </div>
                <div className="glasser">
                <div className="container py-5">
                <div className="" data-aos='fade-up' data-aos-duration="1000">
                 <h2 className="text-center">Hire Startup Developers To Work In-House</h2>
                
                 </div>
                    <div className="row content">
                        <div className="col-lg-6" data-aos="fade-right" data-aos-duration="1000">
                            <div className="section-title mt-3 ">
                                <h2>Pros</h2>
                            </div>



                            <p className="mt-4"><i className="bi bi-check2-all"></i> You can traditionally expect better performance and higher quality of work.</p>
                            <p className="mt-4"><i className="bi bi-check2-all"></i> You own the intellectual property rights from the beginning.</p>
                            <p className="mt-4"><i className="bi bi-check2-all"></i> In-house developers usually work under strict NDAs and other regulations.</p>
                            <p className="mt-4"><i className="bi bi-check2-all"></i> Supporting, updating, and maintaining your product is easier.</p>
                           
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 " data-aos="fade-left" data-aos-duration="1000">
                            <div className="section-title mt-3">
                                <h2>cons</h2>
                            </div>
                            <p className="mt-4"><i className="bi bi-x-circle"></i> You need to rent an office for devs and hire additional staff to maintain it.</p>
                            <p className="mt-4"><i className="bi bi-x-circle"></i> You will be obliged to pay FICA taxes (Medicare and Social Security) and insurances.</p>
                            <p className="mt-4"><i className="bi bi-x-circle"></i> Your in-house devs can get sick or tired, so get ready to pay for their sick days and vacations.</p>
                            <p className="mt-4"><i className="bi bi-x-circle"></i> Even during off-seasons, you will have to pay your in-house devs in full.</p>
                           

                        </div>
                    </div>
                    </div>
                </div>
                <div className="container" >
                <div className="row" >
                    <div className="col-md-12 col-lg-6"data-aos='fade-right' data-aos-duration="1000">
                        <h2 className="mt-5">Freelancers From Generic online Marketplaces:</h2>
                       <div className="mt-3"data-aos='fade-right' data-aos-duration="1000">
                        <p><span className="pe-3">✔ </span>Okay, so prepare for a mind-blowing bold statement.</p>
                        <p><span className="pe-3">✔ </span>All the scary stories you probably heard about working with freelancers are bullshit.Well, most of them.</p>
                        <p><span className="pe-3">✔ </span>In the research we did in 2020, over 300 respondents from the United States pointed out that they not just had the experience of working with freelance developers, but were actually satisfied with the results.</p>
                        <p><span className="pe-3">✔ </span>In other words, if you want to find a programmer for startup, the sky won’t crumble if you try working with freelancers.This applies even to generic freelance marketplaces such as Fiverr or Upwork.</p>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6 "data-aos="fade-left"data-aos-duration="1000">
                        <img src={(require('../../../images/hire_devs/gif3.gif'))} width="100%" height="100%" alt="" />
                    </div>
                </div>


            </div>
            <div className="container" >
                <div className="row" data-aos='fade-right' data-aos-duration="1000">
                <div className="col-md-12 col-lg-6 ">
                        <img src={(require('../../../images/hire_devs/gift2.gif'))} width="100%" height="100%" alt="" />
                    </div>
                    <div className="col-md-12 col-lg-6"data-aos='fade-left' data-aos-duration="1000">
                        <h2 className="mt-5">Vetting Platforms: Your Best Bet, Really</h2>
                       <div className="mt-3">
                        <p><span className="pe-3">✔ </span>So, what should you do if you need to find startup developer fast, but none of the listed options works for you?</p>
                        <p><span className="pe-3">✔ </span>Thank God there are vetting platforms.</p>
                        <p><span className="pe-3">✔ </span>A bold assertion indeed! Freelance platforms for verified developers are akin to monumental inventions like hygiene and space travel. They represent the ultimate means to eliminate ALL risks when collaborating with freelance developers.</p>
                        <p><span className="pe-3">✔ </span>Erase your preconceptions about freelancers; the past 5 years have transformed the narrative. The era of daunting freelance developer tales has evolved into a new reality.</p>
                        </div>
                    </div>
                   
                </div>


            </div>
            <section id="why-us" className="why-us section-bg">
        <div className="container-fluid " data-aos="fade-right" data-aos-duration="1000">

          <div className="row">

            <div className="col-lg-6 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

              <div className="content">
                <h2>AlanTech: a Vetting Platform Designed For Your Startup</h2>
               <p>Absolutely correct. A vetting platform tailored to address the daily challenges of early-stage startups. It offers access to dependable, skilled developers who won't strain your budget or deplete your company's resources.It's a service that connects you with top-notch developers in record time.AlanTech embodies precisely that and more.</p>
              </div>

              <div className="accordion-list">
                <ul>
                  <li>
                    <a data-bs-toggle="collapse" className="collapse mt-3" data-bs-target="#accordion-list-1"><span>01</span>Devs you don’t want to part with<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                    <div id="accordion-list-1" className="collapse" data-bs-parent=".accordion-list">
                      <p>
                      Just recently, we had a Japanese customer buy out one of our DevOps engineers to work for their company full-time.Because our dev was THAT good.</p>
                    </div>
                  </li>

                  <li>
                    <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2 " className="collapsed mt-3"><span>02</span>Hourly rates that other platforms hate us for<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                    <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                      <p>
                      Want to find a programmer for startup fast, easy, for as low as $40 per hour? That’s totally possible.</p>
                    </div>
                  </li>

                  <li>
                    <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed mt-3"><span>03</span>We know what you need<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                    <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                      <p>
                      Whatever your project needs are, we’ll match you with a developer with 100% relevant skills and experience.Hey, did we just read your mind? You never know.</p>
                    </div>
                  </li>

                  <li>
                    <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4" className="collapsed mt-3"><span>04</span>Urning deadlines are no longer a problem<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                    <div id="accordion-list-4" className="collapse" data-bs-parent=".accordion-list">
                      <p>
                      As a startup, you often need things to be done ASAP. Don’t worry. You can start working with our best startup developers in just 24 hours.</p>
                    </div>
                  </li>

                
                </ul>
              </div>

            </div>

            <div className="col-lg-6 align-items-stretch order-1 order-lg-2 img p-4 " data-aos="fade-left" data-aos-duration="1000">
              <img src={(require('../../../images/hire_devs/devo.jpeg'))} width="100%" height="100%" data-aos="fade-left" data-aos-delay="150" /> &nbsp;
            </div>
          </div>

        </div>
      </section>
      <div className="container  ">
        <h2 className="mt-5" data-aos="fade-up" data-aos-duration="1000">How do we ensure the quality and reliability of our freelancers?</h2>

        <div className="row">


          <div className="col-12 col-lg-3" data-aos="fade-up" data-aos-duration="1000">
            <div className=" p-5">
              <img src={(require('../../../images/hire_devs/jquery-education.jpg'))} width="100%" height="140" alt="" />
            </div>
            <div className="pl-2 pr-2">
              <h4 className='ms-3 text-secondary '>Background</h4>
              <p className='   float-end '>We ensure transparency in collaborations. We scrutinize candidates' portfolios, GitHub/Stack Overflow profiles, social media, and former employers' insights for a holistic view. </p>

            </div>
          </div>

          <div className=" col-12 col-lg-3" data-aos="fade-up"data-aos-duration="1000">

            <div className="p-5">
              <img src={(require('../../../images/hire_devs/saas-english.jpg'))} width="100%" height="140" alt="" />
            </div>
            <div className='pl-2 pr-2'>

              <h4 className='text-secondary ms-3 text-center'>English proficiency</h4>
              <p className='   float-end  '>Alignment is key in collaborations. Prior to skill assessment, we verify candidates' English communication proficiency for seamless interaction.</p>
            </div>

          </div>

          <div className=" col-12 col-lg-3 " data-aos="fade-up" data-aos-duration="1000">

            <div className="p-5">
              <img src={(require('../../../images/hire_devs/saas-skill.jpg'))} width="100%" height="100%" alt="" />
            </div>
            <div className='pl-2 pr-2'>
              <h4 className='text-secondary ms-3 text-center'>Professionalism</h4>
              <p className='   float-end  '>We give our candidates real-life tasks to see how they can handle situations and solve problems emerging during the development process.</p>
            </div>
          </div>

          <div className=" col-12 col-lg-3 " data-aos="fade-up" data-aos-duration="1000">

            <div className="p-5">
              <img src={(require('../../../images/hire_devs/saas-interview.jpg'))} width="100%" height="100%" alt="" />
            </div>
            <div className='pl-2 pr-2'>
              <h4 className='text-secondary ms-3 text-center'>Personality</h4>
              <p className='   float-end  '>Video calls offer deeper insights into communication, soft skills, and team dynamics. Direct interaction reveals nuances hard to spot otherwise.</p>
            </div>
          </div>




        </div>

      </div>
      <div className="container" >
                <div className="row" >
                <div className="col-md-12 col-lg-6 "data-aos='fade-right' data-aos-duration="1000">
                        <img src={(require('../../../images/hire_devs/gif5.gif'))} width="100%" height="100%" alt="" />
                    </div>
                    <div className="col-md-12 col-lg-6"data-aos='fade-left' data-aos-duration="1000">
                        <h2 className="mt-5">How safe is it to work with AlanTech?</h2>
                       <div className="mt-3">
                        <p><span className="pe-3">✔ </span>AlanTech is a Delaware C-corp based in Ukraine.Our terms and conditions fully comply with the laws of the United States. You are fully protected by the American laws when working with us.</p>
                        <p><span className="pe-3">✔ </span>When freelance developers agree to our terms of use, they automatically oblige to</p>
                        <p><span className="pe-3">✔ </span>Keep your project details in secret</p>
                        <p><span className="pe-3">✔ </span>Transfer the intellectual property rights to you when you pay for the finished work.</p>
                        </div>
                    </div>
                   
                </div>


            </div>
            <div className="container" >
                <div className="row">
               
                    <div className="col-md-12 col-lg-6"data-aos='fade-right' data-aos-duration="1000">
                        <h2 className="mt-5">The best way to hire startup developers:</h2>
                       <div className="mt-3">
                        <p><span className="pe-3">✔ </span>While seeking referrals from friends and partners is ideal, it's not always feasible. Your network has limitations, and relying solely on referrals becomes impractical as your hiring needs grow.</p>
                        <p><span className="pe-3">✔ </span>At AlanTech, we exclusively collaborate with individuals we'd vouch for to our closest connections. If our network knew them, they'd endorse them as well. </p>
                        <p><span className="pe-3">✔ </span>Think of AlanTech as an extension of your network—an experienced friend who's always ready to introduce you to remarkable talents, eliminating the need for freelance marketplaces.</p>
                        <p><span className="pe-3">✔ </span>In fact, that friend’s name is Kate. You can call her right now. She definitely knows a dev you’ve been looking for.</p>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6 mt-3 "data-aos='fade-left' data-aos-duration="1000">
                        <img src={(require('../../../images/hire_devs/gif7.gif'))} width="100%" height="90%" alt="" />
                    </div>
                </div>


            </div>

<Buttons/>

            <Footer />
        </>
    )
}

export default Startup_js;