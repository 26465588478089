import React from 'react'
import { useNavigate } from 'react-router-dom'
import AdminHeader from './AdminHeader';

function Pro() {
    const navigate = useNavigate();
    return (
        <>
            <input type="checkbox" id="menu-toggle" />
            <div className="sidebar">
                <div className="side-header">
                    {/* <h3>M<span>odern</span></h3> */}
                    <img src={require("../../images/ALAN Final logo-01.png")} className="pt-2" />
                </div>

                <div className="side-content">
                    <div className="side-menu">
                        <ul>
                            <li>
                                <a onClick={() => navigate("/dash")}>
                                    <div className="d-flex justify-content-center">
                                        <span className="fas fa-home me-2"></span>
                                        <small>Dashboard</small>

                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={() => navigate("/Deve")}>
                                    <div className="d-flex justify-content-center">
                                        <span className="fas fa-user-alt me-2"></span>
                                        <small>Developers</small>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={() => navigate("/Clint")}>
                                    <div className="d-flex justify-content-center">
                                        <span className="fas fa-envelope me-2"></span>
                                        <small>Clients List</small>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={() => navigate("/Pro")} className="active">
                                    <div className="d-flex justify-content-center">
                                        <span className="fas fa-clipboard-list ms-3 me-2 "></span>
                                        <small>Add Projects</small>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="main-content">
                <AdminHeader />


                <main className="dashboard-main">

                    <div className="page-header">
                        <h1 className='mt-5'>Project</h1>
                    </div>

                    <div className="page-content ">
                    <button type="submit" class="btn btn-primary mt-4" onClick={() => navigate("/add_pro")}>Add projects</button>

                </div>


                    <div class="row mt-4">

                        <div class="col-sm-12 d-flex justify-content-center">
                            <div class="card-project">
                            
                                <div class="card-body h-25 mx-5">
                                    <h3>MOBILE FRONT-END DEV TO HELP FINALIZE AND POLISH MVP, REACT NATIVE, AWS, FULL-TIME</h3>
                                    <p>MIDDLE-TO-SENIOR MOBILE DEVELOPER</p>
                                    <div className='d-flex'>
                                        <span className='ms-3 border border-success p-2'>react native</span>
                                        <span className='ms-3 border border-success p-2'>aws</span>
                                        <span className='ms-3 border border-success p-2 '>node</span>
                                    </div>
                                    <div className='row mt-4 duration'>
                                        <div className='col-3'>DURATION
                                            1-3 MONTHS</div>
                                        <div className='col-3'>INVOLVEMENT
                                            FULL-TIME</div>
                                        <div className='col-3'>TYPE
                                            40H/WEEK</div>
                                        {/* <div className='col-3'><button className='btn-primary'>Read more</button></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </div>

        </>
    )
}

export default Pro