import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Card from "../../cards";
import { useNavigate } from 'react-router-dom'
function Full_stack() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <section id="stack" className="faq heros d-flex align-items-center">
        <div className="container" data-aos="zoom-out" data-aos-duration="1000">
          <h1>
            FULL STACK DEVELOPER<span><br />JOB DESCRIPTION</span>
          </h1>
        
        </div>
      </section>

  <div className="greybg">
  <div className="container">
        <div id="wondering"  data-aos="fade-up" data-aos-duration="1000" >
          <p className="mt-5">
            Seeking a new team member can be a time-consuming process. However, to
            streamline the hiring process, we offer a ready-to-use template for
            crafting a job description to attract experienced full-stack
            developers. This job description will outline the necessary work<i class="bi bi-check2-all"></i>
            experience, educational background, and skills required for potential
            candidates to join your full-stack development team. Utilize this
            template as a foundation to create a comprehensive job listing for the
            position of a full-stack web developer.
            Here are essential points you may include:
          </p>
       <div className="mediap">
       <p><i class="bi bi-chevron-double-right text-primary"> </i>Job brief</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>Full-stack developer responsibilities</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>Full-stack developer requirements and
            skills </p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>Benefits</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>Frequently asked questions</p>
       </div>

   
        </div>
        <div id="mediabutton">
        <button className="col-sm-3 btn btn-primary mb-3 shadow-lg mt-2"onClick={() => navigate('/hire')}>
            HIRE FULL-STACK DEVS
          </button>
        </div>
      </div>
  </div>
      <div className="container">
        <div id="beginning"  data-aos="fade-up" data-aos-duration="1000" >
          <h2 className="mt-5 mb-3">Job <span>Brief</span></h2>
          <p>
            In the introductory section of the job description, it's essential to
            elucidate the rationale behind our search for a full-stack developer.
            This is an opportunity to provide an overview of our company and
            articulate the specific requirements we aim to address by welcoming a
            skilled full-stack programmer to our team. By detailing our company's
            background and the specific need we intend to fulfill through this
            hiring, we can attract candidates who align with our mission and
            contribute effectively to our development endeavors.</p>
          <p> For example: </p>
          <p> Introducing ourselves as Red Apple Co, a reputable software
            development company, we are currently in search of a talented
            full-stack web developer to join our team. This role carries the
            responsibility of leading our development efforts, utilizing
            full-stack technology to craft diverse mobile and web applications for
            our esteemed clientele. As a pivotal member of our team, the selected
            candidate will have the opportunity to play a crucial role in driving
            our projects to success and contributing to our company's growth and
            innovation in the software development domain.
          </p>
        </div>
      </div>
      <div className="container">
        <div id="general"  data-aos="fade-up" data-aos-duration="1000" >
          <h2 className="mt-4  mb-3">Full-Stack <span> Developer Responsibilities</span></h2>
          <p>
            In this section, we will elucidate the key role and responsibilities
            that the new team member will undertake upon joining us. As a
            full-stack developer, the selected candidate will play a vital role in
            our team, contributing their expertise and skills to various aspects
            of our software development projects. Their responsibilities encompass
            both back-end and front-end development, ensuring seamless integration
            and functionality across our applications.
          </p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>The position requires proficiency in both
            front-end and back-end development, encompassing both mobile and web
            applications. As a full-stack developer in our team, you will be
            expected to excel in handling various aspects of the development
            process, ensuring seamless integration and functionality across both
            front-end and back-end components for both mobile and web platforms.
          </p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>As a full-stack developer in our team,
            you will have the unique opportunity to blend creativity with
            analytical skills. We encourage you to share your innovative ideas and
            propose comprehensive full-stack solutions for our projects. This
            dynamic role allows you to explore creative possibilities while also
            leveraging your analytical mindset to deliver well-rounded and
            efficient solutions across the entire development spectrum.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>In this role, you will be involved in
            working with a diverse range of projects, including websites, software
            applications, and other related components. Your expertise as a
            full-stack developer will be crucial in ensuring the successful
            development and integration of these different elements to deliver
            high-quality and comprehensive solutions to our clients.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>Your proficiency as a full-stack
            developer will be essential in ensuring the seamless functioning of
            our projects across various platforms, including Windows, macOS, and
            Linux. Your expertise in cross-platform compatibility will play a
            vital role in delivering a consistent and optimal user experience to a
            diverse audience. This aspect of your role ensures that our
            applications perform efficiently and reliably on different operating
            systems, meeting the expectations and needs of our clients and
            end-users alike.</p>

        </div>
      </div>
      <div className="container">
        <div id="crucial"  data-aos="fade-up" data-aos-duration="1000" >
          <h2 className="mt-4  mb-3"><span>Full-Stack Developer </span> Requirements And Skills</h2>
          <p>
            In this section, we will outline the role and responsibilities that
            the new team member will undertake upon joining our company. As a
            full-stack developer, the selected candidate will play a pivotal role
            in our team, contributing their expertise and skills to various
            aspects of our software development projects.</p>

          <p> The specific tasks for our full-stack developer encompass a wide range
            of responsibilities, which may include, but are not limited to:</p>

          <p>Collaborating with our team of developers, designers, and project
            managers to conceptualize, plan, and execute cutting-edge web and
            mobile applications.</p>
          <p>  Taking ownership of the entire development life cycle, from ideation
            and design to implementation and deployment.
            Writing clean and efficient code, following best practices, coding
            standards, and ensuring the optimal performance of the
            applications.</p>
          <p>Building and maintaining databases, ensuring data security, integrity,
            and scalability.</p>

          <p>Ensuring that projects run smoothly on different platforms, including
            Windows, macOS, and Linux, to deliver a consistent user experience.
            Staying up-to-date with the latest technologies, trends, and
            advancements in the field of full-stack development and incorporating
            relevant tools and frameworks into our projects.
            Assisting in the enhancement and optimization of existing applications
            to continually improve user experience and functionality.
            Contributing to code reviews, providing constructive feedback, and
            fostering a collaborative and innovative development environment
            within the team.</p>
          <p> While these responsibilities represent general tasks expected from a
            full-stack developer, we may also have specific projects or unique
            requirements tailored to our company's domain and objectives. The new
            team member will have the opportunity to work on exciting and
            challenging projects that showcase their expertise and make a
            significant impact on our clients' businesses.</p>

          <p>For example: Here are the requirements and skills needed for a
            full-stack developer in our company:</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>The fundamental elements of front-end
            development, which include HTML, CSS, and JavaScript, are essential
            skills for this role. Proficiency in these areas will be crucial in
            creating engaging and interactive user interfaces for our web and
            mobile applications. As a full-stack developer, having a strong
            foundation in front-end technologies will enable you to effectively
            collaborate with our team and contribute to the successful
            implementation of our projects.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>The role also requires a solid
            understanding and experience in back-end design and development. Your
            proficiency in this area will be vital in creating robust server-side
            architecture, managing databases, handling server logic, and ensuring
            the seamless integration of the back-end with the front-end
            components. As a full-stack developer, having back-end expertise will
            enable you to contribute comprehensively to our projects, delivering
            efficient and scalable solutions for our clients' needs.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>The position demands proficiency in
            programming languages such as Java, Python, Ruby, or PHP. Your
            expertise in these languages will enable you to tackle diverse
            development tasks and contribute effectively to our projects. As a
            full-stack developer, your ability to work with multiple programming
            languages will be an asset in building versatile and dynamic
            applications for our clients.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>Having basic UI/UX design skills is
            another important aspect of the role. As a full-stack developer, your
            ability to understand and incorporate fundamental UI/UX principles
            will contribute to creating user-friendly and visually appealing
            interfaces for our applications. This skill set will enable you to
            collaborate effectively with designers and deliver seamless user
            experiences that enhance the overall usability and attractiveness of
            our products.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>TProficiency in development frameworks
            and database management is a key requirement for this role. As a
            full-stack developer, your expertise in utilizing various frameworks
            will empower you to streamline the development process and enhance
            code efficiency. Additionally, your knowledge of effective database
            management will enable you to design, implement, and maintain
            databases that support our applications, ensuring data security and
            seamless retrieval of information. These skills are integral to
            delivering high-quality and robust solutions for our clients'
            needs.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>While not mandatory, possessing a
            bachelor's degree in computer science, computer engineering, or a
            related field is advantageous for this role. This educational
            background equips candidates with a strong theoretical foundation and
            practical knowledge that can significantly contribute to their
            effectiveness as a full-stack developer. However, we also welcome
            candidates with equivalent experience and relevant skills, as we
            prioritize expertise and the ability to excel in the responsibilities
            of the role.</p>


       
        </div>
        <div id="mediabutton">
        <button className="col-sm-3 btn btn-primary mb-3 shadow-lg mt-2"onClick={() => navigate('/hire')}>
            GRAB A MIGHT DEV TODAY
          </button>
        </div>
      </div>
 <div className="greybg">
 <div className="container">
        <div id="appreciation"  data-aos="fade-up" data-aos-duration="1000" >
          <h2 className="mt-4  mb-3">Benefits</h2>
          <p>
            At our company, we deeply value our employees, and their contributions
            are at the heart of our success. As a full-stack developer on our
            team, you can expect to be part of a supportive and inclusive work
            environment that fosters growth and innovation. In addition to a
            competitive salary, we offer a range of benefits and perks to enhance
            your overall well-being and work experience.
            For example: These are the additional benefits we offer from our
            side:
            <p><i class="bi bi-chevron-double-right text-primary"> </i>TAs a full-stack developer in our
              company, you will have the flexibility to choose a comfortable working
              environment that suits your preferences. Whether you prefer working at
              our well-equipped company office or from the comfort of your home
              office, we support both arrangements. Our focus is on creating an
              environment where you can deliver your best work while maintaining a
              healthy work-life balance.</p>
            <p><i class="bi bi-chevron-double-right text-primary"> </i>We offer an exceptional opportunity for
              professional growth as a full-stack developer at our company. We are
              committed to nurturing the talents and skills of our team members,
              providing them with avenues for advancement and continuous learning.
              With challenging and exciting projects, access to cutting-edge
              technologies, and supportive mentorship, you will have the perfect
              platform to elevate your career and achieve your full potential as a
              full-stack developer.</p>
            <p><i class="bi bi-chevron-double-right text-primary"> </i>We provide comprehensive medical
              insurance coverage to all our employees. Your health and well-being
              are of utmost importance to us, and we strive to ensure that you and
              your family have access to quality healthcare. With our medical
              insurance plan, you can have peace of mind knowing that your
              health-related needs are taken care of, allowing you to focus on your
              work and personal pursuits with confidence.</p>
            <p><i class="bi bi-chevron-double-right text-primary"> </i>We highly value your opinions and ideas
              as a full-stack developer in our team. Your unique perspectives and
              insights are essential for fostering innovation and driving our
              projects forward. We believe in cultivating a culture of respect and
              open communication, where every team member's contributions are
              recognized and appreciated. Your thoughts and suggestions will be
              warmly welcomed and considered as we collaboratively work towards
              achieving our common goals.</p>
            <p><i class="bi bi-chevron-double-right text-primary"> </i>We offer competitive and fair
              compensation to our full-stack developers, acknowledging the valuable
              skills and expertise you bring to our team. Additionally, in
              recognition of your dedication and commitment, we provide overtime pay
              for any extra hours worked beyond the regular schedule. Our aim is to
              ensure that your efforts are duly rewarded and that you are motivated
              to excel in your role while maintaining a healthy work-life
              balance.</p>
            <p><i class="bi bi-chevron-double-right text-primary"> </i>We offer flexible working hours to our
              full-stack developers, providing you with the freedom to adjust your
              work schedule according to your preferences and personal commitments.
              Moreover, we also embrace the possibility of working from home,
              allowing you to strike the right balance between work and personal
              life. We believe that offering such flexibility enhances productivity
              and job satisfaction, empowering you to perform at your best and
              achieve your goals while enjoying the comfort and convenience of your
              home workspace.</p>
          </p>
        </div>
      </div>
 </div>
      <div className="container">
        <div id="software"  data-aos="fade-up" data-aos-duration="1000">
          <h2 className="mt-4  mb-3"> Job <span>Description Example</span></h2>
          <p>We are Red Apple Co, a renowned software development company, currently seeking a skilled full-stack web
            developer to join our team. As a full-stack developer, you will play a key leadership role, spearheading our
            development endeavors with the use of cutting-edge technology. Your expertise will be instrumental in crafting
            diverse and innovative mobile and web applications to cater to our valued clients' needs. Join us in this
            exciting journey as we continue to make a significant impact in the software development landscape.
            The responsibilities of a full-stack programmer in our company are: </p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>The position requires expertise in both front-end and back-end development,
            encompassing both mobile and web applications. As a full-stack developer, you will be actively involved in
            various aspects of the development process, ensuring seamless integration and functionality across both
            front-end and back-end components for both mobile and web platforms. Your role will encompass the entire
            spectrum of development, allowing you to showcase your versatility and contribute effectively to the success of
            our projects across different platforms.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>As a full-stack developer in our team, we value your ability to harmoniously blend
            creativity with analytical skills. This unique combination empowers you to ideate and share innovative ideas
            while providing comprehensive full-stack solutions for our projects. We encourage a collaborative environment
            where your creativity can thrive, and your analytical mindset can contribute to the efficient and effective
            development of our web and mobile applications. Your diverse skill set will be a driving force in shaping
            exceptional solutions that meet our clients' needs and exceed their expectations.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>In this role, you will have the opportunity to work on a diverse range of projects,
            including websites, software applications, and various other components. As a full-stack developer, you will be
            involved in the development of these different elements, ensuring their seamless integration and functionality.
            Your expertise will contribute to the creation of robust and user-friendly applications that cater to a wide
            range of needs and industries. Embracing this diverse set of projects, you will play a crucial role in
            delivering high-quality solutions and contributing to the success of our clients' ventures.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>Your role as a full-stack developer encompasses the responsibility of ensuring the
            smooth functioning of our projects across various platforms, including Windows, macOS, and Linux. With your
            expertise, you will diligently test and optimize the applications, ensuring they perform seamlessly on different
            operating systems. By prioritizing cross-platform compatibility, you will guarantee that our products deliver a
            consistent user experience, reaching a broader audience and catering to the diverse needs of our
            clients.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>As a full-stack developer, having a strong grasp of front-end development basics is
            essential. Proficiency in fundamental languages such as HTML, CSS, and JavaScript will enable you to create
            engaging and interactive user interfaces for our web and mobile applications. By leveraging these core skills,
            you will collaborate effectively with our design and back-end teams to deliver visually appealing and functional
            user experiences that align with our clients' requirements and industry standards.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>In this role, possessing comprehensive back-end design and development knowledge is
            crucial. As a full-stack developer, your expertise in this area will empower you to design and build robust
            server-side architectures, manage databases, and implement intricate server logic. This proficiency is
            instrumental in ensuring seamless integration of front-end and back-end components, resulting in efficient and
            scalable applications. Your back-end skills will be fundamental in creating the backbone of our projects,
            providing a solid foundation for the success of our web and mobile applications.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>Proficiency in one or more of the following programming languages - Java, Python,
            Ruby, or PHP - is a key requirement for this role. As a full-stack developer, your expertise in these languages
            will be pivotal in undertaking diverse development tasks and contributing to our projects' success. Your fluency
            in these languages will enable you to deliver robust and innovative solutions that meet the specific needs of
            our clients and align with industry best practices.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>Having basic UI/UX design skills is an essential aspect of this role. As a
            full-stack developer, your understanding of UI/UX principles will be instrumental in creating user-friendly and
            visually appealing interfaces for our applications. Your grasp of these design fundamentals will allow you to
            collaborate effectively with our design team, ensuring a seamless user experience that enhances the overall
            usability and aesthetics of our products. Embracing these skills, you will contribute to delivering intuitive
            and engaging interfaces that delight our clients and end-users alike.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>This position requires experience in both framework development and database
            management. As a full-stack developer, your proficiency in creating and utilizing frameworks will play a crucial
            role in streamlining the development process and optimizing code efficiency. Additionally, your expertise in
            database management will empower you to design, implement, and maintain databases that support our applications,
            ensuring data security and smooth information retrieval. </p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>While not a strict requirement, we highly value candidates with a bachelor's degree
            in computer science, computer engineering, or a related field. This educational background equips individuals
            with a strong theoretical foundation and practical knowledge that can significantly contribute to their
            effectiveness as a full-stack developer. However, we also welcome candidates with equivalent experience and
            relevant skills, as we prioritize expertise and the ability to excel in the responsibilities of the role.
            Regardless of your educational background, we encourage talented individuals with a passion for full-stack
            development to apply and become an integral part of our team.Was this response better or worse?Better Worse Same
          </p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>We offer a comfortable working environment that caters to your preferences,
            providing the flexibility to choose between working at our company's office or from the comfort of your home
            office. We believe that a comfortable and conducive workspace is essential for optimal productivity and job
            satisfaction. Whether you thrive in a collaborative office setting or prefer the convenience of a home office,
            we support both options to ensure you can deliver your best work and achieve a healthy work-life
            balance.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>We are proud to provide an exceptional opportunity for professional growth to all
            our full-stack developers. As a part of our team, you will have access to continuous learning and development
            opportunities that will nurture your skills and expertise. We are committed to empowering our employees to reach
            their full potential and achieve their career aspirations. With challenging projects, mentorship, and exposure
            to the latest technologies, you will have ample scope for advancement and the chance to thrive in a dynamic and
            innovative environment. Your growth is integral to our success, and we are dedicated to supporting your journey
            towards professional excellence.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>We offer comprehensive medical insurance coverage to all our employees. Your health
            and well-being are paramount, and we want to ensure that you and your family have access to quality healthcare
            services. With our medical insurance plan, you can have peace of mind knowing that you are protected and
            supported during times of need. We prioritize your health and strive to create a safe and healthy work
            environment for all our team members.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>We deeply respect and value your opinions and ideas as a full-stack developer on
            our team. We believe that every team member brings unique perspectives and insights that contribute to our
            collective success. Your thoughts and contributions will be heard, appreciated, and taken into consideration as
            we work collaboratively to achieve our goals. We foster an inclusive and supportive culture that encourages open
            communication and recognizes the importance of each team member's input. Your creativity and innovative thinking
            are integral to driving our projects forward, and we are committed to creating an environment where you feel
            empowered and respected.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>We offer competitive and fair compensation to our full-stack developers,
            recognizing the value of your skills and dedication. In addition to your regular compensation, we provide
            overtime pay for any extra hours worked beyond the standard schedule. We believe in compensating you fairly for
            your hard work and commitment to our projects. Your contributions are highly valued, and we strive to ensure
            that you are rewarded appropriately for your efforts. With our approach to compensation and overtime pay, we aim
            to create a motivating and fulfilling work experience for our team members.</p>
          <p><i class="bi bi-chevron-double-right text-primary"> </i>We provide flexible working hours to our full-stack developers, allowing you to
            customize your work schedule according to your needs and preferences. Additionally, we embrace the possibility
            of remote work, enabling you to work from the comfort of your home when suitable. Our commitment to flexibility
            stems from our belief that it enhances work-life balance and productivity. By offering these options, we aim to
            create a work environment that empowers you to excel while accommodating your individual circumstances and
            personal commitments.</p>
        </div>
      </div>
      <div className="container">
          <div id="devs" >
            <h2 className="mt-5 mb-4 text-center"  data-aos="fade-up" data-aos-duration="1000">Are you in search of<span> Developers?</span></h2>
            <Card />
          </div>
</div>
      <section id="faq" className="faq section-bg">
        <div className="container" data-aos="fade-up "data-aos-delay="1000">

          <div className="section-title">
            <h2>F.A.Q</h2>
            <h3>Frequently Asked <span>Questions</span></h3>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-10">
              <ul className="faq-list">

                <li>
                  <div data-bs-toggle="collapse" className="collapsed question" href="#faq1">What does a full-stack developer do
                    every day?<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                  <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                    <p>

                      A full-stack engineer works with front-end and back-end development. It means they are responsible for
                      the client side of the app or website besides the server side. They use different languages, such as
                      HTML, CSS, JavaScript, Python, and others. One of their responsibilities is communicating with a
                      development team to discuss ideas and plans and sometimes with the clients to meet all of their
                      expectations.
                    </p>
                  </div>
                </li>

                <li>
                  <div data-bs-toggle="collapse" href="#faq2" className="collapsed question">What language is a full stack? <i
                    className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                  <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                    <p>

                      Full-stack developers are polyglots. They must be good at more than one language to work with a
                      full-stack technology. The most common ones are Java, Ruby, Python, and PHP.
                    </p>
                  </div>
                </li>

                <li>
                  <div data-bs-toggle="collapse" href="#faq3" className="collapsed question">Do full-stack developers work from
                    home? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                    <p>

                      It depends on the company you are working for. Full-stack developers can work in the office as well as
                      work remotely. As a remote full-stack developer, you are expected to work on your tasks at home or
                      wherever you choose and communicate with the team and your manager via video calls or messages
                      regularly to coordinate the teamwork.
                    </p>
                  </div>
                </li>

                <li>
                  <div data-bs-toggle="collapse" href="#faq4" className="collapsed question">Is Python a full-stack language? <i
                    className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                  <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                    <p>
                      Yes, it is one of the full-stack programming languages developers use. It is the most popular language
                      for the server side of the application. Mainly, it is applied when working on scientific-based apps
                      because it is quick and straightforward for mathematical calculations. But also, you can use it to
                      create almost any type of program or software and work not only with back-side app development.</p>
                  </div>
                </li>

                <li>
                  <div data-bs-toggle="collapse" href="#faq5" className="collapsed question">What is a full-stack developer's
                    salary? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                    <p>
                      Indeed reports that the average salary for a full-stack developer is $101,299 per year in the United
                      States. Even with less than one year of experience, you can get up to $83,140 per year. As for Europe,
                      Glassdoor reports the average salary for a full-stack developer in the United Kingdom is £49,753 per
                      year. In Ukraine, it is ₴25,732 per year, as by Payscale.
                    </p>
                  </div>
                </li>

              </ul>
            </div>
          </div>

        </div>
      </section>


      <Footer />
    </>

  )
}

export default Full_stack;