import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'

function Developer_pdf() {
    const location = useLocation();
    const [showPdf, setShowPdf] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const url = urlParams.get('pdfUrl');

        if (url) {
            setPdfUrl(decodeURIComponent(url));
            setShowPdf(true);
        }
    }, [location.search]);

    return (
        <>
            {showPdf && (
                <embed
                    type="application/pdf"
                    src={pdfUrl}
                    width="100%"
                    height="100%"
                    className="pdf-viewer"
                ></embed>
            )}
        </>
    )
}

export default Developer_pdf