import React from "react";
import { useContext, useState, useEffect } from "react";
import { RecoveryContext } from "../App";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useParams } from 'react-router-dom';
import {
  Container,
  InputAdornment,
  Typography,
  Button,
  Box,
  TextField,
  Grid,
  IconButton,
  Paper,
  FormHelperText,
} from "@mui/material";
import Swal from "sweetalert2";

import { useFormik } from 'formik';
import * as Yup from 'yup';


export default function Forgot() {
  const { id } = useParams();
  const navigate = useNavigate();

  const location = useLocation();

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const [newPwdError, setNewPwdError] = useState(false);

  const container = {
    maxWidth: {
      lg: 500,
      xs: 400,
    },
    height: {
      lg: '100vh',
      md: '100vh',
      xs: '600px'
    },
    display: "flex", alignItems: "center"
  }

  // Formik validation schema
  const pwdValidationSchema = Yup.object().shape({
    newPassword: Yup.string().min(8, 'Password must be 8 characters long')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol').required("Please enter the password"),
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], "password must match").required("Please enter the password")
  });

  // UseFormik hook
  const { values, handleBlur, handleChange, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: pwdValidationSchema,
    onSubmit: onsubmit
  })

  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  function SweetAlert() {
    Swal.fire({
      icon: 'success',
      text: 'password updated successfully',
      timer: 1800
    })
  }

  async function onsubmit(values) {

    try {
      const formdata = new FormData();
      formdata.append('id', id)
      formdata.append('password', values.confirmPassword)
      const response = await axios.post(`${process.env.REACT_APP_URL}/Auth2/updatepassword`, formdata,
        {
          headers: {
            'Client-service': process.env.REACT_APP_CLIENT_SERVICE,
            'Auth-Key': process.env.REACT_APP_AUTH_KEY
          }
        });

      if (response.status == 200) {
        SweetAlert();
        setTimeout(() => {
          navigate("/login")
        }, 2000)
      }
    } catch (error) {
      alert('Internal Server Error, Please Try after sometime')
      console.error('Login error:', error);
    }
  }
    return (
      <>
        <Box>
          <Container sx={container}>
            <Paper elevation={5} sx={{
              p: {
                lg: "40px",
                xs: "20px",
                md: "20px"
              }
            }}>

              <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
                <img src={require("../images/ALAN Final logo-01.png")} height={"40%"} width={"60%"} />
              </Box>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ textAlign: "center", my: 1 }}>
                      RESET PASSWORD
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>

                    <TextField
                      label="New Password"
                      name="newPassword"
                      type={showPassword1 ? 'text' : 'password'} // Toggle password visibility
                      fullWidth
                      helperText={errors.newPassword && touched.newPassword ? errors.newPassword : ""}
                      value={values.newPassword}
                      error={touched.newPassword && Boolean(errors.newPassword)}
                      onBlur={handleBlur}
                      onChange={handleChange}

                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={handleClickShowPassword1}
                            >
                              {showPassword1 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                  </Grid>
                  <Grid item xs={12}>

                    <TextField
                      label="Confirm Password"
                      name="confirmPassword"
                      type={showPassword2 ? 'text' : 'password'} // Toggle password visibility
                      fullWidth
                      helperText={errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : ""}
                      value={values.confirmPassword}
                      error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                      onBlur={handleBlur}
                      onChange={handleChange}

                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={handleClickShowPassword2}
                            >
                              {showPassword2 ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />


                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      type="submit"
                      sx={{ py: 1, bgcolor: " #294b86 !important", color: "white" }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Container>
        </Box>
      </>
    );
  }
