import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'

import { useNavigate } from 'react-router-dom'
import AdminHeader from './AdminHeader';
import Pagination from "react-js-pagination";
import axios from 'axios'

function Clint() {
  const [serialNumber, setSerialNumber] = useState(1);
  const navigate = useNavigate();
  const URL = process.env.REACT_APP_URL;
  const [data, setData] = useState([]);
  const [filtereddata, setFiltereddata] = useState([])
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 8;
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const [record, setRecord] = useState('')
  const [record2, setRecord2] = useState([])
  const [parsedData, setParsedData] = useState([]);
  const token = localStorage.getItem('token');
  useEffect(() => {
    const fetchClient = async () => {
      if (!token) {
        return navigate('/');
      }
      const response = await axios.get(`${process.env.REACT_APP_URL}/ClientData`, {
        headers: {
          'Client-service': process.env.REACT_APP_CLIENT_SERVICE,
          "Auth-Key": process.env.REACT_APP_AUTH_KEY,
          'User-id': '1',
          'Authorization': token
        }
      })
      if (response.status == 200) {
        console.log(response, "williams")
        const fetchedData = response.data.data.client;
        const dataWithSerialNumbers = fetchedData.map((user, index) => ({
          ...user,
          serialNumber: serialNumber + index,
        }));
        setData(dataWithSerialNumbers);
        setFiltereddata(dataWithSerialNumbers)
        setSerialNumber(serialNumber + fetchedData.length);
      }
      else {
        return navigate('/');
      }
    }
    fetchClient();
  }, []);
  const handleFilter = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = data.filter((item) =>
      item.fullName?.toLowerCase()?.includes(query) ||
      item.email?.toLowerCase()?.includes(query) ||
      item.mainSpecialization?.toLowerCase()?.includes(query) ||
      item.phoneNumber?.toLowerCase()?.includes(query)
    );
    setFiltereddata(filtered);
  };

  const handleInputChange = (field, value) => {
    setRecord((prevRecord) => ({
      ...prevRecord,
      [field]: value,
    }));
  };
  const [si, setSi] = useState('')
  const modalopen = async (id) => {
    console.log(id, "yogech")
    setSi(id)
    const response = await axios.get(`${process.env.REACT_APP_URL}/ClientData/${id}`, {
      headers: {
        'Client-service': process.env.REACT_APP_CLIENT_SERVICE,
        "Auth-Key": process.env.REACT_APP_AUTH_KEY,
        'User-id': '1',
        'Authorization': token
      }
    })
    if (response.status === 200) {
      const fetchedData = response.data.data.client[0];
      const fetchedDatajob = response.data.data.job;
      setRecord(fetchedData);
      setRecord2(fetchedDatajob)
    } else {
      return navigate('/');
    }

  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(si, "wilspat")
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/ClientData`, {
        id: si,
        fullName: record.fullName,
        email: record.email,
        phoneNumber: record.phoneNumber,
        greatAt: record.greatAt,
        mainFocus: record.mainFocus,
        mainSpecialization: record.mainSpecialization,
        involment: record.involment,
        description: record.description,
      }, {
        headers: {
          'Client-service': process.env.REACT_APP_CLIENT_SERVICE,
          'Auth-Key': process.env.REACT_APP_AUTH_KEY,
          'User-id': '1',
          'Authorization': token
        }
      })
      if (response.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Done!",
          text: "Successfully updated",
        });
        window.location.reload();
      }
      else {
        console.log("something error")
      }
    } catch (error) {
      console.log(error)
    }
    console.log(record, "john willaisn");
  }
  const handleDelete = async (id) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_URL}/ClientData`, {
        id: id,
        is_deleted: '1'
      }, {
        headers: {
          'Client-service': process.env.REACT_APP_CLIENT_SERVICE,
          'Auth-Key': process.env.REACT_APP_AUTH_KEY,
          'User-id': '1',
          'Authorization': token
        }
      });
      if (response.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Done!",
          text: "Deleted Successfully",
        });
        window.location.reload();
      }
      console.log('Delete successful', response);
    } catch (error) {
      console.error('Error deleting data', error);
    }
  };
  return (
    <>
      <input type="checkbox" id="menu-toggle" />
      <div className="sidebar">
        <div className="side-header">
          <img src={require("../../images/ALAN Final logo-01.png")} className="pt-2" />
        </div>

        <div className="side-content">

          <div className="side-menu">
            <ul>
              <li>
                <a onClick={() => navigate("/dash")}>
                  <div className="d-flex justify-content-center">
                    <span className="fas fa-home me-2"></span>
                    <small>Dashboard</small>

                  </div>
                </a>
              </li>
              <li>
                <a onClick={() => navigate("/Deve")}>
                  <div className="d-flex justify-content-center">
                    <span className="fas fa-user-alt me-2"></span>
                    <small>Developers</small>
                  </div>
                </a>
              </li>
              <li>
                <a onClick={() => navigate("/Clint")} className="active">
                  <div className="d-flex justify-content-center">
                    <span className="fas fa-envelope me-2"></span>
                    <small>Clients List</small>
                  </div>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div className="main-content">
        <AdminHeader />

        <main className="dashboard-main" id="develeper">
          <div className="page-header">
            <h1 className="mt-5">Clients</h1>
          </div>

          <div className="page-content">
            <div className="records table-responsive">
              <div className="p-2">
                <input
                  type="text"
                  className="float-end mb-2 mt-2 w-25 me-1 form-control searchbar-bar"
                  onChange={handleFilter}
                  placeholder="Search "
                />
              </div>
              <div>
                <table width="100%" className="mt-5">
                  <thead>
                    <tr>
                      <th className="text-center">
                        <span className="las la-sort"></span>SI.NO
                      </th>
                      <th className="text-center">
                        <span className="las la-sort"></span>NAMES
                      </th>
                      <th className="text-center">
                        <span className="las la-sort"></span>EMAIL
                      </th>
                      <th className="text-center">
                        <span className="las la-sort"></span>MainSpecialization
                      </th>
                      <th className="text-center">
                        <span className="las la-sort"></span>phoneNumber
                      </th>
                      <th className="text-center">
                        <span className="las la-sort"></span> ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtereddata
                      .slice(
                        (activePage - 1) * itemsPerPage,
                        activePage * itemsPerPage
                      )
                      .map((user) => (
                        <tr key={user._id}>
                          <td className="text-center">
                            <h6>{user.serialNumber}</h6>
                          </td>
                          <td className="text-center">

                            <h6>{user.fullName}</h6>
                          </td>
                          <td className="text-center">

                            <h6>{user.email}</h6>
                          </td>

                          <td className="text-center">

                            <h6>{user.mainSpecialization}</h6>
                          </td>

                          <td className="text-center">

                            <h6>{user.phoneNumber}</h6>
                          </td>

                          <td className="text-center">
                            <div className="actions">
                              <span
                                className="fas fa-eye"
                                onClick={() => navigate(`/Clientedit/${user.id}`)}
                              ></span>
                              <span
                                className="fas fa-edit"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() => modalopen(user.id)}
                              ></span>
                              <span
                                className="fas fa-trash"
                                onClick={() => handleDelete(user.id)}
                              ></span>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={data.length}
              pageRangeDisplayed={3}
              onChange={handlePageChange}
              // itemClass="page-item"
              linkClass="page-link"
              innerClass="pagination justify-content-center page  "
            />
          </div>
        </main>
      </div>

      <form
        onSubmit={handleSubmit}
      >
        <div class="modal fade mt-5"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5 text-center" id="exampleModalLabel">Account Details</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="d-flex justify-content-around">
                  <div className="">
                    <label>
                      Name
                    </label>
                    <input
                      type="text"
                      className=" w-100 mb-3 searchbar form-control"
                      value={record.fullName}
                      placeholder="Enter"
                      onChange={(e) => handleInputChange('fullName', e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label>
                      Email
                    </label>
                    <input
                      type="text"
                      className=" w-100 mb-3 searchbar form-control"
                      value={record.email}
                      placeholder="Enter"
                      onChange={(e) => handleInputChange('email', e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-around">
                  <div className="">
                    <label>
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className=" w-100 mb-3 searchbar form-control"
                      value={record.phoneNumber}
                      placeholder="Enter"
                      onChange={(e) => handleInputChange('phoneNumber', e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label>
                      Great At
                    </label>
                    <input
                      type="text"
                      className=" w-100 mb-3 searchbar form-control"
                      value={record.greatAt}
                      placeholder="Enter"
                      onChange={(e) => handleInputChange('greatAt', e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-around">
                  <div className="">
                    <label>
                      Main Focus
                    </label>
                    <input
                      type="text"
                      className=" w-100 mb-3 searchbar form-control"
                      value={record.mainFocus}
                      placeholder="Enter"
                      onChange={(e) => handleInputChange('mainFocus', e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label>
                      Main Specialization
                    </label>
                    <input
                      type="text"
                      className=" w-100 mb-3 searchbar form-control"
                      value={record.mainSpecialization}
                      placeholder="Enter"
                      onChange={(e) => handleInputChange('mainSpecialization', e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-around">
                  <div className="">
                    <label>
                      Involment
                    </label>
                    <input
                      type="text"
                      className=" w-100 mb-3 searchbar form-control"
                      value={record.involment}
                      placeholder="Enter"
                      onChange={(e) => handleInputChange('involment', e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label>
                      Description
                    </label>
                    <input
                      type="text"
                      className=" w-100 mb-3 searchbar form-control"
                      value={record.description}
                      placeholder="Enter"
                      onChange={(e) => handleInputChange('description', e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary" >Submit</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default Clint