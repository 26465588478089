import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Card from "../../cards";
import { useNavigate } from 'react-router-dom'

function Dot_net() {
  const navigate = useNavigate();
  return (
    <>
      <Header />

      <section id="heros" className="faq heros">
        <div
          className="container"
          data-aos="zoom-out"

          data-aos-duration="1000"
        >
          <h1 className="pt-5">
            .NET DEVELOPER <span> <br /> JOB DESCRIPTION</span>
          </h1>
          <br />
        </div>
      </section>

 <div className="greybg">
 <div className="container pt-2" data-aos="fade-up"  data-aos-duration="1000">
        <div id="struggle">
          <p>
            The struggle is undeniably real, and we are well aware of it. However,
            consider yourself fortunate in this situation!
          </p>

          <p>
            Congratulations! If you've come across this, your struggle is over.
            We've created a user-friendly .Net developer job description template
            that can be tailored to your company's needs, streamlining the hiring
            process for the most qualified candidates. Remember, when posting a
            job vacancy on a job board, this template aimx to connect you with the
            perfect candidate and help candidates find their ideal employer – you!
          </p>

          <p>
            When searching for the ideal candidate, your job profile should
            consist of several essential sections to fill in. In this article, we
            will guide you through each of these sections that you should
            incorporate into your job description.
          </p>

         
        </div>
        <div id="mediabutton">
        <button className="col-sm-3 btn btn-primary mb-3 shadow-lg" onClick={() => navigate('/hire')}>
        .NET DEVS HERE
          </button>
        </div>
      </div>

 </div>
      <div className="container mt-4" data-aos="fade-right" data-aos-duration='1000' >
        <div id="brief" >
          <h2 >Job brief</h2>
          <p >
            To begin job posts for the .Net developer position, it is recommended
            to start with a brief and concise job description outlining the
            primary tasks and responsibilities. Limit this section to three to
            five sentences at most. To provide a better understanding, we have
            prepared a sample job description for your reference.
          </p>
          <br />
          <h2 >Sample:</h2>
          <p >
            At (Company name), we specialize in providing (services name).
            Currently, we are seeking a skilled .Net developer to join our
            programming team. Your primary responsibilities will revolve around
            coding, designing, and maintaining software internally. This
            opportunity is perfect for individuals who can produce functional code
            and possess a keen eye for detail to swiftly identify and rectify
            defects.
          </p>
          <br />
        </div>
      </div>
      <div className="container" data-aos="fade-up" data-aos-duration='1000'>
        <div id="company">
          <h2>Company description</h2>
          <p>
            Moving on to the next section, which is the company description. This
            is your opportunity to showcase your company as a truly unique and
            appealing place to work. With numerous similar positions available,
            it's essential to answer the question of why a qualified developer
            should choose you over others. Elaborate on your company's goals,
            mission statement, work culture, and core values. You can also share a
            brief narrative about the journey that shaped your company into what
            it is today. By including these aspects, you can attract the right
            candidates and persuade them to apply for this specific position among
            the myriad of alternatives available.
          </p>
          <br />
          <h2>Sample:</h2>
          <p>
            Established in 2018, you will be joining our company during a pivotal
            phase of growth and expansion. Our primary objective is to become the
            leading digital marketing consultancy provider for 750 clients. To
            realize this vision, we strongly believe in fostering a work
            environment that promotes freedom and equality. We value the input and
            ideas of our employees, encouraging them to share their thoughts
            openly. Our company places great importance on seamless communication
            and efficient task completion. As part of our remote work approach, we
            aim to provide employees with four to six hours of synchronous work
            time.
          </p>
          <br />
        </div>
      </div>
     <div className="greybg">
     <div className="container" data-aos="fade-left" data-aos-duration='1000'>
        <div id="net">
          <h2>
            <span> .NET Developers</span> requirements and
            qualifications
          </h2>
          <p>
            With the previous sections covered, let's now proceed to present the
            role and qualifications for the .Net programmer position. To begin, we
            will outline the specific requirements that an aspiring candidate
            should possess in order to be considered for the role. As we are
            seeking a developer who is proficient in their field, we will tailor
            the description accordingly to reflect the level of expertise we are
            looking for.
          </p>
          <br />
          <h2>Sample:</h2>

          <p><span className="text-primary fs-5">✔</span> A Bachelor's degree in software
            engineering, computer science, web design, or a related field is
            required.</p>

          <p><span className="text-primary fs-5">✔</span> A minimum of three years of experience in
            software development using .NET languages is essential. </p>

          <p><span className="text-primary fs-5">✔</span> We expect a strong proficiency level in
            utilizing MVC (Model-View-Controller).</p>

          <p><span className="text-primary fs-5">✔</span> We are looking for candidates with
            excellent knowledge and understanding of Web Services and APIs.</p>

          <p><span className="text-primary fs-5">✔</span> We require candidates to have advanced
            knowledge of the coding language C#.</p>

          <p><span className="text-primary fs-5">✔</span> Candidates should possess an in-depth
            understanding of database applications, including SQL Server and Oracle.
          </p>

          <p><span className="text-primary fs-5">✔</span> We expect candidates to have a thorough
            understanding and practical experience in applying Agile methodology.</p>

          <p><span className="text-primary fs-5">✔</span> Candidates should demonstrate a solid
            understanding of the .Net Core framework and experience working in a
            multiservice environment.</p>

          <p><span className="text-primary fs-5">✔</span> Candidates should be proficient in various
            client-side technologies, including HTML, JS, CSS, Bootstrap, and have
            experience with one or more of the following frameworks: React, Angular,
            or Vue.js.</p>
        </div>
      </div>

     </div>
      <div className="container mt-3" data-aos="fade-up" data-aos-duration='1000'>
        <div id="filed">
          <h2>Responsibilities for <span>.Net developer</span></h2>
          <p>
            Now that the requirements field has been covered, let's move on to the
            responsibilities of the .Net developer to ensure we are on the same
            page. In this section, we will be transparent about our core plans and
            expectations, leaving no tasks hidden that we anticipate our .Net
            programmer to fulfill.
          </p>
          <br />
          <h2>Sample:</h2>

          <p><span className="text-primary fs-5">✔</span> The .Net developer will be responsible for
            creating software solutions using Microsoft's .NET infrastructure to
            meet the specific requirements of our clients.</p>

          <p><span className="text-primary fs-5">✔</span> One of the key responsibilities of the .Net
            developer is to rewrite and enhance existing code based on the specified
            guidelines provided. </p>

          <p><span className="text-primary fs-5">✔</span> The .Net developer will also be tasked
            with reusing and readapting existing web applications as needed.</p>

          <p><span className="text-primary fs-5">✔</span>  Collaborating within a team, the .Net developer will participate in the
            development of more intricate programx and codes.</p>
          

          <p><span className="text-primary fs-5">✔</span> The .Net developer should possess excellent
            debugging skills to ensure seamless execution of their code.</p>

          <p><span className="text-primary fs-5">✔</span> The .Net developer will be responsible for
            programming and thoroughly testing the output to ensure it is visually
            appealing and user-friendly. </p>

          <p><span className="text-primary fs-5">✔</span> The .Net developer will actively consider
            client feedback to create the best possible product and user
            experience.</p>

          <p><span className="text-primary fs-5">✔</span> The .Net developer should continuously stay
            updated with the latest advancements and trends in the coding world.</p>

         
        </div>
        <div id="mediabutton">
        <button className="col-sm-3 btn btn-primary mb-3 shadow-lg"onClick={() => navigate('/hire')}>
        HIRE THE BEST DEVS
          </button>
        </div>
      </div>

      <div className="container mt-5" data-aos="fade-right" data-aos-duration='1000'>
        <div id="choose">
          <h2>Why choose our company?</h2>
          <p>
            Lastly, we would like to highlight the benefits of choosing our
            company. In this section, we emphasize the opportunities for career
            development, competitive compensation, comprehensive insurance
            coverage, and various other perks that we offer to our employees. We
            aim to demonstrate our commitment to caring for and empowering our
            workforce.
          </p>
          <br />
          <h2>.Net developer job description sample</h2>
          <p>
            We are a digital marketing consultancy firm in search of a middle to
            senior-level .Net developer to join our dynamic engineering team.
            Collaboratively, we will enhance and expand our client reach and
            experience by creating innovative web and mobile applications. Your
            role will involve working closely with both the internal team and our
            esteemed clients. If you possess excellent attention to detail, strong
            communication skills, effective time management, and familiarity with
            agile methodology, I am confident that we can achieve great things
            together!
          </p>
          <br />
          <p>
            Established in 2018, our company is currently in a pivotal phase of
            growth and expansion, and you will play a vital role in this journey.
            Our ambitious goal is to become the primary digital marketing
            consultancy provider for 750 clients. To achieve this, we firmly
            believe in fostering a work environment that promotes freedom,
            equality, and creativity. We highly value the opinions and ideas of
            our employees, and we actively encourage them to express their
            thoughts. Our company places a strong emphasis on effective
            communication and task completion. As part of our flexible work
            culture, we provide employees with four to six hours of synchronous
            remote work to ensure a balanced approach to productivity and
            well-being.
          </p>
          <br />
        </div>
      </div>
    <div className="greybg">
    <div className="container" data-aos="fade-up"  data-aos-duration='1000'>
        <div id="software">
          <h2>Requirements:</h2>

          <p><span className="text-primary fs-5">✔</span> A Bachelor's degree in software engineering, computer science, web design, or a related field is required for this position.</p>

          <p><span className="text-primary fs-5">✔</span> Candidates must have at least three years of experience in software development, focusing on coding in .NET languages. </p>

          <p><span className="text-primary fs-5">✔</span> We expect candidates to demonstrate a high proficiency level in utilizing MVC (Model-View-Controller).</p>

          <p><span className="text-primary fs-5">✔</span> We are seeking candidates with excellent knowledge and a deep understanding of Web Services and APIs..</p>

          <p><span className="text-primary fs-5">✔</span> Candidates should possess advanced knowledge of the coding language C#.</p>

          <p><span className="text-primary fs-5">✔</span> Applicants should have an in-depth understanding of database applications, including SQL Server and Oracle.
          </p>

          <p><span className="text-primary fs-5">✔</span> Candidates should have a clear understanding of Agile methodology and practical experience in its application.</p>

          <p><span className="text-primary fs-5">✔</span> Candidates should have a comprehensive understanding of the .Net Core framework and experience working in a multiservice environment.</p>

          <p><span className="text-primary fs-5">✔</span> We are looking for candidates who are proficient in various client-side technologies, including HTML, JS, CSS, Bootstrap, and have experience with at least one of the following frameworks: React, Angular, or Vue.js.</p>
        </div>
      </div>

    </div>
      <div className="container mt-5" data-aos="fade-left"  data-aos-duration='1000'>
        <div id="solution">
          <h2>Responsibilities: </h2>

          <p><span className="text-primary fs-5">✔</span> The .Net developer will be responsible for generating software solutions using Microsoft's .NET infrastructure to meet the specific requirements of our clients.</p>

          <p><span className="text-primary fs-5">✔</span> As part of their responsibilities, the .Net developer will be tasked with rewriting and enhancing existing code based on the specified guidelines provided.</p>

          <p><span className="text-primary fs-5">✔</span> The .Net developer will also be responsible for reusing and readapting existing web applications as required.</p>

          <p><span className="text-primary fs-5">✔</span> The .Net developer will collaborate within a team to develop more complex programx and codes.</p>

          <p><span className="text-primary fs-5">✔</span> The .Net developer should possess excellent debugging skills to ensure the smooth and efficient execution of their code.</p>

          <p><span className="text-primary fs-5">✔</span> As part of their role, the .Net developer will be responsible for programming and thoroughly testing the output to ensure it is visually appealing and user-friendly. "</p>

          <p><span className="text-primary fs-5">✔</span> The .Net developer will actively consider client feedback to develop the best possible product and user experience.</p>

          <p><span className="text-primary fs-5">✔</span> The .Net developer should continuously stay updated with the latest advancements and trends in the coding world.</p>
        </div>
      </div>
      <div className="container mt-5" data-aos="fade-up"  data-aos-duration='1000'>
        <div id="care">
          <h2>Perks and benefits</h2>
          <p>Last but not least, we deeply care about our employees' well-being. To support your physical health, we offer gym memberships, ensuring you stay strong and healthy. Additionally, we provide a subscription to a mental health app, emphasizing the importance of your overall well-being. While our company offers remote employment, we also offer a home-office bonus to ensure you have a comfortable and efficient workspace. To foster our internal culture, we organize two to three-day meet-up trips despite working remotely. Lastly, we provide a generous 24 days of paid holiday to ensure you have the time to relax and recharge.</p>
        </div>
      </div>

      {/* <div className="container mt-5" data-aos="fade-up">
        <div id="wanna">
          <h2>Wanna hire <span>.NET aces?</span> Check these out:</h2>

          <div className="row ps-5 mx-5 mt-5">
            <div className="col-lg-6 col-md-6">
              <div className="card1 mx-5 px-3">
                <div className="card shadow" style={{ width: "21rem" }}>
                  <div className="row">
                    <div className="col-3 w-50">
                      <img src="./assets/img/job_des/d1.jpg" className="card-img-top p-4" alt="..." />
                    </div>

                    <div className="col-4 mt-5 py-3">
                      <h2 className="fw-bold">$67/h</h2>
                    </div>
                  </div>
                  <div className="card-body">
                    <h2 className="card-title">KRZYSZTOF D.</h2>
                    <h5 className="card-title">//Senior front-end developer</h5>
                    <p className="card-text">8 years of experience behind the belt of this developer, and react is his
                      superpower - when it comes to it, you can't really beat him.
                      <h6>javascript</h6>
                      <h6>react</h6>
                      <h6>typescript</h6>
                    </p>
                    <a href="#" className="btn btn-primary">talk to sales</a>
                  </div>
                </div>
              </div>

            </div>
            <div className=" col-lg-6 col-md-6">
              <div className="card2 mx-2 px-5">
                <div className="card shadow" style={{ width: "21rem" }}>
                  <div className="row">
                    <div className="col-3 w-50">
                      <img src="./assets/img/job_des/d2.jpg" className="card-img-top p-4" alt="..." />
                    </div>
                    <div className="col-4 mt-5 pt-3">
                      <h2 className="fw-bold">$70/h</h2>
                    </div>

                  </div>
                  <div className="card-body">
                    <h2 className="card-title">PRIYADENYS B.</h2>
                    <h5 className="card-title">//Senior front-end developer</h5>
                    <p className="card-text">8 years of experience behind the belt of this developer, and react is his
                      superpower - when it comes to it, you can't really beat him.
                      <h6>javascript</h6>
                      <h6>react</h6>
                      <h6>typescript</h6>
                    </p>
                    <a href="#" className="btn btn-primary">talk to sales</a>
                  </div>
                </div>
              </div>

            </div>
          </div>
      </div> */}
      <div className="mt-3">
      <Card />
      </div>
      <section id="faq" className="faq section-bg">
        <div className="container" data-aos="fade-up" data-aos-duration='1000'>

          <div className="section-title">
            <h2>F.A.Q</h2>
            <h2>Frequently Asked <span>Questions</span></h2>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-10">
              <ul className="faq-list">

                <li>
                  <div data-bs-toggle="collapse" className="collapsed question" href="#faq1">What does a .NET developer do?<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                  <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                    <p>
                      .Net developers are responsible for programming, designing, and implementing web applications using .Net languages, such as VB, C#, and F#, as well as .Net platform technologies. Dot Net programmers are hired to configure systems. Dot net developers have an eye for detail to find and fix a defect and ensure the maintenance of software.
                    </p>
                  </div>
                </li>

                <li>
                  <div data-bs-toggle="collapse" href="#faq2" className="collapsed question">Why hire a .Net developer?<i className="bi bi-chevron-down icon-show"></i><i
                    className="bi bi-chevron-up icon-close"></i></div>
                  <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                    <p>
                      Dot net developers are there to program unique web and mobile app applications that would align with your specific business. For example, writing a program that would analyze data of your business. .Net developers provide user support that can facilitate better productivity and experience. You want to hire a .Net developer to maintain your company’s uniqueness by developing products only applicable to you.
                    </p>
                  </div>
                </li>

                <li>
                  <div data-bs-toggle="collapse" href="#faq3" className="collapsed question">What skills should a .Net developer have?  <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                    <p>
                      Team player;
                      Good verbal communication;
                      Customer service experience;
                      Problem-solving mindset;
                      Time-management;
                      In-depth knowledge of .Net languages;
                      Ability to budget design and manage;
                      Coding.
                    </p>
                  </div>
                </li>

                <li>
                  <div data-bs-toggle="collapse" href="#faq4" className="collapsed question">Is a .NET developer a backend developer? <i className="bi bi-chevron-down icon-show"></i><i
                    className="bi bi-chevron-up icon-close"></i></div>
                  <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                    <p>
                      Yes! A .Net developer (dot net developer) is a backend developer specializing in applying Microsoft software technology .NET to build web applications using languages C#. However, if there is such need, a .Net developer can accomplish some of a front-end tasks.
                    </p>
                  </div>
                </li>

                <li>
                  <div data-bs-toggle="collapse" href="#faq5" className="collapsed question">How much do .NET developers make?  <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                    <p>
                      On average, hiring an experienced .Net developer in New York would cost you 58-60 USD per hour. Los Angeles and Austin developers charge 57-60 USD per hour, according to Glasdoor.
                      AlanTech offers strong middle and senior developers at a much lower cost due to being hired offshore where an hourly wage is lower. For instance, you can hire an experienced
                    </p>
                  </div>
                </li>

              </ul>
            </div>
          </div>

        </div>
      </section>

      <Footer />
    </>
  )
}

export default Dot_net;