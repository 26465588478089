import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import profile from "../../character42/character_2-04.png"
function Clientedit() {

    const [data2, setData2] = useState([])
    const navigate = useNavigate();


    const [data, setData] = useState('')
    const { id } = useParams();
    const [parsedData, setParsedData] = useState([]);
    useEffect(() => {
        const token = localStorage.getItem('token');
        const fetchClient = async () => {
            if (!token) {
                return navigate('/');
            }
            const response = await axios.get(`${process.env.REACT_APP_URL}/ClientData/${id}`, {
                headers: {
                    'Client-service': process.env.REACT_APP_CLIENT_SERVICE,
                    "Auth-Key": process.env.REACT_APP_AUTH_KEY,
                    'User-id': '1',
                    'Authorization': token
                }
            })
            if (response.status === 200) {
                const fetchedData = response.data.data.client[0];
                const fetchedDatajob = response.data.data.job;
                setData(fetchedData);
                setData2(fetchedDatajob)
                const newskill = fetchedDatajob[0].newSkill;
                const arrayData = JSON.parse(newskill);
                setParsedData(arrayData);
            } else {
                return navigate('/');
            }
        }

        fetchClient();
    }, []);
    const backButton = () => {
        navigate('/Clint')
    }
    return (
        <>
            <input type="checkbox" id="menu-toggle" />
            <div className="sidebar">
                <div className="side-header">
                    <img
                        src={require("../../images/ALAN Final logo-01.png")}
                        className="pt-2"
                    />
                </div>

                <div className="side-content">
                    <div className="side-menu">
                        <ul>
                            <li>
                                <a onClick={() => navigate("/dash")} >
                                    <div className="d-flex justify-content-center">
                                        <span className="fas fa-home me-2"></span>
                                        <small>Dashboard</small>

                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={() => navigate("/Deve")} >
                                    <div className="d-flex justify-content-center">
                                        <span className="fas fa-user-alt me-2"></span>
                                        <small>Developers</small>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={() => navigate("/Clint")} className="active">
                                    <div className="d-flex justify-content-center">
                                        <span className="fas fa-envelope me-2"></span>
                                        <small>Clients List</small>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="main-content">
                <div class="profile-admin-card-client">
                    <div class="container">
                        <div className="page-header">
                            <h2 className="mt-3">Client Detail</h2>
                        </div>
                        <button className="sampleButton ms-2" onClick={backButton}><span className="fas fa-arrow-left"></span></button>
                        <div class="main-body-project-card">
                            <div class="row gutters-sm">

                                <div class="col-md-4 mb-3">
                                    <div class="card-project-admin card">
                                        <div class="card-body-project">
                                            <div class="d-flex flex-column align-items-center text-center">
                                                <img src={profile} alt="Admin"
                                                    class="rounded-circle" width="150" />
                                                <div class="mt-3">
                                                    <h4>{data.fullName}</h4>
                                                    <p class="text-secondary mb-1">{data.mainSpecialization}</p>
                                                    <p class="text-muted font-size-sm mb-4"> {data.email}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="card mb-3">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <h6 class="mb-0">ID</h6>
                                                </div>
                                                <div class="col-sm-9 text-secondary">
                                                    {data.id}
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <h6 class="mb-0">Great at</h6>
                                                </div>
                                                <div class="col-sm-9 text-secondary">
                                                    {data.greatAt}
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <h6 class="mb-0">Phone</h6>
                                                </div>
                                                <div class="col-sm-9 text-secondary">
                                                    {data.phoneNumber}
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <h6 class="mb-0">Involvement</h6>
                                                </div>
                                                <div class="col-sm-9 text-secondary">
                                                    {data.involment}
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="row mb-4" >
                                                <div class="col-sm-3">
                                                    <h6 class="mb-0">Main Focus</h6>
                                                </div>
                                                <div class="col-sm-9 text-secondary">
                                                    {data.mainFocus}
                                                </div>
                                            </div>
                                            <div class="row mb-4" >
                                                <div class="col-sm-3">
                                                    <h6 class="mb-0">Description</h6>
                                                </div>
                                                <div class="col-sm-9 text-secondary">
                                                    {data.description}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <h4 className="ms-5">Aplied By Developers</h4>
                <hr />

                {
                    data2.length === 0 ? (
                        <p className="text-center">There is no developers applied this Project</p>
                    ) : (
                        data2.map((item) => (
                            <div className="profile-admin-card" key={item.id}>
                                    <div className="main-body-project-card">
                                        <div className="row gutters-sm">
                                            <div className="col-md-4 mb-3">
                                                <div className="card-project-admin card">
                                                    <div className="card-body-project">
                                                        <div className="d-flex flex-column align-items-center text-center">
                                                            <img src={profile} alt="Admin" className="rounded-circle" width="150" />
                                                            <div className="mt-3">
                                                                <h4>{item.firstName} {item.lastName}</h4>
                                                                <p className="text-secondary mb-1"> {item.email}</p>
                                                                <p className="text-muted font-size-sm mb-4">{item.country}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        <div className="row mb-2">
                                                            <div className="col-sm-3">
                                                                <h6 className="mb-0">ID</h6>
                                                            </div>
                                                            <div className="col-sm-9 text-secondary">
                                                                {item.id}
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row mb-2">
                                                            <div className="col-sm-3">
                                                                <h6 className="mb-0">Rate </h6>
                                                            </div>
                                                            <div className="col-sm-9 text-secondary">
                                                                {item.hourlyRate}
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row mb-2">
                                                            <div className="col-sm-3">
                                                                <h6 className="mb-0">Phone Number</h6>
                                                            </div>
                                                            <div className="col-sm-9 text-secondary">
                                                                {item.phoneNumber}
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row mb-2">
                                                            <div className="col-sm-3">
                                                                <h6 className="mb-0">Seniority Level</h6>
                                                            </div>
                                                            <div className="col-sm-9 text-secondary">
                                                                {item.seniorityLevel}
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row mb-2    ">
                                                            <div className="col-sm-3">
                                                                <h6 className="mb-0">LinkedIn</h6>
                                                            </div>
                                                            <div className="col-sm-9 text-secondary">
                                                                {item.linkedIn}
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row mt-3">
                                                            <div class="col-sm-9 col-lg-12">
                                                                {parsedData.map((item, index) => (
                                                                    <p key={index}>
                                                                        Technology : {item?.skill} || Experience: {item?.experience}
                                                                    </p>
                                                                ))}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        ))
                    )
                }
                <AdminHeader />
            </div>
        </>
    )
}

export default Clientedit