import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

<link href="your-project-dir/icon-font/lineicons.css" rel="stylesheet" />

function Button() {
  const navigate = useNavigate();
  const [show, setshow] = useState()
  const showName = () => {
    if (show == true) {
      setshow(false)

    } else {
      setshow(true)
      document.getElementById('hari').style.display = 'none'
    }
  }
  const [show2, setshow2] = useState()
  const showName2 = () => {
    if (show2 == true) {
      setshow2(false)

    } else {
      setshow2(true)
      document.getElementById('hari2').style.display = 'none'
    }
  }
  return (
    <div className='buttoncomp'>
      <div className='container mt-5 '>
        <div className='row '>
          <h2 className='text-center'>Recruit the skilled developers from AlanTech to join your team.</h2>
          <div className="col-lg-3 col-6 mb-3   ">
            <button  onClick={() => navigate('/hire_android')} class="rounded-3  d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/android.png'))} height="10%" width="14%" />
            
                <p className='m-auto w-100 align-items-center p-2'>Android</p>
           


            </button>
          </div>
          <div className="col-lg-3 col-6  mb-3  ">
            <button onClick={() => navigate('/hire_aws')} class="   m-auto rounded-3    d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/aws.png'))} height="10%" width="14%" />
              <p className='m-auto w-100 align-items-center p-2'>AWS</p>


            </button>
          </div>
          <div className="col-lg-3 col-6  mb-3  ">
            <button onClick={() => navigate('/hire_blockchain')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/blockchain.png'))} height="10%" width="14%" />
              <p className='m-auto w-100 align-items-center p-2'>Blockchain</p>


            </button>
          </div>
          <div className="col-lg-3 col-6  mb-3  ">
            <button onClick={() => navigate('/Ecommerce')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/online-shop.png'))} height="10%" width="14%" />
              <p className='m-auto w-100 align-items-center p-2'>Ecommerce</p>


            </button>
          </div>



        </div>
        <div className='row '>

          <div className="col-lg-3 col-6  mb-3  ">
            <button onClick={() => navigate('/Hire_frontend')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/frontbg.png'))} height="10%" width="14%" />
              <p className='m-auto w-100 align-items-center p-2'>Frontend</p>


            </button>
          </div>
          <div className="col-lg-3 col-6  mb-3  ">
            <button onClick={() => navigate('/hire_ios')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/ios.png'))} height="10%" width="14%" />
              <p className='m-auto w-100 align-items-center p-2'>IOS</p>


            </button>
          </div>
          <div className="col-lg-3 col-6  mb-3  ">
            <button onClick={() => navigate('/hire_php')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/php.png'))} height="10%" width="16%" />
              <p className='m-auto w-100 align-items-center p-2'>PHP</p>

            </button>
          </div>
          <div className="col-lg-3 col-6  mb-3  ">
            <button onClick={() => navigate('/Hire_python')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/python.png'))} height="10%" width="15%" />
              <p className='m-auto w-100 align-items-center p-2'>Python</p>


            </button>
          </div>



        </div>
        <div className='row '>

          <div className="col-lg-3 col-6  mb-3  ">
            <button onClick={() => navigate('/ruby')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/ruby2.png'))} height="10%" width="15%" />
              <p className='m-auto w-100 align-items-center p-2'>Ruby</p>

            </button>
          </div>
          <div className="col-lg-3 col-6  mb-3  ">
            <button onClick={() => navigate('/react_js')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/atom.png'))} height="10%" width="15%" />
              <p className='m-auto w-100 align-items-center p-2'>React</p>


            </button>
          </div>
          <div className="col-lg-3 col-6  mb-3  ">
            <button onClick={() => navigate('/hire_Saas ')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/saas.png'))} height="10%" width="15%" />
              <p className='m-auto w-100 align-items-center p-2'>Saas</p>


            </button>

          </div>
          <div className="col-lg-3 col-6  mb-3  ">
            <button onClick={() => navigate('/hire_net')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/NET.png'))} height="10%" width="15%" />
              <p className='m-auto w-100 align-items-center p-2'>.Net</p>


            </button>
          </div>





        </div>


        {
          show && (<div className='row'>

            <div className="col-lg-3 col-6 ">
              <button onClick={() => navigate('/Hire_javascript')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/js.png'))} height="10%" width="15%" />
                <p className='m-auto w-100 align-items-center p-2'>JS</p>


              </button>
            </div>
            <div className="col-lg-3 col-6  mb-3  ">
              <button onClick={() => navigate('/hire_angular')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/angular2.png'))} height="10%" width="15%" />
                <p className='m-auto w-100 align-items-center p-2'>Angular</p>

              </button>
            </div>
            <div className="col-lg-3 col-6  mb-3  ">
              <button onClick={() => navigate('/devop')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/devops.png'))} height="10%" width="15%" />
                <p className='m-auto w-100 align-items-center p-2'>Devops</p>


              </button>
            </div>
            <div className="col-lg-3 col-6  mb-3  ">
              <button onClick={() => navigate('/hire_vue')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/vue.png'))} height="10%" width="15%" />
                <p className='m-auto w-100 align-items-center p-2'>Vue.js</p>


              </button>
            </div>



          </div>)
        }
        {
          show && (<div className='row '>

            <div className="col-lg-3 col-6  mb-3  ">
              <button onClick={() => navigate('/hire_machine_learning')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/ml.png'))} height="10%" width="13%" />
                <p className='m-auto w-100 align-items-center p-2'>ML/AI</p>

              </button>
            </div>
            <div className="col-lg-3 col-6  mb-3">
              <button onClick={() => navigate('/hire_Jquery')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/jquery.png'))} height="10%" width="12%" />
                <p className='m-auto w-100 align-items-center p-2'>Jquery</p>


              </button>
            </div>





          </div>
          )
        } 
        <button className=' bg-secondary text-white text-center border border-0 rounded-3 pt-2 pb-2 object-fit-cover border rounded' id='hari' onClick={showName}>+SEE ALL</button>

      </div>
      

      <div className='container mt-5  '>
        <h2 className='text-center'>Recruit individuals according to their designated roles.</h2>
        <div className='row '>

          <div className="col-lg-4 col-12 mb-3   ">
            <button onClick={() => navigate('/Hire_frontend')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
              <p className='m-auto w-100 align-items-center p-2'>Frontend</p>

            </button>
          </div>
          <div className="col-lg-4 col-12  mb-3  ">
            <button onClick={() => navigate('/Hire_mobile_app')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
          
                <p className='m-auto w-100 align-items-center p-2'>Mobile developer</p>
       


            </button>
          </div>

          <div className="col-lg-4 col-12  mb-3  ">
            <button onClick={() => navigate('/hire_android')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
              <p className='m-auto w-100 align-items-center p-2'>Android app dev</p>

            </button>
          </div>



        </div>
        <div className='row '>

          <div className="col-lg-4 col-12  mb-3  ">
            <button onClick={() => navigate('/hire_ios')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
   
                <p className='m-auto w-100 align-items-center p-2'>ios developer</p>
     


            </button>
          </div>
          <div className="col-lg-4 col-12  mb-3  ">
            <button onClick={() => navigate('/react_js')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
  
                <p className='m-auto w-100 align-items-center p-2'>React developer</p>
           


            </button>
          </div>
          <div className="col-lg-4 col-12  mb-3  ">
            <button onClick={() => navigate('/hire_aws')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
              <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
          
                <p className='m-auto w-100 align-items-center p-2'>AWS developer</p>
         


            </button>
          </div>




        </div>
        {
          show2 && (<div className='row '>

            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate('/Ecommerce')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
              
                  <p className='m-auto w-100 align-items-center p-2'>Ecommerce dev</p>
               


              </button>
            </div>
            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate('/hire_php')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
                <p className='m-auto w-100 align-items-center p-2'>PHP developer</p>


              </button>
            </div>
            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate('/Hire_python')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
                <p className='m-auto w-100 align-items-center p-2'>Python developer</p>

              </button>
            </div>




          </div>)
        }
        {
          show2 && (<div className='row '>

            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate('/ruby')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
                <p className='m-auto w-100 align-items-center p-2'>Ruby developer</p>


              </button>
            </div>
            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate('/hire_Saas')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
                <p className='m-auto w-100 align-items-center p-2'>SAAS developer</p>

              </button>
            </div>
            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate('/hire_net')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
                <p className='m-auto w-100 align-items-center p-2'>.NET developer</p>

              </button>
            </div>



          </div>)
        }
        <button className='bg-secondary text-white text-center border border-0 rounded-3 pt-2 pb-2 object-fit-cover border rounded' id='hari2' onClick={showName2}>+ Show More </button>
        {
          show2 && (<div className='row '>

            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate('/Hire_part_time')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
                <p className='m-auto w-100 align-items-center p-2'>Part-time dev</p>


              </button>
            </div>
            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate('/Hire_javascript')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
                <p className='m-auto w-100 align-items-center p-2'>Java_script dev</p>

              </button>
            </div>
            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate('/hire_angular')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
                <p className='m-auto w-100 align-items-center p-2'>Angular dev</p>


              </button>
            </div>




          </div>)
        }
        {
          show2 && (<div className='row '>

            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate('/devop')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
                <p className='m-auto w-100 align-items-center p-2'>DevOps Engineer</p>


              </button>
            </div>
            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate('/hire_remote_dev')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
                <p className='m-auto w-100 align-items-center p-2'>Remote developer</p>


              </button>
            </div>

            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate('/Hire_blockchain')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
                <p className='m-auto w-100 align-items-center p-2'>Blockchain dev</p>


              </button>
            </div>

            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate("/vue")} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
                <p className='m-auto w-100 align-items-center p-2'>Vue.js</p>

              </button>
            </div>
            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate('/hire_fullstack')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
                <p className='m-auto w-100 align-items-center p-2'>Full-stack dev</p>

              </button>
            </div>

            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate('/hire_swift')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
                <p className='m-auto w-100 align-items-center p-2'>Swift developer</p>

              </button>
            </div>

            <div className="col-lg-4 col-12  mb-3  ">
              <button onClick={() => navigate('/hire_flutter')} class="rounded-3 d-flex text-white border border-0 bg-secondary">
                <img className='m-auto align-items-center' src={(require('../components/buttonimages/group2.png'))} height="10%" width="8%" />
                <p className='m-auto w-100 align-items-center p-2'>Flutter developer</p>

              </button>
            </div>







          </div>
          )
        }

      </div>
      <div className='container mt-5  '>
        <h2 className='text-center'>Employ based on the projected path of their careers.</h2>
        <div className='row justify-content-center '>

        <div className="col-lg-3 col-6 col-md-6   ">
            <button  onClick={() => navigate('/react_js')} class="rounded-3  d-flex text-white border border-0 bg-secondary">
            <img className='align-items-center m-auto' src={(require('../components/buttonimages/layers.png'))} height="10%" width="15%" />
               
                <p  className='text-center w-100  align-items-center m-auto' >ReactJS developer</p>
            


            </button>
          </div>
          <div className="col-lg-3 col-6 mb-3   ">
            <button  onClick={() => navigate('/hire_angular')} class="rounded-3  d-flex text-white border border-0 bg-secondary">
            <img className='align-items-center m-auto' src={(require('../components/buttonimages/layers.png'))} height="10%" width="15%" />
          
                <p  className='text-center w-100  align-items-center m-auto' >Angular developer</p>
     


            </button>
          </div>

        </div>
        <div className='row justify-content-center '>
        <div className="col-lg-3 col-6 mb-3   ">
            <button  onClick={() => navigate('/hire_iphone')} class="rounded-3  d-flex text-white border border-0 bg-secondary">
            <img className='align-items-center m-auto' src={(require('../components/buttonimages/layers.png'))} height="10%" width="15%" />
      
                <p  className='text-center w-100  align-items-center m-auto'>iPhone developer
</p>
           


            </button>
          </div>
          <div className="col-lg-3 col-6 mb-3   ">
            <button  onClick={() => navigate('/Hire_mobile_app')} class="rounded-3  d-flex text-white border border-0 bg-secondary">
            <img className='align-items-center m-auto' src={(require('../components/buttonimages/layers.png'))} height="10%" width="15%" />
             <p className='text-center w-100  m-auto' >Mobile App developer</p>
        


            </button>
          </div>

        </div>

        <div className='row justify-content-center'>

        <div className="col-lg-3 col-6 mb-3   ">
            <button  onClick={() => navigate('/Ecommerce')} class="rounded-3  d-flex text-white border border-0 bg-secondary">
            <img className='align-items-center m-auto' src={(require('../components/buttonimages/layers.png'))} height="10%" width="15%" />
                 <p className='text-center w-100  m-auto' >eComerce developer</p>
           


            </button>
          </div>
          <div className="col-lg-3 col-6 mb-3   ">
            <button  onClick={() => navigate('/Hire_blockchain')} class="rounded-3  d-flex text-white border border-0 bg-secondary">
            <img className='align-items-center m-auto' src={(require('../components/buttonimages/layers.png'))} height="10%" width="15%" />
           
                <p  className='text-center w-100  m-auto' >Blockchain developer
</p>
            


            </button>
          </div>

        </div>
        <div className='row justify-content-center'>

        <div className="col-lg-3 col-6 mb-3   ">
            <button  onClick={() => navigate('/hire_startup')} class="rounded-3  d-flex text-white border border-0 bg-secondary">
            <img className='align-items-center m-auto' src={(require('../components/buttonimages/layers.png'))} height="10%" width="15%" />
       
                <p className='text-center w-100  m-auto'>Startup developer</p>
        


            </button>
          </div>
 

          <div className="col-lg-3 col-6 mb-3   ">
            <button  onClick={() => navigate('/Hire_offshore')} class="rounded-3  d-flex text-white border border-0 bg-secondary">
            <img className='align-items-center m-auto' src={(require('../components/buttonimages/layers.png'))} height="10%" width="15%" />
    
             <p className='text-center w-100  m-auto'>Offshore developer</p>
          
          


            </button>
          </div>
        </div>
        <div className='row justify-content-center'>

     
        </div>
        
        <div className='row justify-content-center '>



        </div>







      </div>
    </div>
  )
}

export default Button;