import React from "react";
import Button from "react-bootstrap/Button";
import { useNavigate, Link } from "react-router-dom";




function MyExperience() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="finalimage text-center">
        <img
          className="img-responsive"
          src={require("../../../images/home/success.jpg")}
          height={"30%"}
          width={"30%"}
        />
        <h2 className="mt-4">SHOW THE PROJECTS YOU ARE PROUD OF 💜</h2>
        <p>
          // to begin with, add basic details for 3-5 latest <br />
          projects — project names, dates, technologies, and <br />
          your role would be enough to get an idea about your <br />
          commercial experience
        </p>
        <p>
          // you will still be able to edit projects and add <br />
          more specifics later
        </p>
        <div className="project">
          <Button variant="contained"><Link to="/addProject" style={{ padding: "10px", fontSize: "20px", color: "white", backgroundColor: " #062b5b", textDecoration: 'none' }}>Add project</Link></Button>

        </div>
      </div>
      <div className="container text-center">
        <h2 className=" mt-5">YOUR EDUCATION</h2>

        <div className="justify">
          <p>
            Technical education is an advantage, but it is not a problem at all,
            if you don't have one. Your specialized education or training might
            grab client's attention if it's relevant to the project
          </p>
        </div>
        <div className="project">

          <Button variant="contained"><Link to="/education" style={{ padding: "10px", fontSize: "20px", color: "white", backgroundColor: " #062b5b", textDecoration: 'none' }}>Add Education</Link></Button>

        </div>
      </div>
    </div>
  );
}

export default MyExperience;
