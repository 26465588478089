import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

function Faq_dev() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Header />
      <div className="container">

        <Box className="mt-3" sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example" style={{ backgroundColor: "#294b86" }}>
                <Tab style={{ color: "white" }} label="recruitment process" value="1" />
                <Tab style={{ color: "white" }} label="Alantech" value="2" />
                <Tab style={{ color: "white" }} label="clients and projects" value="3" />
                <Tab style={{ color: "white" }} label="legal payments" value="4" />
                <Tab style={{ color: "white" }} label="final" value="5" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up">

                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <ul className="faq-list">

                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq1">
                            I've successfully completed the registration process. What should I expect or do next?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              Thank you for completing the registration! We'll require some time to review your profile. Subsequently, we will either invite you for a brief call or send you some questions via email. To expedite our understanding of your ongoing projects, please ensure you send us your CV.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq2">What is the process for becoming a freelancer with Alantech, and how long does it typically take?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              In addition to the registration process, the freelancer onboarding procedure involves CV screening, a brief conversation with a recruiter, and a technical interview. Once you are registered on the platform, we will initiate the search for a suitable project on your behalf. Typically, this phase can take anywhere from one to two weeks, contingent upon your qualifications and our current capacity.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq3">
                            What are the guidelines or standards you employ when selecting freelancers?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                            <p>Typically, we collaborate with freelancers skilled in various technologies and stacks. However, in some cases, we may advise delaying communication if we are confident that there are currently no projects available for your specific stack, and prospects for such projects are not expected to materialize in the near future.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq4">
                            Could you provide an overview of what your technical interview entails?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                            <p>The technical interview is administered by experienced independent contractors who have established themselves as skilled, dependable, and knowledgeable professionals through our platform. Typically lasting about an hour, this interview encompasses both theoretical questions and a few practical tasks. It serves as a mandatory step before we match you with a project.</p>
                          </div>
                        </li>






                      </ul>
                    </div>
                  </div>

                </div>
              </section>
            </TabPanel>
            <TabPanel value="2">
              <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up">

                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <ul className="faq-list">

                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq1">
                            In what ways do you distinguish yourselves from a traditional outstaffing company?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              Our platform provides flexibility in project selection, offering both short-term and long-term opportunities. You have the freedom to choose projects that align with your interests. If a suggested project doesn't align with your preferences, you have the option to decline, and we will endeavor to find a more suitable match for you. Additionally, we do not subject you to lengthy hiring processes that span several months or involve numerous interview stages.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq2">
                            What benefits or advantages can freelancers expect when collaborating with your platform?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              You won't need to engage in self-promotion, negotiations, or deal with administrative hassles. Our team handles these aspects, but only for the time it takes to get you started. We prioritize a healthy competitive environment and readily onboard developers to meet project demands. We respect your autonomy in your client relationships and don't insert intermediaries or managers into the mix – it's just you and the client. However, our team remains available to support you in any client-related situations.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq3">
                            Does the platform include a time tracking feature?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              We do not utilize time tracking tools. Instead, you simply report the number of hours worked. Occasionally, clients may request the use of a time tracker, but you have the option to decline, and we will find an alternative project for you.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq4">
                            is your platform public? can my current employer see me there?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              Candidates in our platform are not visible to the public, and even clients gain access to candidates only when we arrange a scheduled call.</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </section>
            </TabPanel>
            <TabPanel value="3">
              <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up">

                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <ul className="faq-list">

                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq1">who are your clients?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              Our client base primarily consists of startups from Western Europe and the United States. The duration of mobile app projects typically spans 2-3 months, while web projects extend for 3-6 months on average. Some projects are as short as one month, while others can be ongoing for over a year.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq2">
                            Do you have a particular focus on specific technologies or domains?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              We have a broader range of opportunities for specialists skilled in technologies such as JavaScript and its frameworks, React Native, iOS, Flutter, TypeScript, Python, Ruby, and PHP. Additionally, we are actively seeking talented data scientists and UI/UX designers. If your technology of choice isn't listed here, we encourage you to register anyway. Clients approach us with diverse project requirements, and our recruiters will inform you if there's a suitable match for your expertise at any given time.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq3">
Do I need to adhere to any particular work schedule, including working in alignment with the client's timezone?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                            <p>There aren't stringent schedule requirements; you have the flexibility to select a suitable work time. However, it's essential to coordinate and agree upon the timing for client calls and communication. American clients may request around 3-4 hours of overlapping work time for effective communication with freelancers.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq4">
What is the process for communication with the client?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              
Communication with clients primarily takes place through platforms like Slack, Jira, and Trello for tasks and project management. Many projects involve daily or bi-weekly meetings to synchronize progress and address any pertinent questions or concerns.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq5">
                            
Is there consistently a person on the client's side with expertise in the project's domain?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                            <p>
There are instances where expertise on the client's side may be lacking. We exercise caution, particularly when working with non-technical founders who don't have a CTO. It's also common to encounter teams that don't have duplicated developer expertise in a specific technology stack.!</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq6">
                          Who is responsible for providing work estimates and establishing project deadlines?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq6" className="collapse" data-bs-parent=".faq-list">
                            <p>
                            The developer takes on the responsibility of providing work estimates and setting project deadlines. For instance, they might estimate 40 hours of work with a particular deadline date. In cases where the scope of work lacks detail, it's common to provide a range (minimum to maximum) and specify the factors that influence it.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq7">
                            
Is it possible to acquire new skills and learn unfamiliar technologies while actively engaged in project work?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq7" className="collapse" data-bs-parent=".faq-list">
                            <p>Certainly, it's feasible to learn new technologies during a project, provided you already possess a solid foundation in the required core technology. Clients may be accommodating if you need to acquire additional skills during the project.

If your learning is happening independently of projects, you can reach out to our team once you feel confident in your proficiency. We can arrange a new technical interview and suggest projects involving the newly acquired technologies upon successful validation of your expertise.</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </section>
            </TabPanel>
            <TabPanel value="4">
              <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up">

                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <ul className="faq-list">

                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq1">
                          what about tax? <i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                            <p>
                            We are not qualified as tax advisors and are unable to provide guidance in this regard. We strongly recommend consulting with a tax professional in your state for assistance with any tax-related questions or concerns.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq2">
                            
What is the process for receiving payment?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                            <p>
Alantech initiates payments on a monthly basis, specifically at the end of each month, in accordance with the hours you've recorded during the preceding month. The calculation involves multiplying the logged hours by your rate (with an additional $3 commission when transferring funds to a Payoneer account). Payments are typically processed around the 9th to 10th day of the following month, and you can choose to receive the payment either in your Payoneer account or directly to your card, with no commissions on our part.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq3">
Is there an alternative method of receiving payments available?<i className="bi bi-chevron-down icon-show"></i><i
                            className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                            <p>
                              
You have two payment options: receiving payments directly to your card or having the payment transferred to your Payoneer account. Registering on Payoneer is a straightforward process, taking just 10 minutes, and it allows you to withdraw funds to any desired account.</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq4">
                          What steps should I take to determine my hourly rate?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                            <p>
Your initial hourly rate will be established through discussion before we begin presenting projects to you. This rate will serve as your default rate for any project on our platform. You have the flexibility to adjust your rate, either raising or lowering it, when we propose a specific project to you.</p>
                          </div>
                        </li>

                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq5">
                          
How frequently can I modify my hourly rate?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                            <p>
                            Rate adjustments are typically discussed and finalized before the commencement of a project. While it's possible to consider rate changes for longer-term projects, such adjustments are permissible only once every six months. It's worth noting that clients generally prefer rate expectations to be set at the project's outset rather than altering them during the project's course.</p>
                          </div>
                        </li>

                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq6">
                          do i need to sign a contract?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq6" className="collapse" data-bs-parent=".faq-list">
                            <p>
                            you need to accept the terms&conditions on the platform and sign an nda with the client before we start our cooperation.</p>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </div>

                </div>
              </section>
            </TabPanel>
            <TabPanel value="5">
              <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up">

                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <ul className="faq-list">

                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq1">
                          What extra advantages or perks come with collaborating with Alantech?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                            <p>
As part of your collaboration with Alantech, you gain access to a global community of over 1000 middle to senior developers. This community provides opportunities to engage in meet-ups, learn about various technical subjects, apply your knowledge, and expand your professional network to advance your career. Moreover, you can enjoy some recreational activities, as exemplified by our meetups in 10 different cities across seven countries in 2022. To get a glimpse of the enjoyable gatherings, you can check out our vlog titled "Alantech Euro Trip Vlog."</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq2">
                            What is the process for obtaining a refund if I am dissatisfied with the developer's work?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                            <p>Upon successfully completing our recruitment process and gaining entry into the Alantech community, our matching team will begin forwarding projects that align with your skills and preferences. If you find a proposed project appealing, you can notify our team, and we will facilitate the connection with the client. Following an introductory call, if there's a mutual match, you will commence work directly with the client using their designated communication channels.:</p>
                          </div>
                        </li>
                        <li>
                          <div data-bs-toggle="collapse" className="collapsed question fw-bold fs-6" href="#faq3">
                          how quickly can you find me a project?<i className="bi bi-chevron-down icon-show"></i><i
                              className="bi bi-chevron-up icon-close"></i></div>
                          <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                            <p>The speed at which we can secure a project for you hinges on various factors, including the demand for your expertise in specific technologies and your availability. If you're prepared for full-time work, we're likely to find a project for you in less than a week. However, if you're considering part-time availability, it might take a bit longer. Additionally, your hourly rate can impact the timeline, with higher rates potentially leading to longer waiting times for project placement.</p>
                          </div>
                        </li>

                      </ul>
                    </div>
                  </div>

                </div>
              </section>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
      <Footer />
    </>
  )
}
export default Faq_dev