import React, { useState } from 'react';
import UserDetails from './UserDetails';
import PersonalDetails from './PersonalDetails';
import Signup from './signup'
import { useFormik } from 'formik';
import * as Yup from 'yup';


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const developerSchema = Yup.object().shape({
  firstName: Yup.string().matches(/^[A-Za-z]+$/, 'Only letters are allowed in the name').required('Enter the first name'),
  lastName: Yup.string().matches(/^[a-zA-Z]{1,20}$/, 'Only letters are allowed in the name'),
  linkedIn: Yup.string().matches(/^[A-Za-z]*$/, 'not a proper fomat').required('Enter the LinkedIn'),
  Mainspecialization: Yup.string().required('Select the option'),
  seniorityLevel: Yup.string().required('Select the option'),
  project_engagement: Yup.string().required('Select the option'),
  experiences: Yup.number().required('Enter the experience'),
  hourlyRate: Yup.number().required('Enter the proper format'),
  email: Yup.string().matches(/^[a-zA-Z]+(?:[._-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/, 'proper mailid format is required').email('Invalid email format').required('Enter the email'),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10, "Too short").max(10, "Too long").required("Enter the phonenumber"),
  password: Yup.string().required('Enter the Password').min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol'),
});




function MainForm() {
  const [step, setStep] = useState(1);

  const { values, handleChange, errors, touched, handleBlur, setFieldValue, setFieldTouched } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      country: "",
      linkedIn: "",
      Mainspecialization: "",
      seniorityLevel: "",
      experiences: "",
      newSkill: "",
      hourlyRate: "",
      email: "",
      phoneNumber: "",
      password: "",
    },
    validationSchema: developerSchema,
  })

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  switch (step) {
    case 1:
      return (
        <UserDetails
          nextStep={nextStep}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      );
    case 2:
      return (
        <PersonalDetails
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}

        />
      );
    case 3:
      return (
        <Signup
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
        />
      );
    default:
      return null;
  }
}

export default MainForm;