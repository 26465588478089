import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
function MyApplication() {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/');
    }
  }, [])

  useEffect(() => {
    const fetchDate = async () => {
      const token = localStorage.getItem("token");
      const user_id = localStorage.getItem('user_id')
      if (token == null) {
        navigate("/login");
      } else {
        const response = await axios.get(`${process.env.REACT_APP_URL}/profile`, {
          headers: {
            'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
            "Auth-Key": process.env.REACT_APP_AUTH_KEY,
            'User-id': user_id,
            'Authorization': token
          }
        })
        if (response.status == 200) {
          const developer_id = response.data.data.id
          localStorage.setItem('developer_id', developer_id); 
        }
      }
    }
    fetchDate();
  }, []);

  return (
    <div>
      <div className="">
        <h3>Get in Touch</h3>
        <hr className="custom-width" />
        <div className="w-100 ">
          <span class="custom-text px-5">Thanks! We'll get back to you Shortly</span>
        </div>
      </div>
      {/* <div className="row ooph">
      <div className="col-lg-3">
        <img
          src={require("../../../images/For_devs/oops.jpg")}
          height={"100%"}
          width={"100%"}
        />
      </div>
      <div className="col-lg-9 mt-4 ">
        <h2 className="ms-3">OOPH.. YOU DON'T PASS FOR NOW</h2>
        <p>
          sorry, but we're not likely to have good projects for your
          qualifications
        </p>
      </div>
    </div>
      <h2 className="mt-4 mb-4">Application steps</h2>
      <h4 className="mb-3 mt-1">
        <i className="bi bi-0-circle-fill"></i> Autocheck
      </h4>
      <p>
    // this is were you application stopped for now please find more
        details in your email
      </p>
      <p>
        sad, but true — at the moment we don't have projects that match
        your skillset, availability, or experience. we value your time and
        don't want to waste even more. We'll save your details for future
        and will reach you out once there is something really good to
        offer.
      </p>
      <h4>
        <i className="me-2 bi bi-1-circle-fill"></i>Get to know us better
        (5 minutes)
      </h4>
      <div className="ms-1 mt-2">
        <i className="bi bi-arrow-down"></i>
      </div>
      <h4>
        <i className="me-2 bi bi-2-circle-fill"></i>Complete profile (3
        minutes)
      </h4>
      <div className="ms-1 mt-2">
        <i className="bi bi-arrow-down"></i>
      </div>
      <h4>
        <i className="me-2 bi bi-3-circle-fill"></i>Talk with a recruiter
        (30 minutes)
      </h4>
      <div className="ms-1 mt-2">
        <i className="bi bi-arrow-down"></i>
      </div>
      <h4>
        <i className="me-2 bi bi-4-circle-fill"></i>Pass a technical
        interview (60-90 minutes)
      </h4>
      <div className="ms-1 mt-2">
        <i className="bi bi-arrow-down"></i>
      </div>
      <h4>
        <i className="me-2 bi bi-5-circle-fill"></i>Polish experience (30
        minutes)
      </h4>
      <div className="ms-1 mt-2">
        <i className="bi bi-arrow-down"></i>
      </div>
      <div className="row ooph">
        <div className="col-lg-3">
          <img
            src={require("../../../images/For_devs/success.jpg")}
            height={"70%"}
            width={"70%"}
          />
        </div>
        <div className="col-lg-6">
          <h2>Get your first project</h2>
          <p>
        // here you start a new job in a Silicon Valley startup. For
            real, no kidding
          </p>
        </div>
      </div> */}
    </div>
  )
}

export default MyApplication