import React, { useEffect } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { useState } from "react";
import Swal from 'sweetalert2';

function Project() {


    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    const client_service = process.env.REACT_APP_CLIENT_SERVICE;
    const Auth_Key = process.env.REACT_APP_AUTH_KEY;
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id')
    const developer_id = localStorage.getItem('developer_id')
    const [data, setData] = useState([])
    const [statuses, setStatuses] = useState([]);

    const handleButton = async (itemId) => {
        const formData = new FormData();
        formData.append("developer_id", developer_id);
        formData.append("hires_id", itemId);
        formData.append("is_active", 1);
        try {
            const response = await axios.post(
                "https://dev.alantechnologies.in/api/v1/developers/job",
                formData,
                {
                    headers: {
                        'Client-service': client_service,
                        "Auth-Key": Auth_Key,
                        'User-id': user_id,
                        'Authorization': token
                    }
                }
            );

            if (!response.data.status === 200) {
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Applied  successful.',
                });
                window.location.reload()
            }
        } catch (err) {
            console.error('Error:', err);
        }
    };


    const handleButton2 = async (itemId) => {
        const formData = new FormData();
        formData.append("developer_id", developer_id);
        formData.append("hires_id", itemId);
        formData.append("is_active", 0);
        try {
            const response = await axios.post(
                "https://dev.alantechnologies.in/api/v1/developers/job",
                formData,
                {
                    headers: {
                        'Client-service': client_service,
                        "Auth-Key": Auth_Key,
                        'User-id': user_id,
                        'Authorization': token
                    }
                }
            );

            if (!response.data.status === 200) {
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'canceled successfully.',
                });
                window.location.reload()
            }
        } catch (err) {
            console.error('Error:', err);
        }
    };



    useEffect(() => {
        const fetchProject = async () => {
            if (!token) {
                return navigate('/');
            }
            const response = await axios.get(`https://dev.alantechnologies.in/api/v1/developers/${developer_id}`, {
                headers: {
                    'Client-service': client_service,
                    "Auth-Key": Auth_Key,
                    'User-id': user_id,
                    'Authorization': token
                }
            })
            if (response.status == 200) {
                console.log(response.data.data.job)
                const fetchedData = response.data.data.job;
                setData(fetchedData);
                setStatuses(fetchedData.map(() => ({ value: false, button: false })));
            }
            else {
                return navigate('/');
            }
        }
        fetchProject();
    }, []);

    return (
        <div>
            <div className="">
                <div className="row">
                    {data.map((item) => (
                        <div className="col-lg-6 col-10 mb-3" key={item.id}>
                            <div className="border-project">
                                <div className="border-project-p">
                                    <p className="card-para">{item.mainSpecialization}</p>
                                    <p className="card-invol">Involment - <span>{item.involment}</span></p>
                                    <p className="">Main Focus - <span>{item.mainFocus}</span></p>
                                </div>

                                <div className="">
                                    <p className="card-para">Description</p>
                                    <p className="pt-0 ps-2 pe-2 pb-1">{item.description}</p>
                                </div>
                                <div className="d-flex skills-para">
                                    <p>{item.greatAt}</p>
                                </div>
                                <div className=" ms-2 mt-2">
                                    {item.is_applied == 1 ? (
                                        <div>
                                            <span className="me-2">Applied</span>
                                            <button className="" onClick={() => handleButton2(item.id)}>
                                                Cancel
                                            </button>
                                        </div>
                                    ) : (
                                        <button className="" onClick={() => handleButton(item.id)}>
                                            Apply Now
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                    <br />
                </div>
            </div>
        </div>

    )
}

export default Project