import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

import {
  Container,
  Typography,
  Button,
  MenuItem,
  TextField,
  FormControl,
  Box,
  Grid,
} from "@mui/material";
function ProjectPerformance() {

  const [loading, setLoading] = useState(true);
  const [developer, setDeveloper] = useState({
    project_engagement: "",
    hourlyRate: ""
  });
  const navigate = useNavigate();

  function SweetAlert() {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "PROJECT INVOLVEMENT Updated",
      confirmButtonText: "OK",
      timer: 8000,
    }).then(async (result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    })
  }
  useEffect(() => {
    const fetchDate = async () => {
      const token = localStorage.getItem("token");
      const user_id = localStorage.getItem('user_id')
      if (token == null) {
        navigate("/login");
      } else {
        const response = await axios.get(`${process.env.REACT_APP_URL}/profile`, {
          headers: {
            'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
            "Auth-Key": process.env.REACT_APP_AUTH_KEY,
            'User-id': user_id,
            'Authorization': token
          }
        })
        if (response.status == 200) {
          setDeveloper(response.data.data)
          setLoading(false);
        }
        else {
          setLoading(false)
          alert('sorry, something went wrong please try after sometime')
        }
      }
    }
    fetchDate()
  }, []);


  async function update_hours(e) {
    setLoading(true)
    try {
      const token = localStorage.getItem('token')
      const user_id = localStorage.getItem('user_id');
      const response = await axios.put(`${process.env.REACT_APP_URL}/developers`, {
        id: developer.id,
        project_engagement: developer.project_engagement,
        hourlyRate: developer.hourlyRate
      },
        {
          headers: {
            'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
            "Auth-Key": process.env.REACT_APP_AUTH_KEY,
            'User-id': user_id,
            'Authorization': token
          }
        }

      );
      if (response.status == 200) {
        setLoading(false);
        SweetAlert();
      }
      else {
        setLoading(false)
        alert('sorry, something went wrong please try after sometime')
      }
    } catch (error) {
      console.log(error);
    }
  }


  const handleChange = (event) => {
    const { name, value } = event.target;

    setDeveloper({
      ...developer,
      [name]: value,
    });
  };
  const project_engagement = [
    "full-time",
    "part-time",
    "part/full-time",
    "part-time/ can switch to full-time",
    "none/temporary unavailable",
  ];
  const button = {
    backgroundColor: "#294b86",
  };

  const box = {
    width: {
      lg: 1000,
    },
    height: {
      lg: '100%',
      md: '100%',
      xs: '100%'
    },
  };
  if (loading) {
    return (<div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    )
  }
  return (
    <>
      <Box>
        <Container sx={box}>
          <Grid container spacing={3}>

            <Grid item xs={12}>
              <Typography variant="h4" sx={{ fontSize: { lg: "30px", md: "30px", xs: "20px" } }}>
                PROJECT INVOLVEMENT
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <form onSubmit={update_hours}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={8}>
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      label="  Preferred project engagement"
                      name="project_engagement"
                      value={developer.project_engagement}
                      onChange={handleChange}
                    >
                      <MenuItem value="" disabled>
                        Preferred project engagement
                      </MenuItem>
                      {project_engagement.map((data, index) => (
                        <MenuItem key={index} value={data}>
                          {data}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} lg={12} >
                    <Typography variant="h4" sx={{ fontSize: { lg: "30px", md: "30px", xs: "20px" }, mb: 2 }}>
                      DESIRED RATE
                    </Typography>
                    <Grid item xs={12} lg={4}>
                      <FormControl fullWidth>
                        <TextField
                          type="text"
                          label="Desired hourly Rate($)"
                          name="hourlyRate"
                          value={developer.hourlyRate}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      type="submit"
                      sx={{
                        py: 1,
                        bgcolor: " #294b86 !important",
                        color: "white",
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default ProjectPerformance;
