import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Box, Button, Container, Grid, Paper, TextField, FormHelperText, Typography } from "@mui/material";
import { useFormik } from 'formik';
import * as Yup from 'yup';


function Forgotpassword() {

  const navigate = useNavigate();

  const [emailError, setEmailError] = useState(false);

  const emailSchema = Yup.object().shape({
    email: Yup.string().matches(/^[a-zA-Z]+[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'enter proper mailid').email("Invalid email").required("please enter the email")
  })

  const { values, handleBlur, handleChange, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: emailSchema,
    onSubmit: onsubmit
  })


  const container = {
    maxWidth: {
      lg: 500,
      xs: 400,
    },
    height: {
      lg: '100vh',
      md: "100vh",
      xs: "100vh"
    },
    display: "flex", alignItems: "center"
  }

  React.useEffect(() => {
    if (values.email.length > 0) {
      setEmailError(false)
    }
  }, [values])

  function SweetAlert() {
    Swal.fire({
      icon: 'success',
      text: 'mail sended successfully',
      timer: 1800
    })
  }

  async function onsubmit(values) {
    try {
      const formdata = new FormData();
      formdata.append('email', values.email)
      const response = await axios.post(`${process.env.REACT_APP_URL}/Auth2/forgotpassword`, formdata,
        {
          headers: {
            'Client-service': process.env.REACT_APP_CLIENT_SERVICE,
            'Auth-Key': process.env.REACT_APP_AUTH_KEY
          }
        });

      if (response.status == 200) {
        SweetAlert();
      }
      else {
        console.log("something error")
      }
    } catch (error) {
      alert('Internal Server Error, Please Try after sometime')
    }

  }
  return (
    <>
      <Box >
        <Container sx={container} >

          <Paper elevation={5} sx={{
            p: {
              lg: "30px",
              xs: "20px",
              md: "30px"
            }
          }}>

            <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
              <img src={require("../images/ALAN Final logo-01.png")} height={"40%"} width={"60%"} />
            </Box>

            <Box sx={{ my: 3 }}>
              <Typography variant="h6" gutterBottom textAlign="center">
                Forgot Password
              </Typography>
            </Box>

            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    name="email"
                    fullWidth
                    helperText={errors.email && touched.email ? errors.email : ""}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email) || emailError}
                  />
                  <FormHelperText sx={{ color: "#d32f2f" }}>{emailError ? "email not existed" : ""}</FormHelperText>
                </Grid>

                <Grid item xs={12} >
                  <Button variant="contained" type="submit" fullWidth sx={{ py: 1 }}>Send mail</Button>
                </Grid>
              </Grid>
            </form>

          </Paper>
        </Container>
      </Box>

    </>
  );
}



export default Forgotpassword;
