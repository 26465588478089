import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import jsonData from "./data.json";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Container,
  Typography,
  Button,
  FormControl,
  MenuItem,
  TextField,
  Box,
  Grid,
  IconButton,
  Paper,
  InputAdornment,
} from "@mui/material";
import { end } from "@popperjs/core";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
    // marginLeft: theme.spacing(20),
  },
  select: {
    marginTop: theme.spacing(5),
    minWidth: "90%",
  },
  form: {
    padding: 50,
  },

  form_data: {
    marginBottom: theme.spacing(5),
    padding: 10,

    width: 200,
  },
  addButton: {
    Height: 100,
  },
  nextButton: {
    float: end,
  },
  chip: {
    marginRight: theme.spacing(5),
  },
  cancelButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

function PersonalDetails({ nextStep, handleChange, values, errors, touched, handleBlur, setFieldValue, setFieldTouched }) {
  // data from json
  const seniorityLevel = jsonData.SeniorityLevel;
  const names = jsonData.names;
  const years = jsonData.Years;
  const Mainspecialization = jsonData.Mainspecialization;
  const project_engagement = jsonData.project_engagement

  // UseState
  const classes = useStyles();

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skill, setSkill] = useState("");
  const [newExperience, setNewExperience] = useState("");
  const [hideskillandExperience, setHideskillandExperience] = useState(false)
  const [btn, setBtn] = useState(false)
  const [coreError, setCoreError] = useState(false)
  const [skillErr, setSkillErr] = useState(false)



  React.useEffect(() => {
    values.newSkill = selectedSkills;

    if (selectedSkills.length >= 4) {
      setHideskillandExperience(true)
    } else {
      setHideskillandExperience(false)
    }

    if ((values.firstName && values.seniorityLevel && values.experiences && values.hourlyRate && values.newSkill.length == 4 && values.project_engagement) &&
      (errors.Mainspecialization == undefined && errors.seniorityLevel == undefined & errors.hourlyRate == undefined && errors.experiences == undefined && errors.project_engagement == undefined)) {
      setBtn(false);
    } else {
      setBtn(true);
    }

  }, [values, selectedSkills, errors]);


  // Adding Skills
  const addSkill = () => {
    if (skill && newExperience) {
      setCoreError(false)

      if (selectedSkills.length == 0) {
        setSelectedSkills([
          ...selectedSkills,
          { skill: skill, experience: newExperience },
        ]);
        setSkill("");
        setNewExperience("");

      } else {

        let existSkills = selectedSkills.filter((data) => {
          return data.skill == skill
        })

        if (existSkills.length == 1) {
          setSkillErr(true)

        }
        else {
          setSkillErr(false)
          setSelectedSkills([
            ...selectedSkills,
            { skill: skill, experience: newExperience },
          ]);
          setSkill("");
          setNewExperience("");
        }
      }


    } else {
      setCoreError(true)
    }
  };

  // Removing Skill
  const removeSkill = (index) => {
    const updatedSkills = [...selectedSkills];
    updatedSkills.splice(index, 1);
    setSelectedSkills(updatedSkills);
  };


  // save and continue
  const saveAndContinue = (e) => {
    e.preventDefault();
    nextStep();
  };

  // Style in material UI

  const box = {
    mt: {
      xs: 3
    },
    width: {
      lg: 800,
      md: 600
    },
    height: {
      lg: '100vh',
      md: '100vh',
    },
    display: "flex", alignItems: "center",
  };
  const paperStyle = {
    padding: { lg: "50px", xs: "20px", md: "20px" },
  };
  const button = {
    marginTop: { lg: 5 },
    backgroundColor: "#294b86",
    // color: "white",
  };
  const para = {
    fontSize: "13px",
  };

  const add = {
    backgroundColor: "#294b86",
    color: "white",
    py: 2,
    px: { lg: 1, md: 2 }
  };


  return (
    <Box>
      <Container sx={box}>
        <Paper elevation={5} sx={paperStyle}>
          <form onSubmit={saveAndContinue} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} align="center">
                <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
                  <img
                    src={require("../../images/ALAN Final logo-01.png")}
                    height={"30%"}
                    width={"50%"}
                  />
                </Box>
                <Typography variant="h6" gutterBottom>
                  YOUR TECH STACK
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      label="Main specialization"
                      name="Mainspecialization"
                      value={values.Mainspecialization}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.Mainspecialization && Boolean(errors.Mainspecialization)}
                      helperText={errors.Mainspecialization && touched.Mainspecialization ? errors.Mainspecialization : ""}
                    >
                      <MenuItem value="" disabled>
                        Main specialization
                      </MenuItem>
                      {Mainspecialization.map((data, index) => (
                        <MenuItem key={index} value={data}>
                          {data}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>


                  <Grid item xs={12} lg={6} md={6}>
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      label="SeniorityLevel "
                      name="seniorityLevel"
                      value={values.seniorityLevel}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.seniorityLevel && Boolean(errors.seniorityLevel)}
                      helperText={errors.seniorityLevel && touched.seniorityLevel ? errors.seniorityLevel : ""}
                    >
                      <MenuItem value="" disabled>
                        SeniorityLevel
                      </MenuItem>
                      {seniorityLevel.map((data, index) => (
                        <MenuItem key={index} value={data}>
                          {data}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>


              {/* Core technologies  Start  */}


              <Grid item xs={12} sx={{ display: { lg: "flex", md: "flex", xs: "block" }, textAlign: { xs: "center" }, mt: { xs: 1 } }}>
                <Typography variant="h6">Core technologies</Typography>
                <p sx={para}>(Add up to 4 go-to technologies)</p>
              </Grid>

              <Grid item xs={5} lg={6} md={6}>
                <Autocomplete

                  value={skill}
                  onChange={(_, newValue) => setSkill(newValue)}
                  options={names}
                  disabled={hideskillandExperience}
                  renderInput={(params) => (
                    <TextField
                      xs={12}
                      {...params}
                      label="Choose Skill"
                      variant="outlined"
                      fullWidth
                      error={coreError && !skill || skillErr}
                      helperText={coreError && !skill ? "select the option" : skillErr ? "skill already added" : ""}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={5} lg={5} md={4}>

                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  label="Experience"
                  value={newExperience}
                  onChange={(e) => (setNewExperience(e.target.value))}
                  disabled={hideskillandExperience}
                  error={coreError && !newExperience}
                  helperText={coreError && !newExperience ? "select the option" : ""}
                >
                  <MenuItem value="" disabled>
                    Year of experience
                  </MenuItem>
                  {years.map((data, index) => (
                    <MenuItem key={index} value={data}>
                      {data}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={1} lg={1} md={1}>

                <Button
                  onClick={addSkill}
                  disabled={hideskillandExperience}
                  sx={[
                    add,
                    {
                      "&:hover": {
                        bgcolor: "#294b86",
                      },
                    },
                  ]}
                >
                  +
                </Button>
              </Grid>

              {/* Dynamic Skill and Experience Fields */}

              <Grid item xs={12} sx={{ mt: { lg: 1 } }}>
                <Grid container spacing={1}>
                  {selectedSkills.map((skill, index) => (
                    <Grid item xs={12} lg={6} md={6}>
                      <TextField
                        fullWidth
                        type="text"
                        value={`${skill.skill} - ${skill.experience}`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                className={classes.cancelButton}
                                onClick={() => removeSkill(index)}
                              >   <FontAwesomeIcon icon={faTimes} /></IconButton>

                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  label="What kind of project are you looking for?"
                  name="project_engagement"
                  value={values.project_engagement}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.project_engagement && Boolean(errors.project_engagement)}
                  helperText={errors.project_engagement && touched.project_engagement ? errors.project_engagement : ""}
                >
                  <MenuItem value="" disabled>
                    What kind of project are you looking for?
                  </MenuItem>
                  {project_engagement.map((data, index) => (
                    <MenuItem key={index} value={data}>
                      {data}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} lg={6} md={6}>
                <FormControl fullWidth>
                  <TextField
                    sx={{ mt: { lg: 2 } }}
                    type="number"
                    label="Desired hourly Rate($)"
                    name="hourlyRate"
                    value={values.hourlyRate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.hourlyRate && Boolean(errors.hourlyRate)}
                    helperText={errors.hourlyRate && touched.hourlyRate ? errors.hourlyRate : ""}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={6} md={6}>
                <FormControl fullWidth>
                  <TextField
                    sx={{ mt: { lg: 2 } }}
                    type="text"
                    label="Experience as a dev"
                    name="experiences"
                    value={values.experiences}
                    onChange={(event) => {
                      if (event.target.value.length <= 2) {
                        handleChange(event);
                      }
                    }}
                    onBlur={handleBlur}
                    error={touched.experiences && Boolean(errors.experiences)}
                    helperText={errors.experiences && touched.experiences ? errors.experiences : ''}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  disabled={btn}
                  sx={{ py: 1, bgcolor: " #294b86 !important", color: "white" }}
                  type="submit"
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </Box>
  );
}

export default PersonalDetails;
