import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';

import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Header from './Header';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (

        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>

      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  return (
    <div>
      <Header />

      <Box className='box'
        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
      >

        <Tabs className='review_tabs'
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Disclaimer" {...a11yProps(0)} />
          <Tab label="Talent platforms: bidding vs. vetting" {...a11yProps(1)} />
          <Tab label="Alan Tech small but efficient" {...a11yProps(2)} />
          <Tab label="Matching in 48 hours" {...a11yProps(3)} />
          <Tab label="Management experience requirements" {...a11yProps(4)} />
          <Tab label="Price/quality balance" {...a11yProps(5)} />
          <Tab label="Our limitations" {...a11yProps(6)} />
          <Tab label="Vetting: our secret verification" {...a11yProps(7)} />
          <Tab label="Who are the developers we recruit?" {...a11yProps(8)} />
          <Tab label="Vetting process in detail" {...a11yProps(9)} />
          <Tab label="Our average hourly rates" {...a11yProps(10)} />
          <Tab label="Our fees and commissions" {...a11yProps(11)} />
          <Tab label="Customer reviews" {...a11yProps(12)} />
          <Tab label="How we guarantee security" {...a11yProps(13)} />
          <Tab label="What we do to keep our prices low" {...a11yProps(14)} />

        </Tabs>

        <TabPanel className="w-75" value={value} index={0}>

          <div className=''>
            <h1 className="mt-5 mx-3">Disclaimer</h1>
            <h4 className="mb-3 mx-3">Yep, we're reviewing ourselves.</h4>
            <p>You might think we're pressing the Like button under our own selfie.</p>
            <p>We did our best to stay objective and unbiased in this AlanTech review. We are perfectly capable of critical
              introspective analysis (whatever that means).</p>
            <p>Our content department interviewed other team members about their work. Whenever we make a bold statement
              about ourselves, there is a quote by another team member to support it.</p>
            <p>Now, see how AlanTech ticks from the inside.</p>
          </div>

        </TabPanel>
        <TabPanel className="w-75" value={value} index={1}>


          <div >
            <h1 className="mb-4">The 911 For Your Project</h1>
            <p>Hiring on regular freelance platforms is often like Russian roulette. If you are unlucky, you hire the wrong
              person, who can potentially ruin your entire project.</p>
            <p>That's because generic freelance platforms rely on bidding. When freelancers registered on it start applying
              for your job, you must manually review their bids (rates, terms, portfolios, and testimonials from other
              users). This is time-consuming, and you may run into a scammer or mishire.</p>
            <p>Contrarily, when you recruit on platforms for vetted developers, it's like calling 911:</p>
            <p>When you submit your job description, a tested, qualified engineer is ready to work on it. The platform needs
              little time to find them in its talent pool.</p>
            <p>AlanTech is an illustrative example of such a platform.</p>
          </div>


        </TabPanel>
        <TabPanel className="w-75" value={value} index={2}>

          <div >
            <h1 className="mb-4">Small But Efficient</h1>
            <p>So, what is AlanTech, exactly?</p>
            <p>Unlike our bigger competitors, we are a company of just 71 people who work and hang out together.</p>
            <p>Our talent pool currently consists of 1000+ remote developers whose personal and professional skills have
              been verified prior to hiring.</p>
            <p>Someone would say it's not much. That is true.</p>
            <p>We personally know all developers from our database. We know what they are great at and what they suck at. We
              know what inspires and concerns them. When you submit your job description to us, we know which one will be
              the perfect match for it.</p>
            <p>Besides, if you’ve ever played video games, you know that a smaller size often means great agility. We
              believe we have the fastest matching among other vetting platforms.</p>
          </div>
        </TabPanel>
        <TabPanel className="w-75" value={value} index={3}>
          <div >
            <h1 className="mb-4">What We Promise You</h1>
            <h4 className="mb-2">You get a specialist within 48 hours</h4>
            <p>Minimum actions are needed on your end. Just submit your order description and tend to other matters. The
              next day, you will have an engineer for your project ready and able. Their experience and skills will be the
              perfect match for your needs.</p>
            <p>Sometimes our standards can get us into tight spots. Yevgeniya Kruglova, our Head of Talent Acquisition and
              Strategy, recalls one of the many cases when we were in a pinch:</p>
          </div>
        </TabPanel>
        <TabPanel className="w-75" value={value} index={4}>
          <div >
            <h1 className="mb-4">No Hiring or Management Experience Needed</h1>
            <p>Even if you have zero experience in hiring and managing remote developers, engineers registered at AlanTech
              got you covered. They work proactively and don't need to be instructed on every little detail. They can also
              consult you on how to make the best possible use of their talents.</p>
            <p>And we'll be there to back you up if something doesn't go as planned.</p>
          </div>
        </TabPanel>
        <TabPanel className="w-75" value={value} index={5}>


          <div>
            <h1 className="mb-4">Perfect Price/Quality Balance</h1>
            <p>The study we did in Feb 2020 – Jun 2020 shows that American companies hire freelance developers in Eastern
              Europe as often as in the US and Canada combined.</p>
      
            <h4 className="mb-2">Why is it so?</h4>
            <p>Because Eastern Europe means quality of work standards that are close to American. It also means decent rates
              and Western work ethics. 27% of the companies repeatedly hire devs from this region because of the great
              previous experience.</p>
            <ol>
              <li>they have the best rates</li>
              <li>they have the biggest pool of talent</li>
              <li>because of the language spoken/communication specificity</li>
              <li>the location is in the most convenient time zone for me</li>
              <li>i have great previous experience with developers from there</li>
              <li>i’ve heard only good references</li>
              <li>i have no preferences</li>
              <li>other</li>
            </ol>
          </div>
        </TabPanel>

        <TabPanel className="w-75" value={value} index={6}>
          <div >
            <h1 className="mb-4">No Hidden Catch</h1>
            <p>We aren't a big platform. We have limitations. Sometimes, there might be no dev available to work on your
              project. Or no master with a required skill set in our talent pool.</p>
            <p>In such cases, we will honestly inform you about it. If it's the only way we can help you, we will direct you
              to other platforms that might have the candidates you need.</p>
          </div>
        </TabPanel>

        <TabPanel className="w-75" value={value} index={7}>
          <div>
            <h1 className="mb-4">How AlanTech Makes It Possible</h1>
            <h4 className="mb-2">Better safe than sorry</h4>
            <p>So, how does the AlanTech company work?</p>
            <p>We never hire people based on their portfolios or recommendations alone.
              AlanTech is a talent platform that matches customers with verified remote developers. The verification is done
              through the special screening process and is called vetting. Unlike the bidding platforms (Freelancer or
              Latium, to name a few), developers who want stable workloads and decent payment apply to cooperate with us.
              Before we recruit them, we test our candidates on multiple criteria. Those developers who successfully pass
              all vetting stages get hired. When you submit an order to us, we select one (or several) of our champions and
              assign them to your project.</p>
          </div>
        </TabPanel>

        <TabPanel className="w-75" value={value} index={8}>
          <div >
            <h1 className="mb-4">A Weird Genius? Give us Two, We Hire</h1>
            <p>So, who are the devs we recruit?</p>
            <p>Mostly, AlanTech developers are from Ukraine and other Eastern European countries. During the vetting
              process, it often turns out many of them are workaholic fanatics of programming. Also, half of the devs in our
              talent pool obsess over pet projects of some sort: games, apps, websites, etc.</p>
            <p>Together with our Talent Acquisition team, our CTO Anvar Azizov personally interviews every dev applying to
              work with us. Here are some things that sunk into his mind:</p>
            <p>We pay so much attention to pet projects because we believe they are a good indicator of a developer's
              dedication. You must really like to code if you do it apart from your work duties, for free.</p>
            <p>Our developers speak good English, and both their hard and soft skills have been approved by our talent
              acquisition team.</p>
          </div>
        </TabPanel>

        <TabPanel className="w-75" value={value} index={9}>
          <div>
            <h1 className="mb-4">The insides of our vetting process, revealed</h1>
            <p>To make this AlanTech review even more credible, we're unveiling our Holy Grail – the vetting process.</p>
            <p>To make sure we find you the best of the best developers available, we make them undergo a series of testing
              procedures:</p>
            <h4 className="mb-2">Background check</h4>
            <p>We review the candidates' social networks, GitHub and Stack Overflow profiles.</p>
            <p>Daria Ampilohova, our Talent Acquisition Specialist, knows how important it is to always check the
              background:</p>
            <h4 className="mb-2">English language skills</h4>
            <p>To eliminate possible miscommunication between our customers and engineers we provide, we test each
              candidate's English capabilities.</p>
            <p>I had a call with a developer once. At first, he sweated to answer my questions, but after like 5 minutes of
              torture he apologized and confessed that he overestimated his skills. We agreed he'd contact us again when
              he'd improve his English, Daria recalls.</p>
            <h4 className="mb-2">Test assignment</h4>
            <p>Good reputation and quick tongue mean nothing without the proficiency with the technologies used in
              programming and development. Test assignments we hand to our candidates are real-life problems we face on a
              daily basis.</p>
            <h4 className="mb-2">AlanTech interview</h4>
            <p>Some things can only be seen during face-to-face communication. If a candidate successfully passes the
              previous three stages, we schedule a video call to ask them tricky questions and finish them off. Am I right?
            </p>
          </div>
        </TabPanel>

        <TabPanel className="w-75" value={value} index={10}>
          <div >
            <h1 className="mb-4">Fees and commissions</h1>
            <p>AlanTech company is a mediator between customers and developers. For the time and effort we make to distill
              the best remote developers within our reach, we take a 20% commission.</p>
            <p>Think of us as your sidekicks on a small salary.</p>
            <p>In the time of need, when the COVID-19 pandemic threatened small businesses around the world, we cut our
              commissions and encouraged our devs to lower the prices, too. We called it a Don't Panic! offer and focused it
              on biotech, med, and logistics startups, to contribute to the global fight against the virus.</p>
          </div>
        </TabPanel>

        <TabPanel className="w-75" value={value} index={11}>
          <div>
            <h1 className="mb-4">Is Alan Tech legit?</h1>
            <p>It would be strange if AlanTech review written by AlanTech authors blatantly claimed, "Yes, AlanTech is
              legit."</p>
            <p>We believe testimonials and references from satisfied customers work better than anything we say about
              ourselves. We've gathered a small collection of reviews from Trustpilot and G2Crowd to illustrate the claims
              we made in this review.</p>
            <p>Some of them mention Coding Ninjas – this was our old name before the rebranding. The team, the
              professionals, and the quality of service remain the same.</p>
            <h4 className="mb-2">The good</h4>
            <p>So, to start with, we are proud to have 98% of positive reviews on Trustpilot (out of 60), and 100% of
              positive reviews on G2 Crowd.</p>
            <h4 className="mb-2">The bad</h4>
            <p>Yet, to achieve true perfection we will probably have to meditate for 20 years high in the mountains (not
              now, though, we've got tons of work to do). Two of our customers had poor experience with us – at least, poor
              enough to leave negative reviews.</p>
            <h4 className="mb-2">The ugly</h4>
            <p>Honestly, we couldn't find any. To make up for this, here's our content writer's cat.</p>
          </div>
        </TabPanel>

        <TabPanel className="w-75" value={value} index={12}>
          <div>
            <h1 className="mb-4">How safe is it to use Alan Tech?</h1>
            <p>Of course, now you can ask yourselves, is AlanTech legit with such low prices. Worry not. We are 100 % legit
              (see the reviews above).</p>
            <p>Most of us live in Ukraine, but we are an American corporation registered as a Delaware C Corp under the name
              Coding Ninjas (the way we called ourselves before the rebranding). We own the AlanTech company trademark and
              the AlanTech website.</p>
            <h4 className="mb-2">We do our best to provide you with the best possible customer experience. Here are the four
              major ways in which we do it:</h4>
            <ul>
              <li>thorough vetting of developers</li>
              <li>quick and precise matching</li>
              <li>responsive customer support</li>
              <li>free replacement guarantee (if you do not like working with a developer we provided, or if they cannot
                continue to work on your project, we will provide you with a free substitute).</li>
            </ul>
          </div>
        </TabPanel>

        <TabPanel className="w-75" value={value} index={13}>
          <div>
            <h1 className="mb-4">How come Alan Tech is so affordable?</h1>
            <p>The main ace up our sleeve is that we are located in Ukraine. Living here is 40% lower than in the US or
              Canada.</p>
            <p>Salaries comprise about 90–95% of our maintenance costs. We also save quite a lot of money by bootstrapping
              marketing costs and expensive operations.</p>
            <p>And we don't need an office (who does, anyway?)</p>
            <p>And, AlanTech fees are way lower than our competitors' (check Turing.com, 99Designs, PeoplePerHour, or other
              AlanTech alternatives!).</p>
            <p>As of August 2020, our overhead expenses equal $60,000. This is super-low compared to our competitors, which
              is why we can afford to offer you such low rates.</p>
            <h4 className="mb-2">Slavic names look unpronounceable</h4>
            <p>That's because we call each other by moose roars and owl hoots.</p>
            <h4 className="mb-2">Alan Tech alternatives</h4>
            <p>Of course, we have them. Who doesn't? Read on, make your choice. (Remember, we've told you it is a no-filter
              selfie?)</p>
            <h4 className="mb-2">Gigster vs. Alan Tech</h4>
            <p>Gigster is a vetting marketplace for freelance developers from Silicon Valley — so the hiring businesses can
              be sure that their future employees have previously worked with top tech companies.</p>
            <h4 className="mb-2">Pros</h4>
            <p>Gigster is invite-only, so everyone who comes for devs will choose from the pool of smart and experienced
              tech specialists.</p>
          </div>.
        </TabPanel>

        <TabPanel className="w-75" value={value} index={14}>
          <div>
            <h1 className="mb-4">Alan Tech review TL;DR</h1>
            <p>AlanTech is a talent platform for vetted developers, mostly recruiting its talents in Eastern Europe.
              Geographical location and well-organized remote work allow the company to set reasonable prices.</p>
            <p>AlanTech consists of 71 people who work to match its 1000+ remote developers with customers. A huge part of
              the platform's work is vetting – verifying engineers' skills to cull the weak and weed out scammers. Only 4
              out of 100 developers get into our talent pool.</p>
            <p>The company operates under terms of use that comply with US laws.</p>
            <p>A service does not have to be originally American to be good. Give AlanTech a go, and you will see how fast
              and safe hiring remote developers can be.</p>
            
<Link  className='btn btn-primary mb-3'to="/hire"> Hire Awesome Developer</Link>
            <h4 className="mb-2">Alan Tech FAQ — for those whose questions are still left unanswered</h4>
            <h6>Can your offshore developers choose the projects they will work on?</h6>
            <p>Aye, naturally. We respect everyone’s freedom of choice.</p>
            <h6>How many developers are there now on AlanTech?</h6>
            <p>Before rebranding, in the CodingNinjas times, we used to have 100 offshore devs. Now their number is fastly
              approaching 1000 — and counting.</p>
            <h6>Do you check the code of your devs?</h6>
            <p>We only hire senior-level (and rarely strong mid-level) devs. No juniors, no spaghetti code. Only those
              having years of experience with clean track records.</p>
          </div>
        </TabPanel>

      </Box>


      <div className='mobiletab'>
        <section id="faq" className="faq section-bg">
          <div className="container" data-aos="fade-up mt-5">


            <div className="row justify-content-center">
              <div className="col-xl-10">
                <ul className="faq-list">

                  <li>
                    <div data-bs-toggle="collapse" className="collapsed question ques" href="#faq1"><h3>Disclaimer</h3> <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>

                    <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                      <h2 className="mb-3 visble" style={{ visibility: "visible" }}>Yep, we're reviewing ourselves.</h2>
                      <p>You might think we're pressing the Like button under our own selfie.</p>
                      <p>We did our best to stay objective and unbiased in this AlanTech review. We are perfectly capable of critical
                        introspective analysis (whatever that means).</p>
                      <p>Our content department interviewed other team members about their work. Whenever we make a bold statement
                        about ourselves, there is a quote by another team member to support it.</p>
                      <p>Now, see how AlanTech ticks from the inside.</p>

                    </div>
                  </li>

                  <li>
                    <div data-bs-toggle="collapse" href="#faq2" className="collapsed question ques"><h2>Talent platforms: bidding vs. vetting</h2><i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                      <h2 className="text-dark visble" style={{ visibility: "visible" }}>The 911 For Your Project</h2>
                      <p>Hiring on regular freelance platforms is often like Russian roulette. If you are unlucky, you hire the wrong
                        person, who can potentially ruin your entire project.</p>
                      <p>That's because generic freelance platforms rely on bidding. When freelancers registered on it start applying
                        for your job, you must manually review their bids (rates, terms, portfolios, and testimonials from other
                        users). This is time-consuming, and you may run into a scammer or mishire.</p>
                      <p>Contrarily, when you recruit on platforms for vetted developers, it's like calling 911:</p>
                      <p>When you submit your job description, a tested, qualified engineer is ready to work on it. The platform needs
                        little time to find them in its talent pool.</p>
                      <p>AlanTech is an illustrative example of such a platform.</p>
                    </div>
                  </li>
                  <li>
                    <div data-bs-toggle="collapse" href="#faq3" className="collapsed question ques"><h2>Alan Tech small but efficient</h2><i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                      <h2 className="mb-4" style={{ visibility: "visible" }}>Small But Efficient</h2>
                      <p>So, what is AlanTech, exactly?</p>
                      <p>Unlike our bigger competitors, we are a company of just 71 people who work and hang out together.</p>
                      <p>Our talent pool currently consists of 1000+ remote developers whose personal and professional skills have
                        been verified prior to hiring.</p>
                      <p>Someone would say it's not much. That is true.</p>
                      <p>We personally know all developers from our database. We know what they are great at and what they suck at. We
                        know what inspires and concerns them. When you submit your job description to us, we know which one will be
                        the perfect match for it.</p>
                      <p>Besides, if you’ve ever played video games, you know that a smaller size often means great agility. We
                        believe we have the fastest matching among other vetting platforms.</p>
                    </div></li>

                  <li>
                    <div data-bs-toggle="collapse" href="#faq4" className="collapsed question ques"><h2>Matching in 48 hours</h2><i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                      <h2 className="mb-4" style={{ visibility: "visible" }}>What We Promise You</h2>
                      <h4 className="mb-2" style={{ visibility: "visible" }}>You get a specialist within 48 hours</h4>
                      <p>Minimum actions are needed on your end. Just submit your order description and tend to other matters. The
                        next day, you will have an engineer for your project ready and able. Their experience and skills will be the
                        perfect match for your needs.</p>
                      <p>Sometimes our standards can get us into tight spots. Yevgeniya Kruglova, our Head of Talent Acquisition and
                        Strategy, recalls one of the many cases when we were in a pinch:</p>
                    </div>
                  </li>

                  <li>
                    <div data-bs-toggle="collapse" href="#faq5" className="collapsed question ques"><h2>Management Experience Requirements</h2><i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq5" className="collapse" data-bs-parent=".faq-list">



                      <h2 className="mb-4" style={{ visibility: "visible" }}>No Hiring or Management Experience Needed</h2>
                      <p>Even if you have zero experience in hiring and managing remote developers, engineers registered at AlanTech
                        got you covered. They work proactively and don't need to be instructed on every little detail. They can also
                        consult you on how to make the best possible use of their talents.</p>
                      <p>And we'll be there to back you up if something doesn't go as planned.</p>   </div>
                  </li>
                  <li>
                    <div data-bs-toggle="collapse" href="#faq6" className="collapsed question ques"><h2>Price/quality balance</h2><i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq6" className="collapse" data-bs-parent=".faq-list">



                      <p>The study we did in Feb 2020 – Jun 2020 shows that American companies hire freelance developers in Eastern
                        Europe as often as in the US and Canada combined.</p>
                      <h4 className="mb-">Why is it so?</h4>
                      <p>Because Eastern Europe means quality of work standards that are close to American. It also means decent rates
                        and Western work ethics. 27% of the companies repeatedly hire devs from this region because of the great
                        previous experience.</p>
                      <ol className='' style={{ visibility: "visible" }}>
                        <li>they have the best rates</li>
                        <li>they have the biggest pool of talent</li>
                        <li>because of the language spoken/communication specificity</li>
                        <li>the location is in the most convenient time zone for me</li>
                        <li>i have great previous experience with developers from there</li>
                        <li>i’ve heard only good references</li>
                        <li>i have no preferences</li>
                        <li>other</li>
                      </ol>
                    </div>       </li>

                  <li>
                    <div data-bs-toggle="collapse" href="#faq7" className="collapsed question ques"><h2>Our limitations</h2><i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq7" className="collapse" data-bs-parent=".faq-list">

                      <h2 className="mb-4" style={{ visibility: "visible" }}>No Hidden Catch</h2>
                      <p>We aren't a big platform. We have limitations. Sometimes, there might be no dev available to work on your
                        project. Or no master with a required skill set in our talent pool.</p>
                      <p>In such cases, we will honestly inform you about it. If it's the only way we can help you, we will direct you
                        to other platforms that might have the candidates you need.</p>   </div>
                  </li>
                  <li>
                    <div data-bs-toggle="collapse" href="#faq8" className="collapsed question ques"><h2>Vetting: our secret verification</h2><i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq8" className="collapse" data-bs-parent=".faq-list">

                      <h2 className="mb-4" style={{ visibility: "visible" }}>How AlanTech Makes It Possible</h2>
                      <h4 className="mb-2" style={{ visibility: "visible" }}>Better safe than sorry</h4>
                      <p>So, how does the AlanTech company work?</p>
                      <p>We never hire people based on their portfolios or recommendations alone.
                        AlanTech is a talent platform that matches customers with verified remote developers. The verification is done
                        through the special screening process and is called vetting. Unlike the bidding platforms (Freelancer or
                        Latium, to name a few), developers who want stable workloads and decent payment apply to cooperate with us.
                        Before we recruit them, we test our candidates on multiple criteria. Those developers who successfully pass
                        all vetting stages get hired. When you submit an order to us, we select one (or several) of our champions and
                        assign them to your project.</p>
                    </div>         </li>
                  <li>
                    <div data-bs-toggle="collapse" href="#faq9" className="collapsed question ques"><h2>Who are the developers we recruit?</h2><i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq9" className="collapse" data-bs-parent=".faq-list">


                      <h2 className="mb-4" style={{ visibility: "visible" }}>A Weird Genius? Give us Two, We Hire</h2>
                      <p>So, who are the devs we recruit?</p>
                      <p>Mostly, AlanTech developers are from Ukraine and other Eastern European countries. During the vetting
                        process, it often turns out many of them are workaholic fanatics of programming. Also, half of the devs in our
                        talent pool obsess over pet projects of some sort: games, apps, websites, etc.</p>
                      <p>Together with our Talent Acquisition team, our CTO Anvar Azizov personally interviews every dev applying to
                        work with us. Here are some things that sunk into his mind:</p>
                      <p>We pay so much attention to pet projects because we believe they are a good indicator of a developer's
                        dedication. You must really like to code if you do it apart from your work duties, for free.</p>
                      <p>Our developers speak good English, and both their hard and soft skills have been approved by our talent
                        acquisition team.</p>

                    </div>         </li>
                  <li>
                    <div data-bs-toggle="collapse" href="#faq10" className="collapsed question ques"><h2>Vetting process in detail</h2><i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq10" className="collapse" data-bs-parent=".faq-list">

                      <div >
                        <h2 className="mb-4" style={{ visibility: "visible" }}>The insides of our vetting process, revealed</h2>
                        <p>To make this AlanTech review even more credible, we're unveiling our Holy Grail – the vetting process.</p>
                        <p>To make sure we find you the best of the best developers available, we make them undergo a series of testing
                          procedures:</p>
                        <h4 className="mb-2">Background check</h4>
                        <p>We review the candidates' social networks, GitHub and Stack Overflow profiles.</p>
                        <p>Daria Ampilohova, our Talent Acquisition Specialist, knows how important it is to always check the
                          background:</p>
                        <h4 className="mb-2">English language skills</h4>
                        <p>To eliminate possible miscommunication between our customers and engineers we provide, we test each
                          candidate's English capabilities.</p>
                        <p>I had a call with a developer once. At first, he sweated to answer my questions, but after like 5 minutes of
                          torture he apologized and confessed that he overestimated his skills. We agreed he'd contact us again when
                          he'd improve his English, Daria recalls.</p>
                        <h4 className="mb-2">Test assignment</h4>
                        <p>Good reputation and quick tongue mean nothing without the proficiency with the technologies used in
                          programming and development. Test assignments we hand to our candidates are real-life problems we face on a
                          daily basis.</p>
                        <h4 className="mb-2">AlanTech interview</h4>
                        <p>Some things can only be seen during face-to-face communication. If a candidate successfully passes the
                          previous three stages, we schedule a video call to ask them tricky questions and finish them off. Am I right?
                        </p>

                      </div>
                    </div>         </li>
                    <li>
                    <div data-bs-toggle="collapse" href="#faq11" className="collapsed question ques"><h2>Our average hourly rates</h2><i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq11" className="collapse" data-bs-parent=".faq-list">


                    <h2 className="mb-4"style={{ visibility: "visible" }}>Fees and commissions</h2>
            <p>AlanTech company is a mediator between customers and developers. For the time and effort we make to distill
              the best remote developers within our reach, we take a 20% commission.</p>
            <p>Think of us as your sidekicks on a small salary.</p>
            <p>In the time of need, when the COVID-19 pandemic threatened small businesses around the world, we cut our
              commissions and encouraged our devs to lower the prices, too. We called it a Don't Panic! offer and focused it
              on biotech, med, and logistics startups, to contribute to the global fight against the virus.</p>
       
                    </div>         </li>
                    <li>
                    <div data-bs-toggle="collapse" href="#faq13" className="collapsed question ques"><h2>Our fees and commissions</h2><i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq13" className="collapse" data-bs-parent=".faq-list">


                    <h2 className="mb-4"style={{ visibility: "visible" }}>Is Alan Tech legit?</h2>
            <p>It would be strange if AlanTech review written by AlanTech authors blatantly claimed, "Yes, AlanTech is
              legit."</p>
            <p>We believe testimonials and references from satisfied customers work better than anything we say about
              ourselves. We've gathered a small collection of reviews from Trustpilot and G2Crowd to illustrate the claims
              we made in this review.</p>
            <p>Some of them mention Coding Ninjas – this was our old name before the rebranding. The team, the
              professionals, and the quality of service remain the same.</p>
            <h4 className="mb-2">The good</h4>
            <p>So, to start with, we are proud to have 98% of positive reviews on Trustpilot (out of 60), and 100% of
              positive reviews on G2 Crowd.</p>
            <h4 className="mb-2">The bad</h4>
            <p>Yet, to achieve true perfection we will probably have to meditate for 20 years high in the mountains (not
              now, though, we've got tons of work to do). Two of our customers had poor experience with us – at least, poor
              enough to leave negative reviews.</p>
            <h4 className="mb-2">The ugly</h4>
            <p>Honestly, we couldn't find any. To make up for this, here's our content writer's cat.</p>
          
                    </div>         </li>
                    <li>
                    <div data-bs-toggle="collapse" href="#faq13" className="collapsed question ques"><h2>Customer reviews</h2><i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq13" className="collapse" data-bs-parent=".faq-list">

                    <h2 className="mb-4"style={{ visibility: "visible" }}>How safe is it to use Alan Tech?</h2>
            <p>Of course, now you can ask yourselves, is AlanTech legit with such low prices. Worry not. We are 100 % legit
              (see the reviews above).</p>
            <p>Most of us live in Ukraine, but we are an American corporation registered as a Delaware C Corp under the name
              Coding Ninjas (the way we called ourselves before the rebranding). We own the AlanTech company trademark and
              the AlanTech website.</p>
            <h4 className="mb-2">We do our best to provide you with the best possible customer experience. Here are the four
              major ways in which we do it:</h4>
            <ul>
              <li>thorough vetting of developers</li>
              <li>quick and precise matching</li>
              <li>responsive customer support</li>
              <li>free replacement guarantee (if you do not like working with a developer we provided, or if they cannot
                continue to work on your project, we will provide you with a free substitute).</li>
            </ul>
          
                    </div>         </li>
                    <li>
                    <div data-bs-toggle="collapse" href="#faq14" className="collapsed question ques"><h2>How we guarantee security</h2><i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq14" className="collapse" data-bs-parent=".faq-list">

                    <h2 className="mb-4"style={{ visibility: "visible" }}>How come Alan Tech is so affordable?</h2>
            <p>The main ace up our sleeve is that we are located in Ukraine. Living here is 40% lower than in the US or
              Canada.</p>
            <p>Salaries comprise about 90–95% of our maintenance costs. We also save quite a lot of money by bootstrapping
              marketing costs and expensive operations.</p>
            <p>And we don't need an office (who does, anyway?)</p>
            <p>And, AlanTech fees are way lower than our competitors' (check Turing.com, 99Designs, PeoplePerHour, or other
              AlanTech alternatives!).</p>
            <p>As of August 2020, our overhead expenses equal $60,000. This is super-low compared to our competitors, which
              is why we can afford to offer you such low rates.</p>
            <h4 className="mb-2">Slavic names look unpronounceable</h4>
            <p>That's because we call each other by moose roars and owl hoots.</p>
            <h4 className="mb-2">Alan Tech alternatives</h4>
            <p>Of course, we have them. Who doesn't? Read on, make your choice. (Remember, we've told you it is a no-filter
              selfie?)</p>
            <h4 className="mb-2">Gigster vs. Alan Tech</h4>
            <p>Gigster is a vetting marketplace for freelance developers from Silicon Valley — so the hiring businesses can
              be sure that their future employees have previously worked with top tech companies.</p>
            <h4 className="mb-2">Pros</h4>
            <p>Gigster is invite-only, so everyone who comes for devs will choose from the pool of smart and experienced
              tech specialists.</p>
          
                    </div>         </li>

                                       <li>
                    <div data-bs-toggle="collapse" href="#faq15" className="collapsed question ques"><h2>What we do to keep our prices low</h2><i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq15" className="collapse" data-bs-parent=".faq-list">

                    <h2 className="mb-4"style={{ visibility: "visible" }}>Alan Tech review TL;DR</h2>
            <p>AlanTech is a talent platform for vetted developers, mostly recruiting its talents in Eastern Europe.
              Geographical location and well-organized remote work allow the company to set reasonable prices.</p>
            <p>AlanTech consists of 71 people who work to match its 1000+ remote developers with customers. A huge part of
              the platform's work is vetting – verifying engineers' skills to cull the weak and weed out scammers. Only 4
              out of 100 developers get into our talent pool.</p>
            <p>The company operates under terms of use that comply with US laws.</p>
            <p>A service does not have to be originally American to be good. Give AlanTech a go, and you will see how fast
              and safe hiring remote developers can be.</p>
            <button className="btn btn-primary mb-3"style={{ visibility: "visible" }}>Hire Awesome developers</button>
            <h4 className="mb-2"style={{ visibility: "visible" }}>Alan Tech FAQ — for those whose questions are still left unanswered</h4>
            <h6>Can your offshore developers choose the projects they will work on?</h6>
            <p>Aye, naturally. We respect everyone’s freedom of choice.</p>
            <h6>How many developers are there now on AlanTech?</h6>
            <p>Before rebranding, in the CodingNinjas times, we used to have 100 offshore devs. Now their number is fastly
              approaching 1000 — and counting.</p>
            <h6>Do you check the code of your devs?</h6>
            <p>We only hire senior-level (and rarely strong mid-level) devs. No juniors, no spaghetti code. Only those
              having years of experience with clean track records.</p>
         
          
                    </div>         </li> 

                </ul>

              </div>
            </div>

          </div >

        </section >
      </div>
    </div>


  );
}
