import React from "react";


function Card() {
  return (
    <div className="container">
    <input type="radio" name="dot" id="one"/>
    <input type="radio" name="dot" id="two"/>
    <div className="main-card">
      <div className="cards">
        <div className="card">
         <div className="content">
           <div className="img">
             <img src={(require('../character42/character_2-03.png'))} alt=""/>
           </div>
           <div className="details">
             <div className="name">Amuthan</div>
             <div className="job">PHP Developer</div>
           </div>
           {/* <div className="media-icons">
             <a href="#"><i className="fab fa-facebook-f"></i></a>
             <a href="#"><i className="fab fa-twitter"></i></a>
             <a href="#"><i className="fab fa-instagram"></i></a>
             <a href="#"><i className="fab fa-youtube"></i></a>
           </div> */}
         </div>
        </div>
        <div className="card">
         <div className="content">
           <div className="img">
             <img src={(require('../character42/character_2-04.png'))} alt=""/>
           </div>
           <div className="details">
             <div className="name">MahaDev</div>
             <div className="job">React Js Developer</div>
           </div>
           {/* <div className="media-icons">
             <a href="#"><i className="fab fa-facebook-f"></i></a>
             <a href="#"><i className="fab fa-twitter"></i></a>
             <a href="#"><i className="fab fa-instagram"></i></a>
             <a href="#"><i className="fab fa-youtube"></i></a>
           </div> */}
         </div>
        </div>
        <div className="card">
         <div className="content">
           <div className="img">
             <img src={(require('../character42/character_2-05.png'))} alt=""/>
           </div>
           <div className="details">
             <div className="name">Harry</div>
             <div className="job">Laravel Developer</div>
           </div>
           {/* <div className="media-icons">
             <a href="#"><i className="fab fa-facebook-f"></i></a>
             <a href="#"><i className="fab fa-twitter"></i></a>
             <a href="#"><i className="fab fa-instagram"></i></a>
             <a href="#"><i className="fab fa-youtube"></i></a>
           </div> */}
         </div>
        </div>
      </div>
      <div className="cards">
        <div className="card">
         <div className="content">
           <div className="img">
             <img src={(require('../character42/character_2-06.png'))} alt=""/>
           </div>
           <div className="details">
             <div className="name">Williams</div>
             <div className="job">Node Js developer</div>
           </div>
           {/* <div className="media-icons">
             <a href="#"><i className="fab fa-facebook-f"></i></a>
             <a href="#"><i className="fab fa-twitter"></i></a>
             <a href="#"><i className="fab fa-instagram"></i></a>
             <a href="#"><i className="fab fa-youtube"></i></a>
           </div> */}
         </div>
        </div>
        <div className="card">
         <div className="content">
           <div className="img">
             <img src={(require('../character42/character_2-07.png'))} alt=""/>
           </div>
           <div className="details">
             <div className="name">Adrina</div>
             <div className="job">Flutter Developer</div>
           </div>
           {/* <div className="media-icons">
             <a href="#"><i className="fab fa-facebook-f"></i></a>
             <a href="#"><i className="fab fa-twitter"></i></a>
             <a href="#"><i className="fab fa-instagram"></i></a>
             <a href="#"><i className="fab fa-youtube"></i></a>
           </div> */}
         </div>
        </div>
        <div className="card">
         <div className="content">
           <div className="img">
             <img src={(require('../character42/character_2-08.png'))} alt=""/>
           </div>
           <div className="details">
             <div className="name">Nicole</div>
             <div className="job">Web Devloper</div>
           </div>
           {/* <div className="media-icons">
             <a href="#"><i className="fab fa-facebook-f"></i></a>
             <a href="#"><i className="fab fa-twitter"></i></a>
             <a href="#"><i className="fab fa-instagram"></i></a>
             <a href="#"><i className="fab fa-youtube"></i></a>
           </div> */}
         </div>
        </div>
      </div>
    </div>
    <div className="button">
    <label htmlFor="one" className="active one" style={{ backgroundColor: " #294b86" }}></label>

      <label for="two" className="two" style={{ backgroundColor: " #294b86" }}></label>
    </div>
  </div>
  );
}


export default Card;