import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";

import {
  Container,
  InputAdornment,
  Typography,
  Button,
  Box,
  TextField,
  Grid,
  IconButton,
  FormHelperText,
} from "@mui/material";
import Swal from "sweetalert2";

import { useFormik } from 'formik';
import * as Yup from 'yup';


function Settings() {

  // Material ui style
  const box = { width: { lg: 1000, }, height: { lg: '100%', md: '100%', xs: '100%' } };

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const [newPwdError, setNewPwdError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [developer, setDeveloper] = useState();

  const navigate = useNavigate();

  // Formik validation schema
  const pwdValidationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Please enter the password"),
    newPassword: Yup.string().min(8, 'Password must be 8 characters long')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol').required("Please enter new password"),
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], "password must match").required("Please enter confirm password")
  });

  // UseFormik hook
  const { values, handleBlur, handleChange, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: pwdValidationSchema,
    onSubmit: onsubmit
  })


  // Form submit function in formik
  async function onsubmit(values, actions) {
    setLoading(true);
    try {
      const token = localStorage.getItem('token')
      const user_id = localStorage.getItem('user_id');
      const response = await axios.put(`${process.env.REACT_APP_URL}/profile/updatepassword`,
        {
          "oldPassword": values.currentPassword,
          "newPassword": values.confirmPassword,
        },
        {
          headers: {
            'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
            "Auth-Key": process.env.REACT_APP_AUTH_KEY,
            'User-id': user_id,
            'Authorization': token
          }
        }
      );
      if (response.status === 200) {
        setLoading(false)
        SweetAlert();
      }
      else {
        setLoading(false)
        alert('Sorry, Something Went worng pls try again some time')
      }
    } catch (err) {
      if (err.response.status == 404) {
        setPwdError(true)
      }
      if (err.response.status == 400) {
        setNewPwdError(true)
      }
    }
  }


  // Popup message
  function SweetAlert() {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Password Updated",
      confirmButtonText: "OK",
      timer: 8000,
    }).then(async (result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }

  // Show and hide password 
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleClickShowPassword3 = () => setShowPassword3((show) => !show);


  useEffect(() => {
    if (values.currentPassword.length > 2) {
      setPwdError(false)
    }

    if (values.confirmPassword.length > 2) {
      setNewPwdError(false)
    }
  }, [values])



  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/login");
    } else {
      const data = localStorage.getItem("user");
      const developer = JSON.parse(data);
      setDeveloper(developer);
    }
  }, []);

  if (loading) {
    return (<div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    )
  }

  return (
    <Box>
      <Container sx={box}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                UPDATE PASSWORD
              </Typography>
            </Grid>

            <Grid item xs={12}>

              <TextField
                label="Current Password"
                name="currentPassword"
                type={showPassword1 ? 'text' : 'password'} // Toggle password visibility
                fullWidth
                helperText={errors.currentPassword && touched.currentPassword ? errors.currentPassword : ""}
                value={values.currentPassword}
                error={touched.currentPassword && Boolean(errors.currentPassword) || pwdError}
                onBlur={handleBlur}
                onChange={handleChange}

                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword1}
                      >
                        {showPassword1 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText sx={{ color: "#d32f2f" }}>{pwdError ? "Current password is incorrect" : ""}</FormHelperText>

            </Grid>
            <Grid item xs={12}>

              <TextField
                label="New Password"
                name="newPassword"
                type={showPassword2 ? 'text' : 'password'} // Toggle password visibility
                fullWidth
                helperText={errors.newPassword && touched.newPassword ? errors.newPassword : ""}
                value={values.newPassword}
                error={touched.newPassword && Boolean(errors.newPassword) || newPwdError}
                onBlur={handleBlur}
                onChange={handleChange}

                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword2}
                      >
                        {showPassword2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText sx={{ color: "#d32f2f" }}>{newPwdError ? "Please enter new password its same as old Password" : ""}</FormHelperText>

            </Grid>
            <Grid item xs={12}>

              <TextField
                label="Confirm Password"
                name="confirmPassword"
                type={showPassword3 ? 'text' : 'password'} // Toggle password visibility
                fullWidth
                helperText={errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : ""}
                value={values.confirmPassword}
                error={touched.confirmPassword && Boolean(errors.confirmPassword) || newPwdError}
                onBlur={handleBlur}
                onChange={handleChange}

                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword3}
                      >
                        {showPassword3 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText sx={{ color: "#d32f2f" }}>{newPwdError ? "Please enter new password its same as old Password" : ""}</FormHelperText>


            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                sx={{ py: 1, bgcolor: " #294b86 !important", color: "white" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
}

export default Settings;
