import React, { useState } from 'react';
import './ProgressBar.css'; 

const ProgressBar = ({ percentage }) => {
  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: `${percentage}%` }}>
        <div className="progress-text">{`${percentage}%`}</div>
      </div>
    </div>
  );
};

export default ProgressBar;
