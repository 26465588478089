import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MyApplication from "./Application/MyApplication";
import Project from "./Application/Project";
import Profile from "./Application/Profile";
import MyExperience from "./Application/MyExperience";
import { Container } from "@mui/material";
import axios from "axios";
import { NavLink } from 'react-router-dom';



export default function Application() {
  const [auth, setAuth] = useState(true);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [value, setValue] = useState("1");
  const [value2, setValue2] = useState("5");
  const location = useLocation();
  const navigate = useNavigate();

  const [developer, setDeveloper] = useState("");
  useEffect(() => {
    const fetchDate = async () => {
      const token = localStorage.getItem("token");
      const user_id = localStorage.getItem('user_id')
        const response = await axios.get(`${process.env.REACT_APP_URL}/profile`, {
          headers: {
            'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
            "Auth-Key": process.env.REACT_APP_AUTH_KEY,
            'User-id': user_id,
            'Authorization': token
          }
        })
        if (response.status == 200) {
          setDeveloper(response.data.data)
        }
      }
    fetchDate();
  }, []);





  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [loading, setLoading] = useState(false);
  const clearandnavigate = async () => {
    setLoading(true)
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id');
    const response = await axios.post(`${process.env.REACT_APP_URL}/auth2/logout`, {}, {
      headers: {
        'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
        "Auth-Key": process.env.REACT_APP_AUTH_KEY,
        'User-id': user_id,
        'Authorization': token
      }
    })
    if (response.status == 200) {
      localStorage.clear();
      navigate("/");
    }
  };



  if (loading) {
    return (<div class="spinner2">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    )
  }


  

  return (
    <div>
      <Box sx={{ flexGrow: 1, backgorundColor: "white" }}>
        <AppBar position="static" color="transparent">
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              className="applicationlogo"
            >
              <NavLink to="/"><img
                src={require("../../images/ALAN Final logo-01.png")}
                height={"15%"}
                width={"15%"}
              /></NavLink>
            </Typography>
            {auth && (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"

                >
                  {
                    developer.user_profile ? (
                      <img
                      src={`https://dev.alantechnologies.in/uploads/${developer.user_profile}`}
                      alt="User Profile"
                      width="10%"
                      height="10%"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        cursor: 'pointer'
                      }}
                   
                      />
                    ) : (
                      <AccountCircle sx={{ width: 40, height: 40 }} />
                    )
                  }
                </IconButton>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={clearandnavigate}>Log Out</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </Box>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", backgroundColor: "#294b86" }}>
            <Container>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                aria-label="lab API tabs example"
              >
                <Tab
                  style={{ color: "#fdfdfd" }}
                  label="My Application"
                  value="1"
                />
                <Tab style={{ color: "#fdfdfd" }} label="Clients Project" value="2" />
                <Tab style={{ color: "#fdfdfd" }} label="Profile" value="3" />
                <Tab style={{ color: "#fdfdfd" }} label="My Experience" value="4" />
              </TabList>
            </Container>
          </Box>
          <TabPanel value="1" className="container">
            <MyApplication />
          </TabPanel>

          <TabPanel value="2" className="container">
            <Project />
          </TabPanel>

          <TabPanel value="3">
            <Profile />
          </TabPanel>

          <TabPanel value="4">
            <MyExperience />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
