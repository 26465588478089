import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Card from "../../cards";
import { useNavigate } from 'react-router-dom'
function Ui_ux_designer(){
    const navigate = useNavigate();
    return(
        <>
        <Header/>
        <section id="udesigner" className="faq heros d-flex align-items-center">
        <div className="container" data-aos="zoom-out" data-aos-duration="1000">
            <h1 className="col-sm-6 pt-5">
                UI/UX DESIGNER <span>JOB DESCRIPTION</span>
            </h1>
            <br />
        </div>
    </section>
 <div className="greybg">
 <div className="container">
        <div id="dozens" data-aos="fade-up"  data-aos-duration="1000">
            <p className="pt-2">
                "Did you know that 88% of online shoppers abandon a website due to a poor user experience? Conversely,
                23% of satisfied customers tend to share their positive experiences with numerous others.</p> 

               <p> Subpar usability contributes to the downfall of 70% of web-based businesses. Who takes on the
                responsibility of analyzing and devising optimal strategies for interacting with physical or digital
                entities? These experts are referred to as UI/UX designers.</p> 

               <p> User interface designers have a diverse scope — spanning from standard corporate websites to even coffee
                machines serving up your favorite flat white. Their primary objectives encompass understanding consumer
                behavior, deciphering patterns, and crafting the most delightful and effective user experience
                scenarios. While mobile UI designers focus on mobile apps, web UI/UX designers cater to web applications
                and software.</p> 

              <p>  UI/UX designers ensure that products, websites, and services are user-friendly, accessible, and
                enjoyable. They must also possess a solid grasp of the technical aspects within their domain.</p> 

               <p> Crafting an exceptional job description is essential when seeking to hire the UI/UX designers of your
                aspirations. Without one, it becomes challenging for others to comprehend your needs and requirements.
                This situation can lead to difficulties, including sifting through numerous candidates and investing
                time without yielding productive outcomes.</p> 
                
          
            </div>
            <div id="mediabutton">
            <button className="col-sm-3 btn btn-primary mb-3 shadow-lg mt-2"onClick={() => navigate('/hire')}>
                HIRE YOUR UI/UX DESIGNER!
                </button>
            </div>
    </div>
 </div>
    <div className="container">
        <div id="rebrandings" data-aos="fade-right" data-aos-duration='1000'>
            <h2 className="mt-5">What are The Main Parts of a <span>UI/UX Job Description?</span></h2>
            <p>A proper well-composed job description can consist of the following parts:</p>
            <p><span className="text-primary fs-5">✔</span>  Company description;</p>
            <p><span className="text-primary fs-5">✔</span>  UI/UX designer job brief;</p>
            <p><span className="text-primary fs-5">✔</span>  UI/UX designer roles and responsibilities;</p>
            <p><span className="text-primary fs-5">✔</span>  Requirements and qualifications for a UI/UX designer;</p>
            <p><span className="text-primary fs-5">✔</span>  Benefits and perks your company offers.</p>
            <p>Let’s start with the company description and dig into all the points one by one.</p>
        </div>
    </div>
    <div className="container">
        <div id="strengths" data-aos="fade-up"  data-aos-duration='1000'>
            <h2 className="mt-5">Company <span>Description</span> </h2>
            <p>"Provide an informative overview of your company for user experience engineers who might not be familiar
                with it. Offer candidates comprehensive information upfront to streamline the job interview process and
                avoid spending extra time on these details later.</p>

               <p> Offer a succinct account of your company's origin, historical journey, any rebranding efforts,
                noteworthy business developments, core values, strengths, and areas for improvement. Strive to
                encapsulate your identity in a welcoming and easily comprehensible manner."</p>
            <h2>Examples:</h2>
            <p><span className="pe-1"> 1 </span>"We are an agency that places strong emphasis on values like curiosity,
            equality, and creativity. Our passion lies in collaboratively crafting digital products alongside our target
            audience to bring about positive technological impacts on the world. Our team highly values open-mindedness,
            teamwork, and taking ownership. If you find joy in leveraging technology to tackle significant societal
            challenges, we invite you to join our team of passionate advocates, innovative minds, and technology
            enthusiasts.</p>
           <p> <span className="pe-1"> 2 </span>At CuckooWise, our mission involves developing the operating system for one of
            the fastest-growing demographics in the U.S.: the elderly population. In the current landscape, 56% of total
            U.S. expenditures stem from individuals over 50, yet this demographic remains largely underserved by Silicon
            Valley and FinTech enterprises. This is where our role comes into play. We are dedicated to creating tools
            and services that enhance the quality of life and productivity for those aged 50 and above.</p>
        </div>
    </div>
  <div className="greybg">
  <div className="container">
        <div id="concise" data-aos="fade-left" data-aos-duration='1000'>
            <h2 className="mt-5">UI/UX <span>Designer Job Brief</span></h2>
            <p >Here, it’s appropriate to answer the following questions:</p>
            <p><span className="text-primary fs-5">✔</span>  What does this job presuppose? </p>
            <p><span className="text-primary fs-5">✔</span>  What will the candidate do/create? </p>
            <p><span className="text-primary fs-5">✔</span>  Whom will the candidate work with?</p>
            <p>"A job description should be brief and focused, but it's important to include all the necessary keywords
                related to the position. This helps candidates quickly understand the required UX design
                skills."<br/><br/>
                Example:<br/><br/>
                "As a UI/UX designer with Company&Company in Vancouver, Canada, your role will involve capturing and
                transforming user insights into exceptional experiences. Your main objective will be crafting functional
                and visually appealing features that align with our customer's requirements. Your creative prowess will
                be harnessed for designing and implementing remarkable user experiences. Collaborating with a visual
                designer, product manager, and our development team, you will be responsible for delivering UI mockups,
                prototypes, MVPs, and finalized products. Additionally, you'll have the valuable responsibility of
                advocating for our clients, translating their needs into user-centric designs."</p>
        </div>
    </div>
  </div>
    <div className="container">
        <div id="stress" data-aos="fade-up" data-aos-duration='1000'>
            <h2 className="mt-5">UI/UX Developer<span> Roles And Responsibilities</span> </h2>
            <p>"Outline all the responsibilities that the candidate will undertake upon employment. Thoroughness in
                detailing these responsibilities reduces the likelihood of overlooking important aspects that may become
                significant later.</p>
               <p> Here is a brief possible list:<br/><br/></p>
                <p><span className="text-primary fs-5">✔</span>  "Assessing the user experience design requirements for our digital asset
                collection."</p>
                <p><span className="text-primary fs-5">✔</span>  "Developing a comprehensive UI/UX design strategy for the brand."</p>
                <p><span className="text-primary fs-5">✔</span>  "Utilizing wireframes, visual and graphic designs, flowcharts, storyboards,
                site maps, and prototypes to craft premium UX design solutions."</p>
                <p><span className="text-primary fs-5">✔</span>  "Creating user interface (UI) elements for our digital assets, including
                menus, search boxes, tabs, and widgets."</p>
                <p><span className="text-primary fs-5">✔</span>  "Conducting UI element testing for target links on landing pages, CTAs,
                banners, page designs, flows, and layouts."</p>
                <p><span className="text-primary fs-5">✔</span>  
                "Collaborating with the marketing team, internal and external web UI designers, and other stakeholders
                to create and deliver tailored digital user experiences."</p>
               
        </div>
        <div id="mediabutton">
        <button className="col-sm-3 btn btn-primary mb-3 shadow-lg mt-2"onClick={() => navigate('/hire')}>
                    UI/UX DESIGNERS FOR HIRE!
                    </button>
        </div>
    </div>
    <div className="container">
        <div id="sort" data-aos="fade-right" data-aos-duration='1000'>
            <h2 className="mt-5">UI/UX Developer<span> Requirements And Qualifications </span></h2>
            <p>"Craft a focused and succinct description. Before drafting, clarify your expectations from UX specialists
                in your startup. Identify the necessary tools they should be proficient in. If this marks your initial
                hiring of such a role, consult with the HR department. Refer to the provided example below."</p>
               <p> Example:</p>
                <p>"The preferred UI and UX designer for our startup should possess expertise in at least one of the
                following areas:"</p>
                <p><span className="pe-1"> 1 </span>"Developing a trading application (for prior employers or clients);"</p>
                <p><span className="pe-1"> 2 </span>"Creating trading algorithms and dynamic layouts;"</p>
                <p><span className="pe-1"> 3 </span>"Engaging in active market trading across various asset classes (stocks,
                options, cryptocurrencies, forex, etc);</p>
                <p><span className="pe-1"> 4 </span>"Adept with day-trading platforms like DAS Trader Pro, Sterling Trader Pro,
                Thinkorswim, Trader Workstation, and others;"</p>
                <p><span className="pe-1"> 5 </span>"Proficient in React Native and React, along with Figma experience;"</p>
                <p><span className="pe-1"> 6 </span> "Capable of anticipating app behavior across multiple platforms."</p>
        </div>
    </div>
    <div className="container">
        <div id="insignificant" data-aos="fade-left" data-aos-duration='1000'>
            <h2 className="mt-5">Benefits and Perks<span> For UI/UX Designers</span> </h2>
            <p><span className="text-primary fs-5">✔</span>  Please provide a comprehensive overview of the benefits and perks offered by
            your organization.;</p>
            <p><span className="text-primary fs-5">✔</span>  Be honest: the truth will inevitably surface.</p>
            <p><span className="text-primary fs-5">✔</span>  If the benefits are relatively minor, it might be best to omit mentioning them
            to avoid appearing trivial, as not all companies provide bonuses.</p>
            <p><span className="text-primary fs-5">✔</span>  Optimal benefits vary based on the industry.</p>
            <p><span className="text-primary fs-5">✔</span>  Potential benefits for an IT company could include monthly incentives for
            remote home office work (applicable to remote employees who aren't freelancers), annual bonuses for
            fitness,<br/>
            professional development, and well-being, sick leave, as well as holidays</p>
        </div>
    </div>
    <div className="container">
          <div id="devs" >
            <h2 className="mt-5 mb-4 text-center" data-aos="fade-up" data-aos-duration='1000'>Are you in search of<span> Developers?</span></h2>
            <Card />
          </div>
</div>
    <section id="faq" className="faq section-bg">
        <div className="container" data-aos="fade-up" data-aos-duration='1000'>

            <div className="section-title">
                <h2>F.A.Q</h2>
                <h3>Frequently Asked<span> Questions</span></h3>

            </div>

            <div className="row justify-content-center">
                <div className="col-xl-10">
                    <ul className="faq-list">

                        <li>
                            <div data-bs-toggle="collapse" className="collapsed question" href="#faq1">
                                <strong>How to use this UI-UX designer job description template?</strong> <i
                                    className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                            </div>
                            <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                                <p>

                                    You can post it on job boards and websites where people look for work, and it will
                                    be seen there. However, you can also formalize your requirements with the help of
                                    this template and use Alantech to find the best UI/UX engineers for your startu

                                </p>
                            </div>
                        </li>

                        <li>
                            <div data-bs-toggle="collapse" href="#faq2" className="collapsed question"><strong>Is UI/UX a
                                    developer or a designer?</strong> <i className="bi bi-chevron-down icon-show"></i><i
                                    className="bi bi-chevron-up icon-close"></i>
                            </div>
                            <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                                <p>
                                    Actually, both. Sometimes, their work presupposes pure design elements, but most
                                    often, the companies crave UI/UX specialists who can program or at least can read
                                    code.
                                </p>
                            </div>
                        </li>

                        <li>
                            <div data-bs-toggle="collapse" href="#faq3" className="collapsed question"><strong>Does UI/UX
                                    design require coding?</strong> <i className="bi bi-chevron-down icon-show"></i><i
                                    className="bi bi-chevron-up icon-close"></i>
                            </div>
                            <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                                <p>

                                    Not always, not for all the tasks, but sometimes, yes. Proficient UI/UX designers
                                    should understand the code of web pages (if they are checking the pages’ usability).
                                </p>
                            </div>
                        </li>

                        <li>
                            <div data-bs-toggle="collapse" href="#faq4" className="collapsed question"><strong>What’s the
                                    difference between UI and UX design?</strong><i
                                    className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                            </div>
                            <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                                <p>Here are some of the major differences to keep in mind:
                                    UX and UI prototyping is a very different process. For UI Designers, the prototype
                                    is a high-end model. For UX Designers, it is more about logic. The prototype created
                                    by UI Designers is for the clients, and the one made by UX Designers is for the
                                    programmers. In other words, UI is for the front end, while UX is for the back
                                    end.<br/><br/>
                                    Each role has a distinct set of tools. Graphics are a major aspect of UI Design.
                                    Designers will use tools like Principle and Flinto. UX Design, on the other hand, is
                                    more concerned with layout, content, and functionality. They use wireframing
                                    techniques and tools like Balsamiq and Mockplus.<br/><br/>
                                    One focuses on visuals, while the other emphasizes logic. The prototypes created by
                                    UI Designers are usually in full color. However, only three colors: black, grey, and
                                    white, are used by UX Designers. The difference here is since UI Designers tend to
                                    make their prototypes more presentable, while UX Designers focus on the logic rather
                                    than visual appeal.</p>
                                    </div>
                                    </li>



                        <li>
                            <div data-bs-toggle="collapse" href="#faq5" className="collapsed question"><strong>What does a
                                    UX designer do?</strong> <i className="bi bi-chevron-down icon-show"></i><i
                                    className="bi bi-chevron-up icon-close"></i>
                            </div>
                            <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                                <p>
                                    A UI/UX designer designs the user experience for a (digital) product. They focus on
                                    how users interact with and navigate a product and work to create an intuitive and
                                    easy-to-use interface.
                                </p>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>

        </div>
    </section>
    <Footer/>
        </>
    )
}

export default  Ui_ux_designer;