import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Popover from '@mui/material/Popover';
function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const token = localStorage.getItem('token');
  return (
    
    <div>
      <section id="topbar" className="d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <i className="bi bi-envelope d-flex align-items-center"><a
              href="mailto:support@alantechnologies.in">support@alantechnologies.in</a></i>
            <i className="bi bi-phone d-flex align-items-center ms-4"><span>+91 9629998555</span></i>
          </div>
          <div className="social-links d-none d-md-flex align-items-center">
            <a href="https://twitter.com/alantech2023" className="twitter" target="_blank"><i className="bi bi-twitter"></i></a>
            <a href="https://www.facebook.com/alantechnnologies" className="facebook" target="_blank"><i
              className="bi bi-facebook"></i></a>
            <a href="https://www.instagram.com/alan_technologies/?hl=en" className="instagram" target="_blank"><i
              className="bi bi-instagram"></i></a>
          </div>
        </div>
      </section>
      <header id="header" className="d-flex align-items-center">
        <div className="container d-flex justify-content-between">
          <h1 className="logo"><NavLink to="/"><img src={require("../images/ALAN Final logo-01.png")} /></NavLink></h1>
          <nav id="navbar" className="navbar">
            <ul>
              <li><NavLink to="/">Home</NavLink></li>
              <li><NavLink to="/for_devs">For Devs</NavLink></li>
              <li><NavLink to="/apply_devs">Apply As a Dev</NavLink></li>
              <li><NavLink to="/hire">Hire Talent</NavLink></li>
              {token ? (
                <li><NavLink to='/application'>My Profile</NavLink></li>
              ) : (
                <li><NavLink to="/login">Login</NavLink></li>
              )}
            </ul>
            <i className='bi bi-list buttonmob' aria-describedby={id} variant="contained" onClick={handleClick}>
            </i></nav>
        </div>
      </header>
      <Popover className='navbar-items'
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',

        }}
        transformOrigin={{
          vertical: 'top',
        }}>

        <ul>
          <li><NavLink to="/">Home</NavLink></li>
          <li><NavLink to="/for_devs">For Devs</NavLink></li>
          <li><NavLink to="/apply_devs">Apply As a Dev</NavLink></li>
          <li><NavLink to="/hire">Hire Talent</NavLink></li>
          {token ? (
            <li><NavLink to='/application'>My Profile</NavLink></li>
          ) : (
            <li><NavLink to="/login">Login</NavLink></li>
          )}
        </ul>

      </Popover>


    </div>
  )
}

export default Header;