import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { useNavigate, NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import { useFormik } from 'formik';

const loginSchema = Yup.object().shape({
  email: Yup.string().required('Enter the email'),
  password: Yup.string().required('Enter the password'),
});

function AdminLogin() {
  const navigate = useNavigate();


  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [userEmailValid, setUserEmailValid] = useState(false);
  const [userPwd, setUserPwd] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onSubmit = async (e) => {
    setUserPwd(false)
    setUserEmailValid(false)
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/auth2/login`, {
        username: values.email,
        password: values.password,
      }, {
        headers: {
          'Client-service': process.env.REACT_APP_CLIENT_SERVICE,
          "Auth-Key": process.env.REACT_APP_AUTH_KEY
        }
      });
      if (response.status == 200) {
        setLoading(false)
        const token = response.data.token;
        localStorage.setItem('token', token);
        const developers = response.data.developers
        const hire = response.data.hire
        navigate('/dash', { state: { developers: developers, hire: hire } })
      }
      else {
        setLoading(false)
        alert("Fields are not match")
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  }

  const { values, handleChange, errors, touched, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: loginSchema,
    onSubmit,
  })

  useEffect(() => {
    if (values.email.length > 2 && errors.email != undefined) {
      setUserEmailValid(false)
    }
    if (values.password.length >= 0 && errors.password != undefined) {
      setUserPwd(false)
    }
  }, [errors])

  if (loading) {
    return (<div class="spinner2">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    )
  }
  return (
    <div className="wholevalidateemail">
      <form onSubmit={handleSubmit}>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div class="form-container">
            <h1 className="logo text-center"><NavLink to="/"><img src={require("../../images/ALAN Final logo-01.png")} height={"70%"} width={"70%"} /></NavLink></h1>
            <div class="logo-container">Admin Login</div>



            <TextField
              sx={{ m: 1, width: '40ch' }}
              id="outlined-basic"
              label="Enter your email"
              name="email"
              autoFocus
              helperText={errors.email && touched.email ? errors.email : ""}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email) || userEmailValid}
            />



            <FormControl sx={{ m: 1, width: '40ch' }} variant="outlined">
              <TextField
                label="Password"
                name="password"
                type={showPassword ? 'text' : 'password'} // Toggle password visibility
                fullWidth
                helperText={errors.password && touched.password ? errors.password : ""}
                value={values.password}
                error={touched.password && Boolean(errors.password) || userPwd}
                onBlur={handleBlur}
                onChange={handleChange}

                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText sx={{ color: "#d32f2f" }}>{userPwd ? "password is not match" : ""}</FormHelperText>
            </FormControl>


            <button
              class="form-submit-btn"
              type="submit"
            >
              Login
            </button>
          </div>


        </div>
      </form>
    </div>
  );
}

export default AdminLogin;