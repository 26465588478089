import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Card from "../../cards";
import { useNavigate } from 'react-router-dom'


function Back_end() {
    const navigate = useNavigate();
    return (
        <>
            <Header />
            <section id="backend" className="faq heros d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-duration="1000">
                    <h1>
                        BACK-END DEVELOPER <span><br />JOB DESCRIPTION</span>
                    </h1>
                    <br />
                </div>
            </section>
           <div className="greybg">
           <div className="container">
                <div id="back" data-aos="fade-up" data-aos-duration='1000'>
                    <p className="mt-5">
                        Back-end development plays a crucial role in the software performance, functioning as the backstage of any
                        application. It supports and enhances the performance of front-end developers, who are comparable to actors on
                        the stage. In more technical terms, back-end engineers are responsible for crafting the web services and APIs
                        that are indispensable for front-end developers and mobile application developers. The seamless collaboration
                        between these two teams ensures the delivery of a polished and flawless mobile or web application for users to
                        enjoy.
                    </p>

                    <br />
                 
                </div>
                <div id="mediabutton">
                <button className="col-sm-3 btn btn-primary mb-3 shadow-lg "onClick={() => navigate('/hire')}>
                HIRE BACK-END EXPERTS
                    </button>
                </div>
            </div>
           </div>
            <div className="container">
                <div id="potential" data-aos="fade-right" data-aos-duration='1000'>
                    <h2 className="mt-4">Company description</h2>
                    <p>When crafting a job description, it's essential to begin with an accurate and succinct
                        introduction to your company. Back-end engineers, amidst numerous job postings, heavily rely on this information
                        to identify the best potential employers. To capture their interest effectively, take the time to provide
                        comprehensive details about the company, such as the products it offers, the overarching goals or mission
                        statements, the prevailing work culture, and the shared values within the team. Always keep in mind the
                        fundamental question: "Why should a skilled back-end developer choose to join your company?" while composing
                        this section.</p>
                    <h2>Sample:</h2>
                    <p>We are "GreenLi," a rapidly growing HealthTech company with a mission to revolutionize
                        patient-centric healthcare. Our goal is to develop an innovative application that empowers you with an extensive
                        database of healthcare providers in each city, right at your fingertips. With our app, you can quickly and
                        conveniently book doctor appointments without the hassle of phone calls. Our vision is to optimize the
                        healthcare experience, making it more accessible and efficient for everyone.</p>
                    <p>At our company, diversity is celebrated, and we are committed to fostering a
                        judgment-free environment. What's even more exciting is the genuine appreciation our team members have for the
                        multicultural atmosphere we've cultivated. As we experience rapid growth, our work environment thrives on a
                        fast-paced dynamic. We pride ourselves on being pioneers in our field, which means that we may not always have
                        all the answers to emerging challenges. For this reason, we highly encourage a problem-solving mindset and value
                        the input of every team member.</p>
                </div>
            </div>
            <div className="container">
                <div id="Back-end developer" data-aos="fade-up" data-aos-duration='1000'>
                    <h2><span className="blue">Back-end developer </span>job brief </h2>
                    <p>Once you've established your company profile, it's time to create a compelling job
                        brief for a Node.js developer. This brief will not only attract suitable candidates but also provide a clear
                        overview of the job role, responsibilities, and expectations. It will also outline the necessary qualifications
                        and desirable personality traits for a successful fit within the team. Here are the key aspects to include:</p>
                    <br />
                    <h2>Sample:</h2>
                    <p>We are "GreenLi," a fast-growing HealthTech company dedicated to enhancing
                        patient-centric healthcare. Our mission is to optimize healthcare services for patients by developing a
                        groundbreaking application. With our app, you can access an extensive list of healthcare providers in various
                        cities, all at your fingertips. This convenient tool enables you to book doctor appointments swiftly and without
                        the need for phone calls. Our vision is to revolutionize the way patients interact with healthcare, making it
                        more accessible and efficient for everyone.</p>
                    <p>At "GreenLi," diversity is not only celebrated but also actively promoted, fostering a
                        judgment-free and inclusive environment. One of the most remarkable aspects of our workplace is the genuine
                        enjoyment our team members derive from the multicultural atmosphere we have nurtured. As a rapidly growing
                        company, our operations thrive in a fast-paced environment. We pride ourselves on being innovative, which
                        sometimes means facing emerging challenges without all the answers. In such instances, we highly encourage a
                        problem-solving mindset and prioritize giving every team member a voice.</p>
                  
                </div>
                <div id="mediabutton">
                <button className="col-sm-3 btn btn-primary mb-3 shadow-lg"onClick={() => navigate('/hire')}>
                BEST BACK-ENDERS HERE
                    </button>
                </div>
            </div>
            <div className="container">
                <div id="developer requirements" data-aos="fade-left" data-aos-duration='1000'>
                    <h2 className="mt-4"><span className="blue">Back-end developer </span> requirements </h2>
                    <p>To ensure a successful fit for the position of a middle or senior back-end developer
                        at AlanTech, our team has compiled a comprehensive list of required qualifications and skills. The ideal
                        candidate should possess:</p>
                    <h2>Sample:</h2>

                    <p> <span className="text-primary fs-5">✔</span>   A bachelor's degree in computer science or equivalent software programming training;</p>

                        
                    <p><span className="text-primary fs-5">✔</span>  A minimum of four years of experience in back-end development;</p>

                    <p><span className="text-primary fs-5">✔</span>   Proficiency in back-end programming languages such as Ruby, Java, Python, .NET, etc.;</p>

                       
                    <p><span className="text-primary fs-5">✔</span>  Demonstrated knowledge and understanding of modern web technologies, including
                        JavaScript, GraphQL, Flask, Node.js, REST, HTTP basics, and gRPC;</p>

                    <p><span className="text-primary fs-5">✔</span>     Capable of designing APIs that are both maintainable and high-performing;</p>

                     
                    <p><span className="text-primary fs-5">✔</span>  Practical hands-on experience with both SQL and NoSQL databases;</p>

                    <p><span className="text-primary fs-5">✔</span>  Well-versed in designing, building, deploying, monitoring, and maintaining code in
                        both multi-service and monolithic architectures;.</p>

                    <p><span className="text-primary fs-5">✔</span>   Able to prioritize and enhance the user experience in customer-facing applications;</p>

                       
                    <p><span className="text-primary fs-5">✔</span>  Possesses exceptional technical debugging skills;</p>

                    <p><span className="text-primary fs-5">✔</span>                          Familiarity with the fundamental principles of OWASP security.</p>


                </div>
            </div>
            <div className="container">
                <div id="developer responsibilities" data-aos="fade-up" data-aos-duration='1000'>
                    <h2 className="mt-5"><span className="blue">Back-end developer </span>responsibilities </h2>
                    <p>When hiring a full-time back-end developer, it is crucial to effectively communicate
                        our expectations and outline their daily routines. To align with our company's future goals, we have crafted
                        specific and objective tasks to demonstrate the scope of their responsibilities. The following tasks exemplify
                        how the developer's daily routines might look:</p>
                    <h2>Sample:</h2>

                    <p><span className="text-primary fs-5">✔</span>  Engage in application coding and debugging tasks;</p>

                    <p><span className="text-primary fs-5">✔</span>  Develop functional and stable web applications;</p>

                    <p><span className="text-primary fs-5">✔</span>  Establish and effectively communicate technical requirements;</p>

                    <p><span className="text-primary fs-5">✔</span>  Produce clear and concise technical documentation, detailing system design and
                        implementation.</p>

                    <p><span className="text-primary fs-5">✔</span>  Craft high-quality code with future reusability in mind.</p>

                    <p><span className="text-primary fs-5">✔</span>  Take the lead in conducting UI tests and optimizing performance.</p>

                    <p><span className="text-primary fs-5">✔</span>  Stay abreast of new and emerging technologies in the field.</p>

                    <p><span className="text-primary fs-5">✔</span>  Collaborate closely with front-end developers.</p>
                </div>
            </div>
     <div className="greybg">
     <div className="container">
                <div id="Benefits and perks" data-aos="fade-left" data-aos-duration='1000'>
                    <h2 className="mt-5">Benefits and perks</h2>
                    <p className="mt-3">
                        When crafting this section, consider incorporating benefits and perks that align with your company's
                        values and culture while also appealing to potential candidates. For instance, if your company prioritizes
                        work-life balance, you could offer employees a gym or massage membership to support their well-being and
                        overall health. By providing such enriching and inspiring benefits, you create an attractive and
                        supportive work environment that fosters employee happiness and productivity.
                    </p>
                    <h2 className="mt-4">Sample:</h2>
                    <p>At our company, we deeply care about our employees' well-being and work-life balance. To ensure their
                        health and "me-time" are well taken care of, we provide gym and spa memberships. Furthermore, we value the
                        importance of comprehensive health coverage, offering medical insurance to all our team members. Our
                        commitment to work-life balance extends to providing 20 days of paid holidays and seven days of paid sick
                        leave to support our employees' needs.</p>

                    
                </div>
                <div id="mediabutton">
                <button className="col-sm-3 btn btn-primary mb-3 shadow-lg "onClick={() => navigate('/hire')}>
                        Back-End Aces Here!
                    </button>
                </div>
            </div>
     </div>
            <div className="container">
                <div id="simple" data-aos="fade-up" data-aos-duration='1000'>
                    <p className="mt-4 ">We are "GreenLi," a rapidly growing HealthTech company with a mission to revolutionize
                        patient-centric healthcare. Our focus is on optimizing healthcare services by developing an innovative
                        application that puts an extensive list of healthcare providers in various cities right at your
                        fingertips. With our app, you can easily book a visit to your doctor quickly and without the need for
                        phone calls, making healthcare access more efficient and convenient than ever before.<br /><br />
                        At "GreenLi," diversity is at the heart of our values, and we actively foster a non-judgmental and
                        inclusive environment. Our multicultural workplace is a source of pride, and our team members genuinely
                        enjoy the richness it brings to our collective experience. As a rapidly growing company, we operate in a
                        fast-paced environment that encourages continuous improvement and innovation.</p>

                    <p>   We are currently seeking a highly skilled back-end programmer to join our dynamic engineering team. As a
                        key member, your primary focus will be on the server side of our web application. If you possess
                        exceptional programming skills, a genuine passion for application development, and a strong
                        problem-solving mindset, we believe you would be an excellent match for our team.</p>
                    <p>In the role of a back-end software programmer at our company, you will actively collaborate with our
                        engineering and design team to elevate the user experience and ensure system consistency. Your input and
                        expertise will be instrumental in creating seamless and user-friendly applications that align with our
                        goals of providing exceptional services to our users.</p>
                    <p> Over time, we anticipate that you will develop the ability to build and support fully functional web
                        applications that perfectly align with our company's specific needs and expectations. Your growth and
                        expertise will play a crucial role in delivering outstanding products that cater to our users'
                        requirements and contribute significantly to the success of our organization.</p>
                </div>
            </div>
            <div className="container">
                <div id=" qualifications" data-aos="fade-right" data-aos-duration='1000'>
                    <h2 className="mt-4 ">Skills and qualifications: </h2>

                    <p><span className="text-primary fs-5">✔</span>  We require candidates to possess a degree in computer science or equivalent
                        software programming training;</p>

                    <p><span className="text-primary fs-5">✔</span>  Candidates should have a minimum of four years of experience in back-end
                        development;</p>

                    <p><span className="text-primary fs-5">✔</span>  We seek candidates with proficiency in back-end programming languages such as
                        Ruby, Java, Python, .NET, etc.;</p>

                    <p><span className="text-primary fs-5">✔</span>  We are looking for candidates with a strong knowledge and understanding of
                        modern web technologies, including JavaScript, GraphQL, Flask, Node.js, REST, HTTP basics, and gRPC.</p>

                    <p><span className="text-primary fs-5">✔</span>  Candidates should possess the ability to design maintainable and
                        high-performing APIs.</p>

                    <p><span className="text-primary fs-5">✔</span>  Applicants should have hands-on experience working with both SQL and NoSQL
                        databases.</p>

                    <p><span className="text-primary fs-5">✔</span>  We are seeking candidates who are familiar with designing, building, deploying,
                        monitoring, and maintaining code in both multi-service and monolithic architectures.</p>

                    <p><span className="text-primary fs-5">✔</span>  We are looking for candidates with a strong ability to prioritize and focus on
                        user experience in customer-facing applications.</p>


                    <p><span className="text-primary fs-5">✔</span>  Candidates should possess excellent technical debugging skills.</p>

                    <p><span className="text-primary fs-5">✔</span>  Applicants should have knowledge of OWASP security fundamentals.</p>
                </div>
            </div>
        <div className="greybg">
        <div className="container">
                <div id=" coding and debugging" data-aos="fade-up" data-aos-duration='1000'>
                    <h2 className="mt-5">Responsibilities: </h2>
                    <p>When hiring a full-time back-end developer, it is crucial to effectively
                        communicate our expectations and outline their daily routines. To align with our company's future goals,
                        we have crafted specific and objective tasks to demonstrate the scope of their responsibilities. The
                        following tasks exemplify how the developer's daily routines might look:</p>
                    <h2 className="mb-4">Sample:</h2>

                    <p><span className="text-primary fs-5">✔</span>  Responsibilities include working on application coding and debugging.</p>

                    <p><span className="text-primary fs-5">✔</span>  The role involves creating functional and stable web applications.</p>

                    <p><span className="text-primary fs-5">✔</span>  The position requires setting and effectively communicating technical
                        requirements.</p>

                    <p><span className="text-primary fs-5">✔</span>  Candidates must demonstrate the ability to write clear and concise technical
                        documentation on system design and implementation.</p>

                    <p><span className="text-primary fs-5">✔</span>  The ideal candidate should be capable of writing high-quality code that is
                        reusable in the future.</p>

                    <p><span className="text-primary fs-5">✔</span>  The role involves leading UI testing and performance enhancement efforts.</p>

                    <p><span className="text-primary fs-5">✔</span>  The candidate is expected to stay up-to-date with new and emerging technologies.</p>

                        
                    <p><span className="text-primary fs-5">✔</span>  Collaboration with front-end developers is a key aspect of the role.</p>

                    <p className="mt-4">As part of our commitment to employee well-being, we offer gym and spa memberships to
                        promote health and "me-time" for our team. Additionally, we provide comprehensive medical insurance, along
                        with 20 days of paid holidays and seven days of paid sick leave to support our employees' work-life
                        balance.</p>
                </div>
            </div>
        </div>
            <div className="container">
                <div id="devs" >
                    <h2 className="mt-5 mb-4 text-center" data-aos="fade-up" data-aos-duration='1000'>Are you in search of<span> Developers?</span></h2>
                    <Card />
                </div>
            </div>
            <section id="faq" className="faq section-bg">
                <div className="container" data-aos="fade-up" data-aos-duration='1000'>

                    <div className="section-title">
                        <h2>F.A.Q</h2>
                        <h3>Frequently Asked <span>Questions</span></h3>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <ul className="faq-list">

                                <li>
                                    <div data-bs-toggle="collapse" className="collapsed question" href="#faq1">What does a back-end developer do?<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            Back-end developers are responsible for creating and holding the carcass of an application. They build and run back-end operations, write functional codes, and perform quality assurance testing and debugging. Back-end programmers are responsible for server-side web application logic.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq2" className="collapsed question">Which language is used in the back-end?<i className="bi bi-chevron-down icon-show"></i><i
                                        className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq2" className="collapse" data-bs-parent=".faq-list">

                                        <p>  Some of the languages used in back-end programming are:</p>
                                        <p> C#;</p>
                                        <p>  Java;</p>
                                        <p>JavaScript;</p>
                                        <p>  PHP;</p>
                                        <p> Golang;</p>
                                        <p> Python;</p>
                                        <p> Ruby;</p>
                                        <p>  SQL.</p>

                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq3" className="collapsed question">Which is easier, front-end or back-end?<i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                                    </div>
                                    <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            Front-end programming is easier since you can build an entire website with CSS or HTML. Whereas, for back-end web programming, you might need to learn an actual programming language.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq4" className="collapsed question">Which pays more: front-end or back-end? <i className="bi bi-chevron-down icon-show"></i><i
                                        className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            Back-end pays more, with the average pay cut by 1%. However, the pay may vary depending on the country, city, and level of expertise.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq5" className="collapsed question">Which is the fastest back-end language? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                                    </div>
                                    <div id="faq5" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            Python. Python is the simplest and fastest back-end language to learn, thus making it the most used back-end programming language in 2022. Moreover, Python is ideal for complex AI-based projects.
                                        </p>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
        </>
    )
}

export default Back_end