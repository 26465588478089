import React from "react";
import { useNavigate, } from "react-router-dom";
import { useState, useEffect } from "react";
import AdminHeader from "./AdminHeader";
import axios from 'axios';
function Dash() {
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const token = localStorage.getItem('token');
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  useEffect(() => {
    const fetchdeveloper = async () => {
      if (!token) {
        return navigate('/');
      }
      const response = await axios.get(`${process.env.REACT_APP_URL}/developers`, {
        headers: {
          'Client-service': process.env.REACT_APP_CLIENT_SERVICE,
          "Auth-Key": process.env.REACT_APP_AUTH_KEY,
          'User-id': '1',
          'Authorization': token
        }
      })
      if (response.status == 200) {
        const fetchedData = response.data.data.developer;
        setData(fetchedData)
      }
      else {
        console.log('something error')
      }
    }
    fetchdeveloper();
  }, []);

  useEffect(() => {
    const fetchClient = async () => {
      if (!token) {
        return navigate('/');
      }
      const response = await axios.get(`${process.env.REACT_APP_URL}/ClientData`, {
        headers: {
          'Client-service': process.env.REACT_APP_CLIENT_SERVICE,
          "Auth-Key": process.env.REACT_APP_AUTH_KEY,
          'User-id': '1',
          'Authorization': token
        }
      })
      if (response.status == 200) {
        setData2(response.data.data.client)
      }
      else {
        return navigate('/');
      }
    }
    fetchClient();
  }, []);

  return (
    <>
      <AdminHeader />
      <input type="checkbox" id="menu-toggle" />
      <div className="sidebar">
        <div className="side-header">
          <img
            src={require("../../images/ALAN Final logo-01.png")}
            className="pt-2"
          />
        </div>

        <div className="side-content">


          <div className="side-menu ">
            <ul>
              <li>
                <a onClick={() => navigate("/dash")} className="active">
                  <div className="d-flex justify-content-center">
                    <span className="fas fa-home me-2"></span>
                    <small>Dashboard</small>

                  </div>
                </a>
              </li>
              <li>
                <a onClick={() => navigate("/Deve")}>
                  <div className="d-flex justify-content-center">
                    <span className="fas fa-user-alt me-2"></span>
                    <small>Developers</small>
                  </div>
                </a>
              </li>
              <li>
                <a onClick={() => navigate("/Clint")}>
                  <div className="d-flex justify-content-center">
                    <span className="fas fa-envelope me-2"></span>
                    <small>Clients List</small>
                  </div>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>

      <div className="main-content mt-5">
        <main className="dashboard-main">
          <div className="page-header ">
            <h1 className="mt-5">Dashboard</h1>

          </div>

          <div className="page-content">
            <div className="analytics">
              <div className="dashboard-card">
                <div className="card-head">
                  <h2>Developers</h2>
                  {/* <span className="fa fa-eye"></span> */}
                </div>
                <div className="card-progress">
                  <small>Developers Count  - {data.length}</small>
                  <div className="card-indicator">
                    <div className="indicator two"></div>
                  </div>
                </div>
              </div>

              <div className="dashboard-card">
                <div className="card-head">
                  <h2>Clients</h2>
                  {/* <span className="fa fa-shopping-cart"></span> */}
                </div>
                <div className="card-progress">
                  <small>Clients Count - {data2.length}</small>
                  <div className="card-indicator">
                    <div className="indicator three"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </main>
      </div>

    </>
  );
}

export default Dash;
