import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box, Button, Container, Grid, Paper, TextField, Typography
} from "@mui/material";
import { auto } from "@popperjs/core";
import Swal from "sweetalert2";



function HireRequirements({ handleChange, values, errors, touched, handleBlur }) {

  const imgAndName = [
    { name: "TypeScript", imgName: "typescript.png" },
    { name: "Node Js", imgName: "node2.png" },
    { name: "Ruby", imgName: "ruby.png" },
    { name: "React Native", imgName: "atom.png" },
    { name: "Android", imgName: "android.png" },
    { name: "Python", imgName: "python.png" },
    { name: "Flutter", imgName: "flutterlogo.png" },
    { name: "PHP", imgName: "php.png" },
    { name: "IOS", imgName: "ios.png" },
    { name: "Block Chain", imgName: "blockchain.png" },
    { name: "React", imgName: "atom.png" },
    { name: "AWS", imgName: "aws.png" },

  ];

  const software = ["Front-End", "Back-End", "Full-Stack", "Mobile", "Data Scientist", "AI Engineer"]

  const involment = [
    { "weekTime": "40", "workType": "full-time" },
    { "weekTime": "20-35", "workType": "part-time" },
    { "weekTime": "5-19", "workType": "miniumum" },

  ]

  const devPlatform = [
    "Back End Developer",
    "full stack Developer",
    "Mobile App Developer",
    "Manual QA Engineer",
    "Automation Engineer",
    "Data scientist",
    "Data analyst",
    "Data engineer",
    "Machine Learning Engineer",
    "AI Engineer",
    "Devops",
    "Site Reliability Engineer",
    "UI/UX Designer",
    "Product Designer",
    "Game Developer",
    "Magento Developer",
    "Team Lead",
    "Tech Lead",
    "Architect",
    "Block Engineer",
    "Database administer",
    "Cloud Engineer",
    "Other"
  ]

  // react material ui css 
  const container = {
    padding: { lg: "50px" },
    maxWidth: {
      lg: 1100,
      xs: 500,
    },
    height: {
      lg: '100%'
    },
    display: "flex", alignItems: "center"
  }

  const radioBtn = {
    width: { lg: 140, xs: 170, md: 200 },
    textAlign: "center",
    py: { lg: 2, xs: 2 },
    bgcolor: "#f0ecec",
    borderRadius: "10px",
    border: "2px solid white",
    color: "black",
    fontWeight: "bold",
    fontSize: { lg: "17px", xs: "15px" }
  }

  const developerCheckbox = {
    width: { lg: 220, xs: 170, md: 200 },
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    textAlign: "center",
    py: { lg: 1, xs: 1 },
    bgcolor: "#f0ecec",
    borderRadius: "10px",
    border: "2px solid white",
    color: "black",
    fontWeight: "bold",
    fontSize: { lg: "17px", xs: "15px" }
  }


  const [checkedItems, setCheckedItems] = React.useState({});
  const [check, setCheck] = React.useState(true);
  const [btn, setBtn] = useState(true);

  const navigate = useNavigate();

  // success popup
  function SweetAlert() {
    Swal.fire({
      title: "AWESOME!",
      text: "Thank you, we will be in touch within 3 business days",
      imageUrl: require("../../images/home/success.gif"),
      imageHeight: 200,
      imageWidth: 300,
      timer: 5000,
    });
  }


  const client_service = process.env.REACT_APP_CLIENT_SERVICE;
  const Auth_Key = process.env.REACT_APP_AUTH_KEY;
  const URL = process.env.REACT_APP_URL;


  // submit 
  const saveAndContinue = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('fullName', values.fullName)
    formData.append('phoneNumber', values.phoneNumber)
    formData.append('email', values.email)
    formData.append('mainSpecialization', values.mainSpecialization);
    formData.append('mainFocus', values.mainFocus);
    formData.append('greatAt', values.greatAt);
    formData.append('involment', values.involment);
    formData.append('description', values.description);
    if (Object.keys(errors).length == 0 && Object.keys(checkedItems).length > 0) {
      axios
        .post(`${URL}/ClientData`, formData, {
          headers: {
            'Client-service': client_service,
            "Auth-Key": Auth_Key
          }
        })
        .then((response) => {
          SweetAlert();
          setTimeout(() => {
            navigate("/")
          }, 5000)
        })
        .catch((error) => {
          console.error('Error saving Developer data:', error);
        });
    }
  };


  // checkbox data store 
  const handleChangeCheckbox = (event) => {
    const { name, checked } = event.target;
    if (checked == true) {
      setCheckedItems((prevCheckedItems) => ({
        ...prevCheckedItems,
        [name]: checked
      }));
    }
    else {
      setCheckedItems((prev) => {
        const updatedCheckedItems = Object.keys(prev).reduce((acc, key) => {
          if (key !== name) {
            acc[key] = prev[key];
          }
          return acc;
        }, {});
        return updatedCheckedItems;
      });
    }
  };




  React.useEffect(() => {
    const sam = Object.keys(checkedItems)
    values.greatAt = sam
    if ((values.mainSpecialization && values.mainFocus && values.greatAt.length > 0 && values.involment)) {
      setBtn(false)
    } else {
      setBtn(true)
    }
    if (sam.length > 0) {
      setCheck(false)
    }
  }, [checkedItems, values])


  return (
    <>
      <Box >
        <Container sx={container} >

          <Paper elevation={5} sx={{
            p: {
              lg: "30px",
              xs: "20px",
              md: "20px"
            }
          }}>

            <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
              <img src={require("../../images/ALAN Final logo-01.png")} height={"40%"} width={"40%"} />
            </Box>

            <Box sx={{ textAlign: "center" }}>
              <h3 >SHARE YOUR NEEDS</h3>
            </Box>


            <form onSubmit={saveAndContinue} autoComplete="off">

              <Grid container spacing={4}>

                {/* main specialization => menu item*/}
                <Grid item xs={12}>

                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2">TELL US YOUR REQUIREMENTS</Typography>
                  </Box>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Main Specialization"
                    name="mainSpecialization"
                    fullWidth
                    autoFocus

                    value={values.mainSpecialization}
                    helperText={errors.mainSpecialization && touched.mainSpecialization ? errors.mainSpecialization : ""}
                    error={touched.mainSpecialization && Boolean(errors.mainSpecialization)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {devPlatform.map((dev) => (
                      <MenuItem key={dev} value={dev}>
                        {dev}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* developer main focus => radio button */}
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ mb: 2 }}>YOUR DEVELOPERS MAIN FOCUS</Typography>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="mainFocus"
                      value={values.mainFocus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <Grid container spacing={1}>
                        {software.map((data) => (
                          <Grid item lg={2} xs={6} >

                            <FormControlLabel value={data}
                              control={<Radio
                                icon={
                                  <Box sx={[radioBtn,
                                    {
                                      "&:hover": {
                                        border: "2px solid #294b86",
                                        bgcolor: "#294b86",
                                        color: "white"
                                      }
                                    }
                                  ]}>
                                    {data}
                                  </Box>
                                }
                                checkedIcon={
                                  <Box sx={[radioBtn,
                                    {
                                      border: "2px solid #294b86",
                                      bgcolor: "#294b86",
                                      color: "white"
                                    }]}>
                                    {data}
                                  </Box>
                                }
                              />
                              } />
                          </Grid>
                        ))}
                      </Grid>
                    </RadioGroup>
                    <FormHelperText sx={{ color: "red" }}>{errors.mainFocus}</FormHelperText>
                  </FormControl>

                </Grid>

                {/* developer Great at => checkbox */}
                <Grid item xs={12} >
                  <Typography variant="subtitle2" >YOUR DEVELOPERS SHOULD BE GREAT AT (Select atleast two)</Typography>
                  <FormGroup>
                    <Grid container spacing={0}>
                      {imgAndName.map((data) => (
                        <Grid item xs={6} lg={3}>
                          <FormControlLabel

                            control={<Checkbox checked={checkedItems[data.name] || false}
                              onChange={handleChangeCheckbox} name={data.name}
                              icon={
                                <Box sx={[developerCheckbox]}>
                                  <img src={require(`../../components/buttonimages/${data.imgName}`)} width="25%" height="50px" style={{ padding: "5px" }} />
                                  <span>{data.name}</span>
                                </Box>
                              }
                              checkedIcon={
                                <Box sx={[developerCheckbox,
                                  {
                                    border: "2px solid #294b86",
                                    bgcolor: "#294b86",
                                    color: "white",
                                  }
                                ]}>
                                  <img src={require(`../../components/buttonimages/${data.imgName}`)} width="25%" height="50px" style={{ padding: "5px" }} />
                                  <span>{data.name}</span>
                                </Box>
                              }

                            />}

                          />

                        </Grid>


                      ))}
                      <FormHelperText sx={{ color: "red" }}>{errors.greatAt}</FormHelperText>
                    </Grid>
                    <FormHelperText sx={{ color: "red" }}>{check ? "Select a checkbox" : ""}</FormHelperText>
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>

                  <Typography variant="subtitle2" sx={{ mb: 2 }}>WHAT'S THE EXPECTED LEVEL OF INVOLVEMENT?</Typography>
                  <FormControl>
                    <RadioGroup

                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="involment"
                      value={values.involment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <Grid container spacing={{ lg: 2, xs: 0 }}>

                        {involment.map((data) => (
                          <Grid item xs={4} lg={4}>

                            <FormControlLabel value={data.weekTime + " " + data.workType} control={<Radio

                              icon={

                                <Box sx={[developerCheckbox,
                                  {
                                    width: { lg: auto, xs: auto },
                                    display: { lg: "flex", xs: "block" }

                                  }]}>
                                  <img
                                    src={require("../../components/buttonimages/clockhire.png")}
                                    width="20%"
                                  />
                                  <Box sx={{ fontSize: { lg: "16px", xs: "12px" }, lineHeight: 1.5, marginTop: { xs: 1 } }}>
                                    <p style={{ marginBottom: 0 }}>{data.weekTime} HOURS/WEEK</p>
                                    <span>{data.workType}</span>
                                  </Box>
                                </Box>
                              }
                              checkedIcon={
                                <Box sx={[developerCheckbox,
                                  {
                                    width: { lg: auto, xs: auto },
                                    display: { lg: "flex", xs: "block" },
                                    border: "2px solid #294b86",
                                    bgcolor: "#294b86",
                                    color: "white",
                                  }
                                ]}>
                                  <img
                                    src={require("../../components/buttonimages/clockhire.png")}
                                    width="20%"
                                  />
                                  <Box sx={{ fontSize: { lg: "16px", xs: "12px" }, lineHeight: 1.5, marginTop: { xs: 1 } }}>
                                    <p style={{ marginBottom: 0 }}>{data.weekTime} HOURS/WEEK</p>
                                    <span>{data.workType}</span>
                                  </Box>
                                </Box>
                              }
                            />} />
                          </Grid>
                        ))}
                      </Grid>
                    </RadioGroup>
                    <FormHelperText sx={{ color: "red" }}>{errors.involment}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Project Description"
                    name="description"
                    fullWidth
                    multiline
                    rows={3}  // Set the number of rows
                    helperText={errors.description && touched.description ? errors.description : ""}
                    autoFocus
                    value={values.description}
                    error={touched.description && Boolean(errors.description)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>


                <Grid item xs={12}>

                  <Box >
                    <Button disabled={btn} type="submit" fullWidth sx={{ py: 1, bgcolor: " #294b86 !important", color: "white" }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>

              </Grid>


            </form>

          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default HireRequirements;