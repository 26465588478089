import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import { useNavigate } from 'react-router-dom'
import Buttons from '../../buttons'

function Hire_php() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <section id="hireephp" className="d-flex align-items-center">
        <div className="container" data-aos="zoom-out" data-aos-duration="1000">
          <h1>HIRE PHP<span>DEVELOPERS</span></h1>
          <br />

        </div>
      </section>
      <div className="container mt-5" data-aos="fade-up" >
        <p>
          You made the PHP choice that suits your project best — but the top-notch PHP developers for hire have been elusive like eels? Catch the top talents at AlanTech.</p>
          <div id='mediabutton'>
          <button className="col-sm-3  btn btn-primary mb-5 shadow-lg mt-3" onClick={() => navigate('/hire')}>
            HIRE OUR MIGHTY DEV
          </button>

        </div>
        <p >As per Stack Overflow's data, PHP holds a position within the top 10 most widely used programming languages globally (and within the top 5 according to GitHub). It surpasses the demand for Java and Python, demonstrating its significant popularity. This language has powered the development of major platforms such as Facebook, WordPress, Magento, and various other essential programs and services that shape our daily digital experiences. Hence, your choice to employ a PHP programmer is undoubtedly well-founded.</p>
        <div className="row">

          <div className="choicethat col-12 col-lg-6" data-aos="fade-right" data-aos-duration="1000">


            <h2 className="mt-5">Why PHP?</h2>
            <p className="mt-3"><i class="bi bi-check-lg"></i>  it’s easy to learn and simple to work with;</p>
            <p className="mt-4"><i class="bi bi-check-lg"></i>  it’s an OOP (object-oriented programming) language;</p>
            <p className="mt-4"><i class="bi bi-check-lg"></i>  it’s an open-source language, which means PHP is free and accessible by anyone;</p>
            <p className="mt-4"><i class="bi bi-check-lg"></i>  it performs well on almost any platform;</p>
            <p className="mt-4"><i class="bi bi-check-lg"></i>  it’s flexible and develops constantly (the large community of developers creates all kinds of add-ons and extensions to it regularly);</p>

          </div>
          <div className="smartphoneeress col-12 col-lg-6 mt-4" data-aos="fade-left" data-aos-duration="1000">
            <img className="img-responsive" src={(require('../../../images/hire_devs/gif4.gif'))} width="100%" height="100%" alt="" />
          </div>
        </div>
      </div>
      <div className="container  mt-5">
        <div className="findyo" data-aos="fade-up">
          <h2 className="mt-5">How to Find <span>PHP Developers?</span></h2>
          <p className="mt-4">In the following sections, we will explore various platforms that facilitate the hiring of PHP developers. However, for the present moment, let's center our attention on the essential steps to undertake and key considerations to bear in mind during the recruitment process. Additionally, we will delve into common errors that individuals often encounter when seeking to hire PHP programmers.  </p>
          <h2 className="mt-3"><span>PHP Development</span> Company vs. Remote Development</h2>
          <h2 className="mt-4">What suits you best</h2>
          <p className="mt-4">Based on the specifics of your project, your projected budget, deadlines, and other relevant factors, you could be confronted with the decision of whether to opt for remote developers or engage a development agency. Both options come with their own advantages and disadvantages, which are thoroughly discussed in our article titled "Freelancers vs. Software Development Companies: Choosing the Right Fit for Your Needs." For now, let's focus on the essential information you require to effortlessly locate PHP developers.</p>
        </div>
      </div>
      <div className="container  mt-1" data-aos="fade-up" >
        <h2 className="mt-5">Hire<span> PHP Development</span> Companies</h2>


        <p className="mt-4">In essence, the choice to engage a development company arises when you're in need of a completely new website or web application. While opting for a full-time remote team also holds its advantages, there are distinct benefits to partnering with a development company. However, if your preference leans towards selecting a development company for any specific reason, here are key factors you should take into account.</p>


      </div>

      <section id="anwar" className="aboutphp">
        <div className="container py-5">

          <div className="row content">
            <div className="col-lg-6 " data-aos="fade-right" data-aos-duration="1000">
              <div className="section-title">
                <h2>PROS</h2>
              </div>



              <p className="mt-4"><i className="bi bi-check2-all"></i> They usually provide a full cycle of development, from site layouts to the maintenance of a finished website</p>
              <p className="mt-4"><i className="bi bi-check2-all"></i> There is a large team working on your project. Managers, coders, web designers, testers, QA specialists – each PHP web programmer does their best to meet your requirements</p>
              <p className="mt-4"><i className="bi bi-check2-all"></i> You can expect support and troubleshooting throughout the whole course of the development process</p>
              <p className="mt-4"><i className="bi bi-check2-all"></i>  Making slight alterations to a project in progress might seem complex. However, development companies' larger structure ensures a thorough approach to changes. Their methodical coordination and reorganization guarantee that modifications align with the project's overarching vision.</p>

            </div>
            <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left" data-aos-duration="1000">
              <div className="section-title ">
                <h2>Cons</h2>
              </div>
              <p className="mt-4"><i className="bi bi-x-circle"></i> Costly! Consider the multitude of resources a company allocates for hiring in-house personnel, covering expenses like taxes, salaries, insurance, office rent, and more. Whose wallet do you think a development company will tap into to offset these overheads? (Hint: Yours!)</p>
              <p className="mt-4"><i className="bi bi-x-circle"></i> Ownership rights might seem straightforward – you pay, you own the end product. However, the reality isn't always so simple. Development companies frequently boast a team of legal experts who can adeptly explain why this isn't always the case.</p>
              <p className="mt-4"><i className="bi bi-x-circle"></i> Your influence over the development process is often limited. Naturally, they're the experts in this domain, not you. Nonetheless, there are instances when having complete access to the "workshop," so to speak, can be advantageous.</p>

            </div>
          </div>

        </div>
      </section>

      <div className="container mt-4">
        <h2 className="mt-2" data-aos="fade-up">Advantages of <span>Remote PHP Developers</span></h2>
        <p data-aos="fade-up">The reality is that you can manage website development solely with remote developers, without the need for intermediary companies. As long as these experts possess the necessary skills and experience, and you handle their management effectively, outsourcing directly to individual contractors is a viable option.</p>
        <div className="row">
          <div className="col-lg-6 " data-aos="fade-right" data-aos-duration="1000">
            <div className="section-title">
              <h2 className="mt-5">PROS</h2>
            </div>



            <p className="mt-4"><i className="bi bi-check2-all"></i> They usually provide a full cycle of development, from site layouts to the maintenance of a finished website</p>
            <p ><i className="bi bi-check2-all"></i> There is a large team working on your project. Managers, coders, web designers, testers, QA specialists – each PHP web programmer does their best to meet your requirements</p>
            <p className="mt-4"><i className="bi bi-check2-all"></i> You can expect support and troubleshooting throughout the whole course of the development process</p>
            <p className="mt-4"><i className="bi bi-check2-all"></i>  Making slight alterations to a project in progress might seem complex. However, development companies' larger structure ensures a thorough approach to changes. Their methodical coordination and reorganization guarantee that modifications align with the project's overarching vision.</p>

          </div>
          <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left" data-aos-duration="1000">
            <div className="section-title">
              <h2 className="mt-5">Cons</h2>
            </div>
            <p className="mt-4"><i className="bi bi-x-circle"></i> Costly! Consider the multitude of resources a company allocates for hiring in-house personnel, covering expenses like taxes, salaries, insurance, office rent, and more. Whose wallet do you think a development company will tap into to offset these overheads? (Hint: Yours!)</p>
            <p className="mt-4"><i className="bi bi-x-circle"></i> Ownership rights might seem straightforward – you pay, you own the end product. However, the reality isn't always so simple. Development companies frequently boast a team of legal experts who can adeptly explain why this isn't always the case.</p>
            <p className="mt-4"><i className="bi bi-x-circle"></i> Your influence over the development process is often limited. Naturally, they're the experts in this domain, not you. Nonetheless, there are instances when having complete access to the "workshop," so to speak, can be advantageous.</p>

          </div>
        </div>
      </div>


      <div className="why-us" >
        <div className="container-fluid " data-aos="fade-right" data-aos-animation="1000">

          <div className="row">

            <div className="col-lg-6  order-2 order-lg-1">

              <div className=" content">
                <h2 className="justify-content-center "> Why Hire <strong>PHP Programmers From AlanTech</strong></h2>
              </div>

              <div className="accordion-list mx-3 col-12">
                <ul>
                  <li>
                    <a data-bs-toggle="collapse" className="collapse" data-bs-target="#accordion-list-1"><span>01</span>Only vetted professionals.  <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                    <div id="accordion-list-1" className="collapse" data-bs-parent=".accordion-list">
                      <p>
                        We have developed a four-step vetting procedure that allows us to add to our talent pool and your team only the best PHP professionals out there.
                      </p>
                    </div>
                  </li>

                  <li>
                    <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed"><span>02</span> Agile workflow <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                    <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                      <p>
                        We value productivity. And just like it is hard to swallow big chunks of food, it is hard to accomplish huge tasks. Therefore, we are using the Jira application in order to split big projects into smaller manageable tasks;
                      </p>
                    </div>
                  </li>

                  <li>
                    <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed"><span>03</span>Custom matching. <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                    <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                      <p>
                        Not only custom, hand-picked matching but also a fast one. Give us 48 hours after your request, and we will match you with the first candidate;
                      </p>
                    </div>
                  </li>

                  <li>
                    <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4" className="collapsed"><span>04</span>Minimal burerecraucy. <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                    <div id="accordion-list-4" className="collapse" data-bs-parent=".accordion-list">
                      <p>
                        Not only custom, hand-picked matching but also a fast one. Give us 48 hours after your request, and we will match you with the first candidate;
                      </p>
                    </div>
                  </li>

                  <li>
                    <a data-bs-toggle="collapse" data-bs-target="#accordion-list-5" className="collapsed"><span>05</span>AlanTech application <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                    <div id="accordion-list-5" className="collapse" data-bs-parent=".accordion-list">
                      <p>
                        Once you begin working with a PHP developer, you get access to an application that allows you to track what tasks were completed, when and how much time did it take a developer to do so;
                      </p>
                    </div>
                  </li>




                </ul>
              </div>

            </div>

            <div className="col-lg-6 align-items-stretch order-1 order-lg-2  mt-5" data-aos="fade-left" data-aos-duration="1000">
              <img className="img-responsive" src={(require('../../../images/hire_devs/new2.avif'))} width="80%" height="80%" data-aos="zoom-in" data-aos-delay="150" /> &nbsp;
            </div>
          </div>

        </div>
      </div>
      <div className="Onlyvetteder">
        <div className="container">
          <div className="row">
            <div className="Onlyvetted" data-aos="fade-right" data-aos-duration="1000">
              <h2 className="mt-"> Why <span>Hire PHP Programmers </span>From AlanTech?</h2>
              <p className="mt-5"><i className="bi bi-arrow-right-circle-fill"></i> <strong>Agile workflow</strong> .We have developed a four-step vetting procedure that allows us to add to our talent pool and your team only the best PHP professionals out there. </p>
              <p className="mt-5"><i className="bi bi-arrow-right-circle-fill"></i> <strong>Custom matching</strong> .We value productivity. And just like it is hard to swallow big chunks of food, it is hard to accomplish huge tasks. Therefore, we are using the Jira application in order to split big projects into smaller manageable tasks;  </p>
              <p className="mt-5"><i className="bi bi-arrow-right-circle-fill"></i> <strong>Minimal burerecraucy</strong> .Not only custom, hand-picked matching but also a fast one. Give us 48 hours after your request, and we will match you with the first candidate; </p>
              <p className="mt-5"><i className="bi bi-arrow-right-circle-fill"></i> <strong>NDA in place</strong> .At AlanTech, we know how eager you are to implement your ideas into reality. With us, there is no need to sign tens of papers to begin working with your PHP dev unless decided otherwise; </p>
              <p className="mt-5"><i className="bi bi-arrow-right-circle-fill"></i> <strong>AlanTech application</strong> .We value transparency, therefore, we have an NDA. Once all the payments are complete, you are the one and only owner of the code; </p>
              <p className="mt-5"> <i className="bi bi-arrow-right-circle-fill"></i> <strong>Flexible hiring</strong> .Once you begin working with a PHP developer, you get access to an application that allows you to track what tasks were completed, when and how much time did it take a developer to do so;  </p>
              <p className="mt-5"><i className="bi bi-arrow-right-circle-fill"></i> <strong>Big talent pool</strong> .There is no difference to us if you are looking for a part-time or full-time dev. We will match you with the best we got! </p>
</div>
            </div>
          </div>
        </div>
        <div className="container  mt-">
          <div className="row">
            <div className="setupanress col-12 col-lg-6 mt-5" data-aos="fade-right" data-aos-duration="1000">
              <img className="img-responsive" src={(require('../../../images/hire_devs/new20.gif'))} width="90%" height="90%" alt="" />
            </div>
            <div className="setupan col-12 col-lg-6" data-aos="fade-left" data-aos-duration="1000">
              <h2 className="mt-5">How To<span> Hire PHP Developers</span> Through AlanTech?</h2>
              <p className="mt-4">The process is straightforward. It commences by completing a form on our website. After a thorough review, one of our Sales representatives will reach out via email to arrange an interview. This communication typically occurs within a span of a few hours to two days.</p>

              <p className="mt-4">During the interview, which generally lasts between 25 to 45 minutes, a member of our Sales team will delve into the specifics of your project's requisites and timelines. Should both parties decide favorably to proceed after the interview, our Matching team takes charge.</p>

              <p className="mt-4">As you might have guessed, AlanTech's Matching team is entrusted with identifying suitable PHP candidates who align with all your prerequisites. Within 48 hours, you'll receive the profile of the initial candidate, and arrangements will be made for a second interview.</p>
            </div>

          </div>
        </div>
        <div className="container-fluid  mt-5">
          <div className="Onlyvetted" data-aos="fade-up">


          </div>
        </div>
        <div className="container  mt-">
          <div className="row">
            <h2 data-aos="fade-up">How Much Does It Cost To <span>Hire Full-Time or Part-Time PHP Developers?</span></h2>
            <div className="col-12 col-lg-6" data-aos="fade-right" data-aos-duration="1000">
              <p className="mt-">Sometimes it seems that fooling the system is easy. If you want to hire a PHP specialist, India might look like a perfect option, in this regard. Why?</p>


              <p className="mt-4">Salary! This is why companies and startups consider hiring Indian developers in the first place. Indeed, the yearly income of a PHP programmer from India is often a dozen times lower than that of their American or European colleagues. In 2021, an average yearly income of an Indian PHP developer is about $6,100.</p>
              <p>Cost Savings: As you mentioned, the average yearly income of a PHP developer in India is significantly lower than that of their American or European counterparts. This cost differential allows companies to reduce their operational expenses, making it an attractive option for startups with limited budgets or established companies looking to optimize their development costs.</p>
            </div>
            <div className="col-12 col-lg-6" data-aos="fade-left" data-aos-duration="1000">
              <div className="text-center mt-">
                <img className="img-responsive" src={(require('../../../images/hire_devs/gif2.jpg'))} width="80%" height="80%" />
              </div>
            </div>


          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <h2 className="mt-2">In Los Angeles, a <span>PHP Developer</span> Will Count on At Least $80,000 Per Year. </h2>
            <div className="col-12 col-lg-6" data-aos="fade-right" data-aos-duration="1000">
              <div className="mt-5 text-center">
                <img className="img-responsive" src={(require('../../../images/hire_devs/gif13.gif'))} width="80%" height="80%" />
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-5" data-aos="fade-left" data-aos-duration="1000">
              <p>Los Angeles, often referred to as the entertainment capital of the world, is not just home to Hollywood stars and beautiful beaches. It's also a hotbed for technology and innovation, making it an attractive destination for IT professionals, including PHP developers.</p>
              <p> In the City of Angels, PHP developers can count on earning a minimum of $80,000 per year. Let's delve into why the job market for PHP developers is thriving in Los Angeles.</p>
<p>Los Angeles is home to a diverse range of industries, including entertainment, technology, e-commerce, healthcare, and finance. PHP is a versatile programming language that can be used in various sectors, making PHP developers in high demand across multiple industries.</p>
<p>Los Angeles has a thriving start-up ecosystem, with many emerging tech companies choosing the city as their headquarters. Start-ups often rely on PHP to build their web applications due</p>
            </div>

          </div>
        </div>
        <div className="container  mt-5">
          <div className="row">
            <div className="areprettyers col-12 col-lg-6 mt-5 " data-aos="fade-right" data-aos-duration="1000">
              <h2>Efficient <span>Alternatives</span></h2>
              <p className="mt-5 d-flex"> <img className="img-responsive" src={(require('../../../images/hire_devs/character_2-07.png'))} width="13%" height="10%" data-aos="zoom-in" data-aos-delay="150" /> <span className="mx-4">So, Indian developers are more affordable, but the risks of running into an underskilled coder are pretty high. On the other hand, mature senior pros from the United States or Europe cost much, much more. Not every startup or new business can afford to hire PHP programmers in the West.</span></p>

              <p className="mt-5 d-flex"><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-08.png'))} width="13%" height="10%" data-aos="zoom-in" data-aos-delay="150" /> <span className="mx-4 mt-3">So, what can you do about it?</span></p>

              <p className="mt-5 d-flex "><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-09.png'))} width="13%" height="10%" data-aos="zoom-in" data-aos-delay="150" /> <span className="mx-4">If you haven’t paid attention to Eastern Europe – Ukraine, in particular – it could be just about time to do so. PHP developers from this region gain popularity around the world year to year. You can read more about why it is so in our article, “Top-7 reasons for you to hire Ukrainian developers.”</span></p>

              <p className="mt-5 d-flex"><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-06.png'))} width="13%" height="10%" data-aos="zoom-in" data-aos-delay="150" /><span className="mx-4 mt-2"> Here, we’ll only mention the average annual salary of a Ukrainian PHP developer, which is about $26,400, or $2,200 per month.</span></p>
            </div>
            <div className="areprettyersed col-12 col-lg-6 mt-5 " data-aos="fade-left" data-aos-duration="1000">
              <h2 className="ms-5 d-flex">The cost to hire a <span>PHP developer</span>?</h2>
              <p className="mt-5  d-flex"><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-11.png'))} width="13%" height="10%" data-aos="zoom-in" data-aos-delay="150" /> <span className="mx-4 mt-2">You can hire strong middle-level PHP experts through AlanTech from 40 to 45 USD per hour. If you aim for senior-level PHP professionals, you can find them for 55 to 60 USD an hour. CTO and Tech Lead level programmers could be matched with you for around 65 USD an hour.</span></p>

              <p className="mt-5  d-flex"><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-12.png'))} width="13%" height="10%" data-aos="zoom-in" data-aos-delay="150" /> <span className="mx-4 mt-3">So, what can you do about it?</span></p>

              <p className="mt-5  d-flex"><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-13.png'))} width="13%" height="10%" data-aos="zoom-in" data-aos-delay="150" /> <span className="mx-4">Indeed, sourcing skilled and cost-effective PHP developers, particularly for remote positions, can be a challenging endeavor. However, AlanTech is here to simplify this process for you.you can focus on achieving your project goals without the stress of the hiring process.</span> </p>

              <p className="mt-5  d-flex"><img className="img-responsive" src={(require('../../../images/hire_devs/character_2-14.png'))} width="13%" height="10%" data-aos="zoom-in" data-aos-delay="150" /> <span className="mx-4">AlanTech = (good prices + high quality) x hand-picked remote PHP developers.</span> </p>
            </div>
          </div>
        </div>

        <section id="faq" className="faq section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2 >F.A.Q</h2>


            </div>
            <h2 className='text-center '>Frequently Asked<span> Questions</span></h2>
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <ul className="faq-list">

                  <li>
                    <div data-bs-toggle="collapse" className="mt-5 collapsed question" href="#faq1">Why hire PHP developers?  <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                      <p>

                        PHP is one of the most popular programming languages nowadays — despite the fact that it’s far from new. Some things just remain with us for years and decades, and PHP is definitely one of those things. PHP is the language that will help you with a variety of applications. The TIOBE (The Importance of Being Earnest) index places PHP in the top 10 programming languages — and the situation remains consequent for at least a decade.

                        Here’s the list of main PHP advantages:
                        It’s easy to master and operate (intuitive syntax, less time for development);
                        It’s compact (compatible with the majority of web platforms and systems);
                        It’s open-source (hence, it’s free to use, and it has a vast encouraging community of skilled masters who’ll eagerly help the novices);
                        It’s incredibly flexible (many software environments are suitable for PHP work, plus the developers are free to add any HTML tags to their code).

                      </p>
                    </div>
                  </li>

                  <li>
                    <div data-bs-toggle="collapse" href="#faq2" className="collapsed question">How to become a PHP developer?<i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq2" className="collapse" data-bs-parent=".faq-list">
                      <p>

                        Let’s skip the first, the banalest stage of the process — the one where you study some IT-related course(s) or graduate from the informatics program. It’s obvious that you should gain some preliminary practical knowledge of the subject matter. Afterward, it’s time to get the job, right? How do IT professionals get their jobs nowadays? There are three main ways of acting. The first one is to get a full-time job and work 9 to 5 or even 9 to 6 — with a strictly outlined schedule, regular salary, and pre-planned self-development. For someone, it’s the dream option. For those who decline such boring life, there are a few more! If you want to get the job of a freelance PHP developer and be the master of your own time, then it’s due season to address bidding or vetting marketplaces. At bidding marketplaces (Freelancer, Gigster, Upwork), you propose your price for the open projects and do them if your bid wins. At bidding platforms, there is no pre-check mechanism, so they’re open to everyone, even those having no experience
                      </p>
                    </div>
                  </li>

                  <li>
                    <div data-bs-toggle="collapse" href="#faq3" className="collapsed question">What should PHP developers know? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i>
                    </div>
                    <div id="faq3" className="collapse" data-bs-parent=".faq-list">
                      <p>
                        The desired skillset of a talented PHP developer is the skillset of any proficient developer plus the specific PHP-related dexterities that lead to a clear functional code. The average programmer should be able to analyze the general requirements of the web page, introduce database solutions and amend back-end code to enhance productivity and performance. PHP developers also require this kind of knowledge — however, in addition to it, they also have to be masters of PHP-specific web frameworks: Laravel, CodeIgniter, or the like. Besides, PHP masters should know object-oriented programming like the back of their hands.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div data-bs-toggle="collapse" href="#faq4" className="collapsed question">Are PHP developers in demand now?   <i className="bi bi-chevron-down icon-show"></i><i
                      className="bi bi-chevron-up icon-close"></i></div>
                    <div id="faq4" className="collapse" data-bs-parent=".faq-list">
                      <p>
                        As the web application boom continues, PHP developers are enjoying their share of deserved popularity. What currently (or constantly) popular things can you do with PHP?
                        Generate dynamic web page content;
                        Programming GUIs (graphical user interfaces). The most common examples are web browsers and Microsoft programs where PC users communicate with programs (back-end side) via icons and buttons.
                        Command line script writing;
                        Extreme HTML compatibility (you can write HTML code within PHP code).
                        That’s quite a lot. No wonder PHP developers are in great demand now!
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

          </div >
        </section >

        <Buttons />
        <Footer />
      </>
      )
}
      export default Hire_php