import React, { useState, useRef, useEffect } from "react";
import {
  Box, Button, Container, Grid, Paper, TextField, Typography
} from "@mui/material";
import axios from "axios";
import FormHelperText from '@mui/material/FormHelperText';
import DemoVideo from '../../video/infoVideo.mp4'
function HirePersonalDetails({ nextStep, handleChange, values, errors, touched, handleBlur }) {
  const container = {
    maxWidth: {
      lg: 500,
      xs: 400,
    },
    height: {
      lg: '100vh',
      md: '100vh',
      xs: '600px'
    },
    display: "flex", alignItems: "center"
  }

  const [emailValid, setEmailValid] = React.useState(false);
  const [btn, setBtn] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    if ((values.fullName.length > 3 && values.email.length > 3) && (errors.email == undefined && errors.fullName == undefined)) {
        return nextStep();
    }
  }


  React.useEffect(() => {
    if ((values.email && values.fullName) && (errors.email === undefined && errors.fullName == undefined)) {
      setBtn(false)
    } else {
      setBtn(true)
    }
    if (values.email.length > 2 && errors.email != undefined) {
      setEmailValid(false)
    }
  }, [errors.email, values])

  return (
    <>
    .<div class="row align-items-center mx-5">

      <div class="col-lg-6">
      <video controls width="100%" height="100%" autoPlay>
                <source src={DemoVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
      </div>
      <div class="col-lg-6">
      <Box >
    
    <Container sx={container} >
      <Paper elevation={5} sx={{
        p: {
          lg: "40px",
          xs: "20px", 
          md: "20px"
        }
      }}>

        <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
          <img src={require("../../images/ALAN Final logo-01.png")} height={"40%"} width={"60%"} />
        </Box>

        <Typography variant="h6" sx={{ textAlign: "center", my: 2 }}>
          LET'S FIND YOU A DEV
        </Typography>
        <form onSubmit={handleSubmit} autoComplete="off">

          <Grid container spacing={4}>

            <Grid item xs={12}>
              <TextField
                label="Enter Your FullName"
                name="fullName"
                fullWidth
                helperText={errors.fullName && touched.fullName ? errors.fullName : ""}
                autoFocus
                value={values.fullName}
                error={touched.fullName && Boolean(errors.fullName)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Mobile number"
                name="phoneNumber"
                fullWidth
                helperText={errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : ""}
                value={values.phoneNumber}
                error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                fullWidth
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.email && touched.email ? errors.email : ""}
                error={touched.email && Boolean(errors.email) || emailValid}
              />
              <FormHelperText sx={{ color: "#d32f2f" }}>{emailValid ? "email already exist" : ""}</FormHelperText>
            </Grid>

            {/* next button */}
            <Grid item xs={12} >
              <Button type="submit" fullWidth sx={{ py: 1, bgcolor: " #294b86 !important", color: "white" }} disabled={btn}>Next</Button>
            </Grid>

          </Grid>
        </form>

      </Paper>
    </Container>
  </Box>

      </div>
    </div>
    
     
    </>
  );
}


export default HirePersonalDetails;
