import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import { useNavigate } from 'react-router-dom'
import Buttons from '../../buttons'

function Hire_frontend() {
    const navigate = useNavigate();
    return (
        <>
            <Header />
            <section id="hireefrontend" className="d-flex align-items-center">
                <div className="container col-12" data-aos="zoom-out" data-aos-duration="1000">
                    <h1>HIRE FRONT-END<span> DEVELOPERS, NO SWEAT</span></h1>


                </div>

            </section>

            <div className="container mt-4" data-aos="fade-up" data-aos-duration="1000">
                <p>
                    Trying to make your app look awesome, being practical and efficient at the same time? We bet you need a team of top front-end developers — and we know where to find them. Hit the button!</p>
                <div id='mediabutton'>
                    <button className="col-sm-3  btn btn-primary mb-5 shadow-lg mt-3" onClick={() => navigate('/hire')}>
                        HIRE YOUR MIGHTY DEV
                    </button>

                </div>
            </div>
            <div className="container mt-4">
                <div className="row">
                    <div className="sremess col-12 col-lg-6 " data-aos="fade-right" data-aos-duration="1000">
                        <img className="img-responsive" src={(require('../../../images/hire_devs/mo8.avif'))} width="100%" height="100%" alt="" />
                    </div>
                    <div className="dedicatedpart col-12 col-lg-6" data-aos="fade-left" data-aos-duration="1000">
                        <p>"Perhaps you're thinking, 'It sounds almost too good to be true.' Our response would be: 'Why doubt it?'</p>


                        <p className="mt-4"><i className="bi bi-check2-all"></i> They bring in professionals with the wrong skills and expertise. 'How could I have foreseen? Her CV mentioned 'developer,' and I assumed it was what we required.' This mirrors the level of awareness your older relatives might possess when they ask a programmer to fix their television.</p>
                        <p className="mt-4"><i className="bi bi-check2-all"></i> They engage a back-end coder when they actually need an accomplished front-end developer, and vice versa.</p>
                        <p className="mt-4"><i className="bi bi-check2-all"></i> Mismatched Expectations: When hiring someone based solely on their job title or a general term like "developer," it's akin to assuming that a programmer can effortlessly tackle any technical issue, including fixing a television. In reality, there are numerous specializations within the field of development, just as there are various aspects to consider when fixing electronic devices.</p>


                    </div>
                </div>
            </div>
            <div className="container mt-3">
                <div className="row">
                    <div className="dreamsofews col-md-12 col-lg-6" data-aos="fade-right" data-aos-duration="1000">
                        <h2 className="mb-4">Why Do You Need a Talented <span>Front-End Developer?</span></h2>
                        <p > To answer this question, let us first clarify what front-end development is. Front-end devs make site layouts created by a web designer interactive. Often referred to as client-side programming (don’t worry, it doesn’t mean you will have to code), the front end is often focused on UI/UX and users’ interaction with the website.</p>

                        <p ><i className="bi bi-check2-all"></i> Reanimating website layouts and wireframes created by web designers</p>
                        <p ><i className="bi bi-check2-all"></i> Cooperating with web designers on UI/UX, fixing user interface bugs, and enhancing interaction between the user and the platform</p>
                        <p ><i className="bi bi-check2-all"></i> Cooperating with web designers on UI/UX, fixing user interface bugs, and enhancing interaction between the user and the platform</p>



                    </div>

                    <div className="smartphoneeress col-12 col-lg-6 " data-aos="fade-left" data-aos-duration="1000">
                        <img className="img-responsive" src={(require('../../../images/hire_devs/gif6.gif'))} width="100%" height="80%" alt="" />
                    </div>

                </div>
            </div>

            <div className="container ">
                <div className="row">
                    <div className="dreamsofews col-md-12 col-lg-6 " data-aos="fade-right" data-aos-duration="1000">
                        <h2 className="mb-3"><span>Front-End Developer </span>Salary: How Much For Professionalism?</h2>
                        <p className="">Well, it depends on the career qualification and the volume of duties of a developer you’re hiring. Here are some average figures on how much front-end developers earn.</p>
                        <p>  Junior Front-End Developer: Junior developers typically have 0-2 years of experience and can earn an annual salary ranging from $45,000 to $75,000 or more, depending on the location and company.</p>

                        <p> Mid-Level Front-End Developer: With 2-5 years of experience, mid-level front-end developers can command salaries in the range of $75,000 to $110,000, again depending on location and other factors.</p>

                        <p>  Senior Front-End Developer: Senior developers, often with 5+ years of experience, can earn significantly more. Their salaries can range from $100,000 to $150,000 or even higher in some tech hubs.</p>
                    </div>
                    <div className="moness col-12 col-lg-6  " data-aos="fade-left" data-aos-duration="1000">
                        <h2>Junior <span> Front-End Developer</span> Salary</h2>
                        <img className="img-responsive " src={(require('../../../images/hire_devs/gift2.gif'))} width="100%" height="80%" alt="" />
                    </div>
                </div>
            </div>
           
            <div className="container ">
                <div className="row">
                    <div className="smed col-12 col-lg-6 " data-aos="fade-right" data-aos-duration="1000">
                        <img className="img-responsive m-auto" src={(require('../../../images/hire_devs/devs.avif'))} width="100%" height="100%" alt="" />
                    </div>
                    <div className="Monopoly col-12 col-lg-6 m-auto" data-aos="fade-left" data-aos-duration="1000">
                        <h2 className="mb-4">Before we Forget, Many People Wonder,<span> «Front-End vs. Back-End Developer:</span> What’s The Difference?»</h2>
                        <p >Back-end development (or server-side development) establishes communication between your browser and the website’s databases.</p>
                        <p>If not… well, you know how it goes. Just keep trying different combinations of username and password until the site gives up and lets you in.</p>
                        <p>This simple interaction – as well as other, much more complicated interactions – results from the back-end developers’ work.</p>
                        <p>A typical example is your login and password. You input them into a neatly designed field (hi, front-end devs!) and press Enter. Your browser sends a request to the website’s database to check whether a user with such credentials is registered. If yes, you are granted access.</p>
                    </div>
                </div>
            </div><br />

            <section id="why-us" className="why-us section-bg" >
                <div className="container-fluid" data-aos="fade-right" data-aos-duration="1000">

                    <div className="row">

                        <div className="col-lg-6 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                            <div className="content mb-4">
                                <h2>How to identify <strong>a good front-end developer</strong></h2>

                            </div>

                            <div className="accordion-list">
                                <ul>
                                    <li>
                                        <a data-bs-toggle="collapse" className="collapse" data-bs-target="#accordion-list-1"><span>01</span>Too many programming languages <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-1" className="collapse " data-bs-parent=".accordion-list">
                                            <p>
                                                Working (or, rather, claiming to) in too many programming languages. Pros usually focus on 2-3 languages, which they know perfectly.
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed"><span>02</span> Basic skill set <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                Having a vague and/or basic skill set like, «HTML, CSS, responsible, problem-solver, out-of-the-box thinker.»
                                            </p>
                                        </div>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed"><span>03</span>The excessively long CV with tech jargon<i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                The excessively long CV with tech jargon crammed in every line. Perhaps, they are good but don’t know how to write resumes. Or maybe, they are just exaggerating. Or trying to hide the lack of real work experience. True pros know employers care about the projects they successfully completed, not about how many clever words a candidate knows.</p>
                                        </div>
                                    </li>

                                    <li>
                                        <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4" className="collapsed"><span>04</span>No profile on GitHub or Stack Overflow  <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        <div id="accordion-list-4" className="collapse" data-bs-parent=".accordion-list">
                                            <p>
                                                Do you have a LinkedIn account? As a business owner, we bet you do. It’s the calling card of your company, after all. For front end developers, a profile on GitHub or Stack Overflow, where they can showcase their portfolio serves the same function. If they don’t have it, it’s a reason to think twice about even interviewing a candidate.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div className="col-lg-6 align-items-stretch order-1 order-lg-2 img mt-5" data-aos="fade-left" data-aos-duration="1000">
                            <img className="img-responsive p-4" src={(require('../../../images/hire_devs/gif1.gif'))} width="100%" height="100%" data-aos="zoom-in" data-aos-delay="150" /> &nbsp;
                        </div>
                    </div>

                </div>
            </section>
            <div className="container mt-5">
                <div className="dedicatedparted" data-aos="fade-up" data-aos-duration="1000">
                    <h2><span>Front-end developer</span> skills</h2>
                    <p className="mt-4">
                        <p > Having figured out what a front-end dev shouldn’t be like, let’s look at what they should know better.</p>
                        <p><i className="bi bi-check2-all"></i> We support and accompany our clients while they work with our devs.</p>
                        <p ><i className="bi bi-check2-all"></i> Familiarity with REST and RESTful APIs</p>
                        <p ><i className="bi bi-check2-all"></i> Understanding cross-browser compatibility, knowing how to identify and eliminate possible compatibility issues.</p>
                        <p ><i className="bi bi-check2-all"></i> Knowing how to optimize websites for mobile platforms</p>
                        <p ><i className="bi bi-check2-all"></i> Understanding the principles of UI/UX</p>
                        <p ><i className="bi bi-check2-all"></i> Working with code versioning tools such as Git, CVS, or Mercurial.</p>
                    </p>
                </div>
            </div>
            <div className="thered">
                <div className="container mt-4">
                    <div className="row">
                        <div className="theydrink col-12 col-lg-6" data-aos="fade-right" data-aos-duration="1000">
                            <h2 className="mb-3">In-House Devs</h2>

                            <p>They come to your office, they drink coffee, they get the job done. Plus, they are easy to manage when gathered in one place.</p>
                        </div>
                        <div className="theydrinkerss col-12 col-lg-6 mt-3  " data-aos="fade-left" data-aos-duration="1000">

                            <p >In-house workers (not only developers but any employees) are costly. Pay them salaries, insurances, pay taxes, pay for their vacations. Even when they don’t have much work to do.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-3">
                <div className="row">
                    <div className="mainoptions col-12 col-lg-6 " data-aos="fade-right" data-aos-duration="1000">
                        <h2 className="mb-3">Development Companies</h2>

                        <p >A one-stop-shop that can get you through the whole development cycle, from project idea to the final product.</p>
                    </div>
                    <div className="theydrinkerss col-12 col-lg-6  mt-3" data-aos="fade-left" data-aos-duration="1000">

                        <p className="mt-4 " >Prices! They can get ridiculously high. Oh, and get prepared for endless adjustments and coordination meetings when it comes to implementing changes mid-project.</p>
                    </div>
                </div>
            </div>
            <div className="container ">
                <div className="row">
                    <div className="mainoptions col-12 col-lg-6 mt-3" data-aos="fade-right" data-aos-duration="1000">
                        <h2 className="mb-3">Remote Developers</h2>

                        <p>When there is a project at hand, you can quickly find a front-end developer for hire and pay them for the job. Once it’s done, you don’t have to pay anyone (looking at you, in-house developers!)</p>
                    </div>
                    <div className="theydrinkerss col-12 col-lg-6 mt-3" data-aos="fade-left" data-aos-duration="1000">

                        <p className="mt-5">All the wonderful benefits above work in just one case: when you know where to find good remote developers.</p>
                    </div>
                </div >
            </div >

            <div className="container  mt-4">
                <div className="dedicatedparted" data-aos="fade-up" data-aos-duration="1000">
                    <h2>How Do You Hire a<span> Front-End Developer?</span></h2>
                    <p>All the wonderful benefits above work in just one case: when you know where to find good remote developers.</p>

                    <p>Upwork and other crowdsourcing marketplaces are too much of a guessing game when hiring. So, what should you do?</p>

                    <p>Look at the marketplaces for vetted developers..</p>
                </div >
            </div >





            <div className="container mt-5">
                <div className="row">
                    <div className="subserss col-12 col-lg-6" data-aos="fade-right" data-aos-duration="1000">
                        <h2 >How To Hire <span>Front-End Developers</span> Through AlanTech?</h2>
                        <p className="">From the moment you submit an application on our website, we begin building a relationship with you. Our Sales Team reviews and considers your application before reaching out via email to arrange an interview. This can take from one day to two days.
                        </p>
                        <p>During the call, we will ask questions about your startup or project requirements, needs, and deadlines in order to create an image of a suitable candidate to look for. The interview usually lasts 25-45 minutes. Once both parties agree to move forward, it is time for our Matching Team to start their work of finding suitable front-end developers that fit your criteria. This part typically takes from several hours up to a couple of days, after which we contact you again to set up another call so that you can meet your dev personally.</p>



                    </div>
                    <div className="sserdwer col-12 col-lg-6" data-aos="fade-left" data-aos-duration="1000">
                        <img className="img-responsive" src={(require('../../../images/hire_devs/gif18.gif'))} width="100%" height="100%" alt="" />
                    </div>
                </div>
            </div >
            <div className="container mt-5">
                <div className="row">
                    <div className="Anywebesserd col-12 col-lg-6" data-aos="fade-right ">
                        <img className="img-responsive" src={(require('../../../images/hire_devs/mo7.avif'))} width="90%" height="90%" alt="" />
                    </div>

                    <div className="thatprog col-12 col-lg-6" data-aos="fade-left" data-aos-duration="1000">

                        <h2 className="mt-5">Why Hire <span>Front-End Developers</span> From AlanTech?</h2>
                        <p>Our startup utilizes interactive solutions, such as the Jira application and other technologies, to divide large assignments into manageable pieces in order to enhance efficiency and productivity;</p>
                        <p >We guarantee to source the first suitable candidate within 48 hours of your request, while meeting all requirements;</p>
                        <p >AlanTech realizes how eager you are to start working with your new front-end developer and having them make your ideas a reality. We will make you to sign tens of documents unless decided otherwise;</p>
                        <p >Once payment is finalized, you will be the sole proprietor of the code in accordance with the Non-Disclosure Agreement;</p>
                    </div>

                </div>
            </div>

            <section id="faq" className="faq section-bg" data-aos="fade-up" data-aos-duration="1000">
                <div className="container" data-aos="fade-up mt-5">
                    <div className="section-title">
                        <h2 >F.A.Q</h2>
                        <h3>Frequently Asked<span> Questions</span></h3>

                    </div>

                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <ul className="faq-list">

                                <li>
                                    <div data-bs-toggle="collapse" className="collapsed question" href="#faq1">What does a front-end developer do?  <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                                        <p>

                                            For all those who’ve been exploring their chances of employment in the IT branch and wishing to know their chances of success, it is essential to know what a front-end developer does.

                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq2" className="collapsed question">What is front-end development?<i className="bi bi-chevron-down icon-show"></i><i
                                        className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq2" className="collapse" data-bs-parent=".faq-list">


                                        <p>The main focus of front-end development lies in user experience. Employing proper coding and designing skills, front-end engineers forge the app elements directly accessed by end-users — rendering the entire interface elegant, easy, fast, and efficient. That’s the way to boost user engagement and interaction.</p>

                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq3" className="collapsed question">Who earns more — a front-end or a back-end developer?<i className="bi bi-chevron-down icon-show"></i><i
                                        className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq3" className="collapse" data-bs-parent=".faq-list">


                                        <p>When deciding on a potential career path in the IT industry, it’s important to research different options and work expectations. Computer programmers code software and other applications to meet the needs of clients and employers. By learning more about working as a computer programmer and the types of hours they work, you can make important decisions about whether to obtain a computer programming job. For everyone choosing their road in the IT business, it’s necessary to consider different working conditions, variants, and prospects.</p>
                                        <p>Most often, computer engineers devote their work an average of 40-45  hours weekly, which results in eight hours daily, Monday through Friday. Most commonly, IT engineers work between the hours of 9:00 a.m. and 5:00 p.m or similar schedules common for office culture (making amendments for colleagues from different time zones and striving to keep the 2-4 hours’ overlap). Nevertheless, numerous IT pros fulfill extra work hours to meet deadlines, resolve errors, and control multiple projects at a time. Due to that, computer programmers can work an additional 5-10 hours weekly, making their total around 50 hours.</p>

                                    </div>
                                </li>

                                <li>
                                    <div data-bs-toggle="collapse" href="#faq4" className="collapsed question">How many hours do programmers usually work?<i className="bi bi-chevron-down icon-show"></i><i
                                        className="bi bi-chevron-up icon-close"></i></div>
                                    <div id="faq4" className="collapse" data-bs-parent=".faq-list">


                                        <p>Web developers, i.e., specialists of the IT world who support and build websites and other web applications, collaborate with many other specialists to create exactly what they want — and what their clients order them to construct. Generally speaking, web developers can implement various creative and business-saving ideas brewing in their client’s minds, but here’s a short list of what they frequently do:
                                            Turn a web design into a website;
                                            Collaborate with clients, designers, and UX testers;
                                            Specialize in front- or back-end development;
                                            Attempt to guarantee seamless user experienc</p>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div >
            </section >


            <Buttons />
            <Footer />
        </>
    )
}
export default Hire_frontend  