import React, { useState } from 'react';
import HirePersonalDetails from './hirePersonalDetails';
import HireRequirements from './hireRequirments';
import { useFormik } from 'formik';
import * as yup from 'yup';



function HireMainForm() {
    const [step, setStep] = useState(1);

    const hireSchema=yup.object().shape({
      fullName:yup.string()  
      .matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, 'Only letters are allowed in the name')
      .min(3, 'Minimum 3 letters'),
      email:yup.string()  .min(3, 'Minimum 3 letters')
      .matches(/^[a-zA-Z]+[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'should be in proper format'),
      mainSpecialization:yup.string().required("Select the option"),
      mainFocus:yup.string().required("Select the one option"),
      greatAt:yup.array().required("select the option"),
      involment:yup.string().required("Select the option")
  })

    const {values,handleChange,errors,touched,handleBlur}=useFormik({
      initialValues:{
      fullName:"",
      email:"",
      mainSpecialization:"",
      mainFocus:"",
      greatAt:[],
      involment:""
      },
      validationSchema:hireSchema,
    })


  
    const nextStep = () => {
      setStep(step + 1);
    };
  
    const prevStep = () => {
      setStep(step - 1);
    };
  
  
    switch (step) {
      case 1:
        return (
          <HirePersonalDetails
            nextStep={nextStep}
            handleChange={handleChange}
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
          />
        );
      case 2:
        return (
          // <></>
          <HireRequirements
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
          />
        );
   
     
      default:
        return null;
    }
  }

export default HireMainForm;