import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import profile from "../../character42/character_2-04.png"
function Developerlist() {

    const [selectedUser, setSelectedUser] = useState(null);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
    const [serialNumber, setSerialNumber] = useState(1);
    const navigate = useNavigate();
    const [openmodal, setOpen] = React.useState(false);
    const handleOpen = (user) => {
        setSelectedUser(user);

        setOpen(true);
    };

    const handleClosemodal = () => {
        setSelectedUser(null); // Clear the selectedUser state
        setOpen(false);
    };

    const [data, setData] = useState('')
    const { id } = useParams();
    const [parsedData, setParsedData] = useState([]);
    const [projects, setProject] = useState([]);
    const [education, setEducation] = useState([]);
    useEffect(() => {
        const token = localStorage.getItem('token');
        const fetchClient = async () => {
            if (!token) {
                return navigate('/');
            }
            const response = await axios.get(`${process.env.REACT_APP_URL}/developers/${id}`, {
                headers: {
                    'Client-service': process.env.REACT_APP_CLIENT_SERVICE,
                    "Auth-Key": process.env.REACT_APP_AUTH_KEY,
                    'User-id': '1',
                    'Authorization': token
                }
            })
            if (response.status === 200) {
                const fetchedData = response.data.data.developer[0];
                setData(fetchedData);
                setProject(response.data.data.project)
                setEducation(response.data.data.education)
                const newskill = fetchedData.newSkill;
                const arrayData = JSON.parse(newskill);
                setParsedData(arrayData);

            } else {
                return navigate('/');
            }
        }
        fetchClient();
    }, []);
    const backButton = () => {
        navigate('/deve')
    }

    const handleClick = () => {
        navigate(`/Pdf_view?pdfUrl=${encodeURIComponent(`https://dev.alantechnologies.in/uploads/${data.cvUpload}`)}`);
    }
    return (
        <>
            <input type="checkbox" id="menu-toggle" />
            <div className="sidebar">
                <div className="side-header">
                    <img
                        src={require("../../images/ALAN Final logo-01.png")}
                        className="pt-2"
                    />
                </div>

                <div className="side-content">
                    <div className="side-menu">
                        <ul>
                            <li>
                                <a onClick={() => navigate("/dash")} >
                                    <div className="d-flex justify-content-center">
                                        <span className="fas fa-home me-2"></span>
                                        <small>Dashboard</small>

                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={() => navigate("/Deve")} className="active" >
                                    <div className="d-flex justify-content-center">
                                        <span className="fas fa-user-alt me-2"></span>
                                        <small>Developers</small>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a onClick={() => navigate("/Clint")}>
                                    <div className="d-flex justify-content-center">
                                        <span className="fas fa-envelope me-2"></span>
                                        <small>Clients List</small>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="main-content">
                <main className="dashboard-main" id="develeper">
                    <div className="page-header">
                        <h1 className="mt-3">Developer Detail</h1>
                    </div>
                    <button className="sampleButton" onClick={backButton}><span className="fas fa-arrow-left"></span></button>


                </main>
                <div class="profile-admin-card-deve">
                    <div class="container">
                        <div class="main-body-project-card">
                            <div class="row gutters-sm">
                                <hr />
                                <div class="col-md-4 mb-3">
                                    <div class="card-project-admin card">
                                        <div class="card-body-project">
                                            <div class="d-flex flex-column align-items-center text-center">
                                                <img src={profile} alt="Admin"
                                                    class="rounded-circle" width="150" />
                                                <div class="mt-3">
                                                    <h4>{data.firstName} {data.lastName}</h4>
                                                    <p class="text-secondary mb-1">{data.Mainspecialization}</p>
                                                    <p class="text-muted font-size-sm mb-1"> {data.email}</p>
                                                    <p class="text-muted font-size-sm mb-1">  {data.country}</p>
                                                    <p class="text-muted font-size-sm mb-4">  {data.phoneNumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="card mb-3">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <h6 class="mb-0">Experiences</h6>
                                                </div>
                                                <div class="col-sm-9 text-secondary">
                                                    {data.experiences}
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <h6 class="mb-0">Hourly Rate</h6>
                                                </div>
                                                <div class="col-sm-9 text-secondary">
                                                    {data.hourlyRate}
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <h6 class="mb-0">Involvement</h6>
                                                </div>
                                                <div class="col-sm-9 text-secondary">
                                                    {data.project_engagement}
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="row mb-4" >
                                                <div class="col-sm-3">
                                                    <h6 class="mb-0">seniorityLevel</h6>
                                                </div>
                                                <div class="col-sm-9 text-secondary">
                                                    {data.seniorityLevel}
                                                </div>
                                            </div>
                                            <hr />
                                            <h3>Skills</h3>
                                            <div class="row mb-4">
                                                <div class="col-sm-9 ">
                                                    {parsedData.map((item, index) => (
                                                        <p key={index}>
                                                            Technology : {item?.skill} || Experience: {item?.experience}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                            <div class="row mb-4" >
                                            <h3>CV  details</h3>
                                                <div class="col-sm-9 text-secondary">
                                                    
                                                    {
                                                        data.cvUpload ?(
                                                            <div>
                                                                {data.cvUpload}
                                                            <button className="ms-3 cv-button" type="submit" onClick={handleClick}>View Cv</button>
                                                            </div>

                                                        ) :(
                                                            <p>No pdf Uploaded by Developer</p>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>




                
                <div className="row ms-4">
                    <h5 className="fw-bold mt-4 ms-5 mb-3">PROJECT DETAILS</h5>
                    {
                        projects.length === 0 ? (
                            <p className="mt-2 mb-2 text-center">There is no Projects Uploaded Developer</p>
                        ) : (
                            projects.map((items, index) => (
                                <div className="col-sm-12 d-flex justify-content-center mb-3" key={items.id}>
                                    <div className="card-project">
                                        <div className="card-body h-25 mx-5">
                                            <h3><span className="fw-bold">Project Name:</span> {items.project_or_company_name}</h3>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p><span className="fw-bold">Tech Stack: </span> {items.type_stack}</p>
                                                    <p><span className="fw-bold">Your Role: </span> {items.your_role}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p><span className="fw-bold">Responsibility and Acccomplishment: </span> {items.accomplishments_responsibilities}</p>
                                                    <p><span className="fw-bold">Project Link: </span><a href={items.project_link} target="_blank">
                                                        {items.project_link}
                                                    </a></p>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <p> <span className="fw-bold">Description: </span> {items.project_description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )
                    }
                </div>

                <div className="row ms-4">
                    <h5 className="fw-bold ms-5 mt-3 mb-3">EDUCATION DETAILS</h5>
                    {
                        education.length === 0 ? (
                            <p className="mt-1 mb-3 text-center">There is no Education Uploaded Developer</p>
                        ) : (
                            education.map((educationItem, index) => (
                                <div className="col-sm-12 d-flex justify-content-center mb-3" key={educationItem.id}>
                                    <div className="card-project">
                                        <div className="card-body h-25 mx-5">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p><span className="fw-bold">University Or Institute Name: </span>{educationItem.unversity_other_inst}</p>
                                                    <p><span className="fw-bold">Course: </span> {educationItem.field_coursename}</p>
                                                </div>

                                                <div className="col-6">
                                                    <p><span className="fw-bold">Degree Percentage: </span> {educationItem.your_degree_level}</p>
                                                    <p><span className="fw-bold">Year of Passing: </span>{educationItem.graduation_year}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )
                    }
                </div>
                <AdminHeader />
            </div>



        </>
    )
}

export default Developerlist