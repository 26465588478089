import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TechStack from "./Profiles/TechStack";
import Settings from "./Profiles/Settings";
import CvSamples from "./Profiles/CvSamples";
import ProjectPerformance from "./Profiles/ProjectPerformance";
import { useRef } from "react";
import ProfileContact from "./Profiles/ProfileContact";
import { Typography } from "@mui/material";

function Profile() {
  const navigate = useNavigate;
  const fileInputRef = useRef(null);
  const [value2, setValue2] = useState("5");
  let [developer, setDeveloper] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState('')

  useEffect(() => {
    const fetchDate = async () => {
      const token = localStorage.getItem("token");
      const user_id = localStorage.getItem('user_id')
      if (token == null) {
        navigate("/login");
      } else {
        const response = await axios.get(`${process.env.REACT_APP_URL}/profile`, {
          headers: {
            'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
            "Auth-Key": process.env.REACT_APP_AUTH_KEY,
            'User-id': user_id,
            'Authorization': token
          }
        })
        if (response.status == 200) {
          setDeveloper(response.data.data)
          setLoading(false);
        }
      }
    }
    fetchDate();
  }, []);


  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };
  if (loading) {
    return (<div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    )
  }

  const token = localStorage.getItem('token');
  const user_id = localStorage.getItem('user_id');


  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedImage(URL.createObjectURL(selectedFile));
      setImage(selectedFile)
    }
  };

  const handleimage = async (e) => {
    e.preventDefault();
    try {
      const formdata = new FormData();
      formdata.append('userfile', image);
      const response = await axios.post(
        "https://dev.alantechnologies.in/api/v1/Profile/profileupload",
        formdata,
        {
          headers: {
            'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
            'Auth-Key': process.env.REACT_APP_AUTH_KEY,
            'User-id': user_id,
            'Authorization': token,
          }
        }
      );
      if (response.status === 200) {
        window.location.reload();
      } else {
        console.log(response.error);
      }
    } catch (error) {
      console.error(error, "error");
    }
  };




  return (
    <div>
      <Container>
        <div className="row">
          <div className="col-lg-8 mt-3">
            <Typography variant="h4" textTransform={"capitalize"}>
              {developer.firstName} {developer.lastName}
            </Typography>

            <Typography variant="h6" sx={{ ml: 1, mt: 1 }} textTransform={"capitalize"}>
              {developer.Mainspecialization}
            </Typography>

            <Typography variant="h6" sx={{ ml: 1 }} textTransform={"capitalize"}>
              {developer.experiences} years
            </Typography>

            <Typography variant="h6" sx={{ ml: 1 }} textTransform={"capitalize"}>
              {developer.hourlyRate}$/hour
            </Typography>
          </div>
          <div className="col-lg-4">
            <div className="text-center">
              <div>
                <form onSubmit={handleimage}>
                  {selectedImage ? (
                    <img
                      alt="Selected Image"
                      src={selectedImage}
                      style={{
                        borderRadius: "50%",
                        width: "150px",
                        height: "150px",
                        cursor: 'pointer'
                      }}
                      onClick={handleImageClick}
                    />
                  ) : (
                    <img
                      src={developer.user_profile ? `https://dev.alantechnologies.in/uploads/${developer.user_profile}` : require("../../../images/For_devs/upload.jpg")}
                      alt="User Profile"
                      width="50%"
                      style={{
                        width: "140px",
                        height: "140px",
                        borderRadius: "50%",
                        cursor: 'pointer'
                      }}
                      onClick={handleImageClick}
                      />
                      
                      )}
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    />
                  
                  {
                    selectedImage ? (
                      <button type="submit" className="lab ms-2">Update</button>
                    ) : (
                      null
                    )
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Box className="mt-3" >
        <TabContext value={value2} centered >
          <Box sx={{ borderBottom: 1, borderColor: "divider", backgroundColor: "#294b86" }}>
            <Container>
              <TabList
                onChange={handleChange2}
                variant="scrollable"
                aria-label="lab API tabs example"
                style={{ backgroundColor: "#294b86" }}
              >
                <Tab style={{ color: "#fdfdfd" }} label="Tech Stack" value="5" />
                <Tab style={{ color: "#fdfdfd" }} label="CV & Samples" value="6" />
                <Tab
                  style={{ color: "#fdfdfd" }}
                  label="Project Performance"
                  value="7"
                />
                <Tab style={{ color: "#fdfdfd" }} label="Contacts" value="8" />
                <Tab style={{ color: "#fdfdfd" }} label="Settings" value="9" />
              </TabList>
            </Container>
          </Box>
          <TabPanel value="5">
            <TechStack />
          </TabPanel>

          <TabPanel value="6">
            <CvSamples />
          </TabPanel>

          <TabPanel value="7">
            <ProjectPerformance />
          </TabPanel>

          <TabPanel value="8">
            <ProfileContact />
          </TabPanel>

          <TabPanel value="9">
            <Settings />
          </TabPanel>
        </TabContext>
      </Box>

    </div>
  );
}

export default Profile;
