import React from 'react'
import Footer from "./Footer";
import Header from "./Header";
// import Design from './design';
import { useNavigate } from 'react-router-dom'

function Fordevs() {
    const navigate = useNavigate();
    return (
        <>
            <Header/>
            <div className="top">
                <div className="container" data-aos="zoom-out" data-aos-duration="1000">
                    <h1 className="pt-5">
                        Unlock The Door To <span>Greater Possibilities...</span>
                    </h1>
                    <br />
                    <p className ="text-black">
                        Clients you'll adore, projects to boast about, money born from
                        liberty.
                    </p>
                    <span style={{ display: 'flex' }} className='pb-3'>
                        <button type="button" className="btn btn-primary" onClick={() => navigate('/apply_devs')}>Apply as a devs</button>
                        <h5 className="ms-5 me-5 mt-3 text-black">OR</h5>
                        <button type="button" className="btn btn-primary" onClick={() => navigate('/hire')}>Hire Alan dev</button>
                    </span>
                </div>
            </div>
            <section id="featured-services" className="featured-services-icons">
                <div className="container" data-aos="fade-up" data-aos-duration='1000'>
                    <h2 className="text-center mt-4 mb-4">WHY WORK<span> WITH US</span></h2>
                    <div className="row">
                        <div className="col-md-4 col-lg-4 col-sm-6 d-flex align-items-stretch mb-4 mb-lg-0">
                            <div className="icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <div className="icon">

                                    <img src={(require("../images/For_devs/clock.png"))} height="40%" width="40%" alt="" />
                                </div>

                                <p className="description fw-bold text-center">Honest hourly rates</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-4 col-sm-6 d-flex align-items-stretch mb-4 mb-lg-0">
                            <div className="icon-box aos-init aos-animate" data-aos="fade-up"  data-aos-duration='1000'>
                                <div className="icon">
                                    <img src={(require('../images/For_devs/social-justice.png'))} height="40%" width="40%" alt="" />
                                </div>

                                <p className="description fw-bold text-center">Justice for all</p>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-4 col-sm-6 d-flex align-items-stretch mb-4 mb-lg-0">
                            <div className="icon-box aos-init aos-animate" data-aos="fade-up"  data-aos-duration='1000'>
                                <div className="icon">
                                    <img src={(require('../images/For_devs/programming.png'))} height="40%" width="40%" alt="" />
                                </div>

                                <p className="description fw-bold text-center">
                                    No sales, Just coding
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    {/* <Design className="mt-5"/> */}
           



            <section id="about" className="section-bg">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-lg-6" data-aos="fade-right" data-aos-duration='1000'>
                            <h3 className="pt-5 pb-3">
                                SOME CLAIM ARE HEAVENLY MATCHES,<span><br />
                                    BUT WE KNOW THEY’RE MADE IN CHENNAI</span>
                            </h3>
                            <p>
                            "At Our Awesome Team, we believe in the power of collaboration. When you join us, you'll have the incredible opportunity to meet the talented individuals who make up our exceptional team. You'll get to know them better during the application process, as we value transparency and openness in our interactions.
                            Our dedicated acquisition specialists will guide you through every stage of the testing process.
                            </p>
                        </div>
                        <div className="col-lg-6 pt-4 mt-2 ps-4 pt-lg-0 content d-flex flex-column justify-content-center"
                            data-aos="fade-left">
                            <img src={(require('../images/For_devs/main.jpg'))} className="img-responsive" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section id="team" className="team section-bg">
                <div className="container">
                    <h3 className="pb-3 text-center">
                        Don't Worry; Our Vetting Process is 
                        <span> Easy and Straightforward.</span>
                    </h3>

                    <div className="row">
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up"
                           data-aos-duration='1000'>
                            <div className="member">
                                <div className="member-img">
                                    <img src={(require('../images/For_devs/gift2.gif'))} className="img-fluid mt-4" alt="" />
                                </div>
                                <div className="member-info">
                                    <h4 className="text-center">
                                        Start by Registering. We’ll review your Application & get in
                                        Touch
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up"
                           data-aos-duration='1000'>
                            <div className="member">
                                <div className="member-img">
                                    <img src={(require('../images/For_devs/gift3.gif'))} className="img-fluid mt-4" alt="" />
                                </div>
                                <div className="member-info">
                                    <h4 className="text-center">Next step — 15-Minute English Test</h4>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up"
                          data-aos-duration='1000'>
                            <div className="member">
                                <div className="member-img">
                                    <img src={(require('../images/For_devs/gift4.gif'))} className="img-fluid mt-5" alt="" />
                                </div>
                                <div className="member-info">
                                    <h4 className="text-center">
                                        Then, a Live Interview so we can get to know you Better
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up"
                           data-aos-duration='1000'>
                            <div className="member">
                                <div className="member-img">
                                    <img src={(require('../images/For_devs/gif5.gif'))} className="img-fluid mt-4" alt="" />
                                </div>
                                <div className="member-info mt-4">
                                    <h4 className="text-center">
                                        The final reckoning? tech interview by one of AlanTech devs
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p data-aos="fade-up"data-aos-duration='1000'
                            >
                        Joining Alan is easy, but not too easy. this is true for everyone, not
                        just developers. our clients have to match the standards too.
                    </p>
                </div>
            </section>
            <section id="abouts" className=" pt-0 pb-0">
                <div className="container">
                    <h3 className="pb-4 mt-4 text-center" data-aos="fade-up"data-aos-duration='1000'
                            >
                        IMAGINE HOW COOL IT’S GOING <span>TO BE TO WORK WITH RAJ:</span>
                    </h3>
                    <div className="row">
                        <div className="col-lg-6 col-12"  data-aos="fade-right"data-aos-duration='1000'
                            >
                            <img src={(require('../images/For_devs/explaining-project-points.jpg'))}  height="300" />
                        </div>
                        <div className="col-lg-6  pt-5 mt-5 pt-lg-0 content" data-aos="fade-up">
                            <p>
                                RAJ founded his startup 2 years ago after quitting his job at
                                    faang and built his mvp from scratch with his own  hands.</p>
                                    <p>As our company experiences rapid growth, we recognize the need for additional support to ensure that we continue to deliver new features and innovations on time. We are excited about this phase of expansion and are looking for a highly skilled and motivated individual to join our team.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="pricing" className="pricing pt-1">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mt-5 " data-aos="fade-up" data-aos-duration='1000'>
                            <div className="box">
                                <h2 className="">You won’t have to dumb down</h2>
                                <p className="mb-4">
                                    When you talk to RAJ and work directly. No one in between.
                                </p>
                                <img className="mt-2"src={(require('../images/For_devs/StockSnap.jpg'))} height="300" />
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-6 mt-3 mt-md-0" data-aos="fade-up" data-aos-duration='1000'>
                            <div className="box featured mt-5">
                                <h2 className=" ">
                                    Work with clients you won’t <span>Need to babysit</span>
                                </h2>
                                <img src={(require('../images/For_devs/mann.jpg'))} height="300" className="mt-4" alt="hire" />

                            </div>
                        </div>
                    </div>
                    <div className='text-center mt-3 d-flex justify-content-center'>
                    <button className="btn btn-primary  d-flex justify-content-center" onClick={() => navigate('/hire')}>Apply as a team</button>
                    </div>
                </div>
            </section>

            <section id="about" className="container">
                <h2 className='mt-3'>
                    Are you a sales manager? No, you're a developer—
                    <span>Not a salesperson </span>
                </h2>
                <div className="row">
                    <div className="col-lg-6 col-md-6" data-aos="fade-up"data-aos-duration='1000'>
                        <div className="box pt-5">
                            <p className="mt-3">
                            At our company, we understand that your expertise lies in your craft, not in dealing with the mundane or time-consuming tasks that can bog you down. That's why we've got your back when it comes to handling all the tedious and administrative aspects of the sales process.
                                </p>
                               <p> forget about the
                                sales. forget about those boring, endless, soul-crushing
                                negotiations. they don’t exist for you in our world.
                            </p>
                        </div>
                    </div>
                    
                    <div className="col-lg-6 col-md-6 mt-md-0" data-aos="fade-left"data-aos-duration='1000'>
                        <div className="box featured pt-3 ps-4">
                            <img src={(require('../images/For_devs/office.jpg'))} height="300" alt="hire" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="container mt-3" id="featured-services">
                <h2 className="text-center mt-3">YOU STILL <span>GET TO CHOOSE</span></h2>
                <div className="row">
                    <div className="col-lg-6 col-md-6" data-aos="fade-right" data-aos-duration='1000'>
                        <div className="box pt-5">
                            <img src={(require('../images/For_devs/female.jpg'))} height="300" alt="hire" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-duration='1000'>
                        <div className="box featured pt-5 mt-3">
                            <p>
                                We'll present you with projects that align with your technical
                                skills, experience, and schedule. However, you'll always have the
                                final say
                            </p>
                            <ul>
                                <li>If the task doesn't appear challenging enough</li>
                                <li>If you didn't connect with the client</li>
                                <li>If the workload exceeds your capacity at the moment</li>
                                <li>If you have creative ideas to enhance the project</li>
                                <li>If you're experiencing personal challenges</li>

                            </ul>
                            <p>No worries, we'll find a better-suited one for you!</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container mt-3 pt-1 text-center" id="about" data-aos="fade-up"data-aos-duration='1000'>
                <h2 className='mt-3 mb-3'>SOUNDS ABOUT RIGHT?<span> DON’T BE SHY</span></h2>
                <p>
                    Be a part of something greater. Join our unique community of exceptional
                    developers and embrace the freedom of being yourself at all times, just
                    like these individuals.
                </p>
                <div className="row">
                    <div className="col-lg-6 col-md-6" data-aos="fade-right" data-aos-duration='1000'>
                        <div className="box pt-5">
                            <img src={(require('../images/For_devs/forpage1.jpg'))} className="pb-3" height="300" alt="hire" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 mt-4 mt-md-0 " data-aos="fade-left" data-aos-duration='1000'>
                        <div className="box featured pt-5">
                            <img src={(require('../images/For_devs/forpage2.jpg'))} height="300" className="pb-3" alt="hire" />
                        </div>
                    </div>
                </div>
                <button type="button" className="btn btn-primary" onClick={() => navigate('/hire')}>Apply now</button>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6" data-aos="fade-up"data-aos-duration='1000'>
                            <div className="box">
                                <h2 className="mt-5 pt-5">
                                    Impressive, but do you <br /> collaboratewith teams?<span>
                                        We do!<br /></span>
                                </h2>
                                
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 mt-md-0" data-aos="fade-left"data-aos-duration='1000'>
                            <div className="box featured">
                                <img src={(require('../images/For_devs/prommaer 7.gif'))} height="300" alt="hire" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="container pb-2">
    <h3 data-aos="fade-up"data-aos-duration='1000'>
      Don’t get lost in the sea of opportunities, don’t get eaten by sharks —
      we’ll lead you by hand!
    </h3>
    <p data-aos="fade-up"data-aos-duration='1000'>
      In the realm of remote programming jobs, AlanTech can be your best ally.
      Imagine focusing solely on what you love and leaving the dreaded tasks
      to professionals who excel at them. Let our sales team handle the
      selling, negotiators take care of negotiations, and matching team find
      the perfect client fit for you. Your role? Just indulge in your passion
      for coding! Isn't that appealing?
    </p>
    <br />
    <h3 data-aos="fade-up"data-aos-duration='1000'>Discover the bright side of freelance work!</h3>
    <p data-aos="fade-up"data-aos-duration='1000'>
      We offer remote jobs for web developers who value their time, freeing
      you from freelancer nightmares. No more worries about client ghosting,
      time trackers, or payment issues. Get your work done, and you get paid -
      it's as simple as that. Easy-peasy-Alan-squeezy!
    </p>
    <br />
    <h3 data-aos="fade-up"data-aos-duration='1000'>Want to give it a try? No problem, start part-time!</h3>
    <p data-aos="fade-up"data-aos-duration='1000'>
      We provide part-time programming jobs for those wanting to explore
      freelance work for developers. Part-time jobs often mark the beginning
      of one's freelancing journey - a safe way to test the waters and see how
      it suits you. It could be your side hustle. But based on our experience,
      you won't want to go back.
    </p>
    <br />
    
    <h3 data-aos="fade-up"data-aos-duration='1000'>Dislike the office? We couldn't agree more!</h3>
    <p data-aos="fade-up"data-aos-duration='1000'>
      Envision yourself as that work-from-home software developer, enjoying
      space, time, and freedom. With AlanTech, you can obtain those dreamy
      remote software engineering jobs. Escape the corporate snare and embrace
      the lifestyle you desire.
    </p>
    <br />
    <h3 data-aos="fade-up"data-aos-duration='1000'>Got questions? We have juicy answers!</h3>
    <p data-aos="fade-up"data-aos-duration='1000'>
      If you still have doubts about remote work and freelancing, contact us,
      and we'll gladly address all your concerns. Finding software developer
      jobs may be challenging for some, but we are experts at sourcing the
      best projects at competitive prices. Here's to a successful partnership
      in the future! Click the button below and change your life."
    </p>
  </section>

  <Footer />
        </>
    )
}

export default Fordevs;