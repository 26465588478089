import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import jsonData from "../../../Apply_as_dev/data.json";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import { Container, TextField, Button, Grid, Typography } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
const AddProjectSchema = Yup.object().shape({
  Project_Or_Company_Name: Yup.string().required("Enter the Project Or Company Name"),
  Project_Link: Yup.string().required("Enter the Project Link"),
  Start_Date: Yup.date().required("Select the Start Date"), // Updated validation for date
  End_Date: Yup.date().required("Select the End Date"), // Updated validation for date
  type_stack: Yup.string().required("Enter the type stack"),
  Your_Role: Yup.string().required("Enter the Your Role"),
  accomplishments_responsibilities: Yup.string().required(
    "Enter the accomplishments & responsibilities"
  ),
  Project_Description: Yup.string().required("Enter the Project Description"),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AddProject() {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        Project_Or_Company_Name: "",
        Project_Link: "",
        Start_Date: "",
        End_Date: "",
        type_stack: "",
        Your_Role: "",
        accomplishments_responsibilities: "",
        Project_Description: "",
      },
      validationSchema: AddProjectSchema,
    });
  const [selectedValues, setSelectedValues] = useState([]);

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const theme = useTheme();
  const [stackName, setStackName] = useState([]);
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    values.type_stack = stackName
    values.Start_Date = startDate
    values.End_Date = endDate

  }, [stackName, endDate, startDate])

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setStackName(typeof value === "string" ? value.split(",") : value);
  };

  const [developer, setDeveloper] = useState("");


  function SweetAlert() {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Add Project Updated",
      confirmButtonText: "OK",
      timer: 8000,
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate("/application");
      }
    });
  }

  const token = localStorage.getItem('token')
  const user_id = localStorage.getItem('user_id');
  const navigate = useNavigate();
  useEffect(() => {
    const fetchprofile = async () => {
      if (token == null) {
        navigate("/login");
      } else {
        const response = await axios.get(`${process.env.REACT_APP_URL}/profile`, {
          headers: {
            'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
            "Auth-Key": process.env.REACT_APP_AUTH_KEY,
            'User-id': user_id,
            'Authorization': token
          }
        })
        if (response.status == 200) {
          setDeveloper(response.data.data)
          console.log(response.data.data, "john williamss")
        }

      }
    }
    fetchprofile()
  }, []);



  async function addProject() {
    setLoading(true);
    const formData = new FormData();
    formData.append('project_or_company_name', values.Project_Or_Company_Name);
    formData.append('project_link', values.Project_Link);
    formData.append('project_description', values.Project_Description);
    formData.append('your_role', values.Your_Role);
    formData.append('type_stack', values.type_stack);
    formData.append('accomplishments_responsibilities', values.accomplishments_responsibilities);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/profile/project`, formData, {
        headers: {
          'Client-Service': process.env.REACT_APP_CLIENT_SERVICE,
          "Auth-Key": process.env.REACT_APP_AUTH_KEY,
          'User-id': user_id,
          'Authorization': token
        }
      }
      );
      if (response.status == 200) {
        setLoading(false);
        const user = JSON.stringify(developer);
        localStorage.setItem("user", user);
        SweetAlert();
      }
      else {
        setLoading(false)
        alert('sorry, something went wrong please try after sometime')
      }
    } catch (error) {
      console.log(error);
    }
  }

  const button = {
    marginTop: { lg: 5 },
    backgroundColor: "#294b86",
  };

  const box = {
    width: {
      lg: 1000,
      marginTop: 100,
    },
    height: {
      lg: "100vh",
      md: "100vh",
      xs: "600px",
    },
  };
  const names = jsonData.names;

  const handleDelete = (e, value) => {
    e.preventDefault();
    setStackName(stackName.filter((name) => name !== value));
  };
  if (loading) {
    return (<div class="spinner2">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    )
  }

  return (
    <>
      <Box>
        <Container sx={box}>
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom>
              Add Project
            </Typography>
            <Typography variant="h6" gutterBottom>
              "This isn't your typical lengthy form. It's your chance to
              showcase your excellence in recent projects and impress potential
              clients with your resume."
            </Typography>
            <Typography variant="h6" gutterBottom>
              "Foundational information to kickstart the process."
            </Typography>
          </Grid>

          <Grid item xs={12} lg={6}>
            <TextField
              type="text"
              fullWidth
              margin="normal"
              name="Project_Or_Company_Name"
              label="Project  Name"
              value={values.Project_Or_Company_Name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                !!errors.Project_Or_Company_Name &&
                touched.Project_Or_Company_Name
              }
              helperText={
                touched.Project_Or_Company_Name &&
                  !!errors.Project_Or_Company_Name
                  ? errors.Project_Or_Company_Name
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              margin="normal"
              type="text"
              fullWidth
              label="Project Link"
              name="Project_Link"
              value={values.Project_Link}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.Project_Link && touched.Project_Link}
              helperText={
                touched.Project_Link && !!errors.Project_Link
                  ? errors.Project_Link
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12} lg={12} >
            <FormControl
              margin="normal"
              fullWidth>
              <InputLabel id="demo-multiple-chip-label">Technologies you used in the process -Type Stack</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                margin="normal"
                value={stackName}
                onChange={handleChange1}
                input={
                  <OutlinedInput id="select-multiple-chip" label="Technologies you used in the process -Type Stack" />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        clickable
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(e) => e.stopPropagation()}
                          />
                        }
                        onDelete={(e) => handleDelete(e, value)}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, stackName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} lg={12}>
            <Typography variant="h6" gutterBottom>
              "Provide project details: product, purpose, target audience, and
              key features."
            </Typography>
          </Grid> */}
          <Grid item xs={12} lg={6}>
            <TextField
              type="text"
              fullWidth
              margin="normal"
              name="Project_Description"
              label="Project Description"
              value={values.Project_Description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                !!errors.Project_Description && touched.Project_Description
              }
              helperText={
                touched.Project_Description && !!errors.Project_Description
                  ? errors.Project_Description
                  : ""
              }
            />
          </Grid>
          {/* <Grid item xs={12} lg={12}>
            <Typography variant="h6" gutterBottom>
              What was your official role?
            </Typography>
          </Grid> */}
          <Grid container spacing={1}
          >
            <Grid item xs={12} lg={6}>
              <TextField
                type="text"
                fullWidth
                margin="normal"
                name="Your_Role"
                label="Your Role(Tech lead,Senior front-end developer)"
                value={values.Your_Role}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.Your_Role && touched.Your_Role}
                helperText={
                  touched.Your_Role && !!errors.Your_Role ? errors.Your_Role : ""
                }
              />
            </Grid>
            {/* <Grid item xs={12} lg={12}>
            <Typography variant="h6" gutterBottom>
              "Highlight your strengths and responsibilities without excessive
              modesty."
            </Typography>
          </Grid> */}
            <Grid item xs={12} lg={6}>
              <TextField
                type="text"
                fullWidth
                margin="normal"
                name="accomplishments_responsibilities"
                label="Highlight your strengths and responsibilities "
                value={values.accomplishments_responsibilities}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  !!errors.accomplishments_responsibilities &&
                  touched.accomplishments_responsibilities
                }
                helperText={
                  touched.accomplishments_responsibilities &&
                    !!errors.accomplishments_responsibilities
                    ? errors.accomplishments_responsibilities
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={button} margin="normal">
            <Button
              fullWidth
              sx={{
                py: 2,
                bgcolor: " #294b86 !important",
                color: "white",
              }}
              onClick={addProject}
            >
              Save Project
            </Button>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default AddProject;
