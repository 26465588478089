import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import { useNavigate } from 'react-router-dom'
import Buttons from '../../buttons'

function Architects_js() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div id="hirearchitect" className="d-flex align-items-center">
        <div className="container" data-aos="zoom-out" data-aos-duration="1000">
          <h1 className="pt-5 col-sm-6" style={{ color: "white" }}>
            HIRE <span>SOFTWARE ARCHITECTS</span> FOR YOUR DREAM TEAM
          </h1>
          <div id='mediabutton'>
            <button className="col-sm-3  btn btn-primary mb-5 shadow-lg mt-3" onClick={() => navigate('/hire')}>
              Match me with a dev
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row" >
          <div className="col-md-12 col-lg-6" data-aos="fade-right" data-aos-duration="1000">
            <h2 className="mt-5">
              Who is a <span>Software Architect</span>?
            </h2>
            <div className="mt-3">
              <p>
                <span className="pe-3 text-primary"> ✔  </span>Its challenges could emerge due
                to the variety of programming languages in use, tangled
                technical standards, unintuitive architectural designs, and
                outdated user interface strategies.{" "}
              </p>
              <p>
                <span className="pe-3 text-primary"> ✔  </span> Remember that JIRA ticket,
                which at first glance seemed simple, but as you started
                integrating the requested feature with the rest of the system,
                everything went afoul and started working against you? Poor
                software architecture was likely the main source of your
                problems.{" "}
              </p>
              <p>
                <span className="pe-3 text-primary"> ✔  </span>All software components are
                brought together into a functional program or application via
                the glue known as software architecture. Software architecture
                may be defined as creating and implementing meaningful software.
              </p>
              <p>
                <span className="pe-3 text-primary"> ✔  </span>It is a versatile tool with
                several different working instruments. The standard job
                description for a software architect covers a wide range of
                topics. It emphasizes technical abilities (hard skills) and
                communication/time management/remote work experience (soft
                skills).
              </p>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 mt-5" data-aos="fade-left" data-aos-duration="1000">
            <img
              src={require("../../../images/hire_devs/devo.jpeg")}
              width="100%"
              height="100%"
              alt=""
            />
          </div>
        </div>
        <div
          className="col-lg-12 mt-5"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <h2>What Does a Software Architect Do? </h2>
          <h4 className="mt-3">
            To make the right, reality-tested decisions, software architects
            must:
          </h4>
          <p className="mt-3">
            <span className="pe-3 text-primary"> ✔  </span>Ask for regular (preferably daily)
            advice from clients, product and project managers.
          </p>
          <p className="mt-3">
            <span className="pe-3 text-primary"> ✔  </span>Bravely venture on key software
            design and development decisions
          </p>
          <p className="mt-3">
            <span className="pe-3 text-primary"> ✔  </span>Define and describe the appropriate
            coding standards, languages, platforms, and software tools for the
            products in the making.
          </p>
          <p className="mt-3">
            <span className="pe-3 text-primary"> ✔  </span>Regularly control the code to
            ensure its adherence to the corporate quality standards.
          </p>
          <p className="mt-3">
            <span className="pe-3 text-primary"> ✔  </span>Closely collaborate with the
            development team and assist them in reaching the project’s
            objectives.
          </p>
        </div>
        <div className="row" >
          <h2 className="mt-3">Why Hire a Software Architect? </h2>
          <h4>The main bonuses a qualified software architect will bring:</h4>
          <div className="col-md-12 col-lg-6 mt-2" data-aos="fade-right" data-aos-duration="1000">
            <img
              src={require("../../../images/hire_devs/gif4.gif")}
              width="100%"
              height="80%"
              alt=""
            />
          </div>
          <div className="col-md-12 col-lg-6" data-aos="fade-left" data-aos-duration="1000">
            <div className="mt-5">
              <p>
                <span className="pe-3 text-primary"> ✔  </span>Streamlined and expedited
                application processes.
              </p>
              <p>
                <span className="pe-3 text-primary"> ✔  </span>Simple adding of new product
                features
              </p>
              <p>
                <span className="pe-3 text-primary"> ✔  </span>Developers’ empowerment
              </p>
              <p>
                <span className="pe-3 text-primary"> ✔  </span>Slowing down code degeneration
              </p>
              <p>
                <span className="pe-3 text-primary"> ✔  </span>Easier and quicker multimodule
                integration.
              </p>
            </div>
          </div>
        </div>
        <div className="neerer">
        <div className="col-lg-12" data-aos="fade-down" data-aos-duration="1000">
          <h2>When Do You Need a Software Architect? </h2>
          <h4 className="mt-3">
            There is definitely something wrong with the software architecture
            when:
          </h4>
          <p className="mt-3">
            <span className="pe-3 text-primary"> ✔  </span>A piece of software is hard to test
            for the development team.
          </p>
          <p className="mt-3">
            <span className="pe-3 text-primary"> ✔  </span>It’s hard to integrate new modules
            into the system.
          </p>
          <p className="mt-3">
            <span className="pe-3 text-primary"> ✔  </span>Scaling appears to be troublesome
            and cumbersome.
          </p>
        </div>
        </div>
        <div className="row" data-aos="fade-right" data-aos-duration="1000">
          <div className="col-md-12 col-lg-6">
            <h2 className="mt-5">
              Why Should You Hire Software Architects With AlanTech?
            </h2>
            <div className="mt-3">
              <p>
                <span className="pe-3 text-primary"> ✔  </span>Engaging AlanTech for your
                hiring needs opens the door to a multitude of compelling
                benefits. Our unique focus on remote work and hiring sets us
                apart, as our entire team operates in a remote capacity.
              </p>
              <p>
                <span className="pe-3 text-primary"> ✔  </span> This grants us an unparalleled
                understanding of the intricacies and nuances of remote work
                dynamics, enabling us to cater to your specific requirements
                like no one else.
              </p>
              <p>
                <span className="pe-3 text-primary"> ✔  </span>Having embarked on this journey
                in 2015, we've forged a strong track record of successfully
                partnering with Western startups. Our specialized approach
                enables us to offer highly skilled and proficient developers and
                engineers from Europe and Latin America.
              </p>
              <p>
                <span className="pe-3 text-primary"> ✔  </span>By harnessing our expertise,
                you gain access to a pool of talent that is not only reasonably
                rated but also aligned with your needs for remote collaboration.{" "}
              </p>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 mt-5" data-aos="fade-left" data-aos-duration="1000">
            <img
              src={require("../../../images/hire_devs/gif3.gif")}
              width="100%"
              height="100%"
              alt=""
            />
          </div>
        </div>
        <div className="col-lg-12" data-aos="fade-down" data-aos-duration="1000">
          <h2 className="mt-3">What Does Our Hiring Process Look Like? </h2>
          <p className="mt-3">
            <span className="pe-3 text-primary"> ✔  </span>Swift as an arrow: After you leave
            contacts, our Sales manager calls you and finds out about your needs
            and requirements.{" "}
          </p>
          <p className="mt-3">
            <span className="pe-3 text-primary"> ✔  </span>Transparent: You can arrange a job
            interview with the candidates (including a live coding session). If
            everything is all right, the process continues, and your
            collaboration can start in 7-14 days. .
          </p>
          <p className="mt-3">
            <span className="pe-3 text-primary"> ✔  </span>Easy-paid: Our payment procedure is
            very straightforward: you pay the sum we announce at the beginning —
            no hidden taxes or commissions will emerge later.
          </p>
          <p className="mt-3">
            <span className="pe-3 text-primary"> ✔  </span>Problem-proof: If a developer you
            choose turns sour, our Customer Success department will swiftly
            substitute them for a new one in no time.
          </p>
          <p className="mt-3">
            <span className="pe-3 text-primary"> ✔  </span>Open to questions: Any questions?
            Contact CS specialists from Alantech; they are caring and helpful
            (just like your mom, but slightly better).
          </p>
        </div>
      </div>
      <section id="faq" className="faq section-bg mt-3">
        <div className="container" data-aos="fade-up" data-aos-duration='1000'>
          <div className="section-title">
            <h2>F.A.Q</h2>
            <h3>
              Frequently Asked<span> Questions</span>
            </h3>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-10">
              <ul className="faq-list">
                <li>
                  <div
                    data-bs-toggle="collapse"
                    className="collapsed question"
                    href="#faq1"
                  >
                    How much does it cost to hire software architects?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq1"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      The cost to hire a software architect can vary greatly
                      depending on a number of factors, such as location,
                      experience, and industry.
                    </p>

                    <p>
                      In the United States, the average salary for a software
                      architect is around $120,000 per year, according to
                      Glassdoor. However, this can range from around $90,000 to
                      over $150,000 per year, depending on the individual’s
                      experience and qualifications.
                    </p>
                  </div>
                </li>

                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq2"
                    className="collapsed question"
                  >
                    How can you hire a top software architect?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq2"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      <span className="pe-3"> 1. </span>
                      Define the job requirements: Identify the specific skills,
                      experience, and qualifications required for the position.
                      This will help you to create a clear job description and
                      identify the right candidates.
                    </p>
                    <p>
                      <span className="pe-3"> 2.</span>Post the job on relevant
                      job boards and professional networking sites: This will
                      help you to reach a large pool of potential candidates.
                    </p>
                    <p>
                      <span className="pe-3"> 3. </span>Look for candidates with
                      relevant experience: Look for candidates who have
                      experience working on similar projects and have a proven
                      track record of delivering high-quality software.
                    </p>
                  </div>
                </li>

                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq3"
                    className="collapsed question"
                  >
                    Do software architects write code?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq3"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      Yes, they almost always write some code since they amend
                      and enhance the website or app condition with the help of
                      transparent and efficient code.
                    </p>
                  </div>
                </li>

                <li>
                  <div
                    data-bs-toggle="collapse"
                    href="#faq4"
                    className="collapsed question"
                  >
                    Is the IT architect the same as the software engineer?
                    <i className="bi bi-chevron-down icon-show"></i>
                    <i className="bi bi-chevron-up icon-close"></i>
                  </div>
                  <div
                    id="faq4"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                      <span className="pe-3"> 1. </span>
                      An IT architect and a software engineer are not the same,
                      although they both play essential roles in the software
                      development process.
                    </p>
                    <p>
                      <span className="pe-3"> 2. </span>An IT architect is a
                      senior-level professional responsible for designing and
                      overseeing the overall technology infrastructure of an
                      organization. This includes the hardware, software,
                      networks, and data storage systems that support the
                      organization’s operations. IT architects are also
                      responsible for ensuring that the technology
                      infrastructure aligns with the organization’s business
                      goals and objectives.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Buttons />
      <Footer />
    </>
  );
}
export default Architects_js;
